import axios, { AxiosResponse } from 'axios';
import * as utils from './apiUtils';
import {
  TagIndexParams,
  TagCreateParams,
} from 'src/models/apis/tag/tagRequest';
import { TagIndexResponse, Tag } from 'src/models/apis/tag/tagResponse';
import {
  AdminTagCreateParams,
  AdminTagUpdateParams,
  AdminTagNameIdentifyParams,
} from 'src/models/apis/tag/adminTagRequest';
import { AdminTagIndexResponse, AdminTag } from 'src/models/apis/tag/adminTagResponse';

export default {
  index(): Promise<AxiosResponse<TagIndexResponse>> {
    return axios.get(utils.getApiUrl('/tags'));
  },
  create(params: TagCreateParams): Promise<AxiosResponse<Tag>> {
    return axios.post(utils.getApiUrl('/tags'), params);
  },
  update(id: number, params: TagCreateParams): Promise<AxiosResponse<Tag>> {
    return axios.put(utils.getApiUrl(`/tags/${id}`), params || {});
  },
  destroy(id: number): Promise<AxiosResponse<Tag>> {
    return axios.delete(utils.getApiUrl(`/tags/${id}`));
  },
  adminIndex(params: TagIndexParams): Promise<AxiosResponse<AdminTagIndexResponse>> {
    return axios.get(utils.getApiUrl('/admin/tags'), {
      params: params || {},
    });
  },
  adminCreate(params: AdminTagCreateParams): Promise<AxiosResponse<AdminTag>> {
    return axios.post(utils.getApiUrl('/admin/tags'), params);
  },
  adminUpdate(id: number, params: AdminTagUpdateParams): Promise<AxiosResponse<AdminTag>> {
    return axios.put(utils.getApiUrl(`/admin/tags/${id}`), params || {});
  },
  adminDestroy(id: number): Promise<AxiosResponse<AdminTag>> {
    return axios.delete(utils.getApiUrl(`/admin/tags/${id}`));
  },
  adminNameIdentify(params: AdminTagNameIdentifyParams): Promise<AxiosResponse<AdminTag>> {
    return axios.post(utils.getApiUrl('/admin/tags/name_identify'), params);
  },
};
