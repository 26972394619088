


import group2Api from '@/apis/group2';
import group3Api from '@/apis/group3';
import tagApi from '@/apis/tag';
import {
  TAG_TYPE1_SYSTEM,
  TAG_TYPE1_USER,
} from '@/consts/tag';
import {
  defineComponent,
  toRefs,
  computed,
  onMounted,
  reactive,
} from '@vue/composition-api';
import { useStore } from '@/hooks/useStore';
import { AdminTag, AdminTagIndex } from '@/models/apis/tag/adminTagResponse';
import { AdminTagCreateParams } from '@/models/apis/tag/adminTagRequest';
import { AdminGroup2 } from 'src/models/apis/group2/adminGroup2Response';
import { AdminGroup3 } from 'src/models/apis/group3/adminGroup3Response';
import { AdminListTableField, CustomButton } from 'src/components/lib/AdminListTable.vue';
import { getErrorMessages } from '@/lib/errMsgHelper';
import { AxiosError } from 'axios';

type AdminTagEdit = AdminTagCreateParams & {
  tag_type1?: string;
  tag_type2?: string;
  name_identify_to_tag_id?: number | null;
};
type DisplayData = AdminTag & {
  tagTypeLabel: string;
};
interface SearchParams {
  tag_name: string | null;
  comment_type: string | null;
  user_display_name: string | null;
  dt_from: string | null;
  dt_to: string | null;
  tag_movie_num_from: string | null;
  tag_movie_num_to: string | null;
}

interface AdminTagState {
  showEditModal: boolean;
  editMode: 'create' | 'edit';
  showNameIdentifyModal: boolean;
  showDestroyModal: boolean;
  showErrorModal: boolean;
  tagsOrig: AdminTagIndex[];
  filteredTags: AdminTag[];
  groups2: AdminGroup2[];
  groups3: AdminGroup3[];
  search: SearchParams;
  editting: AdminTagEdit;
  errorMsgs: string[];
  httpErrorMsgs: string[];
  showEditDetail: boolean;
}

export default defineComponent({
  name: 'admin-tags',
  setup() {
    const initSearchState = (): SearchParams => {
      return {
        tag_name: null,
        comment_type: null,
        user_display_name: null,
        dt_from: null,
        dt_to: null,
        tag_movie_num_from: null,
        tag_movie_num_to: null,
      };
    };
    const initEdittingState = (): AdminTagEdit => {
      return {
        id: 0,
        tag_name: '',
        group2_id: 0,
        group3_id: 0,
        tag_type1: '',
        tag_type2: '',
        name_identify_to_tag_id: null,
      };
    };
    const state = reactive<AdminTagState>({
      showEditModal: false,
      editMode: 'edit',
      showNameIdentifyModal: false,
      showDestroyModal: false,
      showErrorModal: false,
      tagsOrig: [],
      filteredTags: [],
      groups2: [],
      groups3: [],
      search: initSearchState(),
      editting: initEdittingState(),
      errorMsgs: [],
      httpErrorMsgs: [],
      showEditDetail: false,
    });
    const store = useStore();
    const userState = store.state.user;
    const isTagNameTooLong = computed<boolean>(() => {
      return halfWidthLetterCount(state.editting.tag_name) > 32;
    });
    const tagNameTooLongMsg = '動画タグは全角16文字以内で入力してください';
    const validateEditting = computed<string[]>(() => {
      let errorMsgs = [];
      if (state.editting.tag_name.length <= 0) {
        errorMsgs.push('動画タグは必須です');
      }
      if (isTagNameTooLong.value) {
        errorMsgs.push(tagNameTooLongMsg);
      }
      if (!Number.isInteger(state.editting.group2_id)) {
        errorMsgs.push('事業所は選択必須です');
      }
      if (!Number.isInteger(state.editting.group3_id)) {
        errorMsgs.push('部署は選択必須です');
      }
      return errorMsgs;
    });
    const selectedEditModalGroup2 = computed<boolean>(() => {
      return state.editting.group2_id != null && state.editting.group2_id.toString() !== '';
    });
    const editModalTitle = computed<string>(() => {
      return state.editMode === 'create' ? '新規作成' : '編集';
    });
    const nameIdentifyToTags = computed<AdminTag[]>(() => {
      return state.tagsOrig.filter((tag: AdminTag) => {
        return tag.id !== state.editting.id;
      });
    });
    const displayData = computed<DisplayData[]>(() => {
      return state.filteredTags.map(tag => {
        return {
          ...tag,
          tagTypeLabel: getTagTypeLabel(tag.tag_type1),
        };
      });
    });
    const g2id = computed<number>(() => {
      return userState.group_info.g2id;
    });
    const g3id = computed<number>(() => {
      return userState.group_info.g3id;
    });

    onMounted(() => {
      group2Api.adminIndex().then(({ data }) => {
        state.groups2 = data;
      });
      group3Api.adminIndex().then(({ data }) => {
        state.groups3 = data;
      });
      doSearch();
    });

    const doSearch = () => {
      resetSearchResults();
      const params = {
        tag_name: null,
      };
      tagApi.adminIndex(params)
        .then(({ data }) => {
          if (!data || data.length === 0) { return; }
          state.tagsOrig = data;
          filterSearch();
        });
    };
    const filterSearch = () => {
      const qTagName = state.search.tag_name;
      const qDtFrom = new Date(state.search.dt_from ? state.search.dt_from : '');
      const qDtTo = new Date(state.search.dt_to ? state.search.dt_to : '');
      const qTagMovieNumFrom = parseInt(state.search.tag_movie_num_from ? state.search.tag_movie_num_from : '');
      const qTagMovieNumTo = parseInt(state.search.tag_movie_num_to ? state.search.tag_movie_num_to : '');
      state.filteredTags = state.tagsOrig.filter(tag => {
        if (qTagName) {
          if (tag.tag_name.indexOf(qTagName) < 0) { return false; }
        }
        const ts = new Date(tag.created_at_date);
        if (qDtFrom) {
          if (ts < qDtFrom) { return false; }
        }
        if (qDtTo) {
          if (ts > qDtTo) { return false; }
        }
        if (!isNaN(qTagMovieNumFrom)) {
          if (tag.tag_movie_num < qTagMovieNumFrom) { return false; }
        }
        if (!isNaN(qTagMovieNumTo)) {
          if (tag.tag_movie_num > qTagMovieNumTo) { return false; }
        }

        return true;
      });
    };
    const filterGroup3 = (groups3: AdminGroup3[], group2Id: number): AdminGroup3[] => {
      return groups3.filter(group3 => {
        return group3.parent_id === group2Id;
      });
    };
    const resetSearchResults = () => {
      state.filteredTags = [];
    };
    const getTagTypeLabel = (type: string): string => {
      if (type === TAG_TYPE1_SYSTEM) { return 'システム'; }
      if (type === TAG_TYPE1_USER) { return 'ユーザー'; }
      return type;
    };
    const isSystem = (tag: AdminTag): boolean => {
      return tag.tag_type1 === TAG_TYPE1_SYSTEM;
    };
    const clearErrors = () => {
      state.errorMsgs = [];
      state.httpErrorMsgs = [];
    };
    const edit = (obj: AdminTag) => {
      state.editting = {
        id: obj.id,
        tag_name: obj.tag_name,
        group2_id: obj.group2_id,
        group3_id: obj.group3_id,
        tag_type1: obj.tag_type1,
        tag_type2: obj.tag_type2,
      };
      clearErrors();
      state.showEditDetail = false;
      state.showEditModal = true;
      state.editMode = 'edit';
    };
    const getGroup2Id = (group3Id: number): number | null => {
      for (const group3 of state.groups3) {
        if (group3.id === group3Id) {
          return group3.parent_id;
        }
      }
      return null;
    };
    const create = () => {
      state.editting = {
        id: 0,
        tag_name: '',
        group2_id: g2id.value,
        group3_id: g3id.value,
      };
      clearErrors();
      state.showEditDetail = false;
      state.showEditModal = true;
      state.editMode = 'create';
    };
    const save = async() => {
      clearErrors();
      try {
        if (state.editMode === 'edit') {
          await tagApi.adminUpdate(state.editting.id, {
            id: state.editting.id,
            tag_name: state.editting.tag_name,
            group2_id: state.editting.group2_id,
            group3_id: state.editting.group3_id,
          });
        } else {
          await tagApi.adminCreate(state.editting);
        }
        state.showEditModal = false;
        doSearch();
      } catch (err) {
        state.httpErrorMsgs = getErrorMessages(err as AxiosError);
      }
    };
    const nameIdentify = (obj: AdminTag) => {
      state.editting.id = obj.id;
      state.editting.name_identify_to_tag_id = null;
      state.showNameIdentifyModal = true;
    };
    const nameIdentifySave = () => {
      tagApi.adminNameIdentify(
        {
          id: state.editting.id,
          name_identify_to_tag_id: state.editting.name_identify_to_tag_id ? state.editting.name_identify_to_tag_id : 0,
        },
      ).then(() => {
        state.showNameIdentifyModal = false;
        doSearch();
      }).catch((err) => {
        console.log(err);
        state.showNameIdentifyModal = false;
        state.showErrorModal = true;
      });
    };
    const tryDestroy = (obj: AdminTag) => {
      state.editting = obj;
      state.showDestroyModal = true;
    };
    const doDestroy = () => {
      tagApi.adminDestroy(
        state.editting.id,
      ).then(() => {
        state.showDestroyModal = false;
        doSearch();
      }).catch((err) => {
        console.log(err);
        state.showDestroyModal = false;
        state.showErrorModal = true;
      });
    };
    const halfWidthLetterCount = (str: string): number => {
      let ret = 0;
      for (let i = 0, len = str.length; i < len; i++) {
        // 半角は1文字、全角は2文字として計算
        const asciiCode = str.charCodeAt(i);
        ret += asciiCode < 128 ? 1 : 2;
      }
      return ret;
    };

    const nameIdentifyModalTitle = '動画タグ 名寄せ';
    const destroyModalTitle = '削除';
    const destroyModalMsg = 'を削除してもよろしいですか？';
    const errorModalTitle = 'エラー';
    const errorModalMsg = '不明なエラーです。ネットワークの状態をご確認いただき再度お試し下さい。';
    const fields: AdminListTableField[] = [
      { name: 'tag_name', label: '動画タグ' },
      { name: 'tagTypeLabel', label: '種類' },
      { name: 'created_at_date', label: '作成日' },
      { name: 'tag_movie_num', label: '登録動画数' },
    ];
    const customButtons: CustomButton[] = [
      {
        label: '名寄せ',
        clickHandler: (obj: AdminTag) => {
          nameIdentify(obj);
        },
        isVisible: (obj: AdminTag) => !isSystem(obj),
      },
    ];
    const canDelete = (obj: AdminTag) => !isSystem(obj);
    const canEdit = (obj: AdminTag) => !isSystem(obj);

    return {
      ...toRefs(state),
      // computed
      g2id,
      g3id,
      isTagNameTooLong,
      validateEditting,
      selectedEditModalGroup2,
      editModalTitle,
      nameIdentifyToTags,
      displayData,
      // methods
      doSearch,
      filterSearch,
      filterGroup3,
      resetSearchResults,
      getTagTypeLabel,
      isSystem,
      clearErrors,
      edit,
      getGroup2Id,
      create,
      save,
      nameIdentify,
      nameIdentifySave,
      tryDestroy,
      doDestroy,
      halfWidthLetterCount,
      // others
      nameIdentifyModalTitle,
      destroyModalTitle,
      destroyModalMsg,
      errorModalTitle,
      errorModalMsg,
      tagNameTooLongMsg,
      fields,
      customButtons,
      canDelete,
      canEdit,
    };
  },
});
