


import group2Api from '@/apis/group2';
import { AdminListTableField } from '@/components/lib/AdminListTable.vue';
import { AdminGroup2 } from '@/models/apis/group2/adminGroup2Response';
import {
  defineComponent,
  computed,
  onMounted,
  reactive,
  toRefs,
} from '@vue/composition-api';
import { getErrorMessages } from '@/lib/errMsgHelper';
import { AxiosError } from 'axios';

interface AdminGroup2Edit {
  name: AdminGroup2['name'];
  disp_order: AdminGroup2['disp_order'];
}

interface AdminGroup2Update extends AdminGroup2Edit {
  id: AdminGroup2['id'];
}

interface AdminGroup2State {
  showEditModal: boolean;
  editMode: 'create' | 'edit';
  showDestroyModal: boolean;
  showErrorModal: boolean;
  errorModalMsg: string;
  groups2Orig: AdminGroup2[];
  filteredGroups2: AdminGroup2[];
  editting: AdminGroup2Edit | AdminGroup2Update;
  httpErrorMsgs: string[];
}

export default defineComponent({
  name: 'admin-groups2',
  setup() {
    const initEditState = (): AdminGroup2Edit => {
      return {
        name: '',
        disp_order: 0,
      };
    };
    const state = reactive<AdminGroup2State>({
      showEditModal: false,
      editMode: 'edit',
      showDestroyModal: false,
      showErrorModal: false,
      errorModalMsg: '不明なエラーです。ネットワークの状態をご確認いただき再度お試し下さい。',
      groups2Orig: [],
      filteredGroups2: [],
      editting: initEditState(),
      httpErrorMsgs: [],
    });

    const validateEditting = computed<Array<string>>(() => {
      let errorMsgs = [];
      if (state.editting.name.length <= 0) {
        errorMsgs.push('事業所名は必須です');
      }
      if (!/^\d+$/.test(state.editting.disp_order.toString())) {
        errorMsgs.push('表示順は数値で入力して下さい');
      }
      return errorMsgs;
    });
    const editModalTitle = computed<string>(() => state.editMode === 'create' ? '新規作成' : '編集');
    const doSearch = () => {
      resetSearchResults();
      group2Api.adminIndex()
        .then(({ data }) => {
          if (!data || data.length === 0) { return; }
          state.groups2Orig = data;
          filterSearch();
        });
    };
    const filterSearch = () => {
      state.filteredGroups2 = state.groups2Orig;
    };
    const resetSearchResults = () => {
      state.filteredGroups2 = [];
    };
    onMounted(doSearch);

    const edit = (obj: AdminGroup2Update) => {
      state.editting = {
        id: obj.id,
        name: obj.name,
        disp_order: obj.disp_order,
      };
      state.httpErrorMsgs = [];
      state.showEditModal = true;
      state.editMode = 'edit';
    };

    const create = () => {
      let maxDispOrder = 0;
      state.groups2Orig.forEach(e => {
        if (!isNaN(e.disp_order) && maxDispOrder < e.disp_order) {
          maxDispOrder = e.disp_order;
        }
      });
      state.editting = {
        name: '',
        disp_order: maxDispOrder + 1,
      };
      state.httpErrorMsgs = [];
      state.showEditModal = true;
      state.editMode = 'create';
    };

    const save = async() => {
      try {
        if (state.editMode === 'edit') {
          await group2Api.adminUpdate((state.editting as AdminGroup2Update).id, {
            name: state.editting.name,
            disp_order: Number(state.editting.disp_order),
          });
        } else {
          await group2Api.adminCreate(state.editting);
        }
        state.httpErrorMsgs = [];
        state.showEditModal = false;
        doSearch();
      } catch (err) {
        state.httpErrorMsgs = getErrorMessages(err as AxiosError);
      }
    };

    const tryDestroy = (obj: AdminGroup2Edit) => {
      state.editting = obj;
      state.showDestroyModal = true;
    };
    const doDestroy = () => {
      group2Api.adminDestroy(
        (state.editting as AdminGroup2Update).id,
      ).then(() => {
        state.showDestroyModal = false;
        doSearch();
      }).catch((err) => {
        const errMsgs = getErrorMessages(err as AxiosError);
        if (errMsgs.includes('has group3s')) {
          state.errorModalMsg = '1件以上の部署が所属しているので削除できません。';
        } else {
          state.errorModalMsg = '不明なエラーです。ネットワークの状態をご確認いただき再度お試し下さい。';
        }
        state.showDestroyModal = false;
        state.showErrorModal = true;
      });
    };

    const destroyModalTitle = '削除';
    const destroyModalMsg = 'を削除してもよろしいですか？';
    const errorModalTitle = 'エラー';
    const fields: AdminListTableField[] = [
      { name: 'name', label: '事業所名' },
      { name: 'disp_order', label: '表示順' },
    ];

    return {
      ...toRefs(state),
      // computed
      validateEditting,
      editModalTitle,
      // methods
      doSearch,
      edit,
      create,
      save,
      tryDestroy,
      doDestroy,
      // others
      destroyModalTitle,
      destroyModalMsg,
      errorModalTitle,
      fields,
    };
  },
});
