


























































































































































import Vue from 'vue';
import mtxApi from '@/apis/mtx';
import { defineComponent, onMounted, PropType, reactive, toRefs, watch } from '@vue/composition-api';
import { Car } from '@/models/apis/cars/carResponse';
import { AxiosError } from 'axios';
import { Mtx, MtxFetchByDeviceIdResponse, MtxMovie } from '@/models/apis/mtx/mtxResponse';
import { dtFormat as dtFormatHelper } from '@/lib/dateHelper';
import { GeoItemMeta, GIDebugMTX } from '@/models/geoItem';
import ExtremeMap from '@/components/lib/ExtremeMap/index.vue';
import EMDebugMTXMovieLayerManager from '@/lib/extreme_map/EMDebugMTXMovieLayerManager';
import GIAbstractManager from '@/lib/geo_item/GIAbstractManager';

const localStorageKey = 'movie-pf-debug';
const layerManagerName = 'EMDebugMTXMovieLayerManager';

interface Disp {
  mtxs: boolean;
  mtxTimestamps: boolean;
  movies: boolean;
  movieGeoIndices: boolean;
}

interface SearchParam {
  device_id: string;
  start_ts: string;
  end_ts: string;
  searchedDeviceId?: string;
}

interface TopDebugToolState {
  carNameToDeviceId: Record<string, string>;
  search: SearchParam;
  searchParamsToRestore: SearchParam | null;
  searchParamCandidacies: SearchParam[];
  disp: Disp;
  errorMsg: string;
  activeTab: string;
  mtxs: Mtx[];
  movies: MtxMovie[];
  metaInfo: GeoItemMeta;
}

export default defineComponent({
  name: 'top-debug-component-tool1',
  props: {
    refExtremeMap: {
      type: Object as PropType<InstanceType<typeof ExtremeMap>>,
      required: true,
    },
    cars: {
      type: Array as PropType<Car[]>,
      required: true,
    },
  },
  setup(props) {
    const initSearchState = () => {
      return {
        device_id: '',
        start_ts: '',
        end_ts: '',
        searchedDeviceId: '',
      };
    };
    const initDispState = () => {
      return {
        mtxs: true,
        mtxTimestamps: true,
        movies: true,
        movieGeoIndices: false,
      };
    };
    const state = reactive<TopDebugToolState>({
      carNameToDeviceId: {},
      search: initSearchState(),
      searchParamsToRestore: null,
      searchParamCandidacies: [],
      disp: initDispState(),
      errorMsg: '',
      activeTab: 'mtxs',
      mtxs: [],
      movies: [],
      metaInfo: {} as GeoItemMeta,
    });
    const isDevelopment = Vue.prototype.$isDevelopment;

    onMounted(() => {
      if (isDevelopment()) {
        state.search.device_id = 'MPF_TAKI001';
        state.search.start_ts = '2019-02-10 13:00:00';
        state.search.end_ts = '2019-02-10 14:10:00';
      }
      try {
        const obj = JSON.parse(localStorage.getItem(localStorageKey) || '{}');
        if (obj.searchParamCandidacies) {
          state.searchParamCandidacies = obj.searchParamCandidacies;
        }
      } catch (e) {}

      initializeDataManagers();
    });

    watch(() => props.cars, () => {
      const obj: Record<string, string> = {};
      props.cars.forEach(car => {
        if (!car.device) { return; }
        obj[car.device.car_name] = car.device_id;
      });
      state.carNameToDeviceId = obj;
    });

    // methods
    const initializeDataManagers = () => {
      const info = { name: 'debugMTXMovie', dispName: '付箋' };
      const layerManager = new EMDebugMTXMovieLayerManager();

      const item: GeoItemMeta = {
        name: info.name,
        dispName: info.dispName,
        layerManager,
        // add this properties to avoid type error.
        manager: {} as GIAbstractManager,
        hideToggleCheckbox: false,
        isCreatable: false,
        isTabPanel: false,
        tabLabel: '',
      };
      state.metaInfo = item;
    };
    const showMTX = async() => {
      state.errorMsg = '';
      state.mtxs = [];
      state.movies = [];
      const search = state.search;
      if (!search.device_id || !search.start_ts || !search.end_ts) { return; }

      let deviceId = search.device_id;
      if (state.carNameToDeviceId[deviceId]) {
        // 車両番号だったら変換してやる
        deviceId = state.carNameToDeviceId[deviceId];
      }
      state.search.searchedDeviceId = deviceId;

      const dtReg = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
      if (!search.start_ts.match(dtReg)) {
        state.errorMsg = '期間fromはyyyy-mm-dd hh:ii:ssで指定してください';
        return;
      }
      if (!search.end_ts.match(dtReg)) {
        state.errorMsg = '期間toはyyyy-mm-dd hh:ii:ssで指定してください';
        return;
      }

      let result: MtxFetchByDeviceIdResponse;
      try {
        const resp = await mtxApi.fetchByDeviceId({
          device_id: deviceId,
          start_ts: search.start_ts,
          end_ts: search.end_ts,
        });
        result = resp.data;
      } catch (e) {
        const error = e as AxiosError;
        let msg = `status:${error.response?.status}, `;
        msg += `statusText:${error.response?.statusText}, `;
        msg += `data:${JSON.stringify(error.response?.data)}`;
        state.errorMsg = msg;
        return;
      }
      if (result.mtxs.length === 0) {
        state.errorMsg = 'データが見つかりませんでした';
        return;
      }
      state.mtxs = result.mtxs;
      state.movies = result.movies;
      state.movies.forEach(e => {
        e.movie_geo_indices.sort((a, b) => {
          return a.ts_msec_diff < b.ts_msec_diff ? -1 : 1;
        });
      });

      state.disp.mtxs = true;
      state.disp.movies = true;
      state.disp.movieGeoIndices = false;

      plotInfoOnMap();
    };
    const plotInfoOnMap = () => {
      const info: GIDebugMTX = {
        mtxs: state.disp.mtxs ? state.mtxs : [],
        movies: [],
      };

      info.mtxs = state.disp.mtxs ? state.mtxs : [];
      info.mtxs.forEach(mtx => {
        mtx.showTimestamp = state.disp.mtxTimestamps;
      });

      info.movies = [];
      if (state.disp.movies) {
        info.movies = state.movies.slice().map(e => {
          const obj = Object.assign({}, e);
          if (!state.disp.movieGeoIndices) {
            obj.movie_geo_indices = [];
          }
          return obj;
        });
      }
      props.refExtremeMap.removeDataLayer(state.metaInfo.name);
      props.refExtremeMap.showDataLayer(state.metaInfo as GeoItemMeta, info);
    };
    const removeMTX = async() => {
      for (const k in state.disp) {
        state.disp[k as keyof Disp] = false;
      }
      props.refExtremeMap.removeDataLayer(layerManagerName);
    };
    const toggleMTXMapDisp = () => {
      state.disp.mtxTimestamps = state.disp.mtxs;
      plotInfoOnMap();
    };
    const toggleMTXMapTsDisp = () => {
      plotInfoOnMap();
    };
    const toggleMovieMapDisp = () => {
      state.disp.movieGeoIndices = state.disp.movies;
      plotInfoOnMap();
    };
    const toggleMGIMapDisp = () => {
      plotInfoOnMap();
    };
    const setNow = (prop: string) => {
      state.search[prop as keyof SearchParam] =
      dtFormatHelper(new Date(), 'yyyy-mm-dd HH:MM:SS');
    };
    const saveLocalStorage = () => {
      const obj = {
        searchParamCandidacies: state.searchParamCandidacies,
      };
      localStorage.setItem(localStorageKey, JSON.stringify(obj));
    };
    const saveSearchParams = () => {
      const maxLen = 50;
      state.searchParamCandidacies.unshift({
        device_id: state.search.device_id,
        start_ts: state.search.start_ts,
        end_ts: state.search.end_ts,
      });
      state.searchParamCandidacies =
        state.searchParamCandidacies.slice(0, maxLen);

      saveLocalStorage();
      alert(`検索条件を保存しました (最大${maxLen}件まで保存できます)`);
    };
    const restoreSearchParams = () => {
      if (!state.searchParamsToRestore) { return; }
      const params = state.searchParamsToRestore;
      state.search.device_id = params.device_id;
      state.search.start_ts = params.start_ts;
      state.search.end_ts = params.end_ts;
    };
    const dtFormat = (dt: string) => {
      return dtFormatHelper(dt, 'yyyy-mm-dd HH:MM:SS');
    };

    return {
      ...toRefs(state),
      // methods
      showMTX,
      removeMTX,
      toggleMTXMapDisp,
      toggleMTXMapTsDisp,
      toggleMovieMapDisp,
      toggleMGIMapDisp,
      setNow,
      saveSearchParams,
      restoreSearchParams,
      dtFormat,
    };
  },
});
