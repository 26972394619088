




























































import extLandApEmergencyApi from '@/apis/extLandApEmergency';
import {
  defineComponent,
  onMounted,
  PropType,
  reactive,
  toRefs,
  watch,
  computed,
  toRef,
} from '@vue/composition-api';
import { MapElemInfo } from '@/models';
import { Emergency } from '@/models/apis/extLandApEmergency/extLandApEmergencyResponse';
import useSwitchGeoItem from '@/composables/useSwitchGeoItem';
import useSavedImage from '@/composables/useSavedImage';
import { dtFormat } from '@/lib/dateHelper';

interface MapElemInfoLandApEmergencyState {
  emergency: Emergency;
  dateDisp: string;
}

export default defineComponent({
  name: 'map-elem-info-land-ap-emergency',
  props: {
    elem: {
      type: Object as PropType<MapElemInfo<Emergency>>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const state = reactive<MapElemInfoLandApEmergencyState>({
      emergency: props.elem.data,
      dateDisp: '',
    });
    const { elem } = toRefs(props);
    const { showPrevious, showNext } = useSwitchGeoItem(emit, elem);

    const modalTitle = computed<string>(() => `${state.dateDisp} - ${state.emergency.landmark_disp || ''}`);
    const downloadFilenameTpl = computed<string>(() => {
      const emergencyTs = dtFormat(state.emergency?.ts, 'yyyymmddHHMMSS');
      return `${state.emergency?.id}_${emergencyTs}_$TIMESTAMP.jpg`;
    });
    const params = {
      refMapElemInfoData: toRef(state, 'emergency'),
      emit,
      fetchApiFn: () => { return extLandApEmergencyApi.getImage(state.emergency.id); },
      modalTitle,
      downloadFilenameTpl,
    };
    const { savedImage, showImage } = useSavedImage(params);

    const initDisplay = () => {
      state.emergency = props.elem.data;
      state.dateDisp = dtFormat(state.emergency.ts);
    };
    onMounted(initDisplay);

    watch(() => props.elem, initDisplay);

    const openEmergencies = () => {
      window.open(state.emergency?.emergencies_link, '_blank');
    };
    const iconPath = '/static/img/land_ap_emergency_pin.png';

    return {
      ...toRefs(state),
      savedImage,
      showPrevious,
      showNext,
      openEmergencies,
      showImage,
      iconPath,
    };
  },
});
