import GIAbstractManager from '@/lib/geo_item/GIAbstractManager';
import movieApi from '@/apis/movie';
import analyticsApi from '@/apis/analytics';
import {
  MovieSearchByAreaParams,
  MovieSearchByRoutesParams,
  MovieSearchParams,
  Route,
} from 'src/models/apis/movie/movieRequest';
import { AnalyticsLogMovieSearchParams } from 'src/models/apis/analytics/analyticsRequest';
import { dtFormat, ensureDate } from '@/lib/dateHelper';
import {
  MasterData,
  TopMovieSearchParams,
} from 'src/models';
import { GIKp, GIMovieList } from 'src/models/geoItem';
import { RoadNameDisp } from 'src/models/apis/master/masterResponse';
import { MovieList } from 'src/models/apis/movie/movieResponse';
import { convMovies } from '@/lib/moviePlayerHelper';

interface InitParams {
  masters: MasterData;
}

type SearchTypes = 'area' | 'route' | 'detailParam' | 'id';

export default class GIMovieManager extends GIAbstractManager {
  private msts: {roadNameDispMap: Record<string, RoadNameDisp>};

  constructor({ masters }: InitParams) {
    super();
    this.msts = {
      roadNameDispMap: masters.roadNameDispMap,
    };
  }

  async getResourceById(id: number): Promise<GIMovieList | null> {
    try {
      const { data } = await movieApi.searchByMovieId(id);
      const results = this.convMovieSearchResults_(data, 'id');
      return results.length > 0 ? results[0] : null;
    } catch (e) {
      return null;
    }
  }

  getDateReqParams_(params: TopMovieSearchParams): { tsFrom: Date; tsTo: Date } {
    const tsFrom = params.dt_from;
    const tsTo = new Date(params.dt_to.valueOf() + 86400 * 1000);
    return { tsFrom, tsTo };
  }

  async logMovieSearch(searchType: string, resultCount: number, resTimeMsec: number): Promise<void> {
    try {
      const params: AnalyticsLogMovieSearchParams = {
        search_type: searchType,
        result_count: resultCount,
        res_time_msec: resTimeMsec,
      };
      await analyticsApi.logMovieSearch(params);
    } catch (e) {
      console.warn('logMovieSearch failed');
      console.warn(e);
    }
  }

  /* 詳細パラメータ指定による検索 */
  async getResourcesByParams(params: TopMovieSearchParams): Promise<GIMovieList[]> {
    // 日付
    const { tsFrom, tsTo } = this.getDateReqParams_(params);
    const reqParams: MovieSearchParams = {
      ts_from: tsFrom,
      ts_to: tsTo,
    };
    reqParams.ts_from = tsFrom;
    reqParams.ts_to = tsTo;
    // 路線名, 方向, KP
    if (params.roadNameDispObj) {
      reqParams.road_name_disp = params.roadNameDispObj.road_name_disp;
      if (params.direction) {
        reqParams.direction = params.direction;
        if (params.kp_calc_from && params.kp_calc_from !== '') {
          const kpCalcFrom = parseFloat(params.kp_calc_from);
          if (!isNaN(kpCalcFrom)) {
            reqParams.kp_calc_from = kpCalcFrom;
          }
        }
        if (params.kp_calc_to && params.kp_calc_to !== '') {
          const kpCalcTo = parseFloat(params.kp_calc_to);
          if (!isNaN(kpCalcTo)) {
            reqParams.kp_calc_to = kpCalcTo;
          }
        }
      }
    }
    // 車番
    if (params.car_name) {
      reqParams.car_name = params.car_name;
    }
    // 動画タグ
    if (params.tags && params.tags.length > 0) {
      reqParams.tag_ids = params.tags.map(e => e.id);
    }

    const tmpTs = new Date();
    const { data } = await movieApi.search(reqParams);
    const searchType = 'detailParam';
    this.logMovieSearch(searchType, data.length, new Date().getTime() - tmpTs.getTime());
    return this.convMovieSearchResults_(data, searchType);
  }

  /* ルート検索 */
  async getResourcesByRoutes(params: TopMovieSearchParams, routes: { data: Route[] }): Promise<GIMovieList[]> {
    // ルート検索の場合、画面のパラメータ使うと範囲が狭すぎる気もする.
    // が、何かしら日付範囲は指定しておいた方がよいだろうから何か指定しておく.
    const { tsTo } = this.getDateReqParams_(params);
    const reqParams: MovieSearchByRoutesParams = {
      q: routes.data,
      ts_to: tsTo,
      ts_from: new Date(tsTo.valueOf() - 86400 * 1000 * 60),
    };

    // TODO テスト用なので用済みとなれば消す
    movieApi.searchByRoutesTest({ q: routes.data }).then(({ data }) => {
      console.log('routeMovieSearchTestLog');
      console.log(data);
    });

    const tmpTs = new Date();
    const { data } = await movieApi.searchByRoutes(reqParams);
    const searchType = 'route';
    this.logMovieSearch(searchType, data.length, new Date().getTime() - tmpTs.getTime());
    const filteredData = data.slice(0, 10); // 先頭10件とかにしておく
    const movieList = filteredData.map<MovieList>(e => {
      return {
        ...e,
        car_name: '',
        device_id: '',
        min_distance: 0,
      };
    });
    return this.convMovieSearchResults_(movieList, searchType);
  }

  /* 範囲検索 */
  async getResourcesByArea(params: TopMovieSearchParams, queryArea: MovieSearchByAreaParams): Promise<Array<GIMovieList>> {
    const reqParams: MovieSearchByAreaParams = Object.assign({}, queryArea);
    const { tsFrom, tsTo } = this.getDateReqParams_(params);
    reqParams.ts_from = tsFrom;
    reqParams.ts_to = tsTo;
    // 動画タグ
    if (params.tags && params.tags.length > 0) {
      reqParams.tag_ids = params.tags.map(e => e.id);
    }

    const tmpTs = new Date();
    const { data } = await movieApi.searchByArea(reqParams);
    const searchType = 'area';
    if (data.length > 0) {
      // 範囲検索の場合は対象道路外をクリックすると普通に0件なので、
      // 検索結果1件以上のデータのみを送るようにしないと集計したあとの数
      // (1検索あたりの件数平均値とか)が少なく出てしまう
      this.logMovieSearch(searchType, data.length, new Date().getTime() - tmpTs.getTime());
    }
    return this.convMovieSearchResults_(data, searchType);
  }

  private getMovieListDateLocationDisp_(ml: GIMovieList, searchType: SearchTypes): string {
    const arr = [];
    if (searchType !== 'route') {
      arr.push(dtFormat(ml.start_ts, 'yyyy/mm/dd HH:MM:SS') + '~');
    } else {
      arr.push(
        '再生時間',
        dtFormat(ml.end_ts, 'HH:MM:SS'),
      );
    }
    arr.push(
      ml.start_kp.roadNameDispShort,
      ml.start_kp.direction,
      ml.start_kp.place_name !== 'main_line' ? ml.start_kp.place_name : '',
      ml.start_kp.kp.toFixed(1) + 'km',
      '付近',
    );
    return arr.filter(e => !!e).join(' ');
  }

  private convMovieSearchResults_(data: MovieList[], searchType: SearchTypes): GIMovieList[] {
    const results: GIMovieList[] = [];
    const emptyKp: GIKp = {
      road_name_disp: '---',
      roadNameDispShort: '---',
      kp: 0,
      kp2: 0,
      kp_calc: 0,
      angle: 0,
      direction: '',
      kp_prefix: '',
      kp_set_id: 0,
      kp_uid: '',
      lat: 0,
      lon: 0,
      place_name: '',
      road_name: '',
      road_section_type: '',
    };
    data.forEach(e => {
      // mgiにkpが紐付いてないことは普通にありうる
      const startKp: GIKp = e.start_kp ? {
        ...e.start_kp,
        roadNameDispShort: this.msts.roadNameDispMap[e.start_kp.road_name_disp].road_name_disp_short,
        kp: parseFloat(e.start_kp.kp.toString()),
        kp2: e.start_kp.kp2 ? parseFloat(e.start_kp.kp2.toString()) : NaN,
        kp_calc: parseFloat(e.start_kp.kp_calc.toString()),
        lat: parseFloat(e.start_kp.lat.toString()),
        lon: parseFloat(e.start_kp.lon.toString()),
      } : emptyKp;
      const endKp: GIKp = e.end_kp ? {
        ...e.end_kp,
        roadNameDispShort: this.msts.roadNameDispMap[e.end_kp.road_name_disp].road_name_disp_short,
        kp: parseFloat(e.end_kp.kp.toString()),
        kp2: e.end_kp.kp2 ? parseFloat(e.end_kp.kp2.toString()) : NaN,
        kp_calc: parseFloat(e.end_kp.kp_calc.toString()),
        lat: parseFloat(e.end_kp.lat.toString()),
        lon: parseFloat(e.end_kp.lon.toString()),
      } : emptyKp;
      const giMovieList: GIMovieList = {
        ...e,
        searchType,
        start_ts: ensureDate(e.start_ts),
        end_ts: ensureDate(e.end_ts),
        start_kp: startKp,
        end_kp: endKp,
        dateLocationDisp: '',
        dateLocationCarDisp: '',
        isSelected: false,
        movies: convMovies(e.movies),
      };
      giMovieList.dateLocationDisp = this.getMovieListDateLocationDisp_(giMovieList, searchType);
      giMovieList.dateLocationCarDisp = giMovieList.dateLocationDisp + ` (車番:${giMovieList.car_name})`;

      results.push(giMovieList);
    });

    return results;
  }

  mergeStateInfo(newResources: GIMovieList[], currentResources: GIMovieList[]): GIMovieList[] {
    const currentResourceMap = currentResources.reduce<Record<string, GIMovieList>>((acc, e) => {
      acc[e.id] = e; return acc;
    }, {});
    newResources.forEach(e => {
      if (currentResourceMap[e.id]) {
        e.isSelected = currentResourceMap[e.id].isSelected;
      }
    });
    return newResources;
  }

  async getResources(): Promise<any> {
    console.warn('GIMovieManager#getResources is noop.');
  }

  async createResource(): Promise<any> {
    console.warn('GIMovieManager#createResource is noop.');
  }

  async updateResource(): Promise<any> {
    console.warn('GIMovieManager#updateResource is noop.');
  }

  async deleteResource(): Promise<any> {
    console.warn('GIMovieManager#deleteResource is noop.');
  }
}
