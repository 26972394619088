

























import {
  defineComponent,
  toRefs,
  reactive,
  ref,
} from '@vue/composition-api';
import axios from 'axios';
import { useStore } from '@/hooks/useStore';
import { LocalStorageActionTypes } from '@/store/modules/localStorage';
import { UserActionTypes } from '@/store/modules/user';

interface LoginState {
 loginForm: {
    username: string;
    password: string;
  };
  showErrorMessage: boolean;
}

export default defineComponent({
  name: 'login',
  setup() {
    const state = reactive<LoginState>({
      loginForm: {
        username: '',
        password: '',
      },
      showErrorMessage: false,
    });

    // methods
    // iOSのchromeだとautofillでchangeが効かないぽいのでがんばる
    const refTextInputUsername = ref<HTMLInputElement>();
    const refTextInputPassword = ref<HTMLInputElement>();
    const ensureLoginFormValuesSynced = () => {
      const username = refTextInputUsername.value?.value;
      const password = refTextInputPassword.value?.value;
      state.loginForm.username = username || '';
      state.loginForm.password = password || '';
    };
    const store = useStore();
    const tryLogin = async() => {
      state.showErrorMessage = false;
      ensureLoginFormValuesSynced();

      // loginしようとするときは明示的に消す.
      // expiredなトークンを送り続けるとログインできないので.
      delete axios.defaults.headers['Authorization'];

      try {
        const res = await store.dispatch(UserActionTypes.LOGIN, state.loginForm);
        await store.dispatch(LocalStorageActionTypes.SET, {
          key: 'serverToken',
          val: res['access_token'],
        });
        location.href = '/';
      } catch (e) {
        state.showErrorMessage = true;
      }
    };

    return {
      ...toRefs(state),
      // refs
      refTextInputUsername,
      refTextInputPassword,
      // methods
      ensureLoginFormValuesSynced,
      tryLogin,
    };
  },
});
