import EMAbstractLayerManager, { EMLayerInfo } from '@/lib/extreme_map/EMAbstractLayerManager';
import VectorSource from 'ol/source/Vector';
import { Feature } from 'ol';
import Circle from 'ol/geom/Circle';
import Point from 'ol/geom/Point';
import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Icon from 'ol/style/Icon';
import { NamedVectorLayer } from '@/lib/OlMapWrapper';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';
import { QueryArea } from '@/models';

export default class EMQueryAreaLayerManager extends EMAbstractLayerManager {
  createLayer_(): void {
    const layer = new NamedVectorLayer('queryArea', {
      source: new VectorSource({features: []}),
    });
    this.layer = layer;
  }

  prepareLayer(): { layer: NamedVectorLayer | null; layerInfo: EMLayerInfo } {
    this.createLayer_();
    return this.getLayer();
  }

  getResourceFeatures_(qArea: QueryArea): Feature[] {
    const center = this.convCoord(qArea);
    const feat1 = new Feature(new Circle(center, qArea.dispRadius));
    feat1.setId('queryArea1');
    feat1.setStyle(new Style({
      fill: new Fill({
        color: 'rgba(255, 255, 255, 0.4)',
      }),
      stroke: new Stroke({
        color: 'rgba(0, 97, 255, 0.8)',
        width: 2,
      }),
    }));

    const feat2 = new Feature(new Point(center));
    feat2.setId('queryArea2');
    feat2.setStyle(new Style({
      image: new Icon({
        src: '/static/img/select_pin.png',
        anchor: [0.5, 0.85],
        anchorXUnits: IconAnchorUnits.FRACTION,
        anchorYUnits: IconAnchorUnits.FRACTION,
        scale: 0.25,
      }),
    }));

    return [feat1, feat2];
  }

  showQueryArea(queryArea: QueryArea): void {
    if (!this.layer) {
      return;
    }
    this.hideQueryArea();
    const feats = this.getResourceFeatures_(queryArea);
    this.layer.getSource().addFeatures(feats);
  }

  showQueryPin(queryArea: QueryArea): void {
    if (!this.layer) {
      return;
    }
    this.hideQueryArea();
    const feats = this.getResourceFeatures_(queryArea);
    this.layer.getSource().addFeatures([feats[1]]);
  }

  hideQueryArea(): void {
    if (!this.layer) {
      return;
    }
    this.layer.getSource().clear();
  }

  getResourceStyles_(): Style[] {
    throw new Error('Method not implemented.');
  }
}
