import { KpMap } from 'src/models';
import { GIKp } from 'src/models/geoItem';
import { KilopostsMaster } from 'src/models/apis/master/masterResponse';
import { Kilopost } from 'src/models/apis/movie/movieResponse';

// KP配列を路線名#方向ごとに仕分ける.
// さらにその中で本線とそれ以外(場所ごと)に仕分けて、KP順に並べる.
export function convKiloposts(obfuscatedKpObj: KilopostsMaster): {
  kpMapByRoadnameDirectionPlace: KpMap;
  kpMapByKpUid: Map<string, GIKp>;
} {
  const kps = deobfuscateKps(obfuscatedKpObj);
  // 路線名、方向、場所(本線 or 場所名) でまとめたもの.
  const ret1: KpMap = new Map();
  // kp_uidをキーにしたもの.
  const ret2: Map<string, GIKp> = new Map();

  for (const kp of kps) {
    // 路線名、方向、場所(本線 or 場所名) でまとめる.
    const k1 = `${kp.road_name}#${kp.direction}`;
    const k2 = kp.place_name;
    if (!ret1.has(k1)) { ret1.set(k1, new Map()); }
    const obj1 = ret1.get(k1);
    // 絶対あるので、TSエラー回避のため
    if (!obj1) { throw new Error('obj1 is null'); }
    if (!obj1.has(k2)) { obj1.set(k2, []); }
    const arr = obj1.get(k2);
    // 絶対あるので、TSエラー回避のため
    if (!arr) { throw new Error('arr is null'); }
    const giKp: GIKp = {
      ...kp,
      kp: parseFloat(kp.kp),
      kp2: kp.kp2 ? parseInt(kp.kp2.toString()) : kp.kp2,
      kp_calc: parseFloat(kp.kp_calc),
      lat: parseFloat(kp.lat),
      lon: parseFloat(kp.lon),
      place_name_disp: kp.place_name === 'main_line' ? '' : kp.place_name,
    };

    arr.push(giKp);

    // kp_uidでまとめる.
    ret2.set(kp.kp_uid, giKp);
  }

  for (const ent1 of ret1) {
    const obj1 = ent1[1];
    for (const ent2 of obj1) {
      ent2[1].sort((a, b) => {
        const v1 = a.kp_calc;
        const v2 = b.kp_calc;
        return v1 < v2 ? -1 : (v1 > v2 ? 1 : 0);
      });
    }
  }
  return { kpMapByRoadnameDirectionPlace: ret1, kpMapByKpUid: ret2 };
}

export function deobfuscateKps(origKpObj: KilopostsMaster): Kilopost[] {
  // サーバ側で短縮されているものを元に戻す.
  // property名、あとroad_nameとroad_name_disp.
  const kps = origKpObj.kiloposts;
  const kpValMap = origKpObj.kilopost_val_map;

  const results: Kilopost[] = [];
  kps.forEach(kp => {
    results.push({
      kp_uid: kp.a,
      road_name_disp: kpValMap['b'] ? kpValMap['b'][kp.b] : '',
      road_name: kpValMap['c'] ? kpValMap['c'][kp.c] : '',
      direction: kpValMap['d'] ? kpValMap['d'][kp.d] : kp.d,
      road_section_type: kpValMap['e'] ? kpValMap['e'][kp.e] : kp.e,
      place_name: kpValMap['f'] ? kpValMap['f'][kp.f] : kp.f,
      kp_prefix: kpValMap['g'] ? kpValMap['g'][kp.g] : kp.g,
      kp: kpValMap['h'] ? kpValMap['h'][kp.h] : kp.h,
      kp2: kpValMap['i'] ? kpValMap['i'][kp.i] : kp.i,
      kp_calc: kpValMap['j'] ? kpValMap['j'][kp.j] : kp.j,
      lat: kpValMap['k'] ? kpValMap['k'][kp.k] : kp.k,
      lon: kpValMap['l'] ? kpValMap['l'][kp.l] : kp.l,
      kp_set_id: kpValMap['m'] ? kpValMap['m'][kp.m] : kp.m,
      bikou1: kpValMap['n'] && kp.n ? kpValMap['n'][kp.n] : kp.n,
      kp_disp: kpValMap['o'] && kp.o ? kpValMap['o'][kp.o] : kp.o,
      angle: kpValMap['p'] ? kpValMap['p'][kp.p] : kp.p,
    });
  });

  return results;
}

export function isKpIncreaseDirection(roadName: string, direction: string): boolean {
  const kpIncDirectionset = new Set(['下', '南', '西', '外']);
  // 大黒は大黒JCTから生麦JCTに向かってKPが増えるが、
  // 何故か生麦に向かう方が上りとなっている
  const directionFlipRoadNameSet = new Set(['高速神奈川5号大黒線']);

  let result = kpIncDirectionset.has(direction);
  if (directionFlipRoadNameSet.has(roadName)) {
    result = !result;
  }
  return result;
}
