import {
  onMounted,
  onUnmounted,
  ref,
  Ref,
} from '@vue/composition-api';
import { IntervalID } from 'src/lib/requestAnimationFrame';

export default function useUpdateTimer(now: Ref<Date>): void {
  const nowUpdateTimer = ref<IntervalID>();

  onMounted(() => {
    nowUpdateTimer.value = window.requestInterval(() => {
      now.value = new Date();
    }, 10 * 1000);
  });

  onUnmounted(() => {
    window.clearRequestInterval(nowUpdateTimer.value);
  });
}
