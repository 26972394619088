import { RootState } from '@/hooks/useStore';
import {
  Store,
  ActionContext,
  ActionTree,
  GetterTree,
  Module,
  MutationTree,
  CommitOptions,
  DispatchOptions,
} from 'vuex';

// the definition of initial state is required.
export interface FullScreenSpinnerState {
  show: boolean;
  message: string;
  isHtmlMessage: boolean;
}

const state: FullScreenSpinnerState = {
  show: false,
  message: '',
  isHtmlMessage: false,
};

export enum FullScreenSpinnerTypes {
  SHOULD_SHOW = 'shouldShow',
}

export interface FullScreenSpinnerGetters<S = FullScreenSpinnerState> {
  [FullScreenSpinnerTypes.SHOULD_SHOW](state: S): boolean;
}

const getters: GetterTree<FullScreenSpinnerState, RootState> & FullScreenSpinnerGetters = {
  [FullScreenSpinnerTypes.SHOULD_SHOW]: (state) => state.show,
};

export enum FullScreenSpinnerMutationTypes {
  UPDATE_STATE = 'UPDATE_STATE',
}
export type FullScreenSpinnerMutations<S = FullScreenSpinnerState> = {
  [FullScreenSpinnerMutationTypes.UPDATE_STATE](state: S, { show, message, isHtmlMessage }: FullScreenSpinnerState): void;
};

const mutations: MutationTree<FullScreenSpinnerState> & FullScreenSpinnerMutations = {
  [FullScreenSpinnerMutationTypes.UPDATE_STATE](state, { show, message, isHtmlMessage }): void {
    state.show = show;
    state.message = message;
    state.isHtmlMessage = isHtmlMessage;
  },
};

export enum FullScreenSpinnerActionTypes {
  NOTIFY_SHOW = 'notifyShow',
  NOTIFY_HIDE = 'notifyHide',
}

type AugmentedActionContext = {
  commit<K extends keyof FullScreenSpinnerMutations>(
    key: K,
    payload?: Parameters<FullScreenSpinnerMutations[K]>[1]
  ): ReturnType<FullScreenSpinnerMutations[K]>;
  getters: {
    [K in keyof FullScreenSpinnerGetters]: ReturnType<FullScreenSpinnerGetters[K]>
  };
} & Omit<ActionContext<FullScreenSpinnerState, RootState>, 'commit' | 'getters'>;

export interface FullScreenSpinnerActions {
  [FullScreenSpinnerActionTypes.NOTIFY_SHOW](
    { commit, getters }: AugmentedActionContext,
    { message, isHtmlMessage }: { message: string; isHtmlMessage?: boolean }
  ): void;
  [FullScreenSpinnerActionTypes.NOTIFY_HIDE]({ commit, getters }: AugmentedActionContext): void;
}

const actions: ActionTree<FullScreenSpinnerState, RootState> & FullScreenSpinnerActions = {
  [FullScreenSpinnerActionTypes.NOTIFY_SHOW]({ commit }, { message, isHtmlMessage = false }): void {
    const obj = { show: true, message, isHtmlMessage };
    commit(FullScreenSpinnerMutationTypes.UPDATE_STATE, obj);
  },
  [FullScreenSpinnerActionTypes.NOTIFY_HIDE]({ commit }): void {
    const obj = { show: false, message: '', isHtmlMessage: false };
    commit(FullScreenSpinnerMutationTypes.UPDATE_STATE, obj);
  },
};

export type FullScreenSpinnerStore<S = FullScreenSpinnerState> = Omit<Store<S>, 'getters' | 'commit' | 'dispatch'>
& {
  getters: {
    [K in keyof FullScreenSpinnerGetters]: ReturnType<FullScreenSpinnerGetters[K]>
  };
} & {
  commit<K extends keyof FullScreenSpinnerMutations, P extends Parameters<FullScreenSpinnerMutations[K]>[1]>(
    key: K,
    payload?: P,
    options?: CommitOptions
  ): ReturnType<FullScreenSpinnerMutations[K]>;
} & {
  dispatch<K extends keyof FullScreenSpinnerActions>(
    key: K,
    payload?: Parameters<FullScreenSpinnerActions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<FullScreenSpinnerActions[K]>;
};

export const store: Module<FullScreenSpinnerState, RootState> = {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
