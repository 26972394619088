import axios, { AxiosResponse } from 'axios';
import * as utils from './apiUtils';
import {
  MtxFetchByMovieIdsParams,
  MtxFetchByDeviceIdParams,
} from 'src/models/apis/mtx/mtxRequest';
import {
  MtxFetchByMovieIdsResponse,
  MtxFetchByDeviceIdResponse,
} from 'src/models/apis/mtx/mtxResponse';

export default {
  fetchByMovieIds(params: MtxFetchByMovieIdsParams): Promise<AxiosResponse<MtxFetchByMovieIdsResponse>> {
    return axios.get(utils.getApiUrl('/mtxs'), {
      params: params || {},
    });
  },
  fetchByDeviceId(params: MtxFetchByDeviceIdParams): Promise<AxiosResponse<MtxFetchByDeviceIdResponse>> {
    return axios.get(utils.getApiUrl(`/mtxs/by_device_id`), {
      params: params || {},
    });
  },
};
