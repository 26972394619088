import { getCurrentInstance } from '@vue/composition-api';
import { FullScreenSpinnerState, FullScreenSpinnerStore } from '@/store/modules/fullScreenSpinner';
import { LocalStorageState, LocalStorageStore } from '@/store/modules/localStorage';
import { UserState, UserStore } from '@/store/modules/user';

export interface RootState {
  fullScreenSpinner: FullScreenSpinnerState;
  user: UserState;
  localStorage: LocalStorageState;
}

export type Store
  = UserStore<Pick<RootState, 'user'>>
  & LocalStorageStore<Pick<RootState, 'localStorage'>>
  & FullScreenSpinnerStore<Pick<RootState, 'fullScreenSpinner'>>;

export function useStore(): Store {
  const instance = getCurrentInstance();
  if (!instance) {
    throw new Error(`Should be used in setup().`);
  }
  return instance.proxy.$store;
}
