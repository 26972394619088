import GIAbstractManager from '@/lib/geo_item/GIAbstractManager';
import junkaiTenkenReportTenkenDataApi from '@/apis/extJunkaiTenkenReportTenkenData';
import { dtFormat } from '@/lib/dateHelper';
import { lineBreakToBR } from '@/lib/utils';
import { GITenkenData } from '@/models/geoItem';
import GeoItemSearchJunkaiTenkenReportTenkenData from '@/components/Top/geoItemSearchComponents/GeoItemSearchJunkaiTenkenReportTenkenData.vue';
import { JunkaiTenkenIndexParams } from '@/models/apis/extJunkaiTenkenReportTenkenData/extJunkaiTenkenReportTenkenDataRequest';
import { TenkenData } from '@/models/apis/extJunkaiTenkenReportTenkenData/extJunkaiTenkenReportTenkenDataResponse';

export default class GIJunkaiTenkenReportTenkenDataManager extends GIAbstractManager {
  async getResources(
    componentRef: InstanceType<typeof GeoItemSearchJunkaiTenkenReportTenkenData>,
  ): Promise<GITenkenData[]> {
    const params = componentRef.getSearchParams();
    if (!params) {
      return [];
    }
    return this.getResourcesByParams(params);
  }

  async getResourcesByParams(params: JunkaiTenkenIndexParams): Promise<GITenkenData[]> {
    try {
      const { data } = await junkaiTenkenReportTenkenDataApi.index(params);
      return this._convData(data);
    } catch (e) {
      return [];
    }
  }

  private _convData(data: TenkenData[]): GITenkenData[] {
    const results: GITenkenData[] = [];
    data.forEach(e => {
      const fmt = e.inspection_time ? 'yyyy/mm/dd HH:MM' : 'yyyy/mm/dd';
      const inspectionTs = new Date(this._getInspectionTs(e));
      // 路上,路下,高架下のデータが含まれていてidが重複する場合があるのでdetail_idで振りなおす
      // detail_idはbase_id+連番で、実質全体に渡って単品でユニーク
      results.push({
        ...e,
        id: parseInt(e.detail_id),
        lat: parseFloat(e.lat),
        lon: parseFloat(e.lon),
        inspectionTs,
        titleDisp: dtFormat(inspectionTs, fmt),
        kpDisp: this._getLocationDisp(e),
        contentDisp: lineBreakToBR(this._getContentDisp(e)),
        isSelected: false,
      });
    });
    // 同一地点の場合日付が新しいものが上
    results.sort((a, b) => {
      return a.inspectionTs < b.inspectionTs ? -1 : a.inspectionTs > b.inspectionTs ? 1 : 0;
    });
    return results;
  }

  private _getInspectionTs(data: TenkenData): string {
    return [
      data.inspection_date,
      data.inspection_time,
    ].filter(e => !!e).join(' ');
  }

  private _getLocationDisp(data: TenkenData): string {
    return [
      data.road_name,
      data.direction,
    ].filter(e => !!e).join(' ');
  }

  private _getContentDisp(data: TenkenData): string {
    return [
      data.landmark_number,
      data.inspection_type,
      data.damage_rank,
    ].filter(e => !!e).join(' ');
  }

  mergeStateInfo(newResources: GITenkenData[], currentResources: GITenkenData[]): GITenkenData[] {
    const currentResourceMap = currentResources.reduce<Record<string, GITenkenData>>((acc, e) => {
      acc[e.id] = e; return acc;
    }, {});
    newResources.forEach(e => {
      if (currentResourceMap[e.id]) {
        e.isSelected = currentResourceMap[e.id].isSelected;
      }
    });
    return newResources;
  }

  async getResourceById(): Promise<any> {
    console.warn('GIJunkaiTenkenManager#getResourceById is noop.');
  }

  async createResource(): Promise<any> {
    console.warn('GIJunkaiTenkenManager#createResource is noop.');
  }

  async updateResource(): Promise<any> {
    console.warn('GIJunkaiTenkenManager#updateResource is noop.');
  }

  async deleteResource(): Promise<any> {
    console.warn('GIJunkaiTenkenManager#deleteResource is noop.');
  }

  filterTabPaneElements(data: GITenkenData[]): GITenkenData[] {
    // 日付が新しいものがリストの上にくるようにソート
    return data.filter(e => !e.lat || !e.lon).sort((a, b) => {
      return a.inspectionTs < b.inspectionTs ? 1 : a.inspectionTs > b.inspectionTs ? -1 : 0;
    });
  }
}
