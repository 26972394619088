


import MoviePlayerControlArea from '@/components/lib/MoviePlayerControlArea/index.vue';
import MovieMetaInfoArea from '@/components/lib/MovieMetaInfoArea/index.vue';
import ExtremeMap from '@/components/lib/ExtremeMap/index.vue';
import MapElemInfoNew from '@/components/Top/mapElemInfoComponents/MapElemInfoNew.vue';
import { getGeoItemMeta, syncGeoItemWithParent } from '@/lib/geoItemHelper';
import {
  getMovieFileUrlObj,
  preCalculateMovieVals,
  openNewInfraDoctorTab,
  getScreenshotBlobOfVid,
  getSensorDataOfMovieList,
  downloadMovieFileOfVid,
  takeScreenshotOfVid,
  getMgiDistance,
  updateMovieTags as updateMovieTagsHelper,
  MovieFileUrlObj,
} from '@/lib/moviePlayerHelper';
import {
  updateVideojsTitleBarText,
  getVideojs, getVideojsControlBar,
} from '@/lib/videojsHelper';
import {
  computed,
  defineComponent,
  getCurrentInstance,
  nextTick,
  onMounted,
  onUnmounted,
  PropType,
  reactive,
  ref,
  toRefs,
  watch,
} from '@vue/composition-api';
import { RoadName } from '@/models/apis/master/masterResponse';
import {
  ExtremeMapEssentials,
  Location,
  MapElemInfo,
  MovieLayerMeta,
  MovieTagModalParams,
} from '@/models';
import { SeekParams, AnalyticsParams, SwitchToFullModeParams } from '@/models/moviePlayer';
import { useStore } from '@/hooks/useStore';
import { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';
import { logMoviePlay, logMoviePlaySeconds } from '@/lib/analyticsHelper';
import {
  GeoItemMeta,
  GeoItemsMeta,
  GIMovie,
  GIMovieGeoIndex,
  GIMovieList,
} from '@/models/geoItem';
import EMPinLayerManager from '@/lib/extreme_map/EMPinLayerManager';
import { CustomGeoItemLayer } from '@/models/apis/user/userResponse';
import { MovieTag } from '@/models/apis/movie/movieResponse';
import { dtFormat } from '@/lib/dateHelper';
import { Comment } from 'src/models/apis/comment/commentResponse';

const PLAYER_TYPE = 'compare';
type LeftRight = 'left' | 'right';
type BothLeftRight = 'both' | 'left' | 'right';

interface MapElemInfoData {
  lat: string;
  lon: string;
  kp_uid: string | null;
}

interface MapElemInfoState extends MapElemInfo<MapElemInfoData> {
  candidateImages: (Blob | string)[];
}

interface MovieSearchParams {
  dt_from: Date;
  dt_to: Date;
}

interface LeftRightState {
  currentMovieLists: GIMovieList[];
  currentMovieListId: string;
  currentMovieList: GIMovieList | null;
  startTimeDisp: string;
  endTimeDisp: string;
  currentMovie: GIMovie | null;
  currentMovieIdx: number;
  isFirstMovie: boolean;
  isLastMovie: boolean;
  currentMgi: GIMovieGeoIndex | null;
  currentMgiIdx: number;
  currentMovieCurrentDt: Date | null;
  currentPlaySpeed: number;
  defaultPlaySpeed: number;
  seek: SeekParams;
  isSeeking: boolean;
  isTryingToPlayAnotherMovie: boolean;
  mapElemInfo: MapElemInfoState | null;
  movieBackDateChoices: Array<{ key: number; val: string }>;
  movieForwardDateChoices: Array<{ key: number; val: string }>;
}

interface StyleState {
  videoAreaWidth: string;
  videoAreaHeight: string;
  infoHeight: string;
}

interface MovieSearchParamstem {
  params: MovieSearchParams;
  baseDate: Date | null;
  isResultEmpty: boolean;
}

interface MoiveSearchState {
  left: MovieSearchParamstem;
  right: MovieSearchParamstem;
}

interface MoviePlayerCompareModeState {
  isInitialized: boolean;
  // vjsMap: {} // vueの管理外
  // vjsMapLR: {} // vueの管理外
  activeVjsLrMap: Record<string, boolean>;
  viewMode: 'comp';
  currentMovieLists: GIMovieList[];
  selectedCameraPosition: 'front' | 'left' | 'right';
  bulkControl: boolean;
  left: LeftRightState;
  right: LeftRightState;
  isCurrentMovieReady: {
    left: boolean;
    right: boolean;
  };
  movieSearch: MoiveSearchState;
  mapPinIdPfx: 'map-pin1-';
  geoItemsMeta: GeoItemsMeta;
  uiPromise: Promise<void>;
  showVideoToolbox: Record<string, boolean>;
  movieTagModal: MovieTagModalParams;
  sizes: {
    control: string;
    meta: string;
  };
  styles: StyleState;
  analytics: AnalyticsParams;
  isDownloadingScreenshot: boolean;
  isDownloadingMovieFile: boolean;
}

export default defineComponent({
  name: 'movie-player-compare-mode',
  props: {
    roadNameDispMap: {
      type: Object as PropType<Record<string, RoadName>>,
      default: () => { return {}; },
    },
    movieLists: {
      type: Array as PropType<Array<GIMovieList>>,
      default: () => { return []; },
    },
    movieListId: {
      type: String,
      default: '',
    },
    extremeMapEssentials: {
      type: Object as PropType<ExtremeMapEssentials>,
      default: () => { return null; },
    },
    parentGeoItemsMeta: {
      type: Object as PropType<GeoItemsMeta>,
      default: () => { return {}; },
    },
    parentMovieLayerMeta: {
      type: Object as PropType<MovieLayerMeta>,
      default: () => { return {}; },
    },
    parentMovieSearch: {
      type: Object,
      default: () => { return {}; },
    },
  },
  setup(props, { emit }) {
    const getMovieDateChoices = (opt: 'back' | 'forward'): Array<{ key: number; val: string }> => {
      const typeMap = { 'back': '前', 'forward': '後' };
      const signMap = { 'back': -1, 'forward': +1 };
      const type = typeMap[opt];
      const sign = signMap[opt];
      return [
        { key: sign * 1, val: `1日${type}` },
        { key: sign * 7, val: `1週間${type}` },
        { key: sign * 14, val: `2週間${type}` },
        { key: sign * 30, val: `1ヶ月${type}` },
        { key: sign * 90, val: `3ヶ月${type}` },
        { key: sign * 180, val: `半年${type}` },
        { key: sign * 365, val: `1年${type}` },
      ];
    };
    const initLeftRightState = (): LeftRightState => {
      return {
        currentMovieLists: [],
        currentMovieListId: '',
        currentMovieList: null,
        startTimeDisp: '00:00:00',
        endTimeDisp: '00:00:00',
        currentMovie: null,
        currentMovieIdx: -1,
        isFirstMovie: false,
        isLastMovie: false,
        currentMgi: null,
        currentMgiIdx: -1,
        currentMovieCurrentDt: null,
        currentPlaySpeed: 1.0,
        defaultPlaySpeed: 1.0,
        seek: {
          current: 0,
          min: 0,
          max: 100,
          step: 1,
        },
        isSeeking: false,
        isTryingToPlayAnotherMovie: false,
        mapElemInfo: null,
        movieBackDateChoices: getMovieDateChoices('back'),
        movieForwardDateChoices: getMovieDateChoices('forward'),
      };
    };
    const initMovieSearchState = (): MoiveSearchState => {
      return {
        left: {
          params: {} as MovieSearchParams,
          baseDate: null,
          isResultEmpty: false,
        },
        right: {
          params: {} as MovieSearchParams,
          baseDate: null,
          isResultEmpty: false,
        },
      };
    };
    const initMovieTagModalState = (): MovieTagModalParams => {
      return {
        show: false,
        tags: [],
        movie: null,
      };
    };
    const initStyleState = (): StyleState => {
      return {
        videoAreaWidth: '50%',
        videoAreaHeight: '48%',
        infoHeight: '51%',
      };
    };
    const initAnalyticsState = (): AnalyticsParams => {
      return {
        accumulatedPlayMsecs: 0,
        tmpCalcBase: -1,
        playMsecSendThres: 5000,
      };
    };
    const state = reactive<MoviePlayerCompareModeState>({
      isInitialized: false,
      // vjsMap: {} // vueの管理外
      // vjsMapLR: {} // vueの管理外
      activeVjsLrMap: {},
      viewMode: 'comp',
      currentMovieLists: [],
      selectedCameraPosition: 'front',
      bulkControl: true,
      left: initLeftRightState(),
      right: initLeftRightState(),
      isCurrentMovieReady: {
        left: false,
        right: false,
      },
      movieSearch: initMovieSearchState(),
      mapPinIdPfx: 'map-pin1-',
      geoItemsMeta: {} as GeoItemsMeta,
      uiPromise: Promise.resolve(),
      showVideoToolbox: {},
      movieTagModal: initMovieTagModalState(),
      sizes: {
        control: 'md',
        meta: 'md',
      },
      styles: initStyleState(),
      analytics: initAnalyticsState(),
      isDownloadingScreenshot: false,
      isDownloadingMovieFile: false,
    });

    onMounted(() => {
      window.addEventListener('resize', onResize);
      initializeDataManagers();
      state.showVideoToolbox = {
        [vid1.value]: false,
        [vid2.value]: false,
      };
    });
    onUnmounted(() => {
      window.removeEventListener('resize', onResize);
    });

    // watch:
    watch(() => props.movieLists, () => {
      state.currentMovieLists = props.movieLists.map(ml => {
        ml = Object.assign({}, ml);
        ml.isSelected = false; // 赤枠
        ml.showTimeTexts = true; // 時間表示
        return ml;
      });
      state.left.currentMovieLists = state.currentMovieLists.map(ml => {
        ml = Object.assign({}, ml);
        ml.isSelected = true; // 赤枠
        return ml;
      });
      state.right.currentMovieLists = state.currentMovieLists.map(ml => {
        ml = Object.assign({}, ml);
        return ml;
      });
    });
    watch(() => props.movieListId, () => {
      if (!props.movieListId) { return; }
      const isInitialized = state.isInitialized;
      state.left.currentMovieListId = props.movieListId;
      onCurrentMovieListIdChange('left');
      if (isInitialized) {
        state.right.currentMovieListId = props.movieListId;
        onCurrentMovieListIdChange('right');
      } else {
        nextTick(() => {
          state.right.currentMovieListId = props.movieListId;
          onCurrentMovieListIdChange('right');
        });
      }
    });

    // refs
    const refExtremeMap = ref<InstanceType<typeof ExtremeMap>>();
    const refTopBarArea = ref<HTMLDivElement>();
    const refVideoArea = ref<HTMLDivElement>();
    const refControlAreaBoth = ref<InstanceType<typeof MoviePlayerControlArea>>();
    const refControlAreaLeft = ref<InstanceType<typeof MoviePlayerControlArea>>();
    const refControlAreaRight = ref<InstanceType<typeof MoviePlayerControlArea>>();
    const refMetaInfoAreaLeft = ref<InstanceType<typeof MovieMetaInfoArea>>();
    const refMetaInfoAreaRight = ref<InstanceType<typeof MovieMetaInfoArea>>();

    // computed:
    const uid = getCurrentInstance()?.uid;
    const store = useStore();
    const ifdLink = computed(() => store.state.user.settings.ifd_link);
    const remainingMovieRatesByDateRanges = computed(() =>
      store.state.user.settings.remaining_movie_rates_by_date_ranges);
    const vid1 = computed(() => {
      return `vid1-${uid}`;
    });
    const vid2 = computed(() => {
      return `vid2-${uid}`;
    });
    const isCurrentMovieListLeftReady = computed(() =>
      !!state.left.currentMovieListId && state.left.currentMovieList);
    const isCurrentMovieListRightReady = computed(() =>
      !!state.right.currentMovieListId && state.right.currentMovieList);
    const isCurrentMovieListBothReady = computed(() =>
      isCurrentMovieListLeftReady.value && isCurrentMovieListRightReady.value);
    const playerControlCurrentTimeDispLeft = computed(() => {
      let ret = '00:00:00';
      if (!state.left.currentMovieList) { return ret; }
      // ルート検索以外の場合は撮影時刻を表示
      ret = dtFormat(state.left.currentMovieCurrentDt, 'HH:MM:SS');
      return ret;
    });
    const playerControlCurrentTimeDispRight = computed(() => {
      let ret = '00:00:00';
      if (!state.right.currentMovieList) { return ret; }
      // ルート検索以外の場合は撮影時刻を表示
      ret = dtFormat(state.right.currentMovieCurrentDt, 'HH:MM:SS');
      return ret;
    });
    const visibleGeoItemLayers = computed(() => {
      return [props.parentGeoItemsMeta.map['comment']];
    });
    const isMovieSearchResultModeDetail = computed(() => {
      return props.parentMovieSearch.resultMode === props.parentMovieSearch.resultModes.DETAIL;
    });
    const isMovieSearchResultModeArea = computed(() => {
      return props.parentMovieSearch.resultMode === props.parentMovieSearch.resultModes.AREA;
    });

    // methods
    const initializeDataManagers = () => {
      const layerListDefault: CustomGeoItemLayer[] = [
        { name: 'pin', dispName: 'pin' },
        { name: 'comment', dispName: '付箋' },
      ];
      state.geoItemsMeta.map = getGeoItemMeta(layerListDefault);
    };
    const refreshMovieLists = async(lr: LeftRight) => {
      const playState = getUIPlayState(lr);
      pauseUI(lr);
      state.isCurrentMovieReady[lr] = false;

      let results: GIMovieList[] = [];
      const mgr = props.parentMovieLayerMeta.manager;
      const params = Object.assign({}, state.movieSearch[lr].params);
      params.dt_to = new Date(params.dt_to.valueOf() + 1 * 86400 * 1000);
      if (isMovieSearchResultModeDetail.value) {
        // 検索条件指定による動画検索
        results = await mgr.getResourcesByParams(params);
      } else if (isMovieSearchResultModeArea.value) {
        // 範囲指定による動画検索
        results = await mgr.getResourcesByArea(params, props.parentMovieSearch.areaQueryObj);
      }
      // 同一路線#方向でしぼる
      const mlCurrent = state[lr].currentMovieList;
      if (!mlCurrent) {
        return;
      }
      const refRoadNameDisp = mlCurrent.start_kp.road_name_disp;
      const refDirection = mlCurrent.start_kp.direction;
      results = results.filter(ml => {
        return refRoadNameDisp === ml.start_kp.road_name_disp &&
          refDirection === ml.start_kp.direction;
      });

      if (results.length === 0) {
        state.movieSearch[lr].isResultEmpty = true;
        state[lr].currentMovieLists = [];
        return;
      }

      state.movieSearch[lr].isResultEmpty = false;

      // 地図上の見た目を調整
      const isSelected = lr === 'left';
      results = results.map(ml => {
        ml.isSelected = isSelected; // 赤枠
        ml.showTimeTexts = true; // 時間表示
        return ml;
      });

      // 検索基点日から一番近い動画を選択
      const baseSearchDate = state.movieSearch[lr].baseDate;
      let closestMovieList = results[0];
      if (!baseSearchDate || !results[0].start_ts) {
        return;
      }
      let dateDiff = Math.abs(baseSearchDate.getTime() - results[0].start_ts.getTime());
      for (const ml of results) {
        const currDiff = Math.abs(baseSearchDate.getTime() - (ml.start_ts?.getTime() ?? 0));
        if (currDiff < dateDiff) {
          closestMovieList = ml;
          dateDiff = currDiff;
        }
      }

      state[lr].currentMovieLists = results;
      state[lr].currentMovieListId = closestMovieList.id;
      onCurrentMovieListIdChange(lr);

      state.uiPromise.then(() => {
        // 元々停止中だった場合は、動画を停止する
        if (playState === 'paused') {
          pauseUI(lr);
        }
        state.isCurrentMovieReady[lr] = true;
      });
    };
    const searchMoviesByDateRange = (lr: LeftRight, dateOffset: number) => {
      const now = new Date();
      // 現在の日付
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      // 基準となる日付 = 現在選択中動画の日付 or 前回の検索基点日
      const baseDate = state.movieSearch[lr].baseDate;
      if (!baseDate) {
        return;
      }
      // 検索基点日
      const baseSearchDate = new Date(baseDate.valueOf() + dateOffset * 86400 * 1000);

      state.movieSearch[lr].baseDate = baseSearchDate;
      // 検索基点日を中心とした動画検索範囲(前後7日間、合計2週間分の動画を取得する)
      const baseSearchDateRange = 7;
      // 検索開始日
      let dtFrom = new Date(baseSearchDate.valueOf() - (baseSearchDateRange * 86400 * 1000));
      // 検索終了日
      let dtTo = new Date(baseSearchDate.valueOf() + (baseSearchDateRange * 86400 * 1000));

      for (const [i, e] of remainingMovieRatesByDateRanges.value.entries()) {
        // 区間開始日
        const dateRangeFrom = new Date(today.valueOf() - (e.days_before_from * 86400 * 1000));
        // 区間終了日
        const dateRangeTo = new Date(today.valueOf() - (e.days_before_to * 86400 * 1000));

        // baseSearchDateが含まれる区間を探す
        if (baseSearchDate < dateRangeFrom || dateRangeTo <= baseSearchDate) {
          continue;
        }

        // baseSearchDateが含まれる区間をi, 1つ前をi+1, 一つ後ろをi-1とする
        // (配列には時間の降順で入っている)

        // 区間iの動画残存率
        const rate1 = e.rate;
        // baseSearchDateRange分の動画量が取得可能と思われる検索範囲
        const searchDateRange = baseSearchDateRange / rate1;
        dtFrom = new Date(baseSearchDate.valueOf() - (searchDateRange * 86400 * 1000));
        dtTo = new Date(baseSearchDate.valueOf() + (searchDateRange * 86400 * 1000));

        // 検索範囲が区間を跨ぐ場合に、
        // baseSearchDateRangeと同等量の動画が取得できるよう検索範囲を補正する

        // 区間開始日が区間i+1とiを跨ぐ場合
        if (dtFrom < dateRangeFrom && i < (remainingMovieRatesByDateRanges.value.length - 1)) {
          // 区間i+1の動画残存率
          const rate2 = remainingMovieRatesByDateRanges.value[i + 1].rate;
          // 区間i内における、基点日から過去側の検索範囲
          const searchDateRangeMsec1 = baseSearchDate.getTime() - dateRangeFrom.getTime();
          // 区間i+1側で検索する範囲
          const searchDateRangeMsec2 =
            ((baseSearchDateRange * 86400 * 1000) - (searchDateRangeMsec1 * rate1)) / rate2;
          // 基点日から過去側の検索範囲全量
          const estimatedSearchDateRangeMsec = searchDateRangeMsec1 + searchDateRangeMsec2;
          dtFrom = new Date(baseSearchDate.valueOf() - estimatedSearchDateRangeMsec);
        }
        // 区間終了日が区間iとi-1を跨ぐ場合
        if (dateRangeTo <= dtTo && i > 0) {
          // 区間i-1の動画残存率
          const rate2 = remainingMovieRatesByDateRanges.value[i - 1].rate;
          // 区間i内における、基点日から未来側の検索範囲
          const searchDateRangeMsec1 = dateRangeTo.getTime() - baseSearchDate.getTime();
          // 区間i-1側で検索する範囲
          const searchDateRangeMsec2 =
            ((baseSearchDateRange * 86400 * 1000) - (searchDateRangeMsec1 * rate1)) / rate2;
          // 基点日から未来側の検索範囲全量
          const estimatedSearchDateRangeMsec = searchDateRangeMsec1 + searchDateRangeMsec2;
          dtTo = new Date(baseSearchDate.valueOf() + estimatedSearchDateRangeMsec);
        }
        break;
      }
      const reqParams = Object.assign({}, props.parentMovieSearch.params);
      reqParams.dt_from = new Date(dtFrom.getFullYear(), dtFrom.getMonth(), dtFrom.getDate());
      reqParams.dt_to = new Date(dtTo.getFullYear(), dtTo.getMonth(), dtTo.getDate());
      state.movieSearch[lr].params = reqParams;
      refreshMovieLists(lr);
    };
    const findFirstSeekPoint = (lr: LeftRight): number => {
      // 一番近かったmgiを探す
      let targetMgiId = null;
      const currentMl = state[lr].currentMovieList;
      if (!currentMl) {
        return 0;
      }
      const minDistance = currentMl.min_distance;
      // 範囲検索以外の場合は99999になっているはず
      if (minDistance > 1000) { return 0; }

      // 一番近かったmgiを探す
      for (const [mgiId, distance] of Object.entries(currentMl.hit_mgi_map)) {
        if (Math.abs(minDistance - parseInt(distance.toString())) < Number.EPSILON) {
          targetMgiId = mgiId.toString();
          break;
        }
      }
      if (!targetMgiId) { return 0; }
      // そこから再生
      for (const movie of currentMl.movies) {
        for (const mgi of movie.movie_geo_indices) {
          if (mgi.id.toString() === targetMgiId) {
            return (movie.accumMsec || 0) + (mgi.startMsecDiff || 0);
          }
        }
      }
      return 0;
    };
    const onCurrentMovieListIdChange = (lr: LeftRight) => {
      for (const ml of Object.values(state[lr].currentMovieLists)) {
        if (ml.id === state[lr].currentMovieListId) {
          state[lr].currentMovieList = ml;
          break;
        }
      }
      const currentMl = state[lr].currentMovieList;
      if (!currentMl) {
        return;
      }
      state.movieSearch[lr].baseDate = currentMl.start_ts;
      preCalculateMovieVals(currentMl, props.roadNameDispMap);
      setMoviePlayerControlDispVals(lr, currentMl);
      if (!state.isInitialized) {
        state.isInitialized = true;
        // 画面が見えてる状態でvideojsの初期化を行わないとvueの処理との兼ね合いで
        // DOMが変なところに挿入されたりする現象があったので、見えていることが
        // 分かっている状態からnextTickしてからinitialize
        nextTick(() => {
          state.uiPromise = state.uiPromise.then(() => {
            return initializeViews().then(() => {
              onResize();
              updateMap();
              return playFromBeginning(lr, findFirstSeekPoint(lr));
            });
          });
        });
      } else {
        state.uiPromise = state.uiPromise.then(() => {
          onResize();
          updateMap();
          return playFromBeginning(lr, findFirstSeekPoint(lr));
        });
      }
      if (refExtremeMap.value) {
        syncGeoItemWithParent(
          state.geoItemsMeta as GeoItemsMeta,
          props.parentGeoItemsMeta,
          refExtremeMap.value,
        );
      }
    };
    let vjsMap: Map<string, VideoJsPlayer>;
    let vjsMapLR: Map<LeftRight, VideoJsPlayer>;
    const initializeViews = () => {
      vjsMap = new Map();
      vjsMapLR = new Map();
      const p1 = prepareVideoPlayers();
      const p2 = prepareMap();
      return Promise.all([p1, p2]);
    };
    const prepareVideoPlayers = async() => {
      const objs: { vid: string; lr: LeftRight }[] = [
        { vid: vid1.value, lr: 'left' },
        { vid: vid2.value, lr: 'right' },
      ];
      const vjsArr = await Promise.all(objs.map(({ lr, vid }) => {
        return prepareVideojs(lr, vid);
      }));
      vjsArr.forEach(([lr, vid, vjs]) => {
        vjsMap.set(vid, vjs);
        vjsMapLR.set(lr, vjs);
        state.activeVjsLrMap[lr] = false;
      });

      // vjsがevtから取れなそうなので昔のfunctionの書き方でやる.
      const vjsLeft = vjsMapLR.get('left');
      const vjsRight = vjsMapLR.get('right');
      if (!vjsLeft || !vjsRight) {
        return;
      }
      vjsLeft.on('timeupdate', () => {
        const vjs = vjsLeft;
        onVjsTimeUpdate('left', vjs);
      });
      vjsRight.on('timeupdate', () => {
        const vjs = vjsRight;
        onVjsTimeUpdate('right', vjs);
      });
      // 再生速度
      vjsLeft.on('canplay', () => {
        const vjs = vjsLeft;
        vjs.playbackRate(state.left.currentPlaySpeed);
      });
      vjsRight.on('canplay', () => {
        const vjs = vjsRight;
        vjs.playbackRate(state.right.currentPlaySpeed);
      });
    };
    const getVideoJsDefaultOptions = (): VideoJsPlayerOptions => {
      // https://docs.videojs.com/tutorial-components.html
      return {
        // autoplay: true,
        // TODO: which on is this.
        aspectRatio: '16:9', // this.aspectRatio,
        loop: false,
      };
    };
    const setVideojsEventListeners = (vjs: VideoJsPlayer) => {
      const controlBar = getVideojsControlBar(vjs).el();
      const tickMovieBackwardBtn = controlBar.querySelector('.vjs-tick-movie-backward');
      const playMovieBtn = controlBar.querySelector('.vjs-play-movie');
      const pauseMovieBtn = controlBar.querySelector('.vjs-pause-movie');
      const tickMovieForwardBtn = controlBar.querySelector('.vjs-tick-movie-forward');
      const playMovieBackwardCheckbox = controlBar.querySelector('.vjs-play-movie-backward');

      if (!tickMovieBackwardBtn || !playMovieBtn || !pauseMovieBtn || !tickMovieForwardBtn || !playMovieBackwardCheckbox) {
        return;
      }
      tickMovieBackwardBtn.addEventListener('click', () => tickMovieBackward('both'), false);
      playMovieBtn.addEventListener('click', () => playUI('both'), false);
      pauseMovieBtn.addEventListener('click', () => pauseUI('both'), false);
      tickMovieForwardBtn.addEventListener('click', () => tickMovieForward('both'), false);
      playMovieBackwardCheckbox.addEventListener('click', () => pauseUI('both'), false);
      playMovieBackwardCheckbox.addEventListener('change', () =>
        playModeChange('both', (playMovieBackwardCheckbox as HTMLInputElement).checked), false);
    };
    const prepareVideojs = (lr: LeftRight, vid: string): Promise<[LeftRight, string, VideoJsPlayer]> => {
      return new Promise((resolve) => {
        const vjs = getVideojs(vid, getVideoJsDefaultOptions(), PLAYER_TYPE);
        setVideojsEventListeners(vjs);
        vjs.ready(() => {
          vjs.muted(false);
          vjs.volume(0.0);
          resolve([lr, vid, vjs]);
        });
      });
    };
    const onVjsTimeUpdate = (lr: LeftRight, vjs: VideoJsPlayer) => {
      if (state[lr].isSeeking || state[lr].isTryingToPlayAnotherMovie) { return; }
      const currentMovie = state[lr].currentMovie;
      if (!currentMovie) { return; }
      // update seekbar
      const currentTimeMsec = parseInt((vjs.currentTime() * 1000).toString());
      const playOffsetDiff =
        currentTimeMsec - (currentMovie.playStartOffsetMsec || 0);
      state[lr].seek.current = (currentMovie.accumMsec || 0) + playOffsetDiff;

      // 現在の再生位置が実際に撮影された時刻
      if (currentMovie.ts) {
        state[lr].currentMovieCurrentDt =
          new Date(currentMovie.ts.valueOf() + currentTimeMsec);
      }

      // mgiの更新
      const currentMgi = state[lr].currentMgi;
      if (currentMgi) {
        if (currentMgi.endMsecDiff && currentMgi.endMsecDiff <= currentTimeMsec) {
          trySetNextMovieGeoIndex(lr);
        }
        // 地図ピンの位置更新
        updateMapPinPosition(lr, {
          lat: currentMgi.lat,
          lon: currentMgi.lon,
        });
      }

      // サーバ側で保持しているdurationはmtxをベースに記録しているが、動画ファイルそのもののdurationは
      // 若干誤差があったりするのでケア.
      const controlArea = lr === 'left' ? refControlAreaLeft.value : refControlAreaRight.value;
      if (!controlArea) {
        return;
      }
      if (!controlArea.isPlayBackward) {
        const endMsec = Math.min(vjs.duration() * 1000, currentMovie.hardEndOffsetMsec || 0);
        if (endMsec <= currentTimeMsec) {
          if (state[lr].isLastMovie) {
            // 終わり
            pauseUI(lr);
          } else {
            // 次の動画を再生
            playNextMovie(lr);
          }
        }
      } else {
        const startMsec = Math.min(vjs.duration() * 1000, (currentMovie.hardStartOffsetMsec || 0));
        if (startMsec >= currentTimeMsec) {
          if (state[lr].isFirstMovie) {
            // 終わり
            pauseUI(lr);
          } else {
            // 次の動画を再生
            playPreviousMovie(lr);
          }
        }
      }

      if (lr === 'left') {
        if (
          state.analytics.tmpCalcBase === -1 ||
          currentTimeMsec < state.analytics.tmpCalcBase
        ) {
          state.analytics.tmpCalcBase = currentTimeMsec;
        }
        const playedMsec = currentTimeMsec - state.analytics.tmpCalcBase;
        state.analytics.accumulatedPlayMsecs += playedMsec;
        if (state.analytics.accumulatedPlayMsecs >= state.analytics.playMsecSendThres) {
          const seconds = parseInt((state.analytics.accumulatedPlayMsecs / 1000).toString());
          logMoviePlaySeconds(PLAYER_TYPE, seconds);
          state.analytics.accumulatedPlayMsecs = 0;
        }
        state.analytics.tmpCalcBase = currentTimeMsec;
      }
    };
    const prepareMap = async() => {
      const metaItem = state.geoItemsMeta.map.pin as GeoItemMeta;
      const point1 = { id: `${state.mapPinIdPfx}left`, lat: 0, lon: 0, text: '左側' };
      const point2 = { id: `${state.mapPinIdPfx}right`, lat: 0, lon: 0, text: '右側', showWeak: true };
      if (refExtremeMap.value) {
        refExtremeMap.value.showDataLayer(metaItem, [point1, point2]);
      }
    };
    const setMoviePlayerControlDispVals = (lr: LeftRight, movieList: GIMovieList) => {
      // 1つ目のmovieのplayStartOffsetMsecを0位置として扱う
      const firstMovie = movieList.movies[0];
      const firstMovieStartOffsetDiffMsec =
        firstMovie.playStartOffsetMsec === undefined || firstMovie.hardStartOffsetMsec === undefined
          ? 0
          : firstMovie.playStartOffsetMsec - firstMovie.hardStartOffsetMsec;
      // なのでseek.minは0以下の数値となる
      state[lr].seek.min = -firstMovieStartOffsetDiffMsec;
      state[lr].seek.max = (movieList.fullDurationMsec ?? 0) - firstMovieStartOffsetDiffMsec;
      state[lr].seek.current = firstMovie.playStartOffsetMsec || 0;
      state[lr].seek.step = 250; // msec
      state[lr].startTimeDisp = dtFormat(movieList.hardStartTs, 'HH:MM:SS');
      state[lr].endTimeDisp = dtFormat(movieList.hardEndTs, 'HH:MM:SS');
    };
    let intervalRewindRight: number;
    let intervalRewindLeft: number;
    const playMovie = (lr_: BothLeftRight) => {
      const lrs: Array<LeftRight> = lr_ === 'both' ? ['left', 'right'] : [lr_];
      lrs.forEach(lr => {
        const controlArea = lr === 'left' ? refControlAreaLeft.value : refControlAreaRight.value;
        if (!controlArea) {
          return;
        }
        if (!controlArea.isPlayBackward) {
          // 逆再生をリセットする
          // 左右個別の再生があるので、逆再生する場合は個別にリセットする
          clearInterval(intervalRewindRight);
          clearInterval(intervalRewindLeft);
          // control-areaのemit経由でのみ呼び出される想定.
          // 再生させたい場合はplayUI()を呼ぶ.
          // playのpromiseが解決しない内に再度呼び出すとエラーを吐くようなのでケアする.
          state.uiPromise = state.uiPromise.then(() => {
            const vjs = vjsMapLR.get(lr);
            const prms = vjs?.play() || Promise.resolve();
            return prms.catch(() => {});
          });
        } else {
          playMovieBackward(lr);
        }
      });
    };
    const playMovieBackward = (lr: LeftRight) => {
      // 動画のフレームレート
      const fps = 15;
      if (lr === 'right') {
        // 逆再生をリセットする
        clearInterval(intervalRewindRight);
        // 再生速度が速いときに逆再生しようとしても仕組み上動画のロードが間に合わないので、
        // 再生速度の選択は無視し、常に1.0xの速度で逆再生するようにする。
        const vjs = vjsMapLR.get('right');
        if (!vjs) {
          return;
        }
        vjs.addClass('vjs-playing');
        vjs.removeClass('vjs-paused');
        intervalRewindRight = setInterval(function() {
          if (vjs.currentTime() === 0) {
            pauseMovieBackward(lr);
          } else {
            vjs.currentTime(vjs.currentTime() - (1 / fps));
          }
        }, 1000 / fps);
      }
      if (lr === 'left') {
        // 逆再生をリセットする
        clearInterval(intervalRewindLeft);
        // 再生速度が速いときに逆再生しようとしても仕組み上動画のロードが間に合わないので、
        // 再生速度の選択は無視し、常に1.0xの速度で逆再生するようにする。
        const vjs = vjsMapLR.get('left');
        if (!vjs) {
          return;
        }
        vjs.addClass('vjs-playing');
        vjs.removeClass('vjs-paused');
        intervalRewindLeft = setInterval(function() {
          if (vjs.currentTime() === 0) {
            pauseMovieBackward(lr);
          } else {
            vjs.currentTime(vjs.currentTime() - (1 / fps));
          }
        }, 1000 / fps);
      }
    };
    const pauseMovie = (lr_: BothLeftRight) => {
      const lrs: Array<LeftRight> = lr_ === 'both' ? ['left', 'right'] : [lr_];
      // control-areaのemit経由でのみ呼び出される想定.
      // 再生させたい場合はpauseUI()を呼ぶ.
      lrs.forEach(lr => {
        state.uiPromise.then(() => {
          const vjs = vjsMapLR.get(lr);
          if (vjs) {
            vjs.pause();
          }
        });
        const vjs = vjsMapLR.get(lr);
        if (vjs && vjs.hasClass('vjs-playing')) {
          pauseMovieBackward(lr);
        }
      });
    };
    const pauseMovieBackward = (lr: LeftRight) => {
      const vjs = vjsMapLR.get(lr);
      if (!vjs) {
        return;
      }
      if (lr === 'right') {
        vjs.removeClass('vjs-playing');
        vjs.addClass('vjs-paused');
        clearInterval(intervalRewindRight);
      }
      if (lr === 'left') {
        vjs.removeClass('vjs-playing');
        vjs.addClass('vjs-paused');
        clearInterval(intervalRewindLeft);
      }
    };
    const playUI = (lr_: BothLeftRight) => {
      const lrs = lr_ === 'both' ? ['left', 'right'] : [lr_];
      lrs.forEach(lr => {
        const controlArea = lr === 'left' ? refControlAreaLeft.value : refControlAreaRight.value;
        if (controlArea) {
          if (lr_ === 'both' && refControlAreaBoth.value) {
            controlArea.isPlayBackward = refControlAreaBoth.value.isPlayBackward;
          }
          controlArea.play();
        }
      });

      // 同時操作部分の表示
      if (lr_ === 'both' && refControlAreaBoth.value) {
        refControlAreaBoth.value.setIsPlaying(true);
      } else {
        nextTick(() => {
          if (
            getUIPlayState('left') === 'playing' &&
            getUIPlayState('right') === 'playing' &&
            refControlAreaBoth.value
          ) {
            refControlAreaBoth.value.setIsPlaying(true);
          }
        });
      }
    };
    const pauseUI = (lr_: BothLeftRight) => {
      const lrs = lr_ === 'both' ? ['left', 'right'] : [lr_];
      lrs.forEach(lr => {
        const controlArea = lr === 'left' ? refControlAreaLeft.value : refControlAreaRight.value;
        if (controlArea) {
          controlArea.pause();
        }
      });

      // 同時操作部分の表示
      if (lr_ === 'both' && refControlAreaBoth.value) {
        refControlAreaBoth.value.setIsPlaying(false);
      } else {
        nextTick(() => {
          if (
            getUIPlayState('left') === 'paused' &&
            getUIPlayState('right') === 'paused' &&
            refControlAreaBoth.value
          ) {
            refControlAreaBoth.value.setIsPlaying(false);
          }
        });
      }
    };
    const getUIPlayState = (lr: LeftRight) => {
      const controlArea = lr === 'left' ? refControlAreaLeft.value : refControlAreaRight.value;
      if (!controlArea) {
        return 'paused';
      }
      return controlArea.getPlayState();
    };
    const tickMovieBackward = async(lr_: BothLeftRight) => {
      const lrs: Array<LeftRight> = lr_ === 'both' ? ['left', 'right'] : [lr_];
      lrs.forEach(lr => {
        pauseUI(lr);
        // 同一の動画内でのみコマ戻し
        const vjs = vjsMapLR.get(lr);
        if (!vjs) {
          return;
        }
        let currentMsec = parseInt((vjs.currentTime() * 1000).toString());
        currentMsec -= 100; // 0.1秒
        vjs.currentTime(currentMsec / 1000);
      });
    };
    const tickMovieForward = async(lr_: BothLeftRight) => {
      const lrs: Array<LeftRight> = lr_ === 'both' ? ['left', 'right'] : [lr_];
      lrs.forEach(lr => {
        pauseUI(lr);
        // 同一の動画内でのみコマ送り
        const vjs = vjsMapLR.get(lr);
        if (!vjs) {
          return;
        }
        let currentMsec = parseInt((vjs.currentTime() * 1000).toString());
        currentMsec += 100; // 0.1秒
        vjs.currentTime(currentMsec / 1000);
      });
    };
    const playPreviousMovie = async(lr: LeftRight) => {
      if (state[lr].isTryingToPlayAnotherMovie) { return; }
      if (state[lr].isFirstMovie) { return; }

      state[lr].isTryingToPlayAnotherMovie = true;
      const prevMovieIdx = state[lr].currentMovieIdx - 1;
      const prevMovie = state[lr].currentMovieList?.movies[prevMovieIdx];
      if (prevMovie && prevMovieIdx !== undefined) {
        const controlArea = lr === 'left' ? refControlAreaLeft.value : refControlAreaRight.value;
        if (!controlArea) {
          return;
        }
        const offsetMsec = controlArea.isPlayBackward ? (prevMovie.durationMsec || 0) : 0;
        await setCurrentMovie(
          lr,
          prevMovie,
          prevMovieIdx,
          offsetMsec,
        );
      }
      state[lr].isTryingToPlayAnotherMovie = false;
    };
    const playNextMovie = async(lr: LeftRight) => {
      if (state[lr].isTryingToPlayAnotherMovie) { return; }
      if (state[lr].isLastMovie) { return; }

      state[lr].isTryingToPlayAnotherMovie = true;
      const nextMovieIdx = state[lr].currentMovieIdx + 1;
      const nextMovie = state[lr].currentMovieList?.movies[nextMovieIdx];
      if (nextMovie && nextMovieIdx !== undefined) {
        await setCurrentMovie(
          lr,
          nextMovie,
          nextMovieIdx,
          0,
        );
      }
      state[lr].isTryingToPlayAnotherMovie = false;
    };
    const setPlaySpeed = async(lr_: BothLeftRight, val: number) => {
      const lrs: Array<LeftRight> = lr_ === 'both' ? ['left', 'right'] : [lr_];
      lrs.forEach(lr => {
        state[lr].currentPlaySpeed = val;
        const vjs = vjsMapLR.get(lr);
        if (vjs) {
          vjs.playbackRate(val);
        }
      });
    };
    const setVolume = async(lr_: BothLeftRight, val: number) => {
      const lrs: Array<LeftRight> = lr_ === 'both' ? ['left', 'right'] : [lr_];
      lrs.forEach(lr => {
        const vjs = vjsMapLR.get(lr);
        if (vjs) {
          vjs.volume(val);
        }
      });
    };
    const playModeChange = (lr_: BothLeftRight, isPlayBackward: boolean) => {
      const lrs: Array<LeftRight> = lr_ === 'both' ? ['left', 'right'] : [lr_];
      if (lr_ !== 'both') {
        return;
      }
      if (!refControlAreaBoth.value) {
        return;
      }
      refControlAreaBoth.value.setPlayBackward(isPlayBackward);
      lrs.forEach(lr => {
        const vjs = vjsMapLR.get(lr);
        if (!vjs) {
          return;
        }
        const controlBar = getVideojsControlBar(vjs).el();
        const playMovieBtn = controlBar.querySelector('.vjs-play-movie');
        const playMovieBackwardCheckbox = controlBar.querySelector('.vjs-play-movie-backward') as HTMLInputElement;
        if (!playMovieBtn || !playMovieBackwardCheckbox) {
          return;
        }
        playMovieBackwardCheckbox.checked = isPlayBackward;
        if (isPlayBackward) {
          playMovieBtn.classList.add('play-backward');
          return;
        }
        playMovieBtn.classList.remove('play-backward');
      });
    };
    const seekBySeekBar = (lr: LeftRight, offsetMsec: number) => {
      state[lr].seek.current = offsetMsec;
      seekMovie(lr, offsetMsec);
    };
    const seekMovie = async(lr: LeftRight, offsetMsec: number) => {
      state[lr].isSeeking = true;
      // movieListのmovieについて順番に、offsetMsecのそのmovie内でのoffsetを算出する.
      // そのmovieのplayDuration内にoffsetが収まっていればそれが再生すべきmovie
      // となる. (最後のmovieについて、playEnd < hardEndでplayEnd以降の位置を
      // 指定されていた場合は最後のmovieを再生させたいのでそのようにしてある)
      let targetMovie;
      let targetMovieIdx;
      let targetMoviePlayOffsetMsec = 0;
      const currentMl = state[lr].currentMovieList;
      if (!currentMl) {
        return;
      }
      for (let i = 0, len = currentMl.movies.length; i < len; i++) {
        const movie = currentMl.movies[i];
        targetMovie = movie;
        targetMovieIdx = i;
        targetMoviePlayOffsetMsec = offsetMsec - (targetMovie.accumMsec || 0);
        if (movie.playDurationMsec === undefined || targetMoviePlayOffsetMsec < movie.playDurationMsec) {
          break;
        }
      }
      if (targetMovie && targetMovieIdx !== undefined) {
        await setCurrentMovie(
          lr,
          targetMovie,
          targetMovieIdx,
          targetMoviePlayOffsetMsec,
        );
      }
      state[lr].isSeeking = false;
    };
    const setCurrentMovie = async(lr: LeftRight, movie: GIMovie, movieIdx: number, playOffsetMsec: number) => {
      const realOffsetMsec = (movie.playStartOffsetMsec || 0) + playOffsetMsec;

      // 一旦再生を止める
      const playStateLeft = getUIPlayState('left');
      const playStateRight = getUIPlayState('right');
      pauseUI('left');
      pauseUI('right');
      state.isCurrentMovieReady[lr] = false;

      let prms = Promise.resolve();
      if (!state[lr].currentMovie || state[lr].currentMovie?.id !== movie.id) {
        // 現在再生しているのと異なる動画なので取得する
        state[lr].currentMovie = movie;
        state[lr].currentMovieIdx = movieIdx;
        state[lr].isFirstMovie = movieIdx === 0;
        const currentMl = state[lr].currentMovieList;
        state[lr].isLastMovie = !!currentMl && movieIdx === currentMl.movies.length - 1;
        const urlObj = await getMovieFileUrlObj(movie.id);
        const vjs = vjsMapLR.get(lr);
        const url = urlObj[state.selectedCameraPosition];
        if (!url) {
          invalidateCurrentMovie(lr);
          state.activeVjsLrMap[lr] = false;
          return;
        }
        if (vjs) {
          vjs.src(url);
          state.activeVjsLrMap[lr] = true;
          prms = new Promise((resolve) => {
            vjs.one('loadeddata', () => { resolve(); });
          });
        }
      }
      try {
        await prms;
      } catch (e) { return; }

      // mgiを計算する
      const mgis = state[lr].currentMovie?.movie_geo_indices;
      if (!mgis) {
        return;
      }
      let targetMgi = null;
      let targetMgiIdx = -1;
      for (let i = 0, len = mgis.length; i < len; i++) {
        targetMgi = mgis[i];
        targetMgiIdx = i;
        if (targetMgi.endMsecDiff && realOffsetMsec < targetMgi.endMsecDiff) {
          break;
        }
      }
      state[lr].currentMgi = targetMgi;
      state[lr].currentMgiIdx = targetMgiIdx;
      updateTitleBarTexts(lr);

      const vjs = vjsMapLR.get(lr);
      if (vjs) {
        vjs.currentTime(realOffsetMsec / 1000);
      }
      if (lr === 'left') {
        state.analytics.tmpCalcBase = realOffsetMsec;
      }

      nextTick(() => {
        // 元々再生中だった場合は、再生を再開
        if (playStateLeft === 'playing') {
          playUI('left');
        }
        if (playStateRight === 'playing') {
          playUI('right');
        }
        state.isCurrentMovieReady[lr] = true;
      });
    };
    const trySetNextMovieGeoIndex = (lr: LeftRight) => {
      const currentMovie = state[lr].currentMovie;
      if (!currentMovie) {
        return;
      }
      const mgis = currentMovie.movie_geo_indices;
      const len = mgis.length;
      if (state[lr].currentMgiIdx < len - 1) {
        state[lr].currentMgi = mgis[state[lr].currentMgiIdx + 1];
        state[lr].currentMgiIdx += 1;
      }
      updateTitleBarTexts(lr);
    };
    const invalidateCurrentMovie = (lr: LeftRight) => {
      state[lr].currentMovie = null;
      state[lr].currentMovieIdx = -1;
      state[lr].isFirstMovie = false;
      state[lr].isLastMovie = false;
      state[lr].currentMgi = null;
      state[lr].currentMgiIdx = -1;
      state[lr].currentMovieCurrentDt = null;
    };
    const updateTitleBarTexts = (lr: LeftRight) => {
      const mgi = state[lr].currentMgi;
      if (!mgi) {
        return;
      }
      const dateDisp = dtFormat(mgi.ts, 'yyyy/mm/dd HH:MM:SS') + '~';
      const locationDisp = mgi.locationDisp;
      const vjs = vjsMapLR.get(lr);
      if (vjs) {
        updateVideojsTitleBarText(vjs, { dateDisp, locationDisp });
      }
    };
    const updateMapPinPosition = (lr: LeftRight, { lat, lon }: { lat: string; lon: string }) => {
      const layerMgr = state.geoItemsMeta.map.pin.layerManager as EMPinLayerManager;
      const point = { lat: parseFloat(lat), lon: parseFloat(lon) };
      const mapPinId = `${state.mapPinIdPfx}${lr}`;
      layerMgr.updatePinPosition(mapPinId, point);
    };
    const findClosestMgi = (refMgi: GIMovieGeoIndex, mgis: GIMovieGeoIndex[]) => {
      // 向島(上) -> 江戸橋JCT -> 都環(外) などのように、曲がりくねっている
      // ところの場合は一旦遠ざかってからまた近づくことがありえるので、厳密には
      // 全部見ていかないとわからない.
      // ただし、同一路線名#方向であることは画面的に保証されているので、
      // 十分に近づいたところからdistanceが増えた場合は、通り過ぎたと判断しても
      // よいだろう.
      let minDistance = 999999; // m
      let minDistanceMgi = mgis[0];
      // mgiが10秒間隔として、60km/h走行で200m弱間隔なので、その半分くらい
      const closeEnoughThres = 90; // m
      const distanceMap: Record<number, number> = {};
      for (const [idx, mgi] of mgis.entries()) {
        const distance = getMgiDistance(refMgi, mgi);
        distanceMap[idx] = distance;
        if (minDistance <= closeEnoughThres && distance > minDistance) {
          // 十分に近づいたところからまた遠くなったら、確定でよいだろう
          return minDistanceMgi;
        }
        if (distance < minDistance) {
          minDistance = distance;
          minDistanceMgi = mgi;
        }
      }

      // 十分に近づかなかった場合
      const { idx } = Object.entries(distanceMap).reduce((acc, [idx, distance]) => {
        if (distance < acc.min) {
          acc.min = distance;
          acc.idx = parseInt(idx);
          return acc;
        }
        return acc;
      }, { min: 999999, idx: -1 });
      return mgis[idx];
    };
    const alignLocationToLeftMovie = async() => {
      // 左側に合わせる
      const refMgi = state.left.currentMgi;
      const mgis = state.right.currentMovieList?.movies.map(movie => {
        return movie.movie_geo_indices;
      }).reduce((acc, e) => acc.concat(e), []);
      if (!refMgi || !mgis) { return; }
      const resultMgi = findClosestMgi(refMgi, mgis);
      seekByMgi('right', resultMgi);
    };
    const alignLocationToRightMovie = async() => {
      // 右側に合わせる
      const refMgi = state.right.currentMgi;
      const mgis = state.left.currentMovieList?.movies.map(movie => {
        return movie.movie_geo_indices;
      }).reduce((acc, e) => acc.concat(e), []);
      if (!refMgi || !mgis) { return; }
      const resultMgi = findClosestMgi(refMgi, mgis);
      seekByMgi('left', resultMgi);
    };
    const closeMoviePlayer = () => {
      setAllUIStateToDefault();
      emit('close');
    };
    const setAllUIStateToDefaultInner = async(lr: LeftRight) => {
      pauseUI(lr);
      state[lr].mapElemInfo = null;
      setPlaySpeed(lr, state[lr].defaultPlaySpeed);
      setVolume(lr, 0);
      const controlArea = lr === 'left' ? refControlAreaLeft.value : refControlAreaRight.value;
      const metaInfoArea = lr === 'left' ? refMetaInfoAreaLeft.value : refMetaInfoAreaRight.value;
      if (controlArea) {
        controlArea.setAllUIStateToDefault();
      }
      if (metaInfoArea) {
        metaInfoArea.setAllUIStateToDefault();
      }
    };
    const setAllUIStateToDefault = async() => {
      setAllUIStateToDefaultInner('left');
      setAllUIStateToDefaultInner('right');
      if (refControlAreaBoth.value) {
        refControlAreaBoth.value.setAllUIStateToDefault();
      }
    };
    const updateMap = async() => {
      if (!isCurrentMovieListBothReady.value ||
        !state.left.currentMovieList || !state.right.currentMovieList
      ) {
        return;
      }
      const movieLists = [
        state.left.currentMovieList,
        state.right.currentMovieList,
      ];
      if (!refExtremeMap.value) {
        return;
      }
      refExtremeMap.value.refreshMovieLayer(movieLists);
      refExtremeMap.value.fitToMovieListsExtent(movieLists);
    };
    const playFromBeginning = async(lr: LeftRight, seekPoint: number) => {
      const playSpeed = state[lr].currentPlaySpeed;
      await seekMovie(lr, seekPoint);
      await setAllUIStateToDefaultInner(lr);
      setPlaySpeed(lr, playSpeed);
      playUI(lr);
      if (lr === 'left') { logMoviePlay(PLAYER_TYPE); }
    };
    const seekByMgi = async(lr: LeftRight, mgi?: GIMovieGeoIndex | null) => {
      if (!mgi || !mgi.ts) { return; }
      state[lr].isSeeking = true;

      // movieListの開始と指定された時刻の差に、seek.minのずらし分を考慮
      // 比較モードの場合はルート検索がないので、これでOK
      let tmpOffset = mgi.ts?.getTime() - (state[lr].currentMovieList?.hardStartTs?.getTime() || 0);
      tmpOffset = Math.max(tmpOffset, 0);
      tmpOffset = Math.min(tmpOffset, (state[lr].currentMovieList?.fullDurationMsec || 0));
      const offsetMsec = tmpOffset + state[lr].seek.min;
      await seekMovie(lr, offsetMsec);

      updateMapPinPosition(lr, { lat: mgi.lat, lon: mgi.lon });
      state[lr].isSeeking = false;
    };
    const seekByMapClick = async(data: Location) => {
      const playStateLeft = getUIPlayState('left');
      const playStateRight = getUIPlayState('right');
      // 両方停止する
      pauseUI('left');
      pauseUI('right');
      nextTick(async() => {
        // まず左を動かして、右はそちらの移動先に合わせる
        let searchOpts, mgi;

        if (!state.left.currentMovieList) {
          return;
        }
        searchOpts = { featureIdPrefix: parseInt(state.left.currentMovieList.id) };
        if (!refExtremeMap.value) { return; }
        mgi = refExtremeMap.value.getClosestMgi(data, searchOpts);
        await seekByMgi('left', mgi);
        await alignLocationToLeftMovie();

        nextTick(() => {
          // 元々再生中だった場合は、再生を再開
          if (playStateLeft === 'playing') {
            playUI('left');
          }
          if (playStateRight === 'playing') {
            playUI('right');
          }
        });
      });
    };
    const startTimeTextEdit = async() => {
      // 両方停止する
      pauseUI('left');
      pauseUI('right');
    };
    const seekByTimeText = async(lr: LeftRight, data: { dt: Date; dateDisp: string; timeDisp: string }) => {
      state[lr].isSeeking = true;
      // movieListの開始と指定された時刻の差に、seek.minのずらし分を考慮
      let tmpOffset = data.dt.getTime() - (state[lr].currentMovieList?.hardStartTs?.getTime() || 0);
      tmpOffset = Math.max(tmpOffset, 0);
      tmpOffset = Math.min(tmpOffset, (state[lr].currentMovieList?.fullDurationMsec || 0));
      const offsetMsec = tmpOffset + state[lr].seek.min;
      await seekMovie(lr, offsetMsec);
      // 両方再生する
      playUI('left');
      playUI('right');
      state[lr].isSeeking = false;
    };
    const onMapItemClicked = async() => {
      // TODO
    };
    const onMapAllDeselected = async() => {
      // TODO
    };
    /* smartphoneの場合はホバーで表示/非表示切替できないので、tapで下記になると思う
    toggleVideoToolbox(vid) {
      const flg = state.showVideoToolbox[vid]
      for (const prop in state.showVideoToolbox) {
        state.showVideoToolbox[prop] = false
      }
      state.showVideoToolbox[vid] = !flg
    },
    */
    const onVideoToolboxAreaClicked = (evt: Event) => {
      // toolboxの黒いバー内が押された場合はtoggleVideoToolboxが
      // 呼ばれないようにする
      evt.preventDefault();
      evt.stopImmediatePropagation();
    };
    const enterFullScreen = async(lr: LeftRight) => {
      const vjs = vjsMapLR.get(lr);
      if (vjs) {
        vjs.requestFullscreen();
      }
    };
    const takeScreenshot = async(lr: LeftRight, vid: string) => {
      // 連打防止
      if (state.isDownloadingScreenshot) { return; }

      state.isDownloadingScreenshot = true;
      const movie = state[lr].currentMovie;
      const dt = state[lr].currentMovieCurrentDt;
      const vjs = vjsMap.get(vid);
      if (!movie || !dt || !vjs) { return; }
      takeScreenshotOfVid(movie, dt, vid, vjs);
      // スクショを保存するだけなのですぐ終わるはずだが、
      // 念の為1秒待つ
      setTimeout(() => {
        state.isDownloadingScreenshot = false;
      }, 1000);
    };
    const getCameraPositionForVid = () => {
      // vidにかかわらず、画面で指定した向きのカメラ
      return state.selectedCameraPosition;
    };
    const selectUrlForVid = (urlObj: MovieFileUrlObj) => {
      // vidにかかわらず、画面で指定した向きのURL
      return urlObj[state.selectedCameraPosition];
    };
    const tryDownload = async(lr: LeftRight, vid: string) => {
      const currentMovie = state[lr].currentMovie;
      // 連打防止
      if (state.isDownloadingMovieFile || !currentMovie) { return; }

      state.isDownloadingMovieFile = true;
      await downloadMovieFileOfVid(currentMovie, vid);
      // この時点でダウンロードは完了しているはずだが、
      // 念の為もう1秒待つ
      setTimeout(() => {
        state.isDownloadingMovieFile = false;
      }, 1000);
    };
    const getCurrentMovieListSensorData = async(lr: LeftRight) => {
      const ml = state[lr].currentMovieList;
      if (!ml) { return; }
      const data = await getSensorDataOfMovieList(ml);
      const metaInfoArea = lr === 'left' ? refMetaInfoAreaLeft.value : refMetaInfoAreaRight.value;
      if (metaInfoArea) {
        metaInfoArea.setSensorData(data);
      }
    };
    const startEditMovieTagsOfMovie = async(movie?: GIMovie | null) => {
      if (!movie) {
        return;
      }
      pauseUI('left');
      pauseUI('right');
      state.movieTagModal.movie = movie;
      state.movieTagModal.tags = movie.tags;
      state.movieTagModal.show = true;
    };
    const startEditMovieTags = async(lr: LeftRight) => {
      startEditMovieTagsOfMovie(state[lr].currentMovie);
    };
    const getScreenshotBlob = async(lr: LeftRight, vid: string) => {
      const currentMovie = state[lr].currentMovie;
      const currentMovieCurrentDt = state[lr].currentMovieCurrentDt;
      const vjs = vjsMapLR.get(lr);
      if (!currentMovie || !currentMovieCurrentDt || !vjs) {
        return;
      }
      return getScreenshotBlobOfVid(
        currentMovie,
        currentMovieCurrentDt,
        vid,
        vjs);
    };
    const startCreateMapElem = async(lr: LeftRight, vid: string) => {
      pauseUI('left');
      pauseUI('right');

      const currentMgi = state[lr].currentMgi;
      if (!currentMgi) {
        return;
      }
      const screenshot = await getScreenshotBlob(lr, vid);
      if (!screenshot) {
        return;
      }
      state[lr].mapElemInfo = {
        dataName: 'new',
        data: {
          lat: currentMgi.lat,
          lon: currentMgi.lon,
          kp_uid: currentMgi.kp_uid,
        },
        candidateImages: [screenshot],
      };
    };
    const createMapElem = async(lr: LeftRight, obj: MapElemInfo<Comment>) => {
      if (!props.parentGeoItemsMeta.map[obj.dataName]) {
        console.warn('tryCreateMapElem. Unknown resource type', obj.dataName);
        return;
      }
      const stateMgi = state[lr].currentMgi;
      if (!stateMgi) { return; }

      // 保存ボタン押下時の位置を登録
      obj.data.lat = stateMgi.lat;
      obj.data.lon = stateMgi.lon;

      const metaItem = props.parentGeoItemsMeta.map[obj.dataName];
      const resource = await metaItem.manager.createResource(obj.data);
      if (props.parentGeoItemsMeta.show[obj.dataName]) {
        metaItem.layerManager.addLayerItem(resource);
      }
      state[lr].mapElemInfo = null;
      if (refExtremeMap.value) {
        syncGeoItemWithParent(
          state.geoItemsMeta as GeoItemsMeta,
          props.parentGeoItemsMeta,
          refExtremeMap.value,
        );
      }
    };
    const changeCameraPosition = async(camPos: 'front' | 'left' | 'right') => {
      if (state.selectedCameraPosition === camPos) { return; }

      state.selectedCameraPosition = camPos;
      const playStateLeft = getUIPlayState('left');
      const playStateRight = getUIPlayState('right');
      pauseUI('left');
      pauseUI('right');
      state.isCurrentMovieReady['left'] = false;
      state.isCurrentMovieReady['right'] = false;

      const promises: Array<Promise<{lr: LeftRight; currentTime: number}>> = [];
      for (const [lr, vjs] of vjsMapLR.entries()) {
        const currentTime = vjs.currentTime();
        const currentMovie = state[lr].currentMovie;
        if (!currentMovie) {
          continue;
        }
        const urlObj = await getMovieFileUrlObj(currentMovie.id);
        const url = urlObj[state.selectedCameraPosition];
        if (!url) {
          state.activeVjsLrMap[lr] = false;
          continue;
        }
        vjs.src(url);
        state.activeVjsLrMap[lr] = true;
        promises.push(new Promise((resolve) => {
          vjs.one('loadeddata', () => { resolve({ lr, currentTime }); });
        }));
      }

      try {
        await Promise.all(promises).then(objs => {
          objs.forEach(({ lr, currentTime }) => {
            const vjs = vjsMapLR.get(lr);
            if (vjs) {
              vjs.currentTime(currentTime);
            }
          });
        });
      } catch (e) { return; }

      nextTick(() => {
        // 元々再生中だった場合は、再生を再開
        if (playStateLeft === 'playing') {
          playUI('left');
        }
        if (playStateRight === 'playing') {
          playUI('right');
        }
        state.isCurrentMovieReady['left'] = true;
        state.isCurrentMovieReady['right'] = true;
      });
    };
    const shortcutKeyAction = (key: string) => {
      switch (key) {
        case 'space':
          // 左を正として両方操作
          const isLeftPlaying = refControlAreaLeft.value && refControlAreaLeft.value.getPlayState() === 'playing';
          if (isLeftPlaying) {
            pauseUI('both');
          } else {
            playUI('both');
          }
          break;
        case 'left':
          tickMovieBackward('both');
          break;
        case 'right':
          tickMovieForward('both');
          break;
        case 'escape':
          if (state.movieTagModal.show) {
            state.movieTagModal.show = false;
          }
          break;
      }
    };
    const switchToFullMode = () => {
      setAllUIStateToDefault();
      const params: SwitchToFullModeParams = {
        leftMovieListId: state.left.currentMovieListId,
        rightMovieListId: state.right.currentMovieListId,
      };
      emit('switch-to-full-mode', params);
    };
    const openInfraDoctor = (lr: LeftRight) => {
      const currentMovie = state[lr].currentMovie;
      const currentMl = state[lr].currentMovieList;
      const currentMgi = state[lr].currentMgi;
      if (!state.isCurrentMovieReady[lr] ||
          !currentMovie ||
          !currentMl ||
          !currentMgi) { return; }
      const params = {
        ifdLinkPrefix: ifdLink.value,
        currentMovieList: currentMl,
        currentMovie: currentMovie,
        currentMovieIdx: state[lr].currentMovieIdx,
        currentMgi: currentMgi,
        currentMgiIdx: state[lr].currentMgiIdx,
        isFirstMovie: state[lr].isFirstMovie,
        isLastMovie: state[lr].isLastMovie,
      };
      openNewInfraDoctorTab(params);
    };
    const onResize = () => {
      if (!isCurrentMovieListBothReady.value) { return; }
      const windowW = window.innerWidth;
      const windowH = window.innerHeight;
      // モーダルのmarginおよびpadding, video間のmargin
      const wMargins = (8 * 2 + 8 * 2) + (2 * 6);
      const hMargins = 6 * 2 + 8 * 2;
      const videoW = (windowW - wMargins) / 3;
      const videoH = videoW / videoAspectRatio;
      const videoWMargins = 8;
      state.styles.videoAreaWidth = parseInt((videoW * 2 + videoWMargins).toString()) + 'px';
      state.styles.videoAreaHeight = parseInt(videoH.toString()) + 'px';
      nextTick(() => {
        if (!refTopBarArea.value || !refVideoArea.value) { return; }
        const topBarH = refTopBarArea.value.clientHeight;
        const videoAreaH = refVideoArea.value.clientHeight;
        const otherHMargins = 16 + 8;
        const infoH = windowH - hMargins - topBarH - videoAreaH - otherHMargins;
        state.styles.infoHeight = infoH + 'px';
        resizeMap();
      });
      if (windowW >= 1400) {
        state.sizes.control = 'md';
        state.sizes.meta = 'md';
      } else if (windowW >= 1000) {
        state.sizes.control = 'sm';
        state.sizes.meta = 'sm';
      } else {
        state.sizes.control = 'xs';
        state.sizes.meta = 'xs';
      }
    };
    const resizeMap = () => {
      const mapHeight = parseInt(state.styles.infoHeight);
      if (!refExtremeMap.value) { return; }
      refExtremeMap.value.setMapHeight(mapHeight);
      refExtremeMap.value.triggerResize();
    };
    const updateMovieTags = (resultTags: MovieTag[]) => {
      if (!state.movieTagModal.movie) {
        return;
      }
      updateMovieTagsHelper(resultTags, state.movieTagModal.movie);
      state.movieTagModal.show = false;
    };

    // others
    const videoAspectRatio = 16 / 9;
    const mapElemIconPath = '/static/img/comment_icon_01.png';

    return {
      ...toRefs(state),
      // refs
      refTopBarArea,
      refVideoArea,
      refExtremeMap,
      refControlAreaBoth,
      refControlAreaLeft,
      refControlAreaRight,
      refMetaInfoAreaLeft,
      refMetaInfoAreaRight,
      // computed
      ifdLink,
      remainingMovieRatesByDateRanges,
      vid1,
      vid2,
      isCurrentMovieListLeftReady,
      isCurrentMovieListRightReady,
      isCurrentMovieListBothReady,
      playerControlCurrentTimeDispLeft,
      playerControlCurrentTimeDispRight,
      visibleGeoItemLayers,
      isMovieSearchResultModeDetail,
      isMovieSearchResultModeArea,
      // methods
      getMovieDateChoices,
      initializeDataManagers,
      refreshMovieLists,
      searchMoviesByDateRange,
      findFirstSeekPoint,
      onCurrentMovieListIdChange,
      initializeViews,
      prepareVideoPlayers,
      getVideoJsDefaultOptions,
      setVideojsEventListeners,
      prepareVideojs,
      onVjsTimeUpdate,
      prepareMap,
      setMoviePlayerControlDispVals,
      playMovie,
      pauseMovie,
      playUI,
      pauseUI,
      getUIPlayState,
      tickMovieBackward,
      tickMovieForward,
      playPreviousMovie,
      playNextMovie,
      setPlaySpeed,
      setVolume,
      playModeChange,
      seekBySeekBar,
      seekMovie,
      setCurrentMovie,
      trySetNextMovieGeoIndex,
      invalidateCurrentMovie,
      updateTitleBarTexts,
      updateMapPinPosition,
      findClosestMgi,
      alignLocationToLeftMovie,
      alignLocationToRightMovie,
      closeMoviePlayer,
      setAllUIStateToDefault,
      updateMap,
      playFromBeginning,
      seekByMgi,
      seekByMapClick,
      startTimeTextEdit,
      seekByTimeText,
      onMapItemClicked,
      onMapAllDeselected,
      onVideoToolboxAreaClicked,
      enterFullScreen,
      takeScreenshot,
      getCameraPositionForVid,
      selectUrlForVid,
      tryDownload,
      getCurrentMovieListSensorData,
      startEditMovieTagsOfMovie,
      startEditMovieTags,
      getScreenshotBlob,
      startCreateMapElem,
      createMapElem,
      changeCameraPosition,
      shortcutKeyAction,
      switchToFullMode,
      openInfraDoctor,
      updateMovieTags,
      dtFormat,
      // others
      videoAspectRatio,
      mapElemIconPath,
    };
  },
  components: {
    MoviePlayerControlArea,
    MovieMetaInfoArea,
    MapElemInfoNew,
  },
});
