import videojs, { VideoJsPlayer } from 'video.js';

export interface TextObj {
  dateDisp: string;
  locationDisp?: string;
}

interface CustomTitleBarOptions {
  textObj?: TextObj;
}

interface CustomControlBarOptions {
  vid: string;
  playerType: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const VjsComponent = videojs.getComponent('Component');

class CustomTitleBar extends VjsComponent {
  constructor(player: VideoJsPlayer, options: CustomTitleBarOptions) {
    super(player);
    if (options.textObj) {
      this.updateTextContent(options.textObj);
    }
  }

  createEl() {
    return videojs.dom.createEl('div', {
      className: 'vjs-custom-title-bar',
    });
  }

  updateTextContent({ dateDisp, locationDisp }: TextObj) {
    videojs.dom.emptyEl(this.el());
    const span1 = videojs.dom.createEl(
      'span', {}, {'class': 'ib mr12'}, dateDisp);
    const span2 = videojs.dom.createEl(
      'span', {}, {'class': 'ib'}, locationDisp);
    videojs.dom.appendContent(this.el(), span1);
    videojs.dom.appendContent(this.el(), span2);
  }
}

class CustomControlBar extends VjsComponent {
  constructor(player: VideoJsPlayer, options: CustomControlBarOptions) {
    super(player);
    this.setPlaybackControls(options.vid, options.playerType);
  }

  createEl() {
    return videojs.dom.createEl('div', {
      className: 'vjs-custom-control-bar',
    });
  }

  setPlaybackControls(vid: string, playerType: string) {
    videojs.dom.emptyEl(this.el());
    const div = videojs.dom.createEl('div', {}, {'class': 'playback-control'}, '');
    let innerHTML = '';
    if (playerType === 'full') {
      innerHTML += `
        <div class="ib vm control-btn vjs-play-60s-backward-movie">
          <img src="/static/img/60s_back.png" class="sec-control-btn">
        </div>
        <div class="ib vm control-btn vjs-play-1s-backward-movie">
          <img src="/static/img/1s_back.png" class="sec-control-btn">
        </div>`;
    }
    innerHTML += `
      <div class="ib vm control-btn vjs-tick-movie-backward">
        <i class="fas fa-backward"></i>
      </div>
      <div class="ib vm control-btn vjs-play-movie">
        <i class="fa-lg far fa-play-circle"></i>
      </div>
      <div class="ib vm control-btn pause vjs-pause-movie">
        <i class="fa-lg far fa-pause-circle"></i>
      </div>
      <div class="ib vm control-btn vjs-tick-movie-forward">
        <i class="fas fa-forward"></i>
      </div>`;
    if (playerType === 'full') {
      innerHTML += `
        <div class="ib vm control-btn vjs-play-1s-forward-movie">
          <img src="/static/img/1s_foward.png" class="sec-control-btn">
        </div>
        <div class="ib vm control-btn vjs-play-60s-forward-movie">
          <img src="/static/img/60s_foward.png" class="sec-control-btn">
        </div>`;
    }
    innerHTML += `
      <div class="ib custom-control custom-checkbox backward-checkbox">
        <input type="checkbox" class="custom-control-input vjs-play-movie-backward" id="is-playbackward-${vid}">
        <label class="custom-control-label" for="is-playbackward-${vid}">逆再生</label>
      </div>`;
    div.innerHTML = innerHTML;
    videojs.dom.appendContent(this.el(), div);
  }
}

class CustomContainer extends VjsComponent {
  createEl() {
    return videojs.dom.createEl('div', {
      className: 'vjs-custom-container1',
    });
  }
}

export function getVideojs(vid: string, defaultOptions: videojs.PlayerOptions, playerType: string): VideoJsPlayer {
  const vjs = videojs(vid, defaultOptions);
  // カスタムコンポーネントを登録
  vjs.addChild('CustomContainer');
  const customContainer = vjs.getChild('CustomContainer')!;
  customContainer.addChild('CustomTitleBar');
  customContainer.addChild('CustomControlBar', { vid, playerType });
  return vjs;
}

export function getVideojsControlBar(vjs: VideoJsPlayer): CustomControlBar {
  return vjs.getChild('CustomContainer')!.getChild('CustomControlBar') as CustomControlBar;
}

function getVideojsTitleBar(vjs: VideoJsPlayer): CustomTitleBar {
  return vjs.getChild('CustomContainer')!.getChild('CustomTitleBar') as CustomTitleBar;
}

export function updateVideojsTitleBarText(vjs: VideoJsPlayer, textObj: TextObj): void {
  const titleBar = getVideojsTitleBar(vjs);
  if (!titleBar) { return; }
  titleBar.updateTextContent(textObj);
}

export function registerVideojsComponents(): void {
  videojs.registerComponent('CustomTitleBar', CustomTitleBar);
  videojs.registerComponent('CustomControlBar', CustomControlBar);
  videojs.registerComponent('CustomContainer', CustomContainer);
}
