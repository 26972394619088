



































import GeoItemSearchCommon from './GeoItemSearchCommon.vue';
import { detectionKindsMap, detectionKinds } from '@/consts/ext_land_ai_result';
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import { DetectionsIndexParams } from 'src/models/apis/extLandAiDetections/extLandAiDetectionsRequest';

interface searchParams {
  contour_area_from: number | null;
  contour_area_to: number | null;
}

interface GeoItemSearchLandAiDetectopmsState {
  detectionKindsDefaultValues: string[];
  showContourAreaRange: boolean;
  search: searchParams;
}

export default defineComponent({
  name: 'geo-item-search-land-ai-detections',
  setup(_, { emit }) {
    const initSearchState = (): searchParams => {
      return {
        contour_area_from: null,
        contour_area_to: null,
      };
    };
    const state = reactive<GeoItemSearchLandAiDetectopmsState>({
      detectionKindsDefaultValues: ['pothole'],
      showContourAreaRange: false,
      search: initSearchState(),
    });
    const setInitialSearchParams = () => {
      const params = {
        contour_area_from: 3000,
        contour_area_to: 10000,
      };
      state.search = params;
    };
    onMounted(setInitialSearchParams);

    // computed
    const contourAreaRangeDisp = computed(() => {
      if (!state.search.contour_area_from && !state.search.contour_area_to) { return ''; }
      const ret = `${state.search.contour_area_from}〜${state.search.contour_area_to}`.replace('null', '');
      return `（${ret}）`;
    });
    const refSearchCommon = ref<InstanceType<typeof GeoItemSearchCommon>>();
    // methods
    const getSearchParams = (): DetectionsIndexParams | null => {
      if (!refSearchCommon.value) { return null; }
      const {
        search_types: detectionKinds,
        ts_from: startTs,
        ts_to: endTs,
        ...other
      } = refSearchCommon.value.getSearchParams();
      return {
        detection_kinds: detectionKinds as string[],
        start_ts: startTs,
        end_ts: endTs,
        road_names: other.road_name_disps,
        directions: other.directions,
        kp_calc_from: other.kp_calc_from,
        kp_calc_to: other.kp_calc_to,
        contour_area_from: state.search.contour_area_from,
        contour_area_to: state.search.contour_area_to,
      };
    };
    const onChange = () => {
      emit('change');
    };

    // others
    const checkboxKeyPrefix = 'dtcs';

    return {
      ...toRefs(state),
      // refs
      refSearchCommon,
      // computed
      contourAreaRangeDisp,
      // methods
      getSearchParams,
      onChange,
      // others
      checkboxKeyPrefix,
      detectionKindsMap,
      detectionKinds,
    };
  },
  components: { GeoItemSearchCommon },
});
