



















































































































































































































































































































































































































































































































































































































































































import ThreeAxisSensorChart from '@/components/lib/ThreeAxisSensorChart/index.vue';
import TagModal from '@/components/lib/TagModal.vue';
import { defineComponent, reactive, toRefs } from '@vue/composition-api';
import { MovieTag } from '@/models/apis/movie/movieResponse';
import { SensorData } from '@/models/apis/mtx/mtxResponse';

interface MovieInfo {
  date: string;
  time: string;
  location: string;
  speed: string;
  tags: Partial<MovieTag>[];
  sensorData: SensorData[];
  showTagModal: boolean;
  showSensorChart: boolean;
}

interface Spinners {
  showSpinner1: boolean;
  showSpinner2: boolean;
}

interface UIShowCaseState {
  modals: {
    showModal1: boolean;
    showModal2: boolean;
    showModal3: boolean;
    showModal4: boolean;
    showModal5: boolean;
  };
  tabs: {
    activeTab: string;
  };
  spinners: Spinners;
  mpParts: {
    isType1: boolean;
    isType2: boolean;
  };
  movieInfo: MovieInfo;
}

export default defineComponent({
  name: 'dev-debug-ui-showcase',
  setup() {
    const state = reactive<UIShowCaseState>({
      modals: {
        showModal1: false,
        showModal2: false,
        showModal3: false,
        showModal4: false,
        showModal5: false,
      },
      tabs: {
        activeTab: 'tab1',
      },
      spinners: {
        showSpinner1: false,
        showSpinner2: false,
      },
      mpParts: {
        isType1: true,
        isType2: false,
      },
      movieInfo: {
        date: '2018/11/29',
        time: '12:34:20',
        location: '高速中央環状線（東京東地区） 上 40.12',
        speed: '44',
        tags: [
          { id: 1, tag_name: '長期保存', tag_type1: 'system', tag_type2: 'default' },
          { id: 4, tag_name: '要確認', tag_type1: 'user', tag_type2: 'default' },
          { id: 6, tag_name: 'ポットホール', tag_type1: 'user', tag_type2: 'default' },
        ],
        sensorData: [
          {
            movie_id: 0,
            start_ts: '2019-02-02 08:11:00+0900',
            ds: [0, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000,
              30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000],
            vs: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 10, 25, 40, 43, 45, 43, 44, 44, 25, 0],
            xs: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 0, 1, 0, 0, 0, 1, 0],
            ys: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 1, 1, 1, 0, 1, 1, 1, 0],
            zs: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 1, 0, 1, 0, 1, 1, 1],
          },
        ],
        showTagModal: false,
        showSensorChart: false,
      },
    });
    const boxes = {
      searchResultTest1: [
        { text1: '2018/11/15 00:00:00 ~', text2: '0.2', selected: false },
        { text1: '2018/11/14 00:00:00 ~', text2: '0.3', selected: false },
        { text1: '2018/11/13 00:00:00 ~', text2: '0.4', selected: true },
      ],
    };
    const routeSearches = {
      arr1: [{v: null, isFirst: true}, {v: null, isLast: true}],
      arr2: [{v: 1, isFirst: true}, {v: 2}, {v: 3, isLast: true}],
    };

    // methods:
    const showSpinner = (prop: 'showSpinner1' | 'showSpinner2') => {
      state.spinners[prop] = true;
      window.requestTimeout(() => {
        state.spinners[prop] = false;
      }, 3000);
    };
    const showSpinner1 = () => {
      showSpinner('showSpinner1');
    };
    const showSpinner2 = () => {
      showSpinner('showSpinner2');
    };
    const mpPartSwitchType = () => {
      if (state.mpParts.isType1) {
        state.mpParts.isType1 = false;
        state.mpParts.isType2 = true;
      } else {
        state.mpParts.isType1 = true;
        state.mpParts.isType2 = false;
      }
    };

    return {
      ...toRefs(state),
      // others
      boxes,
      routeSearches,
      showSpinner1,
      showSpinner2,
      mpPartSwitchType,
    };
  },
  components: { ThreeAxisSensorChart, TagModal },
});
