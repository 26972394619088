import axios, { AxiosResponse } from 'axios';
import * as utils from './apiUtils';
import { DetectionsIndexParams, DetectionsGetImageParams } from 'src/models/apis/extLandAiDetections/extLandAiDetectionsRequest';
import { DetectionsIndexResponse } from 'src/models/apis/extLandAiDetections/extLandAiDetectionsResponse';

export default {
  index(params: DetectionsIndexParams): Promise<AxiosResponse<DetectionsIndexResponse>> {
    return axios.get(utils.getApiUrl('/ext/land-ai/detections'), {
      params: params || {},
    });
  },
  getImage(params: DetectionsGetImageParams): Promise<AxiosResponse<Blob>> {
    return axios.get(utils.getApiUrl(`/ext/land-ai/image`), {
      params: params || {},
      responseType: 'blob',
    });
  },
};
