import GIAbstractManager from '@/lib/geo_item/GIAbstractManager';
import extLandApEmergencyApi from '@/apis/extLandApEmergency';
import GeoItemSearchLandApEmergency from '@/components/Top/geoItemSearchComponents/GeoItemSearchLandApEmergency.vue';
import { EmergencyIndexParams } from 'src/models/apis/extLandApEmergency/extLandApEmergencyRequest';
import { GIEmergency } from 'src/models/geoItem';
import { Emergency } from 'src/models/apis/extLandApEmergency/extLandApEmergencyResponse';

export default class GILandApEmergencyManager extends GIAbstractManager {
  async getResources(
    componentRef: InstanceType<typeof GeoItemSearchLandApEmergency>,
  ): Promise<GIEmergency[]> {
    const params = componentRef.getSearchParams();
    if (!params) {
      return [];
    }
    return this.getResourcesByParams(params);
  }

  async getResourcesByParams(params: EmergencyIndexParams): Promise<GIEmergency[]> {
    try {
      const { data } = await extLandApEmergencyApi.index(params);
      return this._convData(data, params);
    } catch (e) {
      return [];
    }
  }

  private _convData(data: Emergency[], params: EmergencyIndexParams): GIEmergency[] {
    const filteredData = data
      .filter(e => {
        // 選択した種別でフィルタ、カメラは正面のみ
        return params.movie_types.includes(e.movie_type) && e.camera_id === 1;
      })
      .filter(e => {
        // 緊急通報については、地点なしのものは除外する
        return parseFloat(e.lat.toString()) !== 0.0 && parseFloat(e.lon.toString()) !== 0.0;
      });
    const results: GIEmergency[] = [];
    filteredData.forEach(e => {
      results.push({
        ...e,
        lat: parseFloat(e.lat.toString()),
        lon: parseFloat(e.lon.toString()),
        isSelected: false,
      });
    });
    // 同一地点の場合日付が新しいものが上
    results.sort((a, b) => {
      return a.ts < b.ts ? -1 : a.ts > b.ts ? 1 : 0;
    });
    return results;
  }

  mergeStateInfo(newResources: GIEmergency[], currentResources: GIEmergency[]): GIEmergency[] {
    const currentResourceMap = currentResources.reduce<Record<string, GIEmergency>>((acc, e) => {
      acc[e.id] = e; return acc;
    }, {});
    newResources.forEach(e => {
      if (currentResourceMap[e.id]) {
        e.isSelected = currentResourceMap[e.id].isSelected;
      }
    });
    return newResources;
  }

  async createResource(): Promise<any> {
    console.warn('GIemergencyManager#createResource is noop.');
  }

  async getResourceById(): Promise<any> {
    console.warn('GIemergencyManager#getResourceById is noop.');
  }

  async updateResource(): Promise<any> {
    console.warn('GIemergencyManager#updateResource is noop.');
  }

  async deleteResource(): Promise<any> {
    console.warn('GIemergencyManager#deleteResource is noop.');
  }
}
