import EMAbstractLayerManager, { LayerWithInfo } from '@/lib/extreme_map/EMAbstractLayerManager';
import { Feature } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { LineString, MultiPoint, Point } from 'ol/geom';
import VectorSource from 'ol/source/Vector';
import { Circle, Fill, Icon, Stroke, Style, Text } from 'ol/style';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';
import { GIDebugMTX } from '@/models/geoItem';
import { Mtx, MtxMovie } from '@/models/apis/mtx/mtxResponse';
import { NamedVectorLayer } from '@/lib/OlMapWrapper';

export default class EMDebugMTXMovieLayerManager extends EMAbstractLayerManager {
  layerName: string;

  constructor() {
    super();
    this.layerName = 'debugMtxMovie';
  }

  formatDt(date: string): string {
    const dt = new Date(date);
    const y = dt.getFullYear();
    const mon = ('0' + (dt.getMonth() + 1)).slice(-2);
    const d = ('0' + dt.getDate()).slice(-2);
    const h = ('0' + dt.getHours()).slice(-2);
    const min = ('0' + dt.getMinutes()).slice(-2);
    const s = ('0' + dt.getSeconds()).slice(-2);
    const ts = `${y}-${mon}-${d} ${h}:${min}:${s}`;
    return ts;
  }

  getMtxsFeatures(mtxs: Mtx[]): Feature[] {
    const line: Coordinate[] = [];
    const pointsUnderCalc: Coordinate[] = [];
    const pointsNoKp: Coordinate[] = [];
    const pointsYesKp: Coordinate[] = [];
    const texts: Array<{ mtx: Mtx; pt: Coordinate }> = [];
    mtxs.forEach((mtx, i) => {
      const lon = parseFloat(mtx.lon);
      const lat = parseFloat(mtx.lat);
      if (lon < 0.000001 || lat < 0.000001) { return; }
      const pt = this.coordFromLonLat(lon, lat);
      line.push(pt);
      if (!mtx.is_place_filled) {
        pointsUnderCalc.push(pt);
      } else if (!mtx.kilopost) {
        pointsNoKp.push(pt);
      } else {
        pointsYesKp.push(pt);
      }
      if (mtx.showTimestamp && i % 15 === 0) {
        texts.push({ mtx, pt });
      }
    });

    const ret = [];
    let feat: Feature = new Feature(new LineString(line));
    feat.setStyle(new Style({
      stroke: new Stroke({ color: '#bc42f4', width: 0.5 }),
    }));
    ret.push(feat);

    feat = new Feature(new MultiPoint(pointsUnderCalc));
    feat.setStyle(new Style({
      image: new Circle({
        radius: 1,
        fill: new Fill({ color: '#bc42f4' }),
        stroke: new Stroke({ color: '#555555', width: 1 }),
      }),
    }));
    ret.push(feat);

    feat = new Feature(new MultiPoint(pointsNoKp));
    feat.setStyle(new Style({
      image: new Circle({
        radius: 2,
        fill: new Fill({ color: '#222222' }),
        stroke: new Stroke({ color: '#000000', width: 1 }),
      }),
    }));
    ret.push(feat);

    feat = new Feature(new MultiPoint(pointsYesKp));
    feat.setStyle(new Style({
      image: new Circle({
        radius: 3,
        fill: new Fill({ color: '#9de532' }),
        stroke: new Stroke({ color: '#3c5d0c', width: 1 }),
      }),
    }));
    ret.push(feat);

    const feats = texts.map(({ mtx, pt }) => {
      const ts = this.formatDt(mtx.ts).slice(-8);
      const text = ts;
      const feat = new Feature(new Point(pt));
      feat.setStyle(new Style({
        text: new Text({
          text: text,
          font: '8px sans-serif',
          fill: new Fill({ color: '#eeeeee' }),
          stroke: new Stroke({ color: [50, 50, 50, 0.8], width: 3 }),
          offsetX: 0,
          offsetY: 6,
        }),
      }));
      return feat;
    });
    ret.push(...feats);

    return ret;
  }

  getMoviesFeatures(movies: MtxMovie[]): Feature[] {
    const moviePts: Coordinate[] = [];
    const giPts: Coordinate[] = [];
    movies.forEach(movie => {
      const lon = parseFloat(movie.lon);
      const lat = parseFloat(movie.lat);
      if (isNaN(lon) || isNaN(lat)) { return; }
      if (lon < 0.000001 || lat < 0.000001) { return; }
      const pt = this.coordFromLonLat(lon, lat);
      moviePts.push(pt);
      movie.movie_geo_indices.forEach(gi => {
        const lon = parseFloat(gi.lon);
        const lat = parseFloat(gi.lat);
        if (isNaN(lon) || isNaN(lat)) { return; }
        if (lon < 0.000001 || lat < 0.000001) { return; }
        const pt = this.coordFromLonLat(lon, lat);
        giPts.push(pt);
      });
    });

    const movieMp = new MultiPoint(moviePts);
    const movieMpFeat = new Feature(movieMp);
    movieMpFeat.setStyle(new Style({
      image: new Icon({
        src: '/static/img/dbg-component-video-file.png',
        anchor: [0.5, 0.5],
        anchorXUnits: IconAnchorUnits.FRACTION,
        anchorYUnits: IconAnchorUnits.FRACTION,
        scale: 0.04,
      }),
    }));

    const giMp = new MultiPoint(giPts);
    const giMpFeat = new Feature(giMp);
    giMpFeat.setStyle(new Style({
      image: new Icon({
        src: '/static/img/dbg-component-video-camera.png',
        anchor: [0.5, 0.5],
        anchorXUnits: IconAnchorUnits.FRACTION,
        anchorYUnits: IconAnchorUnits.FRACTION,
        scale: 0.04,
      }),
    }));

    return [movieMpFeat, giMpFeat];
  }

  createLayer_(resource: GIDebugMTX): void {
    const features = [];
    features.push(...this.getResourceFeatures_(resource));
    const layer = new NamedVectorLayer(this.layerName, {
      visible: true,
      source: new VectorSource({features: features}),
    });
    this.layer = layer;
  }

  prepareLayer(data: GIDebugMTX): LayerWithInfo {
    this.createLayer_(data);
    return this.getLayer();
  }

  getResourceFeatures_({ mtxs, movies }: GIDebugMTX): Feature[] {
    const mtxFeats = this.getMtxsFeatures(mtxs);
    const movieFeats = this.getMoviesFeatures(movies);
    return [...mtxFeats, ...movieFeats];
  }

  getResourceStyles_(): Style[] {
    throw new Error('Method not implemented.');
  }
}
