


import EMMovieLayerManager from '@/lib/extreme_map/EMMovieLayerManager';
import EMQueryAreaLayerManager from '@/lib/extreme_map/EMQueryAreaLayerManager';
import EMKilopostLayerManager from '@/lib/extreme_map/EMKilopostLayerManager';
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  onUnmounted,
  PropType,
  reactive,
  ref,
  toRefs,
  watch,
} from '@vue/composition-api';
import OlMapWrapper, { MapFeatureInfo, PopupLayer } from '@/lib/OlMapWrapper';
import Vue from 'vue';
import { GisLayer, Settings as UserSettings } from '@/models/apis/user/userResponse';
import {
  KpMap,
  Location,
  QueryArea,
} from '@/models';
import EMAbstractLayerManager from '@/lib/extreme_map/EMAbstractLayerManager';
import MapBrowserEvent from 'ol/MapBrowserEvent';
import { Coordinate } from 'ol/coordinate';
import { JointFeatureCollection, PoleFeatureCollection } from '@/models/apis/getGeoInfoResponse';
import {
  GeoItemMeta,
  GIMovieList,
  GIResource,
  GIDebugMTX,
} from '@/models/geoItem';
import { FitOptions } from 'ol/View';

interface LayerZIndexMap {
  kpLine: number;
  kp: number;
  car: number;
  queryArea: number;
  movie: number;
  currentLayerZIndex: number;
}

interface MapEssentials {
  userSettings: UserSettings;
  kpMap: KpMap;
}

interface ExtremeMapState {
  isDisplayInitialized: boolean;
  queryAreaLayerMgr: EMQueryAreaLayerManager;
  movieLayerMgr: EMMovieLayerManager;
  kpLayerMgr: EMKilopostLayerManager;
  geoItemLayerNameMap: Record<string, GeoItemMeta>;
  layerZIndexMap: LayerZIndexMap;
  onResizeFunc: () => void;
  mapHeight: number;
  popupLayerObj: PopupLayer | null;
  userSettings: UserSettings;
  kpMap: KpMap;
}

export default defineComponent({
  name: 'extreme-map',
  props: {
    mapEssentials: {
      type: Object as PropType<MapEssentials>,
      default: () => {
        return null;
      },
    },
    disableMovieLayerInteraction: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const initLayerZIndexMap = (): LayerZIndexMap => {
      return {
        kpLine: 10,
        kp: 15,
        car: 50,
        queryArea: 80,
        movie: 81,
        currentLayerZIndex: 1000,
      };
    };
    const state = reactive<ExtremeMapState>({
      isDisplayInitialized: false,
      queryAreaLayerMgr: new EMQueryAreaLayerManager({
        dataName: 'queryArea', giManager: null,
      }),
      movieLayerMgr: new EMMovieLayerManager({
        dataName: 'movie', giManager: null,
      }),
      kpLayerMgr: new EMKilopostLayerManager({
        isDebugShowGeoConnections: Vue.prototype.$isDebugShowGeoConnections,
        isDebugShowKpAllLayer: Vue.prototype.$isDebugShowKpAllLayer,
      }),
      geoItemLayerNameMap: {},
      layerZIndexMap: initLayerZIndexMap(),
      onResizeFunc: () => {},
      mapHeight: window.innerHeight - 160,
      popupLayerObj: null,
      // vue管理しない
      // layerMgrMap: {},
      userSettings: {} as UserSettings,
      kpMap: new Map(),
    });
    // connectWithExtremeMapのパラメータに"this"を渡す必要があり、ここでインスタンスを保持する
    const currentInstance = getCurrentInstance();
    const uid = currentInstance?.uid;
    const layerMgrMap: Record<string, EMAbstractLayerManager> = {};

    const olMap = new OlMapWrapper();
    const hideQueryArea = () => {
      state.queryAreaLayerMgr.hideQueryArea();
    };
    const hidePopup = () => {
      if (state.popupLayerObj === null) { return; }
      const { popupOverlay, popupContent } = state.popupLayerObj;
      popupContent.innerHTML = '';
      popupOverlay.setPosition(undefined);
    };
    const notifyDeselectAll = () => {
      hideQueryArea();
      state.movieLayerMgr.deselectAll();
      // 各データレイヤー
      for (const ent of Object.entries(layerMgrMap)) {
        const layerMgr = ent[1];
        layerMgr.deselectAll();
      }
      // ポップアップ
      hidePopup();
      // 外部
      emit('all-deselected');
    };
    const clickedMapBackground = (evt: MapBrowserEvent) => {
      // 各レイヤーや外部に、別のとこが選択されたことを通知する
      notifyDeselectAll();

      let [lon, lat] = olMap.convCoord(
        { lon: evt.coordinate[0], lat: evt.coordinate[1] },
        { srcProj: 'EPSG:3857', destProj: 'EPSG:4326' },
      );
      lon = parseFloat(lon.toFixed(6));
      lat = parseFloat(lat.toFixed(6));
      emit('click-map', { lon, lat });
    };
    const clickedMapFeature = (evt: MapBrowserEvent, info: MapFeatureInfo) => {
      // 各レイヤーや外部に、別のとこが選択されたことを通知する
      notifyDeselectAll();

      if (info.name === 'poles') {
        olMap.showPolePopupFromFeatureInfo(
          state.popupLayerObj as PopupLayer, info.data as PoleFeatureCollection, evt.coordinate);
      } else if (info.name === 'joints') {
        olMap.showJointPopupFromFeatureInfo(
          state.popupLayerObj as PopupLayer, info.data as JointFeatureCollection, evt.coordinate);
      }
    };
    const setMapClickEvent = () => {
      olMap.onMapSingleClick((evt: MapBrowserEvent) => {
        if (evt.originalEvent.defaultPrevented) { return; }

        olMap.getMapFeatureInfo(evt).then((info: MapFeatureInfo) => {
          if (!info) {
            clickedMapBackground(evt);
          } else {
            clickedMapFeature(evt, info);
          }
        });
      });
    };
    const setMapMoveEndEvent = () => {
      let currentZoom = olMap.getZoom();
      olMap.onMapMoveEnd(() => {
        const newZoom = olMap.getZoom();
        if (newZoom !== currentZoom) {
          const evtObj = { zoom: newZoom, oldZoom: currentZoom };
          state.kpLayerMgr.refreshLayersOnZoomChange(evtObj);
          state.movieLayerMgr.refreshLayerOnZoomChange(evtObj);
          emit('zoom-changed', evtObj);
          currentZoom = newZoom;
        }
        const moveEndEvtObj = {
          zoom: newZoom,
          extent: olMap.getExtent(),
        };
        state.kpLayerMgr.refreshLayersOnMoveEnd(moveEndEvtObj);
      });
    };
    const initResizeFunc = () => {
      // resize map on window resize
      state.onResizeFunc = () => {
        const elem = document.getElementById(olMapId.value);
        if (elem === null) { return; }
        elem.style.height = `${state.mapHeight}px`;
        olMap.updateMapSize();
      };
      state.onResizeFunc();
      window.addEventListener('resize', state.onResizeFunc);
    };
    const initializeQueryAreaLayer = () => {
      const { layer } = state.queryAreaLayerMgr.prepareLayer();
      if (!layer) {
        return;
      }
      layer.setZIndex(state.layerZIndexMap.queryArea);
      olMap.addLayer(layer);
    };
    const initializeMovieLayer = () => {
      const { layer, layerInfo } = state.movieLayerMgr.prepareLayer([]);
      if (!layer) {
        return;
      }
      layer.setZIndex(state.layerZIndexMap.movie);
      olMap.addLayer(layer, {
        interaction: {
          click: layerInfo.onLayerClick,
        },
      });
      state.movieLayerMgr.connectWithExtremeMap(currentInstance?.proxy);
    };

    const refPopupContainer = ref<HTMLElement>();
    const refPopupCloser = ref<HTMLElement>();
    const refPopupContent = ref<HTMLElement>();
    onMounted(() => {
      olMap.initMapManager({
        element: `ol-map-${uid}`,
        center: olMap.convCoord({lat: 35.679100, lon: 139.756932}),
        maxZoom: 18,
        minZoom: 4,
        zoom: 10,
        enablePan: true,
        enableZoomButton: true,
        enableMouseWheelZoom: true,
        enableDoubleClickZoom: true,
        enableScaleLine: true,
        enableAttribution: true,
      });

      olMap.addLayer(olMap.getKokudoChiriinLayer());

      if (refPopupContainer.value && refPopupCloser.value && refPopupContent.value) {
        state.popupLayerObj = olMap.getPopupLayer({
          popupContainer: refPopupContainer.value,
          popupCloser: refPopupCloser.value,
          popupContent: refPopupContent.value,
        });
        olMap.addPopupLayer(state.popupLayerObj as PopupLayer);
      }

      setMapClickEvent();
      setMapMoveEndEvent();
      olMap.enableLayerSwitcher();
      initResizeFunc();
      initializeQueryAreaLayer();

      // 今後のrefactorでmovieLayerManagerも外から渡すようにすれば
      // いらなくなるので消したい.
      state.movieLayerMgr.disableInteraction = props.disableMovieLayerInteraction;
      initializeMovieLayer();
    });
    onUnmounted(() => {
      window.removeEventListener('resize', state.onResizeFunc);
    });

    const initKpLayer = () => {
      const { kpLineLayer, kpLayerFiltered, kpLayerAll } =
        state.kpLayerMgr.prepareLayers(state.userSettings, state.kpMap);
      if (!kpLayerFiltered || !kpLineLayer) { return; }
      kpLayerFiltered.setZIndex(state.layerZIndexMap.kp);
      kpLineLayer.setZIndex(state.layerZIndexMap.kpLine);
      if (kpLayerAll) {
        kpLayerAll.setZIndex(state.layerZIndexMap.kp);
        olMap.addLayer(kpLayerAll);
      }
      olMap.addLayer(kpLayerFiltered);
      olMap.addLayer(kpLineLayer);
    };

    const initDisplay = ({ userSettings, kpMap }: MapEssentials) => {
      if (state.isDisplayInitialized) { return; }
      state.isDisplayInitialized = true;
      state.userSettings = userSettings;
      state.kpMap = kpMap;

      const initialMapPos = userSettings.initial_map_pos;
      olMap.setCenter(olMap.convCoord(initialMapPos));

      if (state.userSettings.initial_extent) {
        const coords = state.userSettings.initial_extent.map((e: Location) => {
          return olMap.convCoord(e);
        });
        const options: FitOptions = {
          maxZoom: 20,
          duration: 500,
        };
        // ol6.0以上ではfit関数内に画面サイズが取れないため、パラメータで渡す
        const elem = document.getElementById(olMapId.value);
        if (elem) {
          const metrics = window.getComputedStyle(elem);
          const width = parseInt(metrics.width, 10);
          const height = parseInt(metrics.height, 10);
          if (width > 0 && height > 0) {
            options.size = [width, height];
          }
        }
        olMap.fitToBoundingExtent(coords, options);
      }

      userSettings.gis_layers.forEach((e: GisLayer, i: number) => {
        const layer = olMap.getGISLayer(e.layer_name, e.disp_name, i);
        olMap.addLayer(layer);
      });

      initKpLayer();
    };

    watch(() => props.mapEssentials, () => {
      if (!props.mapEssentials) { return; }
      initDisplay(props.mapEssentials);
    });

    // computed
    const olMapId = computed(() => {
      return `ol-map-${uid}`;
    });

    // methods:
    const triggerResize = () => {
      state.onResizeFunc();
    };
    const setMapHeight = (h: number) => {
      const prevH = state.mapHeight;
      state.mapHeight = h;
      if (h !== prevH) {
        triggerResize();
      }
    };
    const refreshMovieLayer = (movieLists: GIMovieList[]) => {
      state.movieLayerMgr.refreshLayer(movieLists);
    };
    const fitToMovieListsExtent = (movieLists: GIMovieList[]) => {
      const coords: Coordinate[] = [];
      movieLists.forEach(movieList => {
        movieList.movies.forEach((m) => {
          m.movie_geo_indices.forEach((mgi) => {
            const coord = olMap.convCoord({
              lat: parseFloat(mgi.lat),
              lon: parseFloat(mgi.lon),
            });
            coords.push(coord);
          });
        });
      });
      olMap.fitToBoundingExtent(coords, {
        maxZoom: 16,
        duration: 500,
      });
    };
    // 指定されたレイヤーを除いたレイヤーの選択表示を解除する
    const deselectLayersExcept = (exceptLayer: string) => {
      if (exceptLayer !== 'movie') {
        state.movieLayerMgr.deselectAll();
      }
      // 各データレイヤー
      for (const [dataName, layerMgr] of Object.entries(layerMgrMap)) {
        if (exceptLayer !== dataName) {
          layerMgr.deselectAll();
        }
      }
      // ポップアップ
      hidePopup();
    };
    const showQueryArea = (queryArea: QueryArea) => {
      const radiusDeg = olMap.getDegreesPerMeter() * queryArea.radius;
      const center = [queryArea.lon, queryArea.lat];
      const edge = [center[0] + radiusDeg, center[1] + radiusDeg];
      var groundRadius = olMap.getCoordDistance(center, edge);
      queryArea.dispRadius = groundRadius;
      state.queryAreaLayerMgr.showQueryArea(queryArea);
    };
    const showQueryPin = (queryArea: QueryArea) => {
      state.queryAreaLayerMgr.showQueryPin(queryArea);
    };
    const getVisibleDataLayersCount = () => {
      const visibleGeoItemLayersCount =
        Object.entries(state.geoItemLayerNameMap).filter(e => !!e[1]).length;
      return visibleGeoItemLayersCount;
    };
    const showDataLayer = (metaItem: GeoItemMeta, data: GIResource[] | GIDebugMTX, zIndexOffset = 0) => {
      removeDataLayer(metaItem.name);
      const { layer, layerInfo } = metaItem.layerManager.prepareLayer(data);
      const { additionalLayers, additionalLayerInfos } = metaItem.layerManager.prepareAddtionalLayers(data);
      const layers = layer ? [layer, ...additionalLayers] : additionalLayers;
      const layerInfos = [layerInfo, ...additionalLayerInfos];
      const zIndexBase = metaItem.name in state.layerZIndexMap
        ? state.layerZIndexMap[metaItem.name as keyof LayerZIndexMap]
        : state.layerZIndexMap.currentLayerZIndex + zIndexOffset;
      layers.forEach((elem, idx) => {
        // 通常layerと被らないように、addtional layerは1桁増やす
        const zIndex = elem === layer ? zIndexBase : zIndexBase * 10 + idx;
        elem.setZIndex(zIndex);
        olMap.addLayer(elem, {
          interaction: { click: layerInfos[idx].onLayerClick },
        });
      });
      metaItem.layerManager.connectWithExtremeMap(currentInstance?.proxy);
      layerMgrMap[metaItem.name] = metaItem.layerManager;
    };
    const removeDataLayer = (layerName: string) => {
      if (!layerMgrMap[layerName]) { return; }
      const { layer } = layerMgrMap[layerName].getLayer();
      const { additionalLayers } = layerMgrMap[layerName].getAdditionalLayers();
      const layers = layer ? [layer, ...additionalLayers] : additionalLayers;
      layers.forEach(layer => {
        if (!layer.name) { return; }
        olMap.removeLayer(layer.name);
      });
      layerMgrMap[layerName].destroy();
      delete layerMgrMap[layerName];
    };
    /**
     * layerManager側で発火したイベントを受け取るための関数群
     * (とりあえずclickしかないが)
     */
    const handleLayerManagerEventClick = (evtObj: MouseEvent) => {
      emit('click-item', evtObj);
    };
    // 与えられたlat, lonに一番近いmgiを取得して返す.
    const getClosestMgi = (point: Location, searchOpts: { featureIdPrefix?: number } = {}) => {
      return state.movieLayerMgr.getClosestMgi(point, searchOpts);
    };
    // 中心点を移動して特定のズームに設定する.
    // (animationしようかとも思ったが、連打すると目が回るのでやめた.)
    const moveCenterTo = (data: Location) => {
      const pos = olMap.convCoord({
        lat: data.lat,
        lon: data.lon,
      });
      olMap.setCenter(pos);
    };

    return {
      ...toRefs(state),
      // computed
      olMapId,
      // refs
      refPopupContainer,
      refPopupCloser,
      refPopupContent,
      // methods
      triggerResize,
      setMapHeight,
      refreshMovieLayer,
      fitToMovieListsExtent,
      deselectLayersExcept,
      showQueryArea,
      showQueryPin,
      getVisibleDataLayersCount,
      showDataLayer,
      removeDataLayer,
      handleLayerManagerEventClick,
      getClosestMgi,
      moveCenterTo,
      hideQueryArea,
    };
  },
});
