import axios, { AxiosResponse } from 'axios';
import * as utils from './apiUtils';
import { DeviceIndexResponse, Device } from 'src/models/apis/device/deviceResponse';
import { AdminDeviceIndexResponse } from 'src/models/apis/device/adminDeviceResponse';
import { AdminDeviceParams } from 'src/models/apis/device/adminDeviceRequest';

export default {
  index(): Promise<AxiosResponse<DeviceIndexResponse>> {
    return axios.get(utils.getApiUrl('/devices'));
  },
  adminIndex(): Promise<AxiosResponse<AdminDeviceIndexResponse>> {
    return axios.get(utils.getApiUrl('/admin/devices'));
  },
  adminCreate(params: AdminDeviceParams): Promise<AxiosResponse<Device>> {
    return axios.post(utils.getApiUrl('/admin/devices'), params || {});
  },
  adminUpdate(deviceId: string, params: AdminDeviceParams): Promise<AxiosResponse<Device>> {
    return axios.put(utils.getApiUrl(`/admin/devices/${deviceId}`),
      params || {},
    );
  },
  adminDestroy(devideId: string): Promise<AxiosResponse<Device>> {
    return axios.delete(utils.getApiUrl(`/admin/devices/${devideId}`));
  },
};
