




















































































































import Vue from 'vue';
import { calendarLangFunc } from '@/consts/calendar';
import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  computed,
  ref,
  watch,
  reactive,
  nextTick,
  getCurrentInstance,
  PropType,
  toRefs,
} from '@vue/composition-api';
import {
  getDayCount,
  siblingsMonth,
  getYearMonth,
  parse,
  detectDateEmitFormat,
} from '@/lib/calendarHelper';
import { dtFormat } from '@/lib/dateHelper';

interface CalendarLang {
  daysOfWeek: string[];
  months: string[];
  today: string;
  clear: string;
  close: string;
}

interface DateRangeItem {
  text: number;
  date: Date;
  sclass: string;
}
interface DecadeRangeItem {
  text: number;
}

interface PaneStyle {
  width: string;
  right: string;
  position: string;
}

interface CalendarState {
  inputValue: string;
  dateFormat: string;
  dateEmitFormat: string;
  currDate: Date;
  dateRange: DateRangeItem[][];
  decadeRange: DecadeRangeItem[][];
  paneStyle: PaneStyle;
  displayDayView: boolean;
  displayMonthView: boolean;
  displayYearView: boolean;
  rangeStart?: Date;
  rangeEnd?: Date;
}

export default defineComponent({
  name: 'calendar',
  props: {
    value: {
      type: String,
      default: '',
    },
    format: {
      type: String,
      default: 'mm/dd/yyyy',
    },
    inputClassObj: {
      type: Object as PropType<Record<string, string>>,
      default: () => {
        return {};
      },
    },
    disabledDaysOfWeek: {
      type: Array as PropType<string[]>,
      default: () => {
        return [];
      },
    },
    inputClearButton: {
      type: Boolean,
      default: false,
    },
    clearButton2Disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    lang: {
      type: String,
      default: navigator.language,
    },
    placeholder: {
      type: String,
    },
    hasInput: {
      type: Boolean,
      default: true,
    },
    pane: {
      type: Number,
      default: 1,
    },
    borderWidth: {
      type: Number,
      default: 2,
    },
    closeCalendarOnEnter: {
      type: Boolean,
      default: true,
    },
    onDayClick: {
      type: Function as PropType<(date: Date, dateString: string) => void>,
      default: () => {},
    },
    changePane: {
      type: Function as PropType<(year: number, month: number, pane: number) => void>,
      default: () => {},
    },
    specialDays: {
      type: Object as PropType<Record<string, string>>,
      default: () => {
        return {};
      },
    },
    rangeBus: {
      type: Function as PropType<() => Vue>,
      default: () => undefined,
    },
    rangeStatus: {
      type: Number,
      default: 0,
    },
    disableSwitchMonthView: {
      type: Boolean,
      default: false,
    },
    dayHeaderFormat: {
      type: String,
      default: 'm Y',
    },
    displayDatePickerFooter: {
      type: Boolean,
      default: true,
    },
    dateInputBgColor: {
      type: String,
      default: 'white',
    },
  },
  setup(props, { root, emit }) {
    const stringify = (date: Date, format = state.dateFormat): string => {
      return dtFormat(date, format);
    };
    const rawValueToInputValue = (val: string, format: string): string => {
      const parsedDate = parse(val, null);
      if (!parsedDate) { return val; }
      return stringify(parsedDate, format);
    };

    const state = reactive<CalendarState>({
      inputValue: rawValueToInputValue(props.value, props.format),
      dateFormat: props.format,
      dateEmitFormat: detectDateEmitFormat(props.format),
      currDate: new Date(),
      dateRange: [],
      decadeRange: [],
      paneStyle: {
        width: '',
        right: '',
        position: 'absolute',
      },
      displayDayView: false,
      displayMonthView: false,
      displayYearView: false,
    });
    const now = new Date();
    const getItemClasses = (d: DateRangeItem): string => {
      const clazz = [];
      clazz.push(d.sclass);
      if (state.rangeStart && state.rangeEnd && d.sclass !== 'datepicker-item-gray') {
        if (d.date > state.rangeStart && d.date < state.rangeEnd) {
          clazz.push('daytoday-range');
        }
        /* eslint-disable eqeqeq */
        if (stringify(d.date) == stringify(state.rangeStart)) {
          clazz.push('daytoday-start');
        }
        /* eslint-disable eqeqeq */
        if (stringify(d.date) == stringify(state.rangeEnd)) {
          clazz.push('daytoday-end');
        }
      }
      const nowY = now.getFullYear();
      const nowM = now.getMonth();
      const nowD = now.getDate();
      const dY = d.date.getFullYear();
      const dM = d.date.getMonth();
      const dD = d.date.getDate();
      if (nowY === dY && nowM === dM && nowD === dD) {
        clazz.push('today');
      }
      return clazz.join(' ');
    };
    const inputClassObjComputed = computed<Record<string, string>>(() => {
      return Object.assign({}, props.inputClassObj, {
        'with-reset-button': props.inputClearButton,
      });
    });

    const paneStyle = reactive({
      width: '',
      right: '',
      position: '',
    });
    const updatePaneStyle = () => {
      if (!(state.displayMonthView || state.displayYearView)) {
        nextTick(function() {
          let offsetLeft = (el.value as HTMLElement).offsetLeft;
          let offsetWidth =
            (el.value.querySelector('.datepicker-inner') as HTMLElement | null)?.offsetWidth ??
            0;
          let popWidth = props.pane * offsetWidth + props.borderWidth; // add border
          paneStyle.width = popWidth + 'px';
          if (props.hasInput) {
            if (popWidth + offsetLeft > document.documentElement.clientWidth) {
              paneStyle.right = '0px';
            }
          } else {
            paneStyle.position = 'initial';
          }
          root.$forceUpdate();
        });
      }
    };

    const emitValue = computed<string>(() => {
      const parsedDate = parse(state.inputValue, null);
      if (!parsedDate) { return state.inputValue; }
      return stringify(parsedDate, state.dateEmitFormat);
    });
    const onInput = () => {
      emit('input', emitValue.value);
    };
    const clear = () => {
      state.inputValue = '';
      onInput();
    };

    const instance = getCurrentInstance();
    const el = computed<Element>(() => {
      // instanceがないことがないが、null回避のため、nullの場合はダミ-divを返す
      return instance?.proxy.$el ?? document.createElement('div');
    });
    const handleMouseOver = (event: MouseEvent): boolean => {
      let target: HTMLElement = event.target as HTMLElement;
      if (!state.rangeStart) {
        return true;
      }
      if (event.type === 'mouseout') {
        return true;
      }
      while (el.value.contains(target) && !~target.className.indexOf('day-cell')) {
        target = target.parentNode as HTMLElement;
      }
      if (~target.className.indexOf('day-cell') && !~target.className.indexOf('datepicker-item-gray')) {
        const dataDate = parse(target.getAttribute('data-date') ?? '');
        if (dataDate && state.rangeStart < dataDate) {
          state.rangeEnd = dataDate;
        }
      }
      return true;
    };

    const refDatepickerInput = ref<HTMLInputElement>();
    const focusInput = () => {
      if (props.hasInput) {
        const input = refDatepickerInput.value;
        setTimeout(() => input?.focus(), 0);
      }
    };

    const close = () => {
      state.displayDayView = false;
      state.displayMonthView = false;
      state.displayYearView = false;
    };
    const inputClick = () => {
      state.currDate = parse(state.inputValue) || new Date();
      if (state.displayMonthView || state.displayYearView) {
        state.displayDayView = false;
      } else {
        state.displayDayView = !state.displayDayView;
      }
      updatePaneStyle();
    };
    const switchMonthView = () => {
      if (props.disableSwitchMonthView) { return; }
      state.displayDayView = false;
      state.displayMonthView = true;
    };
    const switchDecadeView = () => {
      state.displayMonthView = false;
      state.displayYearView = true;
    };
    const yearSelect = (year: number) => {
      state.displayYearView = false;
      state.displayMonthView = true;
      state.currDate = new Date(year, state.currDate.getMonth(), state.currDate.getDate());
    };
    const monthSelect = (year: number, index: number) => {
      state.displayMonthView = false;
      state.displayDayView = true;
      state.currDate = new Date(year, index, state.currDate.getDate());
      props.changePane(year, index, props.pane);
    };
    const eventbus = props.rangeBus();
    const daySelect = (date: Date, event?: MouseEvent) => {
      if (event &&
        event.target &&
        (event.target as HTMLElement).classList[0] === 'datepicker-item-disable'
      ) {
        return;
      }

      if (!props.hasInput) {
        props.onDayClick(date, stringify(date));
        return;
      }

      state.currDate = date;
      state.inputValue = stringify(state.currDate);
      onInput();
      state.displayDayView = false;
      if (props.rangeStatus === 1) {
        eventbus.$emit('calendar-rangestart', state.currDate);
      }
    };

    const updateRangeStart = (date: Date) => {
      state.rangeStart = date;
      state.currDate = date;
      state.inputValue = stringify(state.currDate);
    };
    const blur = (e: MouseEvent) => {
      if (!el.value.contains(e.target as Node) && props.hasInput) close();
    };
    onMounted(() => {
      state.currDate = parse(state.inputValue) || new Date();
      const year = state.currDate.getFullYear();
      const month = state.currDate.getMonth();
      props.changePane(year, month, props.pane);
      if (!props.hasInput) {
        state.displayDayView = true;
        updatePaneStyle();
      }
      if (props.rangeStatus) {
        if (typeof eventbus === 'object' && !eventbus.$on) {
          console.warn('Calendar rangeBus doesn\'t exist');
        }
      }
      if (props.rangeStatus === 2) {
        eventbus.$on('calendar-rangestart', updateRangeStart);
      }
      document.addEventListener('click', blur);
    });

    onBeforeUnmount(() => {
      document.removeEventListener('click', blur);
      if (props.rangeStatus === 2) {
        eventbus.$off('calendar-rangestart', updateRangeStart);
      }
    });

    watch(() => props.value, () => {
      state.inputValue = rawValueToInputValue(props.value, state.dateFormat);
      state.dateEmitFormat = detectDateEmitFormat(props.value);
    });

    const getDateRange = () => {
      state.dateRange = [];
      state.decadeRange = [];
      for (let p = 0; p < props.pane; p++) {
        let currMonth = siblingsMonth(state.currDate, p);
        let time = {
          year: currMonth.getFullYear(),
          month: currMonth.getMonth(),
        };
        let yearStr = time.year.toString();
        state.decadeRange[p] = [];
        let firstYearOfDecade = parseInt(yearStr.substring(0, yearStr.length - 1)) + 0 - 1;
        for (let i = 0; i < 12; i++) {
          state.decadeRange[p].push({
            text: firstYearOfDecade + i + p * 10,
          });
        }
        state.dateRange[p] = [];
        const currMonthFirstDay = new Date(time.year, time.month, 1);
        let firstDayWeek = currMonthFirstDay.getDay() + 1;
        if (firstDayWeek === 0) {
          firstDayWeek = 7;
        }
        const dayCount = getDayCount(time.year, time.month);
        if (firstDayWeek > 1) {
          const preMonth = getYearMonth(time.year, time.month - 1);
          const prevMonthDayCount = getDayCount(preMonth.year, preMonth.month);
          for (let i = 1; i < firstDayWeek; i++) {
            const dayText = prevMonthDayCount - firstDayWeek + i + 1;
            state.dateRange[p].push({
              text: dayText,
              date: new Date(preMonth.year, preMonth.month, dayText),
              sclass: 'datepicker-item-gray',
            });
          }
        }
        for (let i = 1; i <= dayCount; i++) {
          const date = new Date(time.year, time.month, i);
          const week = date.getDay();
          let sclass = '';
          props.disabledDaysOfWeek.forEach((el) => {
            if (week === parseInt(el, 10)) sclass = 'datepicker-item-disable';
          });
          if (i === state.currDate.getDate()) {
            if (state.inputValue) {
              const valueDate = parse(state.inputValue);
              if (valueDate) {
                if (valueDate.getFullYear() === time.year && valueDate.getMonth() === time.month) {
                  sclass = 'datepicker-dateRange-item-active';
                }
              }
            }
          }
          state.dateRange[p].push({
            text: i,
            date: date,
            sclass: sclass,
          });
        }
        if (state.dateRange[p].length < 42) {
          const nextMonthNeed = 42 - state.dateRange[p].length;
          const nextMonth = getYearMonth(time.year, time.month + 1);
          for (let i = 1; i <= nextMonthNeed; i++) {
            state.dateRange[p].push({
              text: i,
              date: new Date(nextMonth.year, nextMonth.month, i),
              sclass: 'datepicker-item-gray',
            });
          }
        }
      }
    };
    watch(() => state.currDate, getDateRange);

    const preNextDecadeClick = (flag: number) => {
      const year = state.currDate.getFullYear();
      const months = state.currDate.getMonth();
      const date = state.currDate.getDate();
      if (flag === 0) {
        state.currDate = new Date(year - 10, months, date);
      } else {
        state.currDate = new Date(year + 10, months, date);
      }
    };
    const preNextMonthClick = (flag: number) => {
      const year = state.currDate.getFullYear();
      const month = state.currDate.getMonth();
      const date = state.currDate.getDate();
      if (flag === 0) {
        const preMonth = getYearMonth(year, month - 1);
        state.currDate = new Date(preMonth.year, preMonth.month, date);
        props.changePane(preMonth.year, preMonth.month, props.pane);
      } else {
        const nextMonth = getYearMonth(year, month + 1);
        state.currDate = new Date(nextMonth.year, nextMonth.month, date);
        props.changePane(nextMonth.year, nextMonth.month, props.pane);
      }
    };
    const preNextYearClick = (flag: number) => {
      const year = state.currDate.getFullYear();
      const months = state.currDate.getMonth();
      const date = state.currDate.getDate();
      if (flag === 0) {
        state.currDate = new Date(year - 1, months, date);
      } else {
        state.currDate = new Date(year + 1, months, date);
      }
    };
    const onInputKeydown = ($event: KeyboardEvent) => {
      if ($event.key === 'Tab') {
        close();
      }
      if ($event.code === 'Space') {
        inputClick();
        $event.stopImmediatePropagation();
        $event.preventDefault();
      }
    };
    const onInputKeyup = ($event: KeyboardEvent) => {
      if (props.closeCalendarOnEnter && $event.key === 'Enter') {
        close();
      }
      emit('keyup', $event);
    };

    const getSpecailDay = (v: Date): string => {
      return props.specialDays[stringify(v)];
    };
    const stringifyDecadeHeader = (date: Date, pan: number): string => {
      const yearStr = date.getFullYear().toString();
      const firstYearOfDecade = parseInt(yearStr.substring(0, yearStr.length - 1) + 0, 10) + (pan * 10);
      const lastYearOfDecade = firstYearOfDecade + 10;
      return firstYearOfDecade + '-' + lastYearOfDecade;
    };
    const stringifyDayHeader = (date: Date, month = 0): string => {
      const d = siblingsMonth(date, month);
      const fmt = props.dayHeaderFormat;
      return dtFormat(d, fmt);
    };
    const stringifyYearHeader = (date: Date, year = 0): number => {
      return date.getFullYear() + year;
    };
    const translations = (lang: string): CalendarLang => {
      return calendarLangFunc(lang);
    };
    const text = computed<CalendarLang>(() => {
      return translations(props.lang);
    });
    const setToday = () => {
      daySelect(new Date());
    };
    // nullエラー回避のため
    const parseDateWithDefault = (val: string): Date => {
      return parse(val) ?? new Date();
    };

    return {
      ...toRefs(state),
      refDatepickerInput,
      // computeds
      inputClassObjComputed,
      text,
      emitValue,
      // methods
      parseDateWithDefault,
      stringify,
      updatePaneStyle,
      close,
      siblingsMonth,
      getItemClasses,
      handleMouseOver,
      onInput,
      clear,
      focusInput,
      inputClick,
      preNextDecadeClick,
      preNextMonthClick,
      preNextYearClick,
      yearSelect,
      daySelect,
      onInputKeydown,
      onInputKeyup,
      switchMonthView,
      switchDecadeView,
      monthSelect,
      getSpecailDay,
      stringifyDecadeHeader,
      stringifyDayHeader,
      stringifyYearHeader,
      setToday,
    };
  },
});
