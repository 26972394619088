export const carKindsWithSpecialImg: Record<string, string> = {
  '2': 'kipato', // 黄パト
  '19': 'hyosiki_ye', // 標識車
  '20': 'kara-damp_ye', // 資材車
  '29': 'kipato', // ランクル
  '30': 'kipato', // ランクル(標識付)
  '33': 'sanpu_ye', // 散布車
  '200': 'bicycle', // 自転車
  '201': 'smartphone', // スマートフォン
  '202': 'safie', // Safie Pocket2
};
