import axios, { AxiosResponse } from 'axios';
import * as utils from './apiUtils';
import { TagMovieUpdateResponse } from 'src/models/apis/tagMovie/tagMovieResponse';
import { TagMovieUpdateParams } from 'src/models/apis/tagMovie/tagMovieRequest';

export default {
  update(movieId: number, params: TagMovieUpdateParams): Promise<AxiosResponse<TagMovieUpdateResponse>> {
    return axios.put(utils.getApiUrl(`/movies/${movieId}/tags`), params);
  },
};
