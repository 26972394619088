import axios, { AxiosResponse } from 'axios';
import * as utils from './apiUtils';
import { JunkaiTenkenIndexParams } from 'src/models/apis/extJunkaiTenkenReportTenkenData/extJunkaiTenkenReportTenkenDataRequest';
import { JunkaiTenkenIndexResponse } from 'src/models/apis/extJunkaiTenkenReportTenkenData/extJunkaiTenkenReportTenkenDataResponse';

export default {
  index(params: JunkaiTenkenIndexParams): Promise<AxiosResponse<JunkaiTenkenIndexResponse>> {
    return axios.get(utils.getApiUrl('/ext/junkai-tenken-report/tenken_data'), {
      params: params || {},
    });
  },
  getImage(id: string): Promise<AxiosResponse<Blob>> {
    return axios.get(utils.getApiUrl(`/ext/junkai-tenken-report/image/${id}`), {
      responseType: 'blob',
    });
  },
};
