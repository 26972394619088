


import commentApi from '@/apis/comment';
import userApi from '@/apis/user';
import { Kilopost } from 'src/models/apis/movie/movieResponse';
import {
  getNearKps,
  getLocationDispOfKp,
  validateAngle,
  validateComment,
  convertFilesToFileObjs,
  fetchFileAsObjectInfo,
} from '@/lib/commentHelper';
import {
  defineComponent,
  ref,
  reactive,
  onMounted,
  onBeforeUnmount,
  computed,
  toRefs,
} from '@vue/composition-api';
import { AdminCommentIndexRequest } from 'src/models/apis/comment/adminCommentRequest';
import { AdminCommentIndexResponse } from 'src/models/apis/comment/adminCommentResponse';
import { Comment } from 'src/models/apis/comment/commentResponse';
import { CandidateFile } from '@/models';
import { getErrorMessages } from '@/lib/errMsgHelper';
import { AxiosError } from 'axios';
import useMaster from '@/composables/useMaster';
import { lineBreakToBR, splitByLineBreak } from '@/lib/utils';
import { AdminUser } from '@/models/apis/user/adminUserResponse';
import { GIKp } from '@/models/geoItem';

type SearchParams = AdminCommentIndexRequest;

type CommentDisp = Comment & {
  contentDisp: string;
};

interface AdminCommentsEdit {
  id: number;
  comment_type: string;
  content: string | null;
  user_id: number | null;
  share_scope: number | null;
  angle: number | null;
  kpObj?: Kilopost;
  update_ts: boolean;
  contentTextareaRows?: number;
  kp_uid?: string | null;
  lat?: string;
  lon?: string;
  files?: File[];
  remove_file_flag?: boolean;
}

interface AdminCommentsTableList {
  ts_datetime: string;
  commentTypeDisp: string;
  shareScopeLabel: string;
  user_display_name: string;
  contentDisp: string;
  kpInfo?: string;
  angleDisp?: string;
  hasFile: string;
}

interface AdminCommentsState {
  showEditModal: boolean;
  editMode: 'edit' | 'create';
  showDestroyModal: boolean;
  showErrorModal: boolean;
  savedFile: string | null;
  savedFileType: string | null;
  savedFileName: string | null;
  candidateFiles: CandidateFile[];
  search: SearchParams;
  editting: AdminCommentsEdit;
  filteredComments: CommentDisp[];
  users: AdminUser[];
  errorMsgs: string[];
}

export default defineComponent({
  name: 'admin-comments',
  setup() {
    const now = new Date();
    const initSearchState = (): SearchParams => {
      return {
        comment_type: null,
        user_id: null,
        dt_from: new Date(now.getFullYear(), now.getMonth() - 6, now.getDate()),
        dt_to: new Date(now.getFullYear(), now.getMonth(), now.getDate()),
        content: null,
        share_scope: null,
      };
    };
    const initEdittingState = (): AdminCommentsEdit => {
      return {
        id: 0,
        comment_type: '',
        content: null,
        user_id: null,
        share_scope: null,
        angle: null,
        kpObj: {} as Kilopost,
        update_ts: false,
      };
    };
    const state = reactive<AdminCommentsState>({
      showEditModal: false,
      editMode: 'edit',
      showDestroyModal: false,
      showErrorModal: false,
      savedFile: null,
      savedFileType: null,
      savedFileName: null,
      candidateFiles: [],
      search: initSearchState(),
      editting: initEdittingState(),
      filteredComments: [],
      users: [],
      errorMsgs: [],
    });
    const { state: msts } = useMaster();

    const refUploadFile = ref<HTMLInputElement>();
    const resetSearchResults = () => {
      state.filteredComments = [];
    };
    const convComments = (data: AdminCommentIndexResponse): CommentDisp[] => {
      const results = data.map<CommentDisp>(e => {
        return {
          ...e,
          content: splitByLineBreak(e.content).join('\n'),
          contentDisp: lineBreakToBR(e.content),
        };
      });
      return results;
    };
    const doSearch = () => {
      resetSearchResults();
      const params = state.search;
      const reqParams = Object.assign({}, params);
      if (params.dt_to) {
        reqParams.dt_to = new Date(params.dt_to.valueOf() + 86400 * 1000);
      }
      commentApi.adminIndex(reqParams)
        .then(({ data }) => {
          if (!data || data.length === 0) {
            return;
          }
          state.filteredComments = convComments(data);
        });
    };

    onMounted(async() => {
      const [masters, { data }] = await Promise.all([
        window.master.$promise,
        userApi.adminIndex({}),
      ]);
      msts.commentTypes = masters.commentType.vals;
      msts.shareScopes = masters.lovs.share_scope.vals;
      msts.kpMap = masters.kpMap;
      msts.roadNameDispMap = masters.roadNameDispMap;
      state.users = data;
      doSearch();
    });

    const releaseSavedFile = () => {
      if (!state.savedFile) {
        return;
      }
      URL.revokeObjectURL(state.savedFile);
      state.savedFile = null;
    };
    const releaseCandidateFiles = () => {
      state.candidateFiles.forEach(elm => URL.revokeObjectURL(elm.content));
      state.candidateFiles = [];
    };

    onBeforeUnmount(() => {
      releaseSavedFile();
      releaseCandidateFiles();
    });

    const getCommentTypeDisp = (type: string): string => {
      for (const commentType of msts.commentTypes) {
        if (commentType.comment_type === type) {
          return commentType.comment_type_disp;
        }
      }
      return type;
    };
    const getShareScopeLabel = (scope: number): string => {
      for (const shareScope of msts.shareScopes) {
        if (shareScope.key === scope) {
          return shareScope.val;
        }
      }
      return scope.toString();
    };
    const getKpInfo = (kpUid: string | null): string | undefined => {
      if (!kpUid) {
        return;
      }
      const kps = Array.from(msts.kpMap.values())
        .map(e => Array.from(e.values())).flat(2);
      const kpObj = kps.find(e => e.kp_uid === kpUid);
      if (!kpObj) {
        return;
      }
      return getLocationDispOfKp(kpObj, msts.roadNameDispMap);
    };

    const displayData = computed<AdminCommentsTableList[]>(() => {
      return state.filteredComments.map(comment => {
        return {
          ...comment,
          commentTypeDisp: getCommentTypeDisp(comment.comment_type),
          shareScopeLabel: getShareScopeLabel(comment.share_scope),
          kpInfo: getKpInfo(comment.kp_uid),
          angleDisp: comment.angle === null || comment.angle === undefined ? '' : `${comment.angle}°`,
          hasFile: comment.comment_files.length > 0 ? '有' : '',
        };
      });
    });
    const canSave = computed<boolean>(() => {
      const hasNoError = state.errorMsgs.length === 0;
      return validateComment(state.editting) && hasNoError;
    });
    const nearKpThresholdMeters = 100;
    const nearKps = computed<GIKp[]>(() => {
      if (!state.editting.lat || !state.editting.lon) {
        return [];
      }
      return getNearKps(
        {lat: state.editting.lat, lon: state.editting.lon},
        msts.kpMap,
        msts.roadNameDispMap,
        nearKpThresholdMeters,
      );
    });
    const editItemHasValidAngle = computed<boolean>(() => {
      return validateAngle(state.editting.angle);
    });

    const save = async() => {
      try {
        if (state.editMode === 'edit') {
          if (refUploadFile?.value?.files && refUploadFile.value.files.length > 0) {
            state.editting.files = Array.from(refUploadFile.value.files);
          } else if (state.candidateFiles.length === 0 && state.savedFile) {
            // 付箋画像削除時
            state.editting.remove_file_flag = true;
          }
          await commentApi.adminUpdate(state.editting.id, {
            ...state.editting,
            content: state.editting.content || '',
            share_scope: state.editting.share_scope || 0,
          });
          state.errorMsgs = [];
          state.showEditModal = false;
          releaseSavedFile();
          releaseCandidateFiles();
          doSearch();
        }
      } catch (err) {
        state.errorMsgs = getErrorMessages(err as AxiosError);
      }
    };

    const edit = async(obj: Comment) => {
      const minRows = 4;
      let rows = splitByLineBreak(obj.content).length;
      rows = Math.max(rows, minRows);

      state.editting = {
        id: obj.id,
        user_id: obj.user_id,
        comment_type: obj.comment_type,
        content: obj.content,
        contentTextareaRows: rows,
        share_scope: obj.share_scope,
        angle: obj.angle,
        kp_uid: obj.kp_uid,
        lat: obj.lat,
        lon: obj.lon,
        update_ts: false,
      };
      releaseSavedFile();
      releaseCandidateFiles();
      state.errorMsgs = [];
      state.candidateFiles = [];

      // 画像は非同期で取得
      if (obj.comment_files.length > 0) {
        fetchFileAsObjectInfo(obj.comment_files[0].file_path).then(savedFileInfo => {
          if (!savedFileInfo) {
            return;
          }
          state.savedFile = savedFileInfo.url;
          state.savedFileType = savedFileInfo.type;
          state.savedFileName = obj.comment_files[0].file_name;
          if (state.savedFile && state.savedFileType) {
            state.candidateFiles = [{
              id: 0,
              content: state.savedFile,
              type: state.savedFileType,
              name: state.savedFileName ?? '',
              selected: true,
            }];
          }
        });
      }

      state.showEditModal = true;
      state.editMode = 'edit';
    };

    const tryDestroy = (obj: Comment) => {
      state.editting.id = obj.id;
      state.showDestroyModal = true;
    };
    const doDestroy = () => {
      commentApi.adminDestroy(state.editting.id)
        .then(() => {
          state.showDestroyModal = false;
          doSearch();
        }).catch(() => {
          state.showDestroyModal = false;
          state.showErrorModal = true;
        });
    };

    const cancelEdit = () => {
      releaseSavedFile();
      releaseCandidateFiles();
      state.showEditModal = false;
    };
    const handleFileUpload = () => {
      state.candidateFiles = [];
      state.errorMsgs = [];
      const fileList = refUploadFile?.value?.files;
      if (!fileList) {
        return;
      }
      try {
        state.candidateFiles = convertFilesToFileObjs(fileList);
      } catch (error) {
        state.errorMsgs.push(...getErrorMessages(error as AxiosError));
      }
    };
    const removeFileCandidate = (fileId: number) => {
      state.candidateFiles = state.candidateFiles.filter(e => e.id !== fileId);
      // 1画像しかアップロードできないため、ファイル選択がある場合は、ファイル選択を解除する
      if (refUploadFile.value?.value) {
        refUploadFile.value.value = '';
      }
    };

    const fields = [
      { name: 'ts_datetime', label: '最終更新日時' },
      { name: 'commentTypeDisp', label: '種別' },
      { name: 'kpInfo', label: '場所' },
      { name: 'angleDisp', label: '方角' },
      { name: 'shareScopeLabel', label: '公開範囲' },
      { name: 'user_display_name', label: '作成者' },
      { name: 'contentDisp', label: '内容', type: 'html' },
      { name: 'hasFile', label: '添付' },
    ];
    const editModalTitle = '編集';
    const destroyModalTitle = '削除';
    const destroyModalMsg = 'この付箋を削除してもよろしいですか？';
    const errorModalTitle = 'エラー';
    const errorModalMsg = '不明なエラーです。ネットワークの状態をご確認いただき再度お試し下さい。';

    return {
      ...toRefs(state),
      msts,
      refUploadFile,
      // methods:
      doSearch,
      edit,
      tryDestroy,
      doDestroy,
      cancelEdit,
      save,
      handleFileUpload,
      removeFileCandidate,
      // computed:
      displayData,
      canSave,
      nearKps,
      editItemHasValidAngle,
      // others:
      fields,
      editModalTitle,
      destroyModalTitle,
      destroyModalMsg,
      errorModalTitle,
      errorModalMsg,
    };
  },
});
