



























import {
  defineComponent,
  onMounted,
  watch,
  computed,
  PropType,
  reactive,
  toRefs,
} from '@vue/composition-api';
import { MapElemInfo } from '@/models';
import { GICar } from '@/models/geoItem';
import { carKindsWithSpecialImg } from '@/consts/car_kinds_with_special_img';

interface MapElemInfoCarState {
  car: GICar;
}

export default defineComponent({
  name: 'map-elem-info-car',
  props: {
    elem: {
      type: Object as PropType<MapElemInfo<GICar>>,
      required: true,
    },
  },
  setup(props) {
    const state = reactive<MapElemInfoCarState>({
      car: props.elem.data,
    });

    onMounted(() => {
      state.car = props.elem.data;
    });

    watch(() => props.elem, () => {
      state.car = props.elem.data;
    });

    const iconPath = computed<string>(() => {
      const carIconPrefix = '/static/img/car_';
      const statusPrefix = state.car.isMoving ? 'moving_' : 'stopped_';
      const imgCarKind = carKindsWithSpecialImg[state.car.device.car_kind] || 'default';
      return `${carIconPrefix}${statusPrefix}${imgCarKind}.png`;
    });

    return {
      ...toRefs(state),
      iconPath,
    };
  },
});
