



















































































































































import {
  defineComponent,
  reactive,
  toRefs,
  PropType,
  watch,
  computed,
} from '@vue/composition-api';
import { RoadName, RoadNameDisp } from '@/models/apis/master/masterResponse';
import useMaster from '@/composables/useMaster';
import { dtFormat } from '@/lib/dateHelper';

interface SearchParams {
  search_types: Array<string | number>;
  ts_from: Date | null;
  ts_to: Date | null;
  roads: RoadName[];
  directions: string[];
  kp_calc_from: number | null;
  kp_calc_to: number | null;
}

interface IndexRoadName {
  idx: number;
  elems: RoadNameDisp[];
}

type RoadNamesByAreaMap = Record<string, IndexRoadName>;

interface GeoItemSearchCommentState {
  showSearchTypes: boolean;
  showDateRange: boolean;
  showRoads: boolean;
  showRoadByAreas: Record<string, boolean>;
  showDirections: boolean;
  showKpRange: boolean;
  roadNamesByAreas: [string, IndexRoadName][];
  search: SearchParams;
}

type SearchTypeMap = Record<string, {
  val: string;
  disp_order: number;
}>;

interface SearchType {
  key: string | number;
  val: string;
}

export default defineComponent({
  name: 'geo-item-search-common',
  props: {
    checkboxKeyPrefix: {
      type: String,
    },
    searchTypeMap: {
      type: Object as PropType<SearchTypeMap>,
      default: () => ({}),
    },
    searchTypes: {
      type: Array as PropType<Array<SearchType>>,
      default: () => [],
    },
    searchTypeDefaultValues: {
      type: Array as PropType<Array<string | number>>,
      default: () => [],
    },
    isKpSearch: {
      type: Boolean,
      default: true,
    },
    defaultDaysBefore: {
      type: Number,
      default: 0,
    },
    defaultMonthsBefore: {
      type: Number,
      default: 3,
    },
    maxSearchRangeMonths: {
      type: Number,
      default: 3,
    },
  },
  setup(props, { emit }) {
    const initSearchState = (): SearchParams => {
      return {
        search_types: [],
        ts_from: null,
        ts_to: null,
        roads: [],
        directions: [],
        kp_calc_from: null,
        kp_calc_to: null,
      };
    };
    const state = reactive<GeoItemSearchCommentState>({
      showSearchTypes: false,
      showDateRange: false,
      showRoads: false,
      showRoadByAreas: {},
      showDirections: false,
      showKpRange: false,
      roadNamesByAreas: [],
      search: initSearchState(),
    });

    const getRoadNamesByAreas = (): [string, IndexRoadName][] => {
      let areaIdx = 0;
      const roadNamesByAreaMap = Object.values(msts.roadNameDispMap).reduce((acc: RoadNamesByAreaMap, e) => {
        if (!acc[e.area]) {
          acc[e.area] = { idx: areaIdx++, elems: [] };
        }
        acc[e.area].elems.push(e);
        return acc;
      }, {});
      const areas = Object.keys(roadNamesByAreaMap);
      if (areas.length < 2) {
        return [];
      }
      return Object.entries(roadNamesByAreaMap).sort((a, b) => a[1].idx < b[1].idx ? -1 : 1);
    };

    const setInitialSearchParams = () => {
      const now = new Date();
      const tsFrom = props.defaultDaysBefore > 0
        ? new Date(now.getFullYear(), now.getMonth(), now.getDate() - props.defaultDaysBefore)
        : new Date(now.getFullYear(), now.getMonth() - props.defaultMonthsBefore, now.getDate());
      const params = {
        search_types: props.searchTypeDefaultValues.length > 0
          ? props.searchTypeDefaultValues
          : props.searchTypes.map(e => e.key),
        ts_from: tsFrom,
        ts_to: new Date(now.getFullYear(), now.getMonth(), now.getDate()),
        roads: [],
        directions: [],
        kp_calc_from: null,
        kp_calc_to: null,
      };
      state.search = params;
    };

    const { state: msts } = useMaster();
    watch(() => msts.roadNameDispMap, () => {
      state.roadNamesByAreas = getRoadNamesByAreas();
      state.roadNamesByAreas = getRoadNamesByAreas();
      state.showRoadByAreas = state.roadNamesByAreas.reduce((acc: Record<string, boolean>, curr) => {
        acc[curr[0]] = false;
        return acc;
      }, {});
      setInitialSearchParams();
    });
    watch(() => props.searchTypes, () => {
      state.search.search_types = props.searchTypes.map(e => e.key);
    });

    // computed
    const selectedSearchTypesDisp = computed(() => {
      if (state.search.search_types.length === 0) { return ''; }
      const ret = state.search.search_types.slice().sort((a, b) => {
        const val1 = props.searchTypeMap[a].disp_order;
        const val2 = props.searchTypeMap[b].disp_order;
        return val1 < val2 ? -1 : val1 > val2 ? 1 : 0;
      }).map(e => props.searchTypeMap[e].val).join(', ');
      return `（${ret}）`;
    });
    const selectedRoadsDisp = computed(() => {
      if (state.search.roads.length === 0) { return ''; }
      const ret = state.search.roads.map(e => e.road_name_disp_short).join(', ');
      return `（${ret}）`;
    });
    const selectedDirectionsDisp = computed(() => {
      if (state.search.directions.length === 0) { return ''; }
      const ret = state.search.directions.join(', ');
      return `（${ret}）`;
    });
    const kpRangeDisp = computed(() => {
      if (!state.search.kp_calc_from && !state.search.kp_calc_to) { return ''; }
      const ret = `${state.search.kp_calc_from}〜${state.search.kp_calc_to}`.replace('null', '');
      return `（${ret}）`;
    });
    const directionOptions = computed(() => {
      const result: string[] = [];
      state.search.roads.map(e => e.directions).flat().forEach(e => {
        !result.includes(e) && result.push(e);
      });
      return result;
    });
    const dateRangeDisp = computed(() => {
      const tsFrom = dtFormat(state.search.ts_from, 'yyyy/mm/dd');
      const tsTo = dtFormat(state.search.ts_to, 'yyyy/mm/dd');
      const ret = tsFrom + '〜' + tsTo;
      return `（${ret}）`;
    });
    const disabledKpFields = computed(() => {
      return state.search.roads.length === 0 || state.search.directions.length === 0;
    });

    // methods
    const getSearchParams = () => {
      return {
        search_types: state.search.search_types,
        ts_from: state.search.ts_from,
        ts_to: state.search.ts_to ? new Date(state.search.ts_to.valueOf() + 86400 * 1000) : state.search.ts_to,
        road_name_disps: state.search.roads.map(e => e.road_name_disp),
        directions: state.search.directions,
        kp_calc_from: state.search.kp_calc_from,
        kp_calc_to: state.search.kp_calc_to,
      };
    };
    const onSearchTypeClick = (e: MouseEvent) => {
      if (state.search.search_types.length > 1) { return; }
      if ((e.target as HTMLInputElement).checked) { return; }
      // 種別は最低一つ選択必須
      e.preventDefault();
      e.stopPropagation();
    };
    const adjustSearchDateRange = (prop: string) => {
      const propVal = prop === 'from' ? state.search.ts_from : state.search.ts_to;
      if (!propVal) { return; }

      const params = state.search;
      let otherProp: Date | null;
      let sign;
      if (prop === 'from') {
        otherProp = params.ts_to;
        sign = 1;
      } else {
        otherProp = params.ts_from;
        sign = -1;
      }

      const maxRangeDate = new Date(
        propVal.getFullYear(),
        propVal.getMonth() + sign * props.maxSearchRangeMonths,
        propVal.getDate(),
      );
      // 編集していない方の日付が正しい日付ではない または 開始日 > 終了日の場合、
      // 開始日 <= 終了日の場合で、maxSearchRangeMonthsを超えている場合、
      // 検索期間に合うよう、編集していない方の日付を調整する.
      if (
        !otherProp || (params.ts_from && params.ts_to && params.ts_from > params.ts_to) ||
        (
          (prop === 'from' && params.ts_to && params.ts_to > maxRangeDate) ||
          (prop === 'to' && params.ts_from && params.ts_from < maxRangeDate)
        )
      ) {
        otherProp = maxRangeDate;
      }
    };
    const onChange = () => {
      emit('change');
    };
    const onDateParamChange = (prop: string, val: Date) => {
      if (prop === 'from') {
        state.search.ts_from = val;
      } else if (prop === 'to') {
        state.search.ts_to = val;
      }

      adjustSearchDateRange(prop);

      if (state.search.ts_from && state.search.ts_to) {
        onChange();
      }
    };
    const onRoadsOrDirectionsChanged = () => {
      if (disabledKpFields.value) {
        state.search.kp_calc_from = null;
        state.search.kp_calc_to = null;
      }
      onChange();
    };

    return {
      ...toRefs(state),
      msts,
      // computed
      selectedSearchTypesDisp,
      selectedRoadsDisp,
      selectedDirectionsDisp,
      kpRangeDisp,
      directionOptions,
      dateRangeDisp,
      disabledKpFields,
      // methods
      getSearchParams,
      onSearchTypeClick,
      onDateParamChange,
      onRoadsOrDirectionsChanged,
      onChange,
    };
  },
});
