import axios, { AxiosResponse } from 'axios';
import * as utils from './apiUtils';
import {
  AdminMovieGroupIndexParams,
  AdminMovieGroupUpdateMovieTagsParams,
  AdminMovieGroupCollectionDestroyParams,
} from 'src/models/apis/movieGroup/adminMovieGroupRequest';
import {
  AdminMovieGroupIndexResponse,
  AdminMovieGroup,
  AdminMovieGroupUpdateMovieTagsResponse,
  AdminMovieGroupDestroyResponse,
  AdminMovieGroupCollectionDestroyResponse,
} from 'src/models/apis/movieGroup/adminMovieGroupResponse';

export default {
  adminIndex(params: AdminMovieGroupIndexParams): Promise<AxiosResponse<AdminMovieGroupIndexResponse>> {
    return axios.get(utils.getApiUrl('/admin/movie_groups'), {params: params || {}});
  },
  adminShow(id: number): Promise<AxiosResponse<AdminMovieGroup>> {
    return axios.get(utils.getApiUrl(`/admin/movie_groups/${id}`));
  },
  adminUpdateMovieTags(id: number, params: AdminMovieGroupUpdateMovieTagsParams): Promise<AxiosResponse<AdminMovieGroupUpdateMovieTagsResponse>> {
    return axios.put(utils.getApiUrl(`/admin/movie_groups/${id}/movie_tags`), params);
  },
  adminDestroy(id: number): Promise<AxiosResponse<AdminMovieGroupDestroyResponse>> {
    return axios.delete(utils.getApiUrl(`/admin/movie_groups/${id}`));
  },
  adminCollectionDestroy(params: AdminMovieGroupCollectionDestroyParams): Promise<AxiosResponse<AdminMovieGroupCollectionDestroyResponse>> {
    return axios.post(utils.getApiUrl(`/admin/movie_groups/collection`), params || {});
  },
};
