













































































import {
  defineComponent,
  PropType,
  ref,
  computed,
  watch,
  onMounted,
  reactive,
  toRefs,
} from '@vue/composition-api';
import { MapElemInfo } from '@/models';
import { GIMovieList } from '@/models/geoItem';
import { MovieTag } from '@/models/apis/movie/movieResponse';
import useUpdateTimer from '@/composables/useUpdateTimer';
import { dtFormat, fuzzyTimeDisp } from '@/lib/dateHelper';

interface MapElemInfoMovieState {
  movieList: GIMovieList;
}

export default defineComponent({
  name: 'map-elem-info-movie',
  props: {
    elem: {
      type: Object as PropType<MapElemInfo<GIMovieList>>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const state = reactive<MapElemInfoMovieState>({
      movieList: props.elem.data,
    });
    const now = ref<Date>(new Date());
    useUpdateTimer(now);

    onMounted(() => {
      state.movieList = props.elem.data;
    });

    watch(() => props.elem, () => {
      state.movieList = props.elem.data;
    });

    const isSearchTypeRoute = computed<boolean>(() => {
      return state.movieList && state.movieList.searchType === 'route';
    });
    const showStartTsFuzzy = computed<boolean>(() => {
      if (!state.movieList) { return false; }
      const secDiff = (now.value.valueOf() - (state.movieList.start_ts?.valueOf() ?? 0)) / 1000;
      return secDiff < 86400 * 31;
    });
    const startTsFuzzy = computed<string>(() => {
      if (!state.movieList) { return ''; }
      return fuzzyTimeDisp(state.movieList.start_ts, now.value);
    });
    const movieTags = computed<MovieTag[]>(() => {
      if (!state.movieList) { return []; }
      const movieTagMap = state.movieList.movies.reduce((acc: Record<string, MovieTag>, movie) => {
        movie.tags.forEach(tag => { acc[tag.id] = tag; }); return acc;
      }, {});
      return Object.values(movieTagMap);
    });

    const startIconPath = '/static/img/movie_start_pin.png';
    const endIconPath = '/static/img/movie_end_pin.png';

    const play = () => {
      emit('play-movie', { dataName: 'movie', data: state.movieList });
    };
    const editMovieTag = () => {
      emit('edit-movie-tag', { dataName: 'movie', data: state.movieList });
    };

    return {
      ...toRefs(state),
      isSearchTypeRoute,
      startIconPath,
      endIconPath,
      showStartTsFuzzy,
      startTsFuzzy,
      movieTags,
      play,
      editMovieTag,
      dtFormat,
    };
  },
});
