import { Coordinate } from 'ol/coordinate';
import Feature from 'ol/Feature';
import { fromLonLat, transform } from 'ol/proj';
import { Style } from 'ol/style';
import {
  Location,
  QueryArea,
} from 'src/models';
import { CoordinateTransformOptions, NamedLayer, NamedLayerGroup, NamedVectorLayer } from 'src/lib/OlMapWrapper';
import Vue from 'vue';
import GIAbstractManager from '@/lib/geo_item/GIAbstractManager';
import {
  GIDebugMTX,
  GIResource,
  GIResourceWithID,
} from '@/models/geoItem';
import ExtremeMap from '@/lib/ExtremeMap/index.vue';
import { LayerEventHandler } from '@/lib/OlMapManager';

export interface EMLayerInfo {
  onLayerClick: LayerEventHandler;
  extent: null;
}

export interface InitArgs {
  dataName?: string;
  giManager?: GIAbstractManager | null;
}

export interface LayerWithInfo {
  layer: NamedVectorLayer | null;
  layerInfo: EMLayerInfo;
}

export default abstract class EMAbstractLayerManager {
  resourceMap: Record<string, GIResourceWithID>;
  layer: NamedVectorLayer | null;
  layerInfo: EMLayerInfo;
  additionalLayers: NamedLayer[] | NamedLayerGroup[];
  additionalLayerInfos: EMLayerInfo[];
  dataName: string;
  giManager?: GIAbstractManager | null;
  emitter: Vue;
  emListenEventNames: string[];
  isConnectedWithExtremeMap: boolean;
  currentZoom: number;

  constructor({ dataName, giManager }: InitArgs = {}) {
    if (this.constructor === EMAbstractLayerManager) {
      throw new TypeError('Abstract class "EMAbstractLayerManager" cannot be instantiated directly.');
    }

    this.layer = null;
    this.layerInfo = this.getDefaultLayerInfo_();
    this.additionalLayers = [];
    this.additionalLayerInfos = [];
    this.resourceMap = {};

    this.dataName = dataName || ''; // リソース名称(commentとか)
    this.giManager = giManager; // 使うかどうかはわからないがとりあえずもらっておく

    this.emitter = new Vue();
    this.emListenEventNames = []; // fill it in child class
    this.isConnectedWithExtremeMap = false;

    this.currentZoom = -1;
  }

  isArrayAllSame(a: any[], b: any[]): boolean {
    const aLen = a.length;
    const bLen = b.length;
    if (aLen !== bLen) { return false; }
    a = a.slice().sort();
    b = b.slice().sort();
    for (let i = 0; i < aLen; i++) {
      if (a[i] !== b[i]) { return false; }
    }
    return true;
  }

  coordFromLonLat(lon: number, lat: number, opt: CoordinateTransformOptions = {}): Coordinate {
    const destProj = opt.destProj || 'EPSG:3857';
    return fromLonLat([lon, lat], destProj);
  }

  convCoord({ lat, lon }: Location, opt: CoordinateTransformOptions = {}): Coordinate {
    const srcProj = opt.srcProj || 'EPSG:4326';
    const destProj = opt.destProj || 'EPSG:3857';
    return transform([lon, lat], srcProj, destProj);
  }

  getLayer(): LayerWithInfo {
    const layer = this.layer;
    const layerInfo = this.layerInfo;
    return { layer, layerInfo };
  }

  getAdditionalLayers(): { additionalLayers: NamedLayer[] | NamedLayerGroup[]; additionalLayerInfos: EMLayerInfo[] } {
    const additionalLayers = this.additionalLayers;
    const additionalLayerInfos = this.additionalLayerInfos;
    return { additionalLayers, additionalLayerInfos };
  }

  getLayerVisible(): boolean {
    if (!this.layer) { return false; }
    return this.layer.getVisible();
  }

  toggleLayerVisible(): void {
    if (!this.layer) { return; }
    const visible = this.layer.getVisible();
    this.layer.setVisible(!visible);
  }

  destroy(): void {
    this.emitter.$off();
    this.isConnectedWithExtremeMap = false;
    // override in child class if you want to explicitly
    // release some objects or so.
  }

  connectWithExtremeMap(em: InstanceType<typeof ExtremeMap>): void {
    // ExtremeMapの参照をもらい、このクラスでイベントが発火したら
    // ExtremeMapの対応するタイプのメソッドが呼ばれるようにする.
    if (this.isConnectedWithExtremeMap) { return; }
    this.isConnectedWithExtremeMap = true;
    this.emListenEventNames.forEach(evtName => {
      const tmpStr = evtName[0].toUpperCase() + evtName.slice(1);
      const funcName = 'handleLayerManagerEvent' + tmpStr;
      this.emitter.$on(evtName, (data: any) => {
        if (!em.hasOwnProperty(funcName)) { return; }
        em[funcName](data);
      });
    });
  }

  getBaseDataForEmit(): { dataName: string } {
    // layerManager子クラスでemitするときに必ず投げるデータ
    return { dataName: this.dataName };
  }

  abstract createLayer_(resources: GIResource[] | GIDebugMTX): void;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  createAdditionalLayers_(resources: GIResourceWithID[]): void {}

  getDefaultLayerInfo_(): EMLayerInfo {
    return {
      onLayerClick: () => {},
      extent: null,
    };
  }

  prepareLayer(resources: Array<GIResource> | GIDebugMTX): LayerWithInfo {
    if (!this.isLayerResources(resources)) {
      throw new Error('resources is not GIResourceWithID');
    }
    this.resourceMap = resources.reduce<Record<string, GIResourceWithID>>((acc, e) => {
      acc[e.id] = e; return acc;
    }, {});
    this.createLayer_(resources);
    return this.getLayer();
  }

  prepareAddtionalLayers(resources: Array<GIResource> | GIDebugMTX): { additionalLayers: NamedLayer[] | NamedLayerGroup[]; additionalLayerInfos: EMLayerInfo[] } {
    if (!this.isLayerResources(resources)) {
      throw new Error('resources is not GIResourceWithID');
    }
    this.resourceMap = resources.reduce<Record<string, GIResourceWithID>>((acc, e) => {
      acc[e.id] = e; return acc;
    }, {});
    this.createAdditionalLayers_(resources);
    return this.getAdditionalLayers();
  }

  refreshLayer(resources: Array<GIResource>): void {
    if (!this.isLayerResources(resources)) {
      throw new Error('resources is not GIResourceWithID');
    }
    this.resourceMap = resources.reduce<Record<string, GIResourceWithID>>((acc, e) => {
      acc[e.id] = e; return acc;
    }, {});
    if (!this.layer) { return; }
    const layerSource = this.layer.getSource();
    layerSource.clear();
    const feats = resources.map(e => this.getResourceFeatures_(e))
      .reduce((acc, e) => acc.concat(e), []);
    layerSource.addFeatures(feats);
  }

  refreshLayerOnZoomChange({ zoom }: { zoom?: number; oldZoom?: number }): void {
    if (!zoom) {
      return;
    }
    this.currentZoom = zoom;
    // zoomが変更されたときに何かしたかたったら子クラスでオーバーライド.
    // super()は呼ぶように.
  }

  deselectAll(): void {
    if (!this.layer) { return; }
    const layerSource = this.layer.getSource();
    for (const ent of Object.entries(this.resourceMap)) {
      const tmpResource = ent[1];
      const currentIsSelected = tmpResource.isSelected;
      tmpResource.isSelected = false;
      if (currentIsSelected !== tmpResource.isSelected) {
        const feat = layerSource.getFeatureById(tmpResource.id);
        if (!feat) { continue; }
        feat.setStyle(this.getResourceStyles_(tmpResource));
      }
    }
  }

  getResourceMap(): Record<string, GIResourceWithID> {
    return this.resourceMap;
  }

  abstract getResourceFeatures_(resource: GIResource | QueryArea | GIDebugMTX): Feature[];

  abstract getResourceStyles_(resource: GIResource): Style[];

  addLayerItem(resource: GIResource): void {
    if (!this.isLayerResource(resource)) {
      throw new Error('resource without id is not suppored.');
    }
    this.resourceMap[resource.id] = resource;
    const feats = this.getResourceFeatures_(resource);
    if (!this.layer) { return; }
    this.layer.getSource().addFeatures(feats);
  }

  updateLayerItem(resource: GIResource): void {
    if (!this.isLayerResource(resource)) {
      throw new Error('resource without id is not suppored.');
    }
    this.resourceMap[resource.id] = resource;
    if (!this.layer) { return; }
    const layerSource = this.layer.getSource();
    const feat = layerSource.getFeatureById(resource.id);
    feat.setStyle(this.getResourceStyles_(resource));
  }

  deleteLayerItem(resource: GIResource): void {
    if (!this.isLayerResource(resource)) {
      throw new Error('resource without id is not suppored.');
    }
    delete this.resourceMap[resource.id];
    if (!this.layer) { return; }
    const layerSource = this.layer.getSource();
    const feat = layerSource.getFeatureById(resource.id);
    layerSource.removeFeature(feat);
  }

  isLayerResources(resources: Array<GIResource> | GIDebugMTX): resources is GIResourceWithID[] {
    // TSの型エラーを回避するため
    return true;
  }

  isLayerResource(resources: GIResource): resources is GIResourceWithID {
    return resources && resources.hasOwnProperty('id');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getGeoItemByIndexDiff(current: GIResourceWithID, diff: number): GIResourceWithID {
    return current;
  }
}
