import { onMounted, Ref, ref, watch } from '@vue/composition-api';
import { AxiosResponse } from 'axios';
import { EmitFn } from '@/models';

export interface SavedImageParam<T> {
  refMapElemInfoData: Ref<T>;
  emit: EmitFn;
  fetchApiFn: () => Promise<AxiosResponse<Blob>>;
  skipFetchingImage?: Ref<boolean>;
  modalTitle?: Ref<string>;
  downloadFilenameTpl: Ref<string>;
}

export interface ShowImageParams {
  imageSrc: string;
  modalTitle: string;
  downloadFilenameTpl: string;
}

export default function useSavedImage<T>(
  params: SavedImageParam<T>,
): {
  savedImage: Ref<string | null>;
  showImage: () => void;
} {
  const savedImage = ref<string | null>(null);

  const fetchImageAsObjectURL = async(): Promise<string | null> => {
    try {
      const { data: blob } = await params.fetchApiFn();
      return URL.createObjectURL(blob);
    } catch (e) {
      return null;
    }
  };

  const updateSavedImage = async() => {
    if (savedImage.value) {
      URL.revokeObjectURL(savedImage.value);
      savedImage.value = null;
    }
    if (params.skipFetchingImage?.value) { return; }
    savedImage.value = await fetchImageAsObjectURL();
    params.emit('component-resized');
  };
  onMounted(updateSavedImage);

  watch(params.refMapElemInfoData, updateSavedImage);

  const showImage = () => {
    const showImageParams: ShowImageParams = {
      imageSrc: savedImage.value || '',
      modalTitle: params.modalTitle?.value || '',
      downloadFilenameTpl: params.downloadFilenameTpl.value,
    };
    params.emit('show-image', showImageParams);
  };

  return {
    savedImage,
    showImage,
  };
}
