import { LayerInfo, MasterData } from '@/models';
import { User } from '@/models/apis/user/userResponse';
import { GeoItemsMeta, GeoItemMeta } from 'src/models/geoItem';
import ExtremeMap from '@/components/lib/ExtremeMap/index.vue';

interface Options {
  useGIManager: boolean;
  user?: User;
  masters?: MasterData;
}

export function getGeoItemMeta(layerInfoList: LayerInfo[], options: Options = {useGIManager: false}): Record<string, GeoItemMeta> {
  const ret: Record<string, GeoItemMeta> = {};
  layerInfoList.forEach(info => {
    try {
      const tmpStr = info.name[0].toUpperCase() + info.name.slice(1);
      let giManager = null;
      if (options.useGIManager && options.masters) {
        const lovs = options.masters.lovs;
        const commentTypeMap = options.masters.commentType.map;
        const GIMgrClass = require(`@/lib/geo_item/GI${tmpStr}Manager`).default;
        giManager = new GIMgrClass({ user: options.user, lovs, commentTypeMap });
      }
      const EMLayerMgrClass =
        require(`@/lib/extreme_map/EM${tmpStr}LayerManager`).default;
      const layerManager = new EMLayerMgrClass({ dataName: info.name, giManager });
      const item = {
        name: info.name,
        dispName: info.dispName,
        manager: giManager,
        layerManager: layerManager,
        hideToggleCheckbox: !!info.hideToggleCheckbox,
        isCreatable: !!info.isCreatable,
        isTabPanel: !!info.isTabPanel,
        tabLabel: info.tabLabel || '',
      };
      ret[item.name] = item;
    } catch (e) {
      console.warn(`failure requiring geo item manager for '${info.name}'`);
      console.warn(e);
    }
  });

  return ret;
}

export function syncGeoItemWithParent(
  child: GeoItemsMeta,
  parent: GeoItemsMeta,
  extremeMap: InstanceType<typeof ExtremeMap>): void {
  for (const [dataName, metaItem] of Object.entries(child.map)) {
    const parentMetaItem = parent.map[dataName];
    if (!parentMetaItem) { continue; }
    if (parent.show[dataName]) {
      const data = Object.values(parentMetaItem.layerManager.getResourceMap());
      extremeMap.showDataLayer(metaItem, data);
    } else {
      extremeMap.removeDataLayer(metaItem.name);
    }
  }
}
