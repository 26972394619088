import { AdminMovieGroup, AdminMovieTag } from '@/models/apis/movieGroup/adminMovieGroupResponse';
import { GIMovieList } from 'src/models/geoItem';

export type ResultTag = AdminMovieTag & {
  count: number;
  numMovies: number;
  isPartiallySelected?: boolean;
};

export interface MovieTagMap {
  [key:number] : ResultTag;
}

export function getAggregatedMovieTagsFromMovieList(ml: AdminMovieGroup | GIMovieList): MovieTagMap[] {
  return getAggregatedMovieTagsFromMovieLists([ml]);
}

export function getAggregatedMovieTagsFromMovieLists(mls: Array<AdminMovieGroup | GIMovieList>): MovieTagMap[] {
  const movieTagMap: MovieTagMap = {};
  const numMovies = mls.reduce((acc, ml) => acc + ml.movies.length, 0);
  for (const ml of mls) {
    for (const movie of ml.movies) {
      for (const tag of movie.tags) {
        if (!movieTagMap[tag.id]) {
          movieTagMap[tag.id] = {
            ...tag,
            count: 0,
            numMovies: numMovies,
          };
        }
        movieTagMap[tag.id].count++;
        movieTagMap[tag.id].isPartiallySelected =
          movieTagMap[tag.id].count < numMovies;
      }
    }
  }
  return Object.values(movieTagMap);
}
