














































































import { computed, defineComponent } from '@vue/composition-api';
import { useRoute } from '@/hooks/useRoute';
import { useStore } from '@/hooks/useStore';

export default defineComponent({
  name: 'admin-top',
  setup() {
    const store = useStore();
    const fullScreenSpinnerState = store.state.fullScreenSpinner;
    const showFullScreenSpinner = computed(() => fullScreenSpinnerState.show);
    const spinnerMessage = computed(() => fullScreenSpinnerState.message);
    const isHtmlSpinnerMessage = computed(() => fullScreenSpinnerState.isHtmlMessage);

    const { route } = useRoute();
    const routeName = computed(() => route.value.name);

    return {
      showFullScreenSpinner,
      spinnerMessage,
      isHtmlSpinnerMessage,
      routeName,
    };
  },
});
