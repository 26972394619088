import EMAbstractLayerManager, { EMLayerInfo, InitArgs } from '@/lib/extreme_map/EMAbstractLayerManager';
import EMEventNames from '@/consts/extreme_map_event_names';
import { Style, Icon, Circle, Fill, Stroke } from 'ol/style';
import { Feature } from 'ol';
import Point from 'ol/geom/Point';
import VectorSource from 'ol/source/Vector';
import { GICar } from '@/models/geoItem';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';
import { NamedVectorLayer } from '@/lib/OlMapWrapper';
import { FeatureLike } from 'ol/Feature';
import { carKindsWithSpecialImg } from '@/consts/car_kinds_with_special_img';

export default class EMCarLayerManager extends EMAbstractLayerManager {
  carMap: Record<string, GICar>;

  constructor(args: InitArgs) {
    super(args);
    this.carMap = {};
    this.emListenEventNames = [EMEventNames.EM_EVENT_CLICK];
  }

  getCarIconPath_(car: GICar): { carIconPath: string; selectedFrameIconPath: string } {
    const carIconPrefix = '/static/img/car_';
    const statusPrefix = car.isMoving ? 'moving_' : 'stopped_';
    const imgCarKind = carKindsWithSpecialImg[car.device.car_kind] || 'default';
    const carIconPath = `${carIconPrefix}${statusPrefix}${imgCarKind}.png`;
    const selectedFrameIconPath = `${carIconPrefix}selected.png`;
    return { carIconPath, selectedFrameIconPath };
  }

  getResourceStyles_(car: GICar): Style[] {
    const ret = [];
    const { carIconPath, selectedFrameIconPath } = this.getCarIconPath_(car);
    const zIndex = car.carZIndex;

    const iconStyle = new Style({
      image: new Icon({
        src: carIconPath,
        anchor: [0.5, 0.95],
        anchorXUnits: IconAnchorUnits.FRACTION,
        anchorYUnits: IconAnchorUnits.FRACTION,
        scale: 0.25,
      }),
    });
    if (zIndex) { iconStyle.setZIndex(zIndex); }
    ret.push(iconStyle);

    if (car.isSelected) {
      const selectedIconStyle = new Style({
        image: new Icon({
          src: selectedFrameIconPath,
          anchor: [0.5, 0.95],
          anchorXUnits: IconAnchorUnits.FRACTION,
          anchorYUnits: IconAnchorUnits.FRACTION,
          scale: 0.25,
          opacity: 1.0,
        }),
      });
      if (zIndex) { selectedIconStyle.setZIndex(zIndex + 1); }
      ret.push(selectedIconStyle);
    }

    return ret;
  }

  getCarFeature_(car: GICar): Feature {
    const pos = this.convCoord({
      lon: parseFloat(car.lon.toString()), lat: parseFloat(car.lat.toString()),
    });
    const feat = new Feature(new Point(pos));
    feat.setId(car.device_id);
    feat.setStyle(this.getResourceStyles_(car));
    return feat;
  }

  getTrailingPointFeatures(car: GICar): Feature[] {
    // 車に近い方から遠ざかっていく方に並んでいるので、逆にする
    // (車に近い方を上にしたいので)
    const trailingPoints = (car.tail || []).map(pos => {
      return this.convCoord({
        lon: parseFloat(pos.lon), lat: parseFloat(pos.lat),
      });
    });
    const points = trailingPoints.slice().reverse();
    points.push(this.convCoord({lon: car.lon, lat: car.lat}));
    const stepMargin = 3;
    const step = 1 / (points.length + stepMargin);
    let zIndex = car.trailZIndex ?? 0;

    const feats = points.map((point, i) => {
      const progress = (i + stepMargin + 1) * step;
      const feat = new Feature(new Point(point));
      feat.setStyle(new Style({
        image: new Circle({
          radius: 6 * progress,
          fill: new Fill({
            color: [102, 194, 255, 0.8 * progress],
          }),
          stroke: new Stroke({
            color: [0, 122, 204, 0.8 * progress],
            width: 1,
          }),
        }),
        zIndex: zIndex++,
      }));
      return feat;
    });
    return feats;
  }

  createCarFeatures_(cars: GICar[]): Feature[] {
    const feats: Feature[] = [];
    let trailZIndex = 1;
    let carZIndex = 200001;
    cars.forEach(car => {
      car.carZIndex = carZIndex;
      car.trailZIndex = trailZIndex;

      const carFeats = this.getResourceFeatures_(car);
      feats.push(...carFeats);
      trailZIndex += car.hideCarIcon ? carFeats.length : carFeats.length - 1;

      if (!car.hideCarIcon) {
        carZIndex += 5; // styleが複数の場合が有りえるので適当に増やす
      }

      // 配列の先頭側にある方を上にする
      carZIndex -= 50;
    });
    return feats;
  }

  getResourceFeatures_(car: GICar): Feature[] {
    const feats = this.getTrailingPointFeatures(car);
    if (!car.hideCarIcon) {
      feats.push(this.getCarFeature_(car));
    }
    return feats;
  }

  onClickFeature_(targetFeat: FeatureLike): void {
    const targetFeatId = targetFeat.getId();
    if (!targetFeatId) {
      return;
    }
    const targetCar = this.carMap[targetFeatId];

    if (!this.layer) {
      return;
    }
    // 地図上の見た目を調整
    const layerSource = this.layer.getSource();
    for (const ent of Object.entries(this.carMap)) {
      const tmpCar = ent[1];
      const currentIsSelected = tmpCar.isSelected;
      tmpCar.isSelected =
        tmpCar.device_id === targetCar.device_id &&
        !tmpCar.isSelected;
      if (currentIsSelected !== tmpCar.isSelected) {
        const feat = layerSource.getFeatureById(tmpCar.device_id);
        feat.setStyle(this.getResourceStyles_(tmpCar));
      }
    }

    // イベント発火
    const obj: { dataName: string; data?: GICar } = this.getBaseDataForEmit();
    obj.data = targetCar;
    this.emitter.$emit(EMEventNames.EM_EVENT_CLICK, obj);
  }

  createLayer_(cars: GICar[]): void {
    const feats = this.createCarFeatures_(cars);
    const layer = new NamedVectorLayer('cars', {
      source: new VectorSource({features: feats}),
    });
    this.layer = layer;
    this.layerInfo.onLayerClick = ({ event, feature }) => {
      // 重なってたりする場合はそれぞれ飛んでくるので、一回で止める
      if (!event || event.originalEvent.defaultPrevented) { return; }
      event.preventDefault();
      if (!feature) { return; }
      this.onClickFeature_(feature);
    };
  }

  prepareLayer(cars: GICar[]): { layer: NamedVectorLayer | null; layerInfo: EMLayerInfo } {
    this.carMap = cars.reduce<Record<string, GICar>>((acc, e) => {
      acc[e.device_id] = e; return acc;
    }, {});
    this.createLayer_(cars);
    return this.getLayer();
  }

  refreshLayer(cars: GICar[]): void {
    if (!this.layer) {
      return;
    }
    this.layer.getSource().clear();

    this.carMap = cars.reduce<Record<string, GICar>>((acc, e) => {
      acc[e.device_id] = e; return acc;
    }, {});

    const feats = this.createCarFeatures_(cars);
    this.layer.getSource().addFeatures(feats);
  }

  deselectAll(): void {
    if (!this.layer) {
      return;
    }
    const layerSource = this.layer.getSource();
    for (const ent of Object.entries(this.carMap)) {
      const tmpCar = ent[1];
      const currentIsSelected = tmpCar.isSelected;
      tmpCar.isSelected = false;
      if (currentIsSelected !== tmpCar.isSelected) {
        const feat = layerSource.getFeatureById(tmpCar.device_id);
        if (!feat) { continue; }
        feat.setStyle(this.getResourceStyles_(tmpCar));
      }
    }
  }
}
