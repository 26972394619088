


























































import {
  defineComponent,
  onMounted,
  PropType,
  reactive,
  toRefs,
  watch,
} from '@vue/composition-api';
import { MapElemInfo, Tab } from '@/models';
import { GIComment, GIResourceWithID } from '@/models/geoItem';
import { fuzzyTimeDisp } from '@/lib/dateHelper';

interface TabLable {
  dataName: string;
  label: string;
}

interface TabData {
  dataName: string;
  // template内でdataNameを見て属性を参照しているため、anyでなくてはいけない
  resources: any;
}

interface TabPaneState {
  activeTab: string;
  tabLabels: TabLable[];
  tabData: TabData[];
  styles: {
    tabContainerMinHeight: string;
    tabContainerMaxHeight: string;
    tabContentMaxHeight: string;
  };
}

export default defineComponent({
  name: 'tab-pane-component',
  props: {
    tabs: {
      type: Array as PropType<Tab[]>,
      default: () => [],
    },
    defaultActiveTab: {
      type: String,
      default: '',
    },
    tabContainerMinHeight: {
      type: String,
      default: '',
    },
    tabContainerMaxHeight: {
      type: String,
      default: '',
    },
    tabContentMaxHeight: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const state = reactive<TabPaneState>({
      activeTab: '',
      tabLabels: [],
      tabData: [],
      styles: {
        tabContainerMinHeight: '',
        tabContainerMaxHeight: '',
        tabContentMaxHeight: '',
      },
    });

    onMounted(() => {
      state.activeTab = props.defaultActiveTab;
      state.styles.tabContainerMinHeight = props.tabContainerMinHeight;
      state.styles.tabContainerMaxHeight = props.tabContainerMaxHeight;
      state.styles.tabContentMaxHeight = props.tabContentMaxHeight;
    });

    // watch:
    watch(() => props.tabs, () => {
      state.tabLabels = props.tabs.map(e => {
        return { dataName: e.dataName, label: e.label };
      });
      state.tabData = props.tabs.map(e => {
        return { dataName: e.dataName, resources: e.resources };
      });
      // tabsの中にactiveTabが含まれない時、選択を初期化する
      if (!props.tabs.map(e => e.dataName).includes(state.activeTab)) {
        state.activeTab = props.defaultActiveTab;
      }
    });
    watch(() => props.tabContainerMinHeight, () => {
      state.styles.tabContainerMinHeight = props.tabContainerMinHeight;
    });
    watch(() => props.tabContainerMaxHeight, () => {
      state.styles.tabContainerMaxHeight = props.tabContainerMaxHeight;
    });
    watch(() => props.tabContentMaxHeight, () => {
      state.styles.tabContentMaxHeight = props.tabContentMaxHeight;
    });

    // methods
    const onTabClick = (dataName: string) => {
      state.activeTab = dataName;
    };
    const onRecentCommentsClick = (comment: GIComment) => {
      const params: MapElemInfo<GIComment> = {
        data: comment,
        dataName: 'comment',
      };
      emit('click', params);
    };
    const onTabElementClick = (element: GIResourceWithID, dataName: string) => {
      const params: MapElemInfo<GIResourceWithID> = {
        data: element,
        dataName,
      };
      emit('click', params);
    };

    return {
      ...toRefs(state),
      // methods
      onTabClick,
      onRecentCommentsClick,
      onTabElementClick,
      fuzzyTimeDisp,
    };
  },
});
