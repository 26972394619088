import { Collection } from 'ol';
import { Options as GroupOptions } from 'ol/layer/Group';
import HeatMapLayer, { Options as HeatmapOptions } from 'ol/layer/Heatmap';
import VectorLayer from 'ol/layer/Vector';
import Style from 'ol/style/Style';
import Text from 'ol/style/Text';
import ClusterSource from 'ol/source/Cluster';
import VectorSource from 'ol/source/Vector';
import { FeatureLike } from 'ol/Feature';
import { EventsKey } from 'ol/events';
import { NamedLayerGroup } from '@/lib/OlMapWrapper';

type Options = HeatmapOptions & GroupOptions & {
  showCluster: boolean;
};

export default class CustomHeatmapLayer extends NamedLayerGroup {
  private source_: VectorSource | null;

  constructor(name: string, {
    opacity,
    visible,
    extent,
    zIndex,
    minResolution,
    maxResolution,
    gradient,
    radius,
    blur,
    weight,
    source,
    showCluster,
  }: Options) {
    const baseOptions = {
      opacity,
      visible,
      extent,
      zIndex,
      minResolution,
      maxResolution,
    };
    super(name, baseOptions);
    const layers = [];
    const heatmapOptions = {
      gradient,
      radius,
      blur,
      weight,
      source,
    };
    const heatmapLayer = this.getHeatmapLayer_(heatmapOptions);
    layers.push(heatmapLayer);
    this.source_ = null;
    if (showCluster && source) {
      this.source_ = source;
      const clusterLayer = this.getClusterLayer_(source);
      layers.push(clusterLayer);
    }

    this.setLayers(new Collection(layers.slice(), {unique: true}));
  }

  // 個別レイアzIndexを設定しないと、一番後ろに表示されてしまう
  setZIndex(zIndex: number): void {
    const collection = this.getLayers();
    if (!collection) { return; }

    const layers = collection.getArray();
    layers.forEach(layer => {
      layer.setZIndex(zIndex);
    });
  }

  getHeatmapLayer_(options: HeatmapOptions): HeatMapLayer {
    return new HeatMapLayer(options);
  }

  getClusterLayer_(source: VectorSource): VectorLayer {
    const clusterSource = new ClusterSource({
      source,
    });
    const styleCache: Record<string, Style> = {};
    const styleFun = (feature: FeatureLike) => {
      const size = feature.get('features').length;
      let style = styleCache[size];
      if (!style) {
        style = new Style({
          text: new Text({
            text: size.toString(),
          }),
        });
        styleCache[size] = style;
      }
      return style;
    };
    return new VectorLayer({
      source: clusterSource,
      style: styleFun,
    });
  }

  // TSエラーを回避する為に、オーバーライド
  on(): EventsKey {
    throw new Error('on is not supported');
  }

  // TSエラーを回避する為に、オーバーライド
  once(type: string | string[], listener: (p0: any) => void): EventsKey {
    const result = super.on(type, listener);
    if (Array.isArray(result)) {
      return result[0];
    }
    return result;
  }

  getSource(): VectorSource {
    if (!this.source_) {
      throw new Error('source is not set');
    }
    return this.source_;
  }

  setMap(): void {
    console.warn('setMap is not supported');
  }

  setSource(): void {
    console.warn('setSource is not supported');
  }
}
