












import {
  computed,
  defineComponent,
  reactive,
  toRefs,
} from '@vue/composition-api';
import { SensorData } from '@/models/apis/mtx/mtxResponse';
import LineChart, { LineChartProps } from './LineChart.vue';
import { ChartData, ChartOptions, ChartTooltipItem } from 'chart.js';
import { dtFormat, ensureDate } from '@/lib/dateHelper';

interface ThreeAxisSensorChartState {
  sensorData: SensorData[];
  chartData: LineChartProps;
}

export default defineComponent({
  name: 'three-axis-sensor-chart',
  setup(_, { emit }) {
    const state = reactive<ThreeAxisSensorChartState>({
      sensorData: [],
      chartData: {
        data: {},
        opts: {},
      },
    });

    const hasSensorData = computed(() => {
      return state.sensorData.length > 0;
    });

    // methods
    const formatSensorData = (data: SensorData[]) => {
      const xLabels: Date[] = [];
      const vs: number[] = [];
      const xs: number[] = [];
      const ys: number[] = [];
      const zs: number[] = [];
      data.forEach(obj => {
        let current = ensureDate(obj.start_ts)?.valueOf();
        // ds, vs, xs, ys, zsは全て同じlengthのはず..を軽くチェック
        if (obj.ds.length !== obj.vs.length) { return; }
        obj.ds.forEach((d) => {
          if (!current) {
            return;
          }
          const dt = new Date(current + d);
          xLabels.push(dt);
          current += d;
        });
        vs.push(...obj.vs);
        xs.push(...obj.xs);
        ys.push(...obj.ys);
        zs.push(...obj.zs);
      });

      return { xLabels, vs, xs, ys, zs };
    };

    const updateChartData = (sensorData: SensorData[]) => {
      state.sensorData = sensorData;
      const { xLabels, vs, xs, ys, zs } = formatSensorData(sensorData);
      const data: ChartData = {
        labels: xLabels,
        datasets: [
          {
            data: vs,
            type: 'line',
            yAxisID: 'y-axis-velocity',
            label: '速度',
            fill: true,
            lineTension: 0.1,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
            borderWidth: 1,
            borderCapStyle: 'butt',
            borderJoinStyle: 'miter',
            pointBackgroundColor: 'rgba(75,192,192,1)',
            pointBorderWidth: 0,
            pointHoverRadius: 3,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 0,
            pointHitRadius: 10,
            spanGaps: false,
          },
          {
            data: xs,
            type: 'line',
            yAxisID: 'y-axis-gsensor',
            label: 'X軸',
            fill: false,
            lineTension: 0,
            backgroundColor: 'rgba(214,69,65,0.4)',
            borderColor: 'rgba(214,69,65,1)',
            borderWidth: 1,
            borderCapStyle: 'butt',
            borderJoinStyle: 'miter',
            pointBackgroundColor: 'rgba(214,69,65,1)',
            pointBorderWidth: 0,
            pointHoverRadius: 3,
            pointHoverBackgroundColor: 'rgba(214,69,65,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 0,
            pointHitRadius: 10,
            spanGaps: false,
          },
          {
            data: ys,
            type: 'line',
            yAxisID: 'y-axis-gsensor',
            label: 'Y軸',
            fill: false,
            lineTension: 0,
            backgroundColor: 'rgba(52,152,219,0.4)',
            borderColor: 'rgba(52,152,219,1)',
            borderWidth: 1,
            borderCapStyle: 'butt',
            borderJoinStyle: 'miter',
            pointBackgroundColor: 'rgba(52,152,219,1)',
            pointBorderWidth: 0,
            pointHoverRadius: 3,
            pointHoverBackgroundColor: 'rgba(52,152,219,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 0,
            pointHitRadius: 10,
            spanGaps: false,
          },
          {
            data: zs,
            type: 'line',
            yAxisID: 'y-axis-gsensor',
            label: 'Z軸',
            fill: false,
            lineTension: 0,
            backgroundColor: 'rgba(233,212,96,0.4)',
            borderColor: 'rgba(233,212,96,1)',
            borderWidth: 1,
            borderCapStyle: 'butt',
            borderJoinStyle: 'miter',
            pointBackgroundColor: 'rgba(233,212,96,1)',
            pointBorderWidth: 0,
            pointHoverRadius: 3,
            pointHoverBackgroundColor: 'rgba(233,212,96,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 1,
            pointRadius: 0,
            pointHitRadius: 10,
            spanGaps: false,
          },
        ],
      };
      const opts: ChartOptions = {
        layout: {
          padding: {
            top: 0,
            right: 0,
            bottom: 12,
            left: 0,
          },
        },
        animation: {
          duration: 0,
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: 'top',
        },
        tooltips: {
          mode: 'index',
          position: 'nearest',
          callbacks: {
            title: (tooltipItemArr: ChartTooltipItem[]) => {
              const ti = tooltipItemArr[0];
              if (ti.index === undefined) { return ''; }
              const dt = xLabels[ti.index];
              return dtFormat(dt, 'H:MM:SS.sss');
            },
            label: (tooltipItem: ChartTooltipItem, data: ChartData) => {
              const datasetIdx = tooltipItem.datasetIndex;
              if (data.datasets === undefined || datasetIdx === undefined) { return ''; }
              const dataset = data.datasets[datasetIdx];
              const val = tooltipItem.yLabel;
              let ret;
              if (datasetIdx > 0) {
                ret = `${dataset.label}: ${val}`;
              } else {
                ret = `${dataset.label}: ${val} km/h`;
              }
              return ret;
            },
          },
        },
        scales: {
          xAxes: [{
            type: 'time',
            position: 'top',
            time: {
              minUnit: 'minute',
              displayFormats: {
                hour: 'H:mm',
                minute: 'H:mm',
              },
            },
          }],
          yAxes: [
            {
              id: 'y-axis-velocity',
              position: 'left',
              ticks: {
                beginAtZero: true,
              },
              scaleLabel: {
                display: true,
                labelString: 'km/h',
              },
            },
            {
              id: 'y-axis-gsensor',
              position: 'right',
              ticks: {
                suggestedMin: -1.5,
                suggestedMax: 1.5,
              },
              scaleLabel: {
                display: true,
                labelString: '3軸センサー',
              },
            },
          ],
        },
        // MetaData type not exported from Chart.js
        onClick: (e: MouseEvent, elements: Array<any>) => {
          if (elements.length === 0) { return; }

          const elem = elements[0];
          const data = elem._chart.data;
          const barIndex = elem._index;
          const datasetIndex = elem._datasetIndex;
          const xLabel = data.labels[barIndex];
          const yLabel = data.datasets[datasetIndex].data[barIndex];
          const datasetLabel = data.datasets[datasetIndex].label;
          console.log(xLabel, yLabel, datasetLabel);
          emit('click', xLabel);
        },
      };
      state.chartData = { data, opts };
    };

    return {
      ...toRefs(state),
      hasSensorData,
      updateChartData,
    };
  },
  components: { LineChart },
});
