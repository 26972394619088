


import group2Api from '@/apis/group2';
import group3Api from '@/apis/group3';
import userApi from '@/apis/user';
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  toRefs,
} from '@vue/composition-api';
import { Lov } from 'src/models/apis/master/masterResponse';
import { AdminGroup2 } from 'src/models/apis/group2/adminGroup2Response';
import { AdminGroup3 } from 'src/models/apis/group3/adminGroup3Response';
import { AdminUser, AdminUserUploadCsvResponse } from 'src/models/apis/user/adminUserResponse';
import { useStore } from '@/hooks/useStore';
import { downloadBlob } from '@/lib/downloadHelper';
import { getErrorMessages } from '@/lib/errMsgHelper';
import { AxiosError } from 'axios';
import { lineBreakToBR } from '@/lib/utils';
import { dtFormat } from '@/lib/dateHelper';

interface SearchParams {
  group2_id: number | null;
  group3_id: number | null;
  user_id_or_user_name: string | null;
  role: string | null;
}

interface AdminUserEdit {
  id: number;
  group2_id?: number | null;
  group_id: number | null;
  username: string;
  display_name: string;
  role: string | null;
  password?: string;
  password_confirmation?: string;
}

interface CsvResult extends AdminUserUploadCsvResponse {
  hasErrors: boolean;
}

interface AdminUsersState {
  showEditModal: boolean;
  editMode: 'create' | 'edit' | 'editPassword';
  showDestroyModal: boolean;
  showErrorModal: boolean;
  showComfirmCsvUploadModal: boolean;
  showCsvUploadCompleteModal: boolean;
  showCsvErrorModal: boolean;
  csvErrorBody: string;
  filteredUsers: AdminUser[];
  roles: Lov[];
  groups2: AdminGroup2[];
  groups3: AdminGroup3[];
  search: SearchParams;
  editting: AdminUserEdit;
  httpErrorMsgs: string[];
  createOrUpdatePasswordOkPushed: boolean;
  uploadingCsv: File;
  csvResult: CsvResult;
  isDownloading: boolean;
  isUploading: boolean;
}

export default defineComponent({
  name: 'admin-users',
  setup() {
    const initSearchState = (): SearchParams => {
      return {
        group2_id: null,
        group3_id: null,
        user_id_or_user_name: null,
        role: null,
      };
    };
    const initEdittingState = (): AdminUserEdit => {
      return {
        id: 0,
        group2_id: null,
        group_id: null, // group_id
        username: '',
        display_name: '',
        role: null,
        password: '',
        password_confirmation: '',
      };
    };
    const state = reactive<AdminUsersState>({
      showEditModal: false,
      editMode: 'edit',
      showDestroyModal: false,
      showErrorModal: false,
      showComfirmCsvUploadModal: false,
      showCsvUploadCompleteModal: false,
      showCsvErrorModal: false,
      csvErrorBody: '',
      filteredUsers: [],
      roles: [],
      groups2: [],
      groups3: [],
      search: initSearchState(),
      editting: initEdittingState(),
      httpErrorMsgs: [],
      createOrUpdatePasswordOkPushed: false,
      uploadingCsv: {} as File,
      csvResult: {} as CsvResult,
      isDownloading: false,
      isUploading: false,
    });

    const store = useStore();
    const userState = store.state.user;
    const isSuperAdmin = computed<boolean>(() => {
      return userState.has_role_super_admin;
    });
    const isValidPasswordString = (password: string) => {
      const regAlphaLower = /[a-z]/;
      const regAlphaUpper = /[A-Z]/;
      const regNumber = /[0-9]/;
      const regSymbol = /[!@#$%^&*(){}[\]:;"'<>,.?/~`_+\-=|\\ ]/;
      // 3種類以上の文字種を含めることを強制する.
      let numStrKinds = 0;
      if (password.match(regAlphaLower)) { numStrKinds++; }
      if (password.match(regAlphaUpper)) { numStrKinds++; }
      if (password.match(regNumber)) { numStrKinds++; }
      if (password.match(regSymbol)) { numStrKinds++; }
      return numStrKinds >= 3;
    };
    const validateEditting = computed(() => {
      let errorMsgs = [];
      if ((state.editMode === 'create' || state.editMode === 'edit') &&
        (!state.editting.group2_id || !Number.isInteger(state.editting.group2_id))
      ) {
        errorMsgs.push('事業所は選択必須です');
      }
      if ((state.editMode === 'create' || state.editMode === 'edit') &&
        (!state.editting.group_id || !Number.isInteger(state.editting.group_id))
      ) {
        errorMsgs.push('部署は選択必須です');
      }
      if ((state.editMode === 'create' || state.editMode === 'edit') &&
        (!state.editting.username || state.editting.username.length <= 0)
      ) {
        errorMsgs.push('ユーザーIDは必須です');
      }
      if ((state.editMode === 'create' || state.editMode === 'edit') &&
        (!state.editting.display_name || state.editting.display_name.length <= 0)
      ) {
        errorMsgs.push('ユーザー名は必須です');
      }
      if ((state.editMode === 'create' || state.editMode === 'edit') &&
        (!state.editting.role || !Number.isInteger(parseInt(state.editting.role)))
      ) {
        errorMsgs.push('権限は必須です');
      }
      if ((state.editMode === 'create' || state.editMode === 'editPassword')) {
        if (!state.editting.password || state.editting.password.length < 8) {
          errorMsgs.push('パスワードは8文字以上にしてください');
        }
        if (state.editting.password && !isValidPasswordString(state.editting.password)) {
          errorMsgs.push('パスワードには英小文字、英大文字、数字、記号から3種類以上の文字種を含めてください。');
        }
      }
      return errorMsgs;
    });
    const passwordErrorMsg = computed(() => {
      if ((state.editMode === 'create' || state.editMode === 'editPassword') &&
        state.editting.password !== state.editting.password_confirmation) {
        return 'パスワードが一致しません。';
      }
      return null;
    });
    const selectedEditModalGroup2 = computed(() => {
      return state.editting.group2_id != null;
    });
    const selectableSearchGroup3 = computed(() => {
      if (state.search.group2_id == null) {
        return state.groups3;
      }
      return state.groups3.filter((e) => {
        return e.parent_id === state.search.group2_id;
      });
    });
    const editModalTitle = computed(() => {
      if (state.editMode === 'create') {
        return '新規作成';
      } else if (state.editMode === 'editPassword') {
        return 'パスワード変更';
      } else {
        return '編集';
      }
    });
    const selectableRoles = computed(() => {
      if (isSuperAdmin.value) {
        return state.roles;
      } else {
        return state.roles.filter((e) => {
          return parseInt(e.key) >= 100;
        });
      }
    });
    const getRoleLabel = (role: number) => {
      for (const roleItem of state.roles) {
        if (parseInt(roleItem.key) === role) {
          return roleItem.val;
        }
      }
      return role;
    };
    const displayData = computed(() => {
      return state.filteredUsers.map(user => {
        const gName = `${user.group_info.g2name}\n${user.group_info.g3name}`;
        return {
          ...user,
          gName: lineBreakToBR(gName),
          roleDisp: getRoleLabel(user.role),
        };
      });
    });

    const resetSearchResults = () => {
      state.filteredUsers = [];
    };
    const filterSearch = (usersOrig: AdminUser[]) => {
      state.filteredUsers = usersOrig.filter(user => {
        if (state.search.group2_id != null &&
          user.group_info.g2id !== state.search.group2_id) {
          return false;
        }
        if (state.search.group3_id != null &&
          user.group_info.g3id !== state.search.group3_id) {
          return false;
        }
        if (state.search.user_id_or_user_name != null &&
          state.search.user_id_or_user_name !== '' &&
          user.username.indexOf(state.search.user_id_or_user_name) < 0 &&
          user.display_name.indexOf(state.search.user_id_or_user_name) < 0) {
          return false;
        }
        if (state.search.role != null &&
          state.search.role !== '' &&
          user.role !== parseInt(state.search.role)) {
          return false;
        }
        return true;
      });
    };
    const doSearch = () => {
      resetSearchResults();
      userApi.adminIndex({})
        .then(({ data }) => {
          if (!data || data.length === 0) { return; }
          filterSearch(data);
        });
    };

    onMounted(() => {
      window.master.$promise.then(() => {
        state.roles = window.master.lovs.role.vals;
      });
      group2Api.adminIndex().then(({ data }) => {
        state.groups2 = data;
      });
      group3Api.adminIndex().then(({ data }) => {
        state.groups3 = data;
      });
      doSearch();
    });

    const editPassword = (obj: AdminUser) => {
      state.createOrUpdatePasswordOkPushed = false;
      state.editting = {
        id: obj.id,
        group2_id: obj.group_info.g2id,
        group_id: obj.group_id,
        username: obj.username,
        display_name: obj.display_name,
        role: obj.role.toString(),
        password: '',
        password_confirmation: '',
      };
      state.httpErrorMsgs = [];
      state.showEditModal = true;
      state.editMode = 'editPassword';
    };
    const create = () => {
      state.createOrUpdatePasswordOkPushed = false;
      state.editting = {
        id: 0,
        group2_id: null,
        group_id: null, // group_id
        username: '',
        display_name: '',
        role: null,
        password: '',
        password_confirmation: '',
      };
      state.httpErrorMsgs = [];
      state.showEditModal = true;
      state.editMode = 'create';
    };
    const openConfirmCsvUploadModal = (evt: Event) => {
      const eventTarget = evt.target as HTMLInputElement;
      if (!eventTarget || !eventTarget.files) {
        return;
      }
      const file = eventTarget.files[0];
      if (!file) {
        return;
      }
      state.uploadingCsv = file;
      state.showComfirmCsvUploadModal = true;
    };
    const downloadCSV = async() => {
      state.isDownloading = true;
      try {
        const { data } = await userApi.adminDownloadCSV(state.search);
        const timestamp = dtFormat(new Date(), 'yyyymmdd_HHMMSS');
        const filename = `ユーザー一覧_${timestamp}`;
        downloadBlob(data, filename);
        state.isDownloading = false;
      } catch (e) {
        console.error('error', e);
        state.csvErrorBody = 'CSVダウンロードに失敗しました。再度操作を行ってください';
        state.showCsvErrorModal = true;
        state.isDownloading = false;
      }
    };
    const edit = (obj: AdminUser) => {
      state.editting = {
        id: obj.id,
        group2_id: obj.group_info.g2id,
        group_id: obj.group_info.g3id, // group_id
        username: obj.username,
        display_name: obj.display_name,
        role: obj.role.toString(),
      };
      state.httpErrorMsgs = [];
      state.showEditModal = true;
      state.editMode = 'edit';
    };
    const tryDestroy = (obj: AdminUser) => {
      state.editting = {
        ...obj,
        role: obj.role.toString(),
      };
      state.showDestroyModal = true;
    };
    const doDestroy = () => {
      if (!state.editting.id) {
        return;
      }
      userApi.adminDestroy(
        state.editting.id,
      ).then(() => {
        state.showDestroyModal = false;
        doSearch();
      }).catch((err) => {
        console.log(err);
        state.showDestroyModal = false;
        state.showErrorModal = true;
      });
    };
    const save = async() => {
      if (state.editMode === 'editPassword' || state.editMode === 'create') {
        state.createOrUpdatePasswordOkPushed = true;
        if (passwordErrorMsg.value) {
          return;
        }
      }
      try {
        if (state.editMode === 'edit') {
          if (!state.editting.id ||
            !state.editting.username ||
            !state.editting.display_name ||
            !state.editting.role ||
            !state.editting.group_id
          ) {
            return;
          }
          await userApi.adminUpdate(state.editting.id, {
            username: state.editting.username,
            display_name: state.editting.display_name || '',
            role: parseInt(state.editting.role),
            group_id: state.editting.group_id,
          });
        } else if (state.editMode === 'editPassword') {
          if (!state.editting.id) {
            return;
          }
          await userApi.adminPasswordUpdate(state.editting.id, {
            password: state.editting.password || '',
            password_confirmation: state.editting.password_confirmation || '',
          });
        } else {
          if (!state.editting.role ||
            !state.editting.group_id ||
            !state.editting.password ||
            !state.editting.password_confirmation ||
            !state.editting.password_confirmation
          ) {
            return;
          }
          await userApi.adminCreate({
            ...state.editting,
            role: parseInt(state.editting.role),
            group_id: state.editting.group_id,
            password: state.editting.password,
          });
        }
        state.httpErrorMsgs = [];
        state.showEditModal = false;
        doSearch();
      } catch (err) {
        state.httpErrorMsgs = getErrorMessages(err as AxiosError);
      }
    };
    const filterGroup3 = (groups3: AdminGroup3, group2Id: number) => {
      return state.groups3.filter((group3) => {
        return group3.parent_id === group2Id;
      });
    };
    const closeConfirmCsvUploadModal = () => {
      state.uploadingCsv = {} as File;
      state.showComfirmCsvUploadModal = false;
    };
    const closeCsvUploadCompleteModal = () => {
      state.csvResult = {} as CsvResult;
      state.showCsvUploadCompleteModal = false;
    };
    const uploadCSV = async() => {
      state.isUploading = true;
      try {
        const formData = new FormData();
        formData.append('file', state.uploadingCsv, state.uploadingCsv.name);
        const { data } = await userApi.adminUploadCSV(formData);
        state.csvResult = {
          ...data,
          hasErrors: data.failures.length > 0,
        };
        state.showCsvUploadCompleteModal = true;
        closeConfirmCsvUploadModal();
        state.isUploading = false;
        await doSearch();
      } catch (e) {
        console.error('error', e);
        state.csvErrorBody = 'CSVアップロードに失敗しました。再度操作を行ってください';
        state.showCsvErrorModal = true;
        closeConfirmCsvUploadModal();
        state.isUploading = false;
      }
    };

    const destroyModalTitle = '削除';
    const destroyModalMsg = 'を削除してもよろしいですか？';
    const errorModalTitle = 'エラー';
    const errorModalMsg = '不明なエラーです。ネットワークの状態をご確認いただき再度お試し下さい。';
    const fields = [
      { name: 'gName', label: '所属組織', type: 'html', class: 'ws-nowrap' },
      { name: 'username', label: 'ユーザーID' },
      { name: 'display_name', label: 'ユーザー名' },
      { name: 'roleDisp', label: '権限', class: 'ws-nowrap' },
    ];
    const customButtons = [
      {
        label: 'パスワード変更',
        clickHandler: (obj: AdminUser) => {
          editPassword(obj);
        },
      },
    ];

    return {
      ...toRefs(state),
      // computeds
      isSuperAdmin,
      validateEditting,
      passwordErrorMsg,
      selectedEditModalGroup2,
      selectableSearchGroup3,
      editModalTitle,
      selectableRoles,
      displayData,
      // methods
      doSearch,
      create,
      openConfirmCsvUploadModal,
      downloadCSV,
      edit,
      tryDestroy,
      doDestroy,
      save,
      filterGroup3,
      uploadCSV,
      closeConfirmCsvUploadModal,
      closeCsvUploadCompleteModal,
      // others
      destroyModalTitle,
      destroyModalMsg,
      errorModalTitle,
      errorModalMsg,
      fields,
      customButtons,
    };
  },
});
