












import {
  defineComponent,
  PropType,
  computed,
  ref,
} from '@vue/composition-api';
import { RoadName } from '@/models/apis/master/masterResponse';
import BasicMoviePlayer from './BasicMoviePlayer/index.vue';
import LidarMoviePlayer from './LidarMoviePlayer/index.vue';
import { GeoItemsMeta, GIMovieList } from '@/models/geoItem';
import { ExtremeMapEssentials } from '@/models';
import { MoviePlayerParams } from '@/models/moviePlayer';

export default defineComponent({
  name: 'movie-player',
  props: {
    showLidarMode: {
      type: Boolean,
      default: false,
    },
    roadNameDispMap: {
      type: Object as PropType<Record<string, RoadName>>,
      default: () => { return {}; },
    },
    movieLists: {
      type: Array as PropType<Array<GIMovieList>>,
      default: () => { return []; },
    },
    movieListId: {
      type: String,
      default: '',
    },
    extremeMapEssentials: {
      type: Object as PropType<ExtremeMapEssentials>,
      default: () => { return null; },
    },
    parentGeoItemsMeta: {
      type: Object as PropType<GeoItemsMeta>,
      default: () => { return {}; },
    },
  },
  setup(props, { emit }) {
    const concreteComponent = computed(() => {
      return props.showLidarMode
        ? 'lidar-movie-player'
        : 'basic-movie-player';
    });
    const emitCloseEvent = () => {
      emit('close');
    };
    const emitSwitchToCompareMode = (e: MoviePlayerParams) => {
      emit('switch-to-compare-mode', e);
    };
    // Topからの制御をそのまま渡すため。
    const refMoviePlayerVideo = ref<InstanceType<typeof BasicMoviePlayer | typeof LidarMoviePlayer>>();
    const shortcutKeyAction = (key: string) => {
      if (!refMoviePlayerVideo.value) {
        return;
      }
      refMoviePlayerVideo.value.shortcutKeyAction(key);
    };

    return {
      // computed
      concreteComponent,
      // refs
      refMoviePlayerVideo,
      // methods
      emitCloseEvent,
      emitSwitchToCompareMode,
      shortcutKeyAction,
    };
  },
  methods: {
  },
  components: {
    BasicMoviePlayer,
    LidarMoviePlayer,
  },
});
