import GIAbstractManager from '@/lib/geo_item/GIAbstractManager';
import extLandAiDetectionsApi from '@/apis/extLandAiDetections';
import GeoItemSearchLandAiDetections from '@/components/Top/geoItemSearchComponents/GeoItemSearchLandAiDetections.vue';
import { DetectionsIndexParams } from 'src/models/apis/extLandAiDetections/extLandAiDetectionsRequest';
import { Detection } from 'src/models/apis/extLandAiDetections/extLandAiDetectionsResponse';
import { GIDetection } from 'src/models/geoItem';

export default class GILandAiDetectionsManager extends GIAbstractManager {
  async getResources(
    componentRef: InstanceType<typeof GeoItemSearchLandAiDetections>,
  ): Promise<GIDetection[]> {
    const params = componentRef.getSearchParams();
    if (!params) {
      return [];
    }
    return this.getResourcesByParams(params);
  }

  async getResourcesByParams(params: DetectionsIndexParams): Promise<GIDetection[]> {
    try {
      const { data } = await extLandAiDetectionsApi.index(params);
      return this._convData(data, params);
    } catch (e) {
      return [];
    }
  }

  async getResourceById(): Promise<any> {
    console.warn('GILandAiDetectionsManager#getResourceById is noop.');
  }

  private _convData(data: Detection[], params: DetectionsIndexParams): GIDetection[] {
    const filteredData = data.filter(e => {
      // 「場所なし」は省く. そのうちスイッチ追加してもよいかもしれない.
      if (e.road_name_disp === null) {
        return false;
      }
      // ジョイントは特にこれ以上絞る内容がない
      if (e.detection_kind === 'joint_damage') {
        return true;
      }
      // 以下、ポットホールやヒビ用
      const contourAreaFrom = params.contour_area_from;
      const contourAreaTo = params.contour_area_to;
      if (contourAreaFrom !== null && contourAreaFrom.toString() !== '') {
        if (e.contour_area_max < contourAreaFrom) {
          return false;
        }
      }
      if (contourAreaTo !== null && contourAreaTo.toString() !== '') {
        if (e.contour_area_max > contourAreaTo) {
          return false;
        }
      }
      return true;
    });
    const detectionKindsMap: Record<string, 'ポットホール' | 'ヒビ' | 'ジョイント損傷'> = {
      pothole: 'ポットホール',
      crack: 'ヒビ',
      joint_damage: 'ジョイント損傷',
    };
    const results: GIDetection[] = [];
    filteredData.forEach((e) => {
      results.push({
        ...e,
        id: e.detection_detail_view_group_id,
        lat: parseFloat(e.lat),
        lon: parseFloat(e.lon),
        detectionKindDisp: detectionKindsMap[e.detection_kind],
        jointTypesDisp: e.joint_types ? this._getJointTypesDisp(e.joint_types) : '',
        isSelected: false,
      });
    });
    // 同一地点の場合日付が新しいものが上
    results.sort((a, b) => {
      return a.ts < b.ts ? -1 : a.ts > b.ts ? 1 : 0;
    });
    return results;
  }

  private _getJointTypesDisp(jointTypes: string): string {
    const jointTypeMap: Record<string, 'フィンガー' | 'ゴム(ダイヤフリー)' | 'ゴム(トランスフレックス)' | '簡易鋼製' | '簡易鋼製'> = {
      finger: 'フィンガー',
      rubber_diafree: 'ゴム(ダイヤフリー)',
      rubber_transflex: 'ゴム(トランスフレックス)',
      steel_wave: '簡易鋼製',
      steel_wave2_straight: '簡易鋼製',
    };
    const jointTypeDisps = jointTypes.split(',').map(e => jointTypeMap[e] || 'その他');
    // 重複をなくして、「、」で区切りする文字列を返す
    return jointTypeDisps.filter((e, idx) => {
      return jointTypeDisps.indexOf(e) === idx;
    }).join('、');
  }

  mergeStateInfo(newResources: GIDetection[], currentResources: GIDetection[]): GIDetection[] {
    const currentResourceMap = currentResources.reduce<Record<string, GIDetection>>((acc, e) => {
      acc[e.id] = e; return acc;
    }, {});
    newResources.forEach(e => {
      if (currentResourceMap[e.id]) {
        e.isSelected = currentResourceMap[e.id].isSelected;
      }
    });
    return newResources;
  }

  async createResource(): Promise<any> {
    console.warn('GILandAiDetectionsManager#createResource is noop.');
  }

  async updateResource(): Promise<any> {
    console.warn('GILandAiDetectionsManager#updateResource is noop.');
  }

  async deleteResource(): Promise<any> {
    console.warn('GILandAiDetectionsManager#deleteResource is noop.');
  }
}
