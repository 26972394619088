import axios, { AxiosResponse } from 'axios';
import * as utils from './apiUtils';
import {
  MovieSearchParams,
  MovieSearchContinuedMoviesParams,
  MovieSearchByRoutesParams,
  MovieSearchByRoutesTestParams,
  MovieSearchByAreaParams,
} from 'src/models/apis/movie/movieRequest';
import {
  MovieGetMovieFilesResponse,
  MovieSearchResponse,
  ContinuedMoviesResponse,
  MovieSearchByRoutesResponse,
  MovieSearchByRoutesTestResponse,
} from 'src/models/apis/movie/movieResponse';

export default {
  getMovieFiles(id: number): Promise<AxiosResponse<MovieGetMovieFilesResponse>> {
    return axios.get(utils.getApiUrl(`/movies/${id}/files`));
  },
  search(params: MovieSearchParams): Promise<AxiosResponse<MovieSearchResponse>> {
    return axios.get(utils.getApiUrl('/movies/search'), {
      params: params || {},
    });
  },
  searchContinuedMovies(params: MovieSearchContinuedMoviesParams): Promise<AxiosResponse<ContinuedMoviesResponse>> {
    return axios.get(utils.getApiUrl('/movies/search_continued_movies'), {
      params: params || {},
    });
  },
  searchByRoutes(params: MovieSearchByRoutesParams): Promise<AxiosResponse<MovieSearchByRoutesResponse>> {
    return axios.get(utils.getApiUrl('/movies/search_by_routes'), {
      params: params || {},
    });
  },
  // TODO テスト用なので用済みとなれば消す
  searchByRoutesTest(params: MovieSearchByRoutesTestParams): Promise<AxiosResponse<MovieSearchByRoutesTestResponse>> {
    return axios.get(utils.getApiUrl('/movies/search_by_routes_test'), {
      params: params || {},
    });
  },
  searchByArea(params: MovieSearchByAreaParams): Promise<AxiosResponse<MovieSearchResponse>> {
    return axios.get(utils.getApiUrl('/movies/search_by_area'), {
      params: params || {},
    });
  },
  searchByMovieId(id: number): Promise<AxiosResponse<MovieSearchResponse>> {
    return axios.get(utils.getApiUrl(`/movies/search_by_movie_id`), {
      params: { id },
    });
  },
};
