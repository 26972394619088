export default {
  MapElemInfoNone: require('./MapElemInfoNone').default,
  MapElemInfoNew: require('./MapElemInfoNew').default,
  MapElemInfoCar: require('./MapElemInfoCar').default,
  MapElemInfoMovie: require('./MapElemInfoMovie').default,
  MapElemInfoComment: require('./MapElemInfoComment').default,
  MapElemInfoCommentContainer: require('./MapElemInfoCommentContainer').default,
  MapElemInfoLandApEmergency: require('./MapElemInfoLandApEmergency').default,
  MapElemInfoJunkaiTenkenReportTenkenData: require('./MapElemInfoJunkaiTenkenReportTenkenData').default,
  MapElemInfoLandAiDetections: require('./MapElemInfoLandAiDetections').default,
};
