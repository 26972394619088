






































































import { RoadName } from '@/models/apis/master/masterResponse';
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  toRefs,
  watch,
} from '@vue/composition-api';
import { GeoConnection } from '@/models';
import { GIKp } from '@/models/geoItem';
import Vue from 'vue';
import { Route } from '@/models/apis/movie/movieRequest';
import { isKpIncreaseDirection } from '@/lib/kilopostHelper';
import useMaster from '@/composables/useMaster';

interface RouteInfoItem {
  type: 'start' | 'end';
  road_name_disp: string;
  road_name: string;
  direction: string;
  place_name: string;
  kp_end?: number;
  kp_start?: number;
}

interface RouteInfo {
  routeInfo: RouteInfoItem[];
  dstRoadNameDisp: string;
  dstRoadName: string;
  dstDirection: string;
}

interface GetRouteInfoParams {
  k: string;
  obj: GeoConnection;
  srcRoadName: string;
  srcDirection: string;
}

type CanMapElement = RoadName & {routeInfoMap: {[k: string]: RouteInfoItem[] }};

type LocalRoute = Route & {
  roadNameDisps: RoadName[];
  selectedRoadNameDispObj: CanMapElement | null;
  selectedDirection: string;
};

interface RouteSearchState {
  routes: LocalRoute[];
}

export default defineComponent({
  name: 'route-search',
  props: {
    roadNameDisps: {
      type: Array as PropType<RoadName[]>,
      default: () => [],
    },
    isValidDateParams: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const state = reactive<RouteSearchState>({
      routes: [],
    });
    const { state: msts } = useMaster();
    onMounted(() => {
      if (props.roadNameDisps.length > 0 && state.routes.length === 0) {
        initData();
      }
    });

    watch(() => props.roadNameDisps, () => {
      if (props.roadNameDisps.length > 0 && state.routes.length === 0) {
        initData();
      }
    });

    // computed
    const canRouteSearch = computed(() => {
      if (!props.isValidDateParams) { return false; }
      if (state.routes.length < 2) { return false; }
      for (const route of state.routes) {
        if (!isRowFilled(route)) {
          return false;
        }
      }
      return true;
    });

    // methods
    const initData = () => {
      const arr: LocalRoute[] = [];
      arr.push(getNewRow(props.roadNameDisps));
      arr.push(getNewRow([]));
      state.routes = arr;
    };
    const getNewRow = (candidacies: RoadName[]): LocalRoute => {
      return {
        roadNameDisps: candidacies,
        selectedRoadNameDispObj: null,
        selectedDirection: '',
      } as LocalRoute;
    };
    const isLast = (idx: number) => {
      return idx >= state.routes.length - 1;
    };
    const isRowFilled = (rowObj: LocalRoute) => {
      return !!rowObj.selectedRoadNameDispObj &&
        !!rowObj.selectedDirection;
    };
    const getRouteInfo = ({ k, obj, srcRoadName, srcDirection }: GetRouteInfoParams): RouteInfo => {
      let [dstRoadName, dstDirection, dstPlaceName] = k.split('#');
      const routeInfo: RouteInfoItem[] = [
        {
          type: 'end',
          road_name_disp: msts.roadNameToRoadNameDispMap[srcRoadName],
          road_name: srcRoadName,
          direction: srcDirection,
          place_name: 'main_line',
          kp_end: obj.src_kp_end,
        },
        {
          type: 'start',
          road_name_disp: msts.roadNameToRoadNameDispMap[dstRoadName],
          road_name: dstRoadName,
          direction: dstDirection,
          place_name: dstPlaceName || 'main_line',
          kp_start: obj.dst_kp_start,
        },
      ];
      if (obj.next_key) {
        const [viaRoadName, viaDirection, viaPlaceName] =
          [dstRoadName, dstDirection, dstPlaceName];
        const tmpSplit = obj.next_key.split('#');
        dstRoadName = tmpSplit[0];
        dstDirection = tmpSplit[1];
        dstPlaceName = tmpSplit[2];
        if (dstPlaceName) {
          // 行き先が本線以外の場合というのはないとは思うが、あったらはじく
          return {} as RouteInfo;
        }
        const nextKey = obj.next_key as keyof GeoConnection;
        routeInfo.push(
          {
            type: 'end',
            road_name_disp: msts.roadNameToRoadNameDispMap[viaRoadName],
            road_name: viaRoadName,
            direction: viaDirection,
            place_name: viaPlaceName || 'main_line',
            kp_end: (obj[nextKey] as GeoConnection).src_kp_end,
          },
          {
            type: 'start',
            road_name_disp: msts.roadNameToRoadNameDispMap[dstRoadName],
            road_name: dstRoadName,
            direction: dstDirection,
            place_name: dstPlaceName || 'main_line',
            kp_start: (obj[nextKey] as GeoConnection).dst_kp_start,
          },
        );
      }
      const dstRoadNameDisp = msts.roadNameToRoadNameDispMap[dstRoadName];
      return { routeInfo, dstRoadNameDisp, dstRoadName, dstDirection };
    };
    const getNextCandidacies = (rowObj: LocalRoute) => {
      if (!isRowFilled(rowObj) || rowObj.selectedRoadNameDispObj === null) { return []; }
      // roadNameDispに含まれるroadNameごとに処理を行う
      const srcRoadNameDisp = rowObj.selectedRoadNameDispObj.road_name_disp;
      const srcDirection = rowObj.selectedDirection;

      const candMap: { [k: string]: CanMapElement } = {};
      const srcRoadNames = msts.roadNameDispMap[srcRoadNameDisp].road_names;
      srcRoadNames.forEach(srcRoadName => {
        const connection = msts.geoConnections[`${srcRoadName}#${srcDirection}`];
        if (!connection) { return; }
        // src情報からありうるdst情報を算出
        for (const [k, obj] of Object.entries(connection)) {
          const { routeInfo, dstRoadNameDisp, dstDirection } =
            getRouteInfo({ k, obj, srcRoadName, srcDirection });
          if (!routeInfo) { continue; }
          // 自分への接続は認めない
          if (
            srcRoadNameDisp === dstRoadNameDisp &&
            srcDirection === dstDirection
          ) { continue; }
          // dstRoadNameDispごとにまとめ直す
          // dstRoadNameDisp#dstDirectionではかぶらないはずだから、
          // それ単位であとで取れるようにrouteInfoを保存しておく
          if (!dstRoadNameDisp || !dstDirection) { continue; }
          if (!candMap[dstRoadNameDisp]) {
            candMap[dstRoadNameDisp] =
              JSON.parse(JSON.stringify(msts.roadNameDispMap[dstRoadNameDisp]));
            candMap[dstRoadNameDisp].directions = [];
            candMap[dstRoadNameDisp].routeInfoMap = {};
          }
          const candMapEnt = candMap[dstRoadNameDisp];
          if (candMapEnt.directions.indexOf(dstDirection) === -1) {
            candMapEnt.directions.push(dstDirection);
          }
          candMapEnt.routeInfoMap[dstDirection] = routeInfo;
        }
      });
      const ret = Object.entries(candMap).reduce((acc: CanMapElement[], ent) => {
        acc.push(ent[1]); return acc;
      }, []);
      return ret;
    };

    const maxLen = 3;
    const addNextRow = (currentRow: LocalRoute) => {
      if (state.routes.length >= maxLen) { return; }
      if (!isRowFilled(currentRow)) { return; }
      const cands = getNextCandidacies(currentRow);
      const row = getNewRow(cands);
      state.routes.push(row);
    };
    const deleteLastRow = () => {
      const idx = state.routes.length - 1;
      if (idx <= 0) { return; }
      Vue.delete(state.routes, idx);
    };
    const onSelectionChange = (idx: number) => {
      // 後続の行があれば全て選択解除
      state.routes.slice(idx + 1).forEach(e => {
        e.roadNameDisps = [];
        e.selectedRoadNameDispObj = null;
        e.selectedDirection = '';
      });
      // 次の行があれば候補をセット
      if (idx >= state.routes.length - 1) { return; }
      const cands = getNextCandidacies(state.routes[idx]);
      state.routes[idx + 1].roadNameDisps = cands;
    };
    const onRoadNameDispChange = (idx: number) => {
      state.routes[idx].selectedDirection = '';
      onSelectionChange(idx);
    };
    const onDirectionChange = (idx: number) => {
      onSelectionChange(idx);
    };
    const kpBinSearch = (kps: GIKp[], num: number) => {
      // numを見つける. kpsは昇順前提.
      let low = 0;
      let high = kps.length - 1;
      let mid, guess;
      while (low <= high) {
        mid = Math.floor((low + high) / 2);
        guess = kps[mid];
        if (Math.abs(guess.kp_calc - num) < Number.EPSILON) {
          return mid;
        } else if (guess.kp_calc > num) {
          high = mid - 1;
        } else {
          low = mid + 1;
        }
      }
      return -1;
    };
    const findFirstKpOutsideDistance = (
      roadName: string,
      direction: string,
      kpCalc0: number,
      moveIndexFunc: (_: number) => number,
      distance: number,
    ): GIKp | null => {
      const kpMapKey = `${roadName}#${direction}`;
      const kps = msts.kpMap.get(kpMapKey)?.get('main_line');
      if (!kps) { return null; }
      const lastIdx = kps.length - 1;
      let idx = kpBinSearch(kps, kpCalc0);
      while (true) {
        const nextIdx = moveIndexFunc(idx);
        if (nextIdx < 0 || nextIdx > lastIdx) { break; }
        idx = nextIdx;
        const cmpKpObj = kps[idx];
        if (Math.abs(kpCalc0 - cmpKpObj.kp_calc) > distance) {
          break;
        }
      }
      return kps[idx];
    };
    // routesの始点側と終点側に付け足すKPの長さ
    // (路線全体だとさすがに長くなりすぎるので)
    const routeMarginDistance = 2.0;
    const getStartKpOfRoute = (routeObj: LocalRoute) => {
      const isKpInc = isKpIncreaseDirection(
        routeObj.road_name,
        routeObj.direction,
      );
      const moveIndexFunc = isKpInc ? (i: number) => i - 1 : (i: number) => i + 1;
      const kpObj = findFirstKpOutsideDistance(
        routeObj.road_name,
        routeObj.direction,
        routeObj.kp_calc_end,
        moveIndexFunc,
        routeMarginDistance,
      );
      return kpObj?.kp_calc;
    };
    const getEndKpOfRoute = (routeObj: LocalRoute) => {
      const isKpInc = isKpIncreaseDirection(
        routeObj.road_name,
        routeObj.direction,
      );
      const moveIndexFunc = isKpInc ? (i: number) => i + 1 : (i: number) => i - 1;
      const kpObj = findFirstKpOutsideDistance(
        routeObj.road_name,
        routeObj.direction,
        routeObj.kp_calc_start,
        moveIndexFunc,
        routeMarginDistance,
      );
      return kpObj?.kp_calc;
    };
    const fillStartSideRouteInfo = (routes: LocalRoute[]) => {
      // routesの先頭要素にkp_calc_startが入ってないので入れる.
      // 場合によっては先頭側に新たな要素を追加する必要もあるが、
      // (road_nameの前側に同じroad_name_dispで異なるroad_nameがあるとか)
      // 今は省略する.
      const firstRoute = routes[0];
      firstRoute.kp_calc_start = getStartKpOfRoute(firstRoute) || 0;
    };
    const fillEndSideRouteInto = (routes: LocalRoute[]) => {
      // routesの末尾要素にkp_calc_endが入ってないので入れる.
      // あと、fillStartSideRouteInfoと同様に省略.
      const lastRoute = routes[routes.length - 1];
      lastRoute.kp_calc_end = getEndKpOfRoute(lastRoute) || 0;
    };
    const doRouteSearch = () => {
      const routes: LocalRoute[] = [];
      let currentRouteBlock: LocalRoute | null = null;
      for (const route of state.routes) {
        if (!isRowFilled(route)) { return; }
        const routeInfoMap = route.selectedRoadNameDispObj?.routeInfoMap;
        // 最初と最後はあとで詰める
        if (!routeInfoMap) { continue; }

        const routeInfo = routeInfoMap[route.selectedDirection];
        routeInfo.forEach(e => {
          if (!currentRouteBlock) {
            // 初回
            currentRouteBlock = {
              road_name_disp: e.road_name_disp,
              road_name: e.road_name,
              direction: e.direction,
              place_name: e.place_name,
            } as LocalRoute;
          }
          if (e.type === 'start') {
            currentRouteBlock = {
              road_name_disp: e.road_name_disp,
              road_name: e.road_name,
              direction: e.direction,
              place_name: e.place_name,
              kp_calc_start: e.kp_start,
            } as LocalRoute;
          } else {
            currentRouteBlock.kp_calc_end = e.kp_end || 0;
            routes.push(currentRouteBlock);
          }
        });
      }
      if (currentRouteBlock) {
        routes.push(currentRouteBlock);
      }

      // 先頭側のルート情報を追加
      fillStartSideRouteInfo(routes);
      // 末尾のルート情報を追加
      fillEndSideRouteInto(routes);

      emit('route-search', { data: routes });
    };

    return {
      ...toRefs(state),
      // computed
      canRouteSearch,
      // methods
      isRowFilled,
      isLast,
      addNextRow,
      deleteLastRow,
      onRoadNameDispChange,
      onDirectionChange,
      doRouteSearch,
      // others
      maxLen,
      routeMarginDistance,
    };
  },
});
