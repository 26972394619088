import EMAbstractLayerManager, { InitArgs } from '@/lib/extreme_map/EMAbstractLayerManager';
import EMEventNames from '@/consts/extreme_map_event_names';
import CustomHeatmapLayer from './CustomHeatmapLayer';
import { Style, Icon } from 'ol/style';
import { Feature } from 'ol';
import Point from 'ol/geom/Point';
import VectorSource from 'ol/source/Vector';
import { GIEmergency } from '@/models/geoItem';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';
import { NamedVectorLayer } from '@/lib/OlMapWrapper';
import { FeatureLike } from 'ol/Feature';

export default class EMLandApEmergencyLayerManager extends EMAbstractLayerManager {
  resourceMap: Record<string, GIEmergency>;
  displayLayerSwitchResolution: number;

  constructor(args: InitArgs) {
    super(args);
    this.resourceMap = {};
    this.emListenEventNames = [EMEventNames.EM_EVENT_CLICK];
    this.displayLayerSwitchResolution = 10;
  }

  getIconPath_(): { iconPath: string; selectedFramePath: string } {
    const iconPath = '/static/img/land_ap_emergency_pin.png';
    const selectedFramePath = `/static/img/pin_selected.png`;
    return { iconPath, selectedFramePath };
  }

  getResourceStyles_(emergency: GIEmergency): Style[] {
    const ret = [];
    const { iconPath, selectedFramePath } = this.getIconPath_();
    if (emergency.isSelected) {
      ret.push(new Style({
        image: new Icon({
          src: selectedFramePath,
          anchor: [0.5, 0.82],
          anchorXUnits: IconAnchorUnits.FRACTION,
          anchorYUnits: IconAnchorUnits.FRACTION,
          scale: 0.30,
          opacity: 1.0,
        }),
      }));
    }
    ret.push(new Style({
      image: new Icon({
        src: iconPath,
        anchor: [0.5, 0.82],
        anchorXUnits: IconAnchorUnits.FRACTION,
        anchorYUnits: IconAnchorUnits.FRACTION,
        scale: 0.30,
      }),
    }));
    return ret;
  }

  getResourceFeatures_(emergency: GIEmergency): Feature[] {
    const coord = this.convCoord({
      lon: parseFloat(emergency.lon.toString()),
      lat: parseFloat(emergency.lat.toString()),
    });
    const feat = new Feature(new Point(coord));
    feat.setId(emergency.id);
    feat.setStyle(this.getResourceStyles_(emergency));
    return [feat];
  }

  onClickFeature_(targetFeat: FeatureLike): void {
    const targetFeatId = targetFeat.getId();
    if (!targetFeatId || !this.layer) {
      return;
    }
    const targetResource = this.resourceMap[targetFeatId];

    // 地図上の見た目を調整
    const layerSource = this.layer.getSource();
    for (const ent of Object.entries(this.resourceMap)) {
      const tmpResource = ent[1];
      const currentIsSelected = tmpResource.isSelected;
      tmpResource.isSelected =
        tmpResource.id === targetResource.id &&
        !tmpResource.isSelected;
      if (currentIsSelected !== tmpResource.isSelected) {
        const feat = layerSource.getFeatureById(tmpResource.id);
        feat.setStyle(this.getResourceStyles_(tmpResource));
      }
    }

    // イベント発火
    const obj: { dataName: string; data?: GIEmergency } = this.getBaseDataForEmit();
    obj.data = targetResource;
    this.emitter.$emit(EMEventNames.EM_EVENT_CLICK, obj);
  }

  createLayer_(resources: GIEmergency[]): void {
    const feats: Feature[] = [];
    resources.forEach(resource => {
      feats.push(...this.getResourceFeatures_(resource));
    });
    const layer = new NamedVectorLayer(this.dataName, {
      source: new VectorSource({features: feats}),
      // 縮尺が500m以下になった場合に表示
      maxResolution: this.displayLayerSwitchResolution,
    });
    this.layer = layer;
    this.layerInfo.onLayerClick = ({ event, feature }) => {
      // 重なってたりする場合はそれぞれ飛んでくるので、一回で止める
      if (!event || event.originalEvent.defaultPrevented) { return; }
      event.preventDefault();
      if (!feature) {
        return;
      }
      this.onClickFeature_(feature);
    };
  }

  createAdditionalLayers_(resources: GIEmergency[]): void {
    const feats: Feature[] = [];
    resources.forEach(resource => {
      feats.push(...this.getResourceFeatures_(resource));
    });
    feats.forEach(e => e.setStyle());
    const layer = new CustomHeatmapLayer(this.dataName + '_heatmap', {
      source: new VectorSource({features: feats}),
      // 縮尺が500m超えになった場合に表示
      minResolution: this.displayLayerSwitchResolution,
      showCluster: true,
      // デフォルト: 15
      blur: 35,
    });
    this.additionalLayers = [layer];
    const defaultLayerInfo = this.getDefaultLayerInfo_();
    this.additionalLayerInfos = [defaultLayerInfo];
  }

  getGeoItemByIndexDiff(current: GIEmergency, diff: number): GIEmergency {
    const candidateEmergencies = Object.values(this.resourceMap)
      .sort((a, b) => a.ts < b.ts ? -1 : a.ts > b.ts ? 1 : 0);
    const currentIdx = candidateEmergencies.indexOf(current);
    // ないはずはないが、念の為救済
    if (currentIdx === -1) { return current; }

    const modBase = candidateEmergencies.length;
    const move = modBase + diff % modBase;
    const targetIdx = (currentIdx + move) % modBase;
    return candidateEmergencies[targetIdx];
  }
}
