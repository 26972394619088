import axios, { AxiosResponse } from 'axios';
import * as utils from './apiUtils';
import { AdminGroup3IndexResponse, AdminGroup3 } from 'src/models/apis/group3/adminGroup3Response';
import { AdminGroup3Params } from 'src/models/apis/group3/adminGroup3Request';

export default {
  adminIndex(): Promise<AxiosResponse<AdminGroup3IndexResponse>> {
    return axios.get(utils.getApiUrl('/admin/groups3'));
  },
  adminCreate(params: AdminGroup3Params): Promise<AxiosResponse<AdminGroup3>> {
    return axios.post(utils.getApiUrl('/admin/groups3'), params);
  },
  adminUpdate(id: number, params: AdminGroup3Params): Promise<AxiosResponse<AdminGroup3>> {
    return axios.put(utils.getApiUrl(`/admin/groups3/${id}`), params || {});
  },
  adminDestroy(id: number): Promise<AxiosResponse<AdminGroup3>> {
    return axios.delete(utils.getApiUrl(`/admin/groups3/${id}`));
  },
};
