import EMAbstractLayerManager, { InitArgs, LayerWithInfo } from '@/lib/extreme_map/EMAbstractLayerManager';
import EMEventNames from '@/consts/extreme_map_event_names';
import { getBearing } from '@/lib/geoCalcHelper';
import {
  Style,
  Icon,
  Circle,
  Fill,
  Stroke,
  Text,
} from 'ol/style';
import { Feature } from 'ol';
import { Point, MultiLineString, MultiPoint } from 'ol/geom';
import VectorSource from 'ol/source/Vector';
import {
  GIMovie,
  GIMovieGeoIndex,
  GIMovieList,
} from '@/models/geoItem';
import { Location } from '@/models';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';
import ImageStyle from 'ol/style/Image';
import { Coordinate } from 'ol/coordinate';
import { NamedVectorLayer } from '@/lib/OlMapWrapper';
import { FeatureLike } from 'ol/Feature';

interface StyleObject {
  image?: ImageStyle;
  stroke?: Stroke;
  text?: Text;
  zIndex?: number;
}

interface MovieLayerInitArgs extends InitArgs {
  disableInteraction?: boolean;
}

export default class EMMovieLayerManager extends EMAbstractLayerManager {
  topZIndex: number;
  readonly zIndexStep: number;
  movieListMap: Record<string, GIMovieList>;
  disableInteraction: boolean;
  movieLists: GIMovieList[];
  movieMap: Record<number, GIMovie>;
  mgiMap: Record<number, GIMovieGeoIndex>;

  constructor(args: MovieLayerInitArgs) {
    super(args);
    this.movieLists = [];
    this.movieListMap = {};
    this.movieMap = {};
    this.mgiMap = {};
    this.emListenEventNames = [EMEventNames.EM_EVENT_CLICK];
    this.disableInteraction = !!args.disableInteraction;
    this.topZIndex = 1;
    this.zIndexStep = 10;
  }

  formatHHMMSS(dt: Date): string {
    const h = ('0' + dt.getHours()).slice(-2);
    const m = ('0' + dt.getMinutes()).slice(-2);
    const s = ('0' + dt.getSeconds()).slice(-2);
    return `${h}:${m}:${s}`;
  }

  getPinIconPath_(pinType: string): { pinIconPath: string; selectedFramePath: string } {
    const pinIconPath = `/static/img/movie_${pinType}_pin.png`;
    const selectedFramePath = `/static/img/pin_selected.png`;
    return { pinIconPath, selectedFramePath };
  }

  getPinStyles_(movieList: GIMovieList, pinType: string): Style[] {
    const ret: Style[] = [];
    const { pinIconPath, selectedFramePath } = this.getPinIconPath_(pinType);

    {
      const styleObj: StyleObject = {
        image: new Icon({
          src: pinIconPath,
          anchor: [0.5, 0.82],
          anchorXUnits: IconAnchorUnits.FRACTION,
          anchorYUnits: IconAnchorUnits.FRACTION,
          scale: 0.25,
          opacity: movieList.isSelected ? 1.0 : 0.75,
        }),
      };
      if (movieList.isSelected) {
        styleObj.zIndex = this.topZIndex;
        this.topZIndex += this.zIndexStep;
      }
      ret.push(new Style(styleObj));
    }

    if (movieList.isSelected) {
      const styleObj: StyleObject = {
        image: new Icon({
          src: selectedFramePath,
          anchor: [0.5, 0.82],
          anchorXUnits: IconAnchorUnits.FRACTION,
          anchorYUnits: IconAnchorUnits.FRACTION,
          scale: 0.25,
          opacity: 1.0,
        }),
      };
      styleObj.zIndex = this.topZIndex;
      this.topZIndex += this.zIndexStep;
      ret.push(new Style(styleObj));
    }

    return ret;
  }

  getStartPinStyles_(movieList: GIMovieList): Style[] {
    return this.getPinStyles_(movieList, 'start');
  }

  getEndPinStyles_(movieList: GIMovieList): Style[] {
    return this.getPinStyles_(movieList, 'end');
  }

  getDotsStyles_(movieList: GIMovieList, { hit }: { hit?: boolean } = {}): Style[] {
    let shapeFillColor, shapeStrokeColor;
    const showWeak = !hit;
    if (movieList.isSelected) {
      shapeFillColor = [0, 74, 149, 1.0];
      shapeStrokeColor = [0, 74, 149, 1.0];
      if (showWeak) {
        shapeFillColor[3] = 0.6;
        shapeStrokeColor[3] = 0.6;
      }
    } else {
      shapeFillColor = [102, 194, 255, 0.75];
      shapeStrokeColor = [0, 97, 176, 0.75];
      if (showWeak) {
        shapeFillColor[3] = 0.55;
        shapeStrokeColor[3] = 0.55;
      }
    }

    const ret = [];
    const styleObj: StyleObject = {
      image: new Circle({
        radius: 3,
        fill: new Fill({ color: shapeFillColor }),
        stroke: new Stroke({ color: shapeStrokeColor, width: 1 }),
      }),
    };
    if (movieList.isSelected) {
      styleObj.zIndex = this.topZIndex;
      this.topZIndex += this.zIndexStep;
    }
    ret.push(new Style(styleObj));
    return ret;
  }

  getMultiLineStyles_(movieList: GIMovieList, { hit }: { hit?: boolean } = {}): Style[] {
    const strokeColor = movieList.isSelected
      ? [0, 74, 149, 1.0] : [102, 194, 255, 0.75];
    const strokeWidth = 3;
    const showWeak = !hit;
    if (showWeak) {
      strokeColor[3] = 0.6;
    }

    const ret = [];
    const styleObj: StyleObject = {
      stroke: new Stroke({
        color: strokeColor,
        width: strokeWidth,
      }),
    };
    if (movieList.isSelected) {
      styleObj.zIndex = this.topZIndex;
      this.topZIndex += this.zIndexStep;
    }
    ret.push(new Style(styleObj));
    return ret;
  }

  getTextStyles_(movieList: GIMovieList, text: string): Style[] {
    const fillColor = [238, 238, 238, 1.0];
    const strokeColor = [50, 50, 50, 0.8];
    if (!movieList.isSelected) {
      fillColor[3] = 0.8;
      strokeColor[3] = 0.6;
    }

    const ret = [];
    const styleObj: StyleObject = {
      text: new Text({
        text,
        font: '8px sans-serif',
        fill: new Fill({ color: fillColor }),
        stroke: new Stroke({ color: strokeColor, width: 3 }),
        offsetX: 0,
        offsetY: 6,
      }),
    };
    styleObj.zIndex = this.topZIndex;
    this.topZIndex += this.zIndexStep;

    ret.push(new Style(styleObj));
    return ret;
  }

  getAngleArrowStyles_({ hit, angle }: { hit: number; angle?: number | null}): Style[] {
    const ret: Style[] = [];
    if (!angle) {
      return ret;
    }
    // zoomがセットされていない場合及び一定以上引いている場合は表示しない.
    if (this.currentZoom === -1 || this.currentZoom < 13) {
      return ret;
    }

    const styleObj: StyleObject = {
      image: new Icon({
        src: '/static/img/arrow-small1.png',
        scale: 0.5,
        rotation: angle / 180.0 * Math.PI,
        opacity: hit ? 0.75 : 0.55,
      }),
    };
    styleObj.zIndex = this.topZIndex;
    this.topZIndex += this.zIndexStep;
    ret.push(new Style(styleObj));
    return ret;
  }

  createSelectedOnlyFeatures_(movieList: GIMovieList): Feature[] {
    const ret: Feature[] = [];
    const fgPrefix = movieList.id;
    // 元のmgiに直接入れないとzoomでの表示変更時に再計算することになるので、行儀は悪いが入れてしまう.
    const mgis = movieList.movies.map(e => e.movie_geo_indices).flat();
    mgis.forEach(mgi => {
      mgi.location = { lat: parseFloat(mgi.lat), lon: parseFloat(mgi.lon) };
    });
    mgis.forEach((mgi, idx) => {
      if (!mgi.location || idx + 1 >= mgis.length) {
        mgi.angle = null;
        return;
      }
      const nextMgi = mgis[idx + 1];
      mgi.angle = nextMgi.location ? getBearing(mgi.location, nextMgi.location) : null;
    });
    mgis.forEach(mgi => {
      if (!mgi.location) {
        return;
      }
      const feature = new Feature(new Point(this.convCoord(mgi.location)));
      const hit = movieList.hit_mgi_map[mgi.id];
      feature.setStyle(this.getAngleArrowStyles_({ hit, angle: mgi.angle }));
      feature.setId(`${fgPrefix}.selectedDot.${mgi.id}`);
      ret.push(feature);
    });
    return ret;
  }

  findSelectedOnlyFeatures_(movieList: GIMovieList): Feature[] {
    if (!this.layer) {
      return [];
    }
    const fgPrefix = movieList.id;
    const source = this.layer.getSource();
    return movieList.movies.map(e => e.movie_geo_indices).flat().map(mgi => {
      return source.getFeatureById(`${fgPrefix}.selectedDot.${mgi.id}`);
    }).filter(e => !!e);
  }

  getResourceFeatures_(movieList: GIMovieList): Feature[] {
    const ret: Feature[] = [];

    const fgPrefix = movieList.id;
    const hitDotsCoords: Coordinate[] = [];
    const missDotsCoords: Coordinate[] = [];
    const hitMultiLineCoords: Coordinate[][] = [];
    const missMultiLineCoords: Coordinate[][] = [];
    const timeTextInfoArr: Array<{ coord: Coordinate; mgi: GIMovieGeoIndex; isHit: boolean }> = [];
    const addDummyPointFeats = movieList.mightCalculateClosestMgi;
    const dummyPointFeats: Feature[] = [];
    let prevIsHit = false;
    let tmpMultiLineCoords: Coordinate[] = [];
    const mgis: GIMovieGeoIndex[] = [...movieList.movies.map(e => e.movie_geo_indices)].flat();
    const mgisLen = mgis.length;
    mgis.forEach((mgi, idx) => {
      const coord = this.convCoord({
        lon: parseFloat(mgi.lon),
        lat: parseFloat(mgi.lat),
      });
      let isHit;
      if (movieList.hit_mgi_map[mgi.id]) {
        hitDotsCoords.push(coord);
        isHit = true;
      } else {
        missDotsCoords.push(coord);
        isHit = false;
      }
      // 最初と最後プラス、1分程度おき
      if (idx === 0 || idx % 6 === 0 || idx === mgisLen - 1) {
        timeTextInfoArr.push({ coord, mgi, isHit });
      }

      tmpMultiLineCoords.push(coord);
      if (
        // hit/missが切り替ったか、最後か
        (prevIsHit !== isHit || idx === mgisLen - 1) &&
        tmpMultiLineCoords.length > 1
      ) {
        if (prevIsHit) {
          hitMultiLineCoords.push(tmpMultiLineCoords);
          // ラスト2個 (ヒット側を広めに表示したいので)
          tmpMultiLineCoords = tmpMultiLineCoords.slice(-2);
        } else {
          missMultiLineCoords.push(tmpMultiLineCoords);
          // ラスト1個
          tmpMultiLineCoords = tmpMultiLineCoords.slice(-1);
        }
      }
      prevIsHit = isHit;

      if (addDummyPointFeats) {
        // あとでgetClosestFeatureToCoordinateで使うために、
        // 見えないpointも登録する(multipointだとcoord全体が返ってきて、
        // どれが近かったのかわからない)
        const pt = new Feature(new Point(coord));
        pt.setId(fgPrefix + '.mgi.' + mgi.id);
        pt.setStyle(new Style({}));
        dummyPointFeats.push(pt);
      }
    });

    const hitMultiLine = new Feature(
      new MultiLineString(hitMultiLineCoords));
    hitMultiLine.setStyle(this.getMultiLineStyles_(movieList, { hit: true }));
    const missMultiLine = new Feature(
      new MultiLineString(missMultiLineCoords));
    missMultiLine.setStyle(this.getMultiLineStyles_(movieList, { hit: false }));
    ret.push(missMultiLine, hitMultiLine);

    const hitDots = new Feature(new MultiPoint(hitDotsCoords));
    hitDots.setStyle(this.getDotsStyles_(movieList, { hit: true }));
    const missDots = new Feature(new MultiPoint(missDotsCoords));
    missDots.setStyle(this.getDotsStyles_(movieList, { hit: false }));
    ret.push(missDots, hitDots);

    if (movieList.showTimeTexts) {
      const timeTexts: Feature[] = [];
      timeTextInfoArr.forEach(({ coord, mgi }) => {
        // 呼び出し側で事前にmgiにtsプロパティを入れておく必要あり.
        if (!mgi.ts) {
          return;
        }
        const ts = this.formatHHMMSS(mgi.ts);
        const feat = new Feature(new Point(coord));
        feat.setStyle(this.getTextStyles_(movieList, ts));
        timeTexts.push(feat);
      });
      ret.push(...timeTexts);
    }

    ret.push(...dummyPointFeats);

    // pins
    const startPinCoord = this.convCoord({
      lon: parseFloat(movieList.start_pt.lon),
      lat: parseFloat(movieList.start_pt.lat),
    });
    const startPin = new Feature(new Point(startPinCoord));
    startPin.setStyle(this.getStartPinStyles_(movieList));
    ret.push(startPin);

    const endPinCoord = this.convCoord({
      lon: parseFloat(movieList.end_pt.lon),
      lat: parseFloat(movieList.end_pt.lat),
    });
    const endPin = new Feature(new Point(endPinCoord));
    endPin.setStyle(this.getEndPinStyles_(movieList));
    ret.push(endPin);

    hitDots.setId(`${fgPrefix}.hitDots`);
    missDots.setId(`${fgPrefix}.missDots`);
    hitMultiLine.setId(`${fgPrefix}.hitMultiLine`);
    missMultiLine.setId(`${fgPrefix}.missMultiLine`);
    startPin.setId(`${fgPrefix}.startPin`);
    endPin.setId(`${fgPrefix}.endPin`);

    if (movieList.isSelected) {
      ret.push(...this.createSelectedOnlyFeatures_(movieList));
    }

    return ret;
  }

  onClickFeature_(targetFeat: FeatureLike): void {
    const targetFeatId = targetFeat.getId();
    if (!targetFeatId) {
      return;
    }
    const movieListId = targetFeatId.toString().split('.')[0];
    const targetMovieList = this.movieListMap[movieListId];

    // 地図上の見た目を調整
    for (const ent of Object.entries(this.movieListMap)) {
      const tmpMovieList = ent[1];
      const currentIsSelected = tmpMovieList.isSelected;
      tmpMovieList.isSelected =
        tmpMovieList.id.toString() === movieListId.toString() &&
        !tmpMovieList.isSelected;
      if (currentIsSelected !== tmpMovieList.isSelected) {
        this.updateFeatureGroupStyles(tmpMovieList);
      }
    }

    // イベント発火
    const obj: { dataName: string; data?: GIMovieList } = this.getBaseDataForEmit();
    obj.data = targetMovieList;
    this.emitter.$emit(EMEventNames.EM_EVENT_CLICK, obj);
  }

  createLayer_(movieLists: GIMovieList[]): void {
    const feats: Feature[] = [];
    movieLists.forEach(movieList => {
      feats.push(...this.getResourceFeatures_(movieList));
    });
    const layer = new NamedVectorLayer('movies', {
      source: new VectorSource({features: feats}),
    });
    this.layer = layer;
    if (!this.disableInteraction) {
      this.layerInfo.onLayerClick = ({ event, feature }) => {
        // 重なってたりする場合はそれぞれ飛んでくるので、一回で止める
        if (!event || event.originalEvent.defaultPrevented) { return; }
        event.preventDefault();
        if (!feature) {
          return;
        }
        this.onClickFeature_(feature);
      };
    }
  }

  setResourceMap(movieLists: GIMovieList[]): void {
    const movieListMap: Record<string, GIMovieList> = {};
    const movieMap: Record<number, GIMovie> = {};
    const mgiMap: Record<number, GIMovieGeoIndex> = {};
    movieLists.forEach(ml => {
      movieListMap[ml.id] = ml;
      ml.movies.forEach(m => {
        movieMap[m.id] = m;
        m.movie_geo_indices.forEach(mgi => {
          mgiMap[mgi.id] = mgi;
        });
      });
    });
    this.movieListMap = movieListMap;
    this.movieMap = movieMap;
    this.mgiMap = mgiMap;
  }

  prepareLayer(movieLists: GIMovieList[]): LayerWithInfo {
    this.movieLists = movieLists;
    this.setResourceMap(movieLists);
    this.topZIndex = this.zIndexStep * movieLists.length;
    this.createLayer_(movieLists);
    return this.getLayer();
  }

  refreshLayer(movieLists: GIMovieList[]): void {
    if (!this.layer) {
      return;
    }
    this.layer.getSource().clear();
    this.movieLists = movieLists;
    this.setResourceMap(movieLists);
    this.topZIndex = this.zIndexStep * movieLists.length;

    const feats: Feature[] = [];
    movieLists.forEach(movieList => {
      feats.push(...this.getResourceFeatures_(movieList));
    });
    this.layer.getSource().addFeatures(feats);
  }

  refreshLayerOnZoomChange({ zoom, oldZoom }: { zoom?: number; oldZoom?: number }): void {
    super.refreshLayerOnZoomChange({ zoom, oldZoom });
    // 選択中の矢印のみ変更する
    this.movieLists.forEach(movieList => {
      if (!movieList.isSelected) { return; }
      const selectedOnlyFeats = this.findSelectedOnlyFeatures_(movieList);
      selectedOnlyFeats.forEach(feat => {
        const featId = feat.getId();
        if (!featId || featId.toString().indexOf('.selectedDot.') === -1) {
          return;
        }
        const mgiId = parseInt(featId.toString().split('.')[2]);
        const mgi = this.mgiMap[mgiId];
        const hit = movieList.hit_mgi_map[mgi.id];
        const style = this.getAngleArrowStyles_({ hit, angle: mgi.angle });
        feat.setStyle(style);
      });
    });
  }

  findFeatureGroup(movieList: GIMovieList): {
    startPin: Feature;
    endPin: Feature;
    hitDots: Feature;
    missDots: Feature;
    hitMultiLine: Feature;
    missMultiLine: Feature;
    selectedOnlyFeats: Feature[];
  } {
    const fgId = movieList.id;
    if (!this.layer) {
      throw new Error('layer is not prepared');
    }
    const source = this.layer.getSource();

    const startPin = source.getFeatureById(`${fgId}.startPin`);
    const endPin = source.getFeatureById(`${fgId}.endPin`);
    const hitDots = source.getFeatureById(`${fgId}.hitDots`);
    const missDots = source.getFeatureById(`${fgId}.missDots`);
    const hitMultiLine = source.getFeatureById(`${fgId}.hitMultiLine`);
    const missMultiLine = source.getFeatureById(`${fgId}.missMultiLine`);
    const selectedOnlyFeats = this.findSelectedOnlyFeatures_(movieList);
    return { startPin, endPin, hitDots, missDots, hitMultiLine, missMultiLine, selectedOnlyFeats };
  }

  updateFeatureGroupStyles(movieList: GIMovieList): void {
    if (!this.layer) {
      return;
    }
    const fg = this.findFeatureGroup(movieList);
    if (fg.hitDots) {
      fg.hitDots.setStyle(this.getDotsStyles_(movieList, { hit: true }));
    }
    if (fg.missDots) {
      fg.missDots.setStyle(this.getDotsStyles_(movieList, { hit: false }));
    }
    if (fg.hitMultiLine) {
      fg.hitMultiLine.setStyle(this.getMultiLineStyles_(movieList, { hit: true }));
    }
    if (fg.missMultiLine) {
      fg.missMultiLine.setStyle(this.getMultiLineStyles_(movieList, { hit: false }));
    }
    if (fg.startPin) {
      fg.startPin.setStyle(this.getStartPinStyles_(movieList));
    }
    if (fg.endPin) {
      fg.endPin.setStyle(this.getEndPinStyles_(movieList));
    }

    const layerSource = this.layer.getSource();
    fg.selectedOnlyFeats.forEach(e => layerSource.removeFeature(e));
    if (movieList.isSelected) {
      this.addSelectedOnlyFeatures_(movieList);
    }
  }

  addSelectedOnlyFeatures_(movieList: GIMovieList): void {
    if (!this.layer) {
      return;
    }
    const feats = this.createSelectedOnlyFeatures_(movieList);
    this.layer.getSource().addFeatures(feats);
  }

  deselectAll(): void {
    if (this.disableInteraction) { return; }
    for (const ent of Object.entries(this.movieListMap)) {
      const movieList = ent[1];
      const currentIsSelected = movieList.isSelected;
      movieList.isSelected = false;
      if (currentIsSelected !== movieList.isSelected) {
        this.updateFeatureGroupStyles(movieList);
      }
    }
  }

  getClosestMgi({ lat, lon }: Location, searchOpts: { featureIdPrefix?: number } = {}): GIMovieGeoIndex {
    if (!this.layer) {
      throw new Error('layer is not prepared');
    }
    const coord = this.convCoord({ lon, lat });
    const source = this.layer.getSource();
    // getClosestFeatureToCoordinateの定義が間違っているので、anyで回避
    const pointFeat = source.getClosestFeatureToCoordinate(coord, ((feat: Feature) => {
      // only mgi dummy points
      const featId = feat.getId();
      if (!featId) {
        return false;
      }
      if (
        searchOpts.featureIdPrefix &&
        featId.toString().indexOf(searchOpts.featureIdPrefix.toString()) !== 0) {
        // featureIdPrefixの指定がある場合はそれに該当するもののみ
        return false;
      }
      return featId.toString().indexOf('.mgi.') !== -1;
    }) as any);
    const idParts = pointFeat.getId()?.toString().split('.');
    if (!idParts) {
      throw new Error('invalid feature id');
    }
    const mgiId = parseInt(idParts[2]);
    return this.mgiMap[mgiId];
  }
  getResourceStyles_(): Style[] {
    throw new Error('Method not implemented.');
  }
}
