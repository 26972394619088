import axios, { AxiosResponse } from 'axios';
import * as utils from './apiUtils';
import { EmergencyIndexParams } from 'src/models/apis/extLandApEmergency/extLandApEmergencyRequest';
import { EmergencyIndexResponse } from 'src/models/apis/extLandApEmergency/extLandApEmergencyResponse';

export default {
  index(params: EmergencyIndexParams): Promise<AxiosResponse<EmergencyIndexResponse>> {
    return axios.get(utils.getApiUrl('/ext/land-ap/emergency'), {
      params: params || {},
    });
  },
  getImage(id: number): Promise<AxiosResponse<Blob>> {
    return axios.get(utils.getApiUrl(`/ext/land-ap/image/${id}`), {
      responseType: 'blob',
    });
  },
};
