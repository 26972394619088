import axios, { AxiosResponse } from 'axios';
import * as utils from './apiUtils';
import {
  AnalyticsLogMoviePlayParams,
  AnalyticsLogMoviePlaySecondsParams,
  AnalyticsLogMovieSearchParams,
  AnalyticsLogTagMovieParams,
} from 'src/models/apis/analytics/analyticsRequest';

export default {
  logMoviePlay(params: AnalyticsLogMoviePlayParams): Promise<AxiosResponse<Record<string, []>>> {
    return axios.post(utils.getApiUrl('/analytics/movie/play'), params);
  },
  logMoviePlaySeconds(params: AnalyticsLogMoviePlaySecondsParams): Promise<AxiosResponse<Record<string, []>>> {
    return axios.post(utils.getApiUrl('/analytics/movie/play_seconds'), params);
  },
  logMovieSearch(params: AnalyticsLogMovieSearchParams): Promise<AxiosResponse<Record<string, []>>> {
    return axios.post(utils.getApiUrl('/analytics/movie/search'), params);
  },
  logTagMovieAdd(params: AnalyticsLogTagMovieParams): Promise<AxiosResponse<Record<string, []>>> {
    return axios.post(utils.getApiUrl('/analytics/tag_movie/add'), params);
  },
  logTagMovieDelete(params: AnalyticsLogTagMovieParams): Promise<AxiosResponse<Record<string, []>>> {
    return axios.post(utils.getApiUrl('/analytics/tag_movie/delete'), params);
  },
  logCommentAdd(): Promise<AxiosResponse<Record<string, []>>> {
    return axios.post(utils.getApiUrl('/analytics/comment/add'));
  },
  logCommentDelete(): Promise<AxiosResponse<Record<string, []>>> {
    return axios.post(utils.getApiUrl('/analytics/comment/delete'));
  },
};
