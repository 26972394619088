





























































import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  computed,
  toRefs,
} from '@vue/composition-api';

interface ModalState{
  modalBodyMaxHeight: number;
}

export default defineComponent({
  name: 'modal',
  props: {
    title: {
      type: String,
      default: '',
    },
    body: {
      type: String,
      default: '',
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    showTopDismissButton: {
      type: Boolean,
      default: false,
    },
    topDismissButtonLabel: {
      type: String,
      default: 'x',
    },
    topDismissButtonClass: {
      type: String,
      default: 'close',
    },
    isCloseButtonBusy: {
      type: Boolean,
      default: false,
    },
    isCloseButtonDisabled: {
      type: Boolean,
      default: false,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    showBottomCloseButton: {
      type: Boolean,
      default: true,
    },
    bottomCloseButtonLabel: {
      type: String,
      default: 'OK',
    },
    bottomCloseButtonClass: {
      type: String,
      default: 'btn-primary',
    },
    showBottomCloseButtonIcon: {
      type: Boolean,
      default: false,
    },
    bottomCloseButtonIconClass: {
      type: String,
      default: 'fa-trash',
    },
    showBottomDismissButton: {
      type: Boolean,
      default: true,
    },
    bottomDismissButtonLabel: {
      type: String,
      default: 'キャンセル',
    },
    flipBottomButton: {
      type: Boolean,
      default: false,
    },
    maxWidthStr: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const refModalDialog = ref<HTMLElement>();
    const refModalHeader = ref<HTMLElement>();
    const refModalFooter = ref<HTMLElement>();
    const state = reactive<ModalState>({
      modalBodyMaxHeight: 350,
    });

    onMounted(() => {
      const windowH = window.innerHeight;
      const dialogOffsetTop = refModalDialog.value?.offsetTop || 0;
      const modalHeaderH = refModalHeader.value?.clientHeight || 0;
      const modalFooterH = refModalFooter.value?.clientHeight || 0;
      state.modalBodyMaxHeight = windowH - modalHeaderH - modalFooterH - dialogOffsetTop * 2;
    });

    const shouldCloseButtonDisabled = computed(() => {
      return props.isCloseButtonBusy || props.isCloseButtonDisabled;
    });

    return {
      ...toRefs(state),
      refModalDialog,
      refModalHeader,
      refModalFooter,
      shouldCloseButtonDisabled,
    };
  },
});
