
















import { defineComponent } from '@vue/composition-api';
import TopDebugComponentTool1 from './tool1.vue';

export default defineComponent({
  name: 'top-debug-component',
  props: ['refExtremeMap', 'cars'],
  components: {
    TopDebugComponentTool1,
  },
});
