import Vue from 'vue';
import { VideoJsPlayer } from 'video.js';
import movieApi from '@/apis/movie';
import tagMovieApi from '@/apis/tagMovie';
import mtxApi from '@/apis/mtx';
import { downloadBlob, downloadUrl } from './downloadHelper';
import { Movie, MovieTag } from '@/models/apis/movie/movieResponse';
import {
  GIMovie,
  GIMovieGeoIndex,
  GIMovieList,
} from '@/models/geoItem';
import { MtxFetchByMovieIdsResponse } from '@/models/apis/mtx/mtxResponse';
import { getBearing, getDistanceInMeters } from './geoCalcHelper';
import { RoadName } from '@/models/apis/master/masterResponse';
import { logTagMovieAdd, logTagMovieDelete } from './analyticsHelper';
import { dtFormat, ensureDate } from './dateHelper';

export function getMgiDistance(mgi1: GIMovieGeoIndex, mgi2: GIMovieGeoIndex): number {
  const c1 = {
    lon: parseFloat(mgi1.lon),
    lat: parseFloat(mgi1.lat),
  };
  const c2 = {
    lon: parseFloat(mgi2.lon),
    lat: parseFloat(mgi2.lat),
  };
  return getDistanceInMeters(c1, c2);
}

export async function getScreenshotBlobOfVid(movie: GIMovie, movieDt: Date, vid: string, vjs: VideoJsPlayer): Promise<Blob | string | null> {
  if (!movie || !movieDt || !vid) { return null; }
  const videoEl = document.querySelector(`#${vid}`)?.querySelector('video');
  const canvas = document.createElement('canvas');
  canvas.width = vjs.videoWidth();
  canvas.height = vjs.videoHeight();
  const ctx = canvas.getContext('2d');
  if (!ctx || !videoEl) { return null; }
  ctx.drawImage(videoEl, 0, 0, canvas.width, canvas.height);

  const mimeTypeJpeg = 'image/jpeg';
  const jpegQuality = 0.92;
  return new Promise(resolve => {
    if (canvas.toBlob) {
      canvas.toBlob(blob => {
        resolve(blob);
      }, mimeTypeJpeg, jpegQuality);
    } else {
      // toDataURLで戻ってくるのはstringだが、中で呼び出してるFileSaverで
      // stringの場合はCORSのチェックをするためにsynchronousなXMLHttpRequest
      // を投げていて、少なくともchromeだとdeprecatedなwarningが出て気になる
      // のでなるべく使いたくない.
      const blob = canvas.toDataURL(mimeTypeJpeg, jpegQuality);
      resolve(blob);
    }
  });
}

function getCameraPositionForVid(vid: string) {
  let cameraPosition = '';
  if (vid.startsWith('vid1-')) {
    cameraPosition = 'left';
  } else if (vid.startsWith('vid2-')) {
    cameraPosition = 'front';
  } else if (vid.startsWith('vid3-')) {
    cameraPosition = 'right';
  } else if (vid.startsWith('vidLidar1-')) {
    cameraPosition = 'lidar1';
  }
  return cameraPosition;
}

export async function takeScreenshotOfVid(movie: GIMovie, movieDt: Date, vid: string, vjs: VideoJsPlayer): Promise<void> {
  const blob = await getScreenshotBlobOfVid(movie, movieDt, vid, vjs);
  if (blob) {
    const filename = dtFormat(movieDt, 'yyyymmdd_HHMMSS') +
    '_' + movie.id + '_' + getCameraPositionForVid(vid) + '.jpg';
    downloadBlob(blob, filename);
  }
}

export interface MovieFileUrlObj {
  left: string;
  right: string;
  front: string;
  lidar1: string;
}

export async function getMovieFileUrlObj(movieId: number): Promise<MovieFileUrlObj> {
  const { data } = await movieApi.getMovieFiles(movieId);
  const ret = { front: '', left: '', right: '', lidar1: '' };
  for (const fileInfo of Object.values(data.movie_file_map)) {
    const protoHostPort = Vue.prototype.$isDevelopment()
      ? 'http://' + fileInfo.http_hostport : 'https://' + fileInfo.https_hostport;
    const authToken = data.auth_token;
    const moviePath = `${protoHostPort}${fileInfo.movie_path}?t=${authToken}`;
    if (fileInfo.camera_position === 'front') {
      ret.front = moviePath;
    } else if (fileInfo.camera_position === 'left') {
      ret.left = moviePath;
    } else if (fileInfo.camera_position === 'right') {
      ret.right = moviePath;
    } else if (fileInfo.camera_position === 'lidar1') {
      ret.lidar1 = moviePath;
    }
  }
  return ret;
}

function selectUrlForVid(urlObj: MovieFileUrlObj, vid: string) {
  if (vid.startsWith('vid1-')) {
    return urlObj.left;
  } else if (vid.startsWith('vid2-')) {
    return urlObj.front;
  } else if (vid.startsWith('vid3-')) {
    return urlObj.right;
  } else if (vid.startsWith('vidLidar1-')) {
    return urlObj.lidar1;
  }
  return '';
}

export async function downloadMovieFileOfVid(movie: GIMovie, vid: string): Promise<void> {
  if (!movie || !vid) { return; }
  // 一応取り直す
  const urlObj = await getMovieFileUrlObj(movie.id);
  const url = selectUrlForVid(urlObj, vid);
  const filename = dtFormat(movie.ts, 'yyyymmdd_HHMMSS') +
    '_' + movie.id + '_' + getCameraPositionForVid(vid) + '.mp4';
  await downloadUrl(url, filename);
}

export function convMovies(movies: Movie[]): GIMovie[] {
  return movies.map<GIMovie>(m => {
    return {...m,
      movie_geo_indices: m.movie_geo_indices.map<GIMovieGeoIndex>(mgi => {
        return {
          ...mgi,
          kilopost: mgi.kilopost ? {
            ...mgi.kilopost,
            kp: parseFloat(mgi.kilopost.kp),
            kp_calc: parseFloat(mgi.kilopost.kp_calc),
            lat: parseFloat(mgi.lat),
            lon: parseFloat(mgi.lon),
          } : null,
        };
      }),
    };
  });
}

export async function searchContinuedMovies(currentMovie: GIMovie): Promise<GIMovie[]> {
  const params = {
    device_id: currentMovie.device_id,
    start_ts: currentMovie.ts ?? new Date(currentMovie.start_ts),
    quantity: 5,
  };
  const { data } = await movieApi.searchContinuedMovies(params);
  const result = convMovies(data);

  return result;
}

function getLatLonDisp_(lat: number, lon: number): string {
  return `(緯度: ${convertDecimalLatLonToDms_(lat)}, 経度: ${convertDecimalLatLonToDms_(lon)})`;
}

function convertDecimalLatLonToDms_(latLon: number): string {
  const deg = Math.floor(latLon);
  const min = Math.floor((latLon - deg) * 60);
  const sec = ((latLon - deg) * 60 - min) * 60;
  return `${deg}°${min}′${sec.toFixed(4)}″`;
}

export function preCalculateMovieVals(movieList: GIMovieList, roadNameDispMap: Record<string, RoadName>): void {
  if (movieList.isPreCalculated) {
    return;
  }
  // 再生に必要な数値類を事前に計算しておく
  // playXXX: サーバ側からもらった、動画ごとの再生範囲
  // hardXXX: 画面側で決定した、再生しようと思えば再生できる範囲
  let accumMsec = 0;
  movieList.movies.forEach((movie, idx) => {
    movie.durationMsec = parseFloat(movie.duration) * 1000;
    // movieの中で検索にあたった範囲についての再生情報
    movie.playStartOffsetMsec = movie.start_offset_msec || 0;
    movie.playEndOffsetMsec = movie.end_offset_msec || movie.durationMsec;
    movie.playDurationMsec = movie.playEndOffsetMsec - movie.playStartOffsetMsec;

    // 実際にプレイヤーで再生できる範囲についての再生情報
    movie.hardStartOffsetMsec = movie.playStartOffsetMsec;
    movie.hardEndOffsetMsec = movie.playEndOffsetMsec;
    // ルート検索でない場合、最初と最後の動画については
    // 検索範囲外であっても再生したければさせる
    if (movieList.searchType !== 'route') {
      if (idx === 0) {
        movie.hardStartOffsetMsec = 0;
      }
      if (idx === movieList.movies.length - 1) {
        movie.hardEndOffsetMsec = movie.durationMsec;
      }
    }

    // この動画の直前までの経過再生時間msec
    movie.accumMsec = accumMsec;
    accumMsec += movie.playDurationMsec;

    movie.ts = ensureDate(movie.start_ts);

    const mgis = movie.movie_geo_indices;
    for (let i = 0, len = mgis.length; i < len; i++) {
      const mgi = mgis[i];
      if (!movie.ts) { continue; }
      mgi.ts = new Date(movie.ts.valueOf() + mgi.ts_msec_diff);
      const nextTsMsecDiff = i < len - 1
        ? mgis[i + 1].ts_msec_diff : movie.playDurationMsec;
      mgi.startMsecDiff = mgi.ts_msec_diff;
      mgi.endMsecDiff = nextTsMsecDiff;

      mgi.locationDisp = '';
      if (mgi.kilopost) {
        const roadNameDisp = mgi.kilopost.road_name_disp;
        mgi.kilopost.roadNameDispShort =
          (roadNameDispMap[roadNameDisp] || {}).road_name_disp_short;
        mgi.locationDisp = [
          mgi.kilopost.roadNameDispShort,
          mgi.kilopost.direction,
          mgi.kilopost.place_name === 'main_line' ? '' : mgi.kilopost.place_name,
          parseFloat(mgi.kilopost.kp.toString()).toFixed(1) + 'km',
          '付近',
          getLatLonDisp_(parseFloat(mgi.lat), parseFloat(mgi.lon)),
        ].filter(e => !!e).join(' ');
      }
    }
  });

  if (movieList.searchType !== 'route') {
    // ルート検索以外の場合、hard start/end は動画で再生可能な範囲の全体
    const firstMovie = movieList.movies[0];
    const lastMovie = movieList.movies[movieList.movies.length - 1];
    if (!firstMovie.ts || !lastMovie.ts) { return; }
    movieList.hardStartTs =
      new Date(firstMovie.ts.valueOf() + (firstMovie.hardStartOffsetMsec || 0));
    movieList.hardEndTs =
      new Date(lastMovie.ts.valueOf() + (lastMovie.hardEndOffsetMsec || 0));
  } else {
    // ルート検索の場合、hard start/end は与えられたstart_tsとend_tsそのもの
    movieList.hardStartTs = new Date(movieList.start_ts?.toString() || '');
    movieList.hardEndTs = new Date(movieList.end_ts?.toString() || '');
  }
  movieList.fullDurationMsec = movieList.hardEndTs.getTime() - movieList.hardStartTs.getTime();

  movieList.mightCalculateClosestMgi = true;
  movieList.isPreCalculated = true;
}

export async function getSensorDataOfMovieList(ml: GIMovieList): Promise<MtxFetchByMovieIdsResponse> {
  const movieIds = ml.movies.map(e => e.id);
  const { data } = await mtxApi.fetchByMovieIds({ movie_ids: movieIds });
  return data;
}

export async function updateMovieTags(resultTags: MovieTag[], targetMovie: GIMovie): Promise<void> {
  const tagIds = resultTags.map(e => e.id);

  let adds = 0;
  let deletes = targetMovie.tags.length;
  const currentTagMap = targetMovie.tags.reduce((acc: Record<number, MovieTag>, e) => {
    acc[e.id] = e; return acc;
  }, {});
  for (const resTag of resultTags) {
    if (!currentTagMap[resTag.id]) {
      // 今自分についてなければ新たに追加したことになる
      ++adds;
    } else {
      // 自分についてるやつは、削除件数から引き算
      --deletes;
    }
  }

  await tagMovieApi.update(targetMovie.id, { tag_ids: tagIds });
  targetMovie.tags = resultTags;

  if (adds > 0) { logTagMovieAdd(1); }
  if (deletes > 0) { logTagMovieDelete(1); }
}

interface OpenNewInfraDoctorTabParamsInner {
  lat: string;
  lon: string;
  altitude?: number;
  heading: number;
  pitch?: number;
  roll?: number;
}

function openNewInfraDoctorTab_(
  ifdLinkPrefix: string,
  { lat, lon, altitude = 0, heading, pitch = 0, roll = 0 }: OpenNewInfraDoctorTabParamsInner,
) {
  const params = [];
  params.push(`lat=${lat}`);
  params.push(`lon=${lon}`);
  params.push(`altitude=${altitude}`);
  params.push(`heading=${heading}`);
  params.push(`pitch=${pitch}`);
  params.push(`roll=${roll}`);
  window.open(`${ifdLinkPrefix}&${params.join('&')}`, '_blank');
}

interface OpenNewInfraDoctorTabParams {
  ifdLinkPrefix: string;
  currentMovieList: GIMovieList;
  currentMovie: GIMovie;
  currentMovieIdx: number;
  currentMgi: GIMovieGeoIndex;
  currentMgiIdx: number;
  isFirstMovie: boolean;
  isLastMovie: boolean;
}

export function openNewInfraDoctorTab(params: OpenNewInfraDoctorTabParams): void {
  const {
    ifdLinkPrefix,
    currentMovieList,
    currentMovie,
    currentMovieIdx,
    currentMgi,
    currentMgiIdx,
    isFirstMovie,
    isLastMovie,
  } = params;

  // 次のmgi、なければ前のmgiを取得する
  let pt1: GIMovieGeoIndex | null = null;
  let pt2: GIMovieGeoIndex | null = null;
  const mgis = currentMovie.movie_geo_indices;
  if (currentMgiIdx < mgis.length - 1) {
    // 同一movie内で次のmgiを発見
    pt1 = currentMgi;
    pt2 = mgis[currentMgiIdx + 1];
  } else if (!isLastMovie) {
    // 次の動画の最初のmgiが次のmgi
    const nextMovieIdx = currentMovieIdx + 1;
    const nextMovie = currentMovieList.movies[nextMovieIdx];
    pt1 = currentMgi;
    pt2 = nextMovie.movie_geo_indices[0];
  } else if (currentMgiIdx > 0) {
    // 同一movie内で前のmgiを発見
    pt1 = mgis[currentMgiIdx - 1];
    pt2 = currentMgi;
  } else if (!isFirstMovie) {
    // 前の動画の最後のmgiが前のmgi
    const prevMovieIdx = currentMovieIdx - 1;
    const prevMovie = currentMovieList.movies[prevMovieIdx];
    const lastIdx = prevMovie.movie_geo_indices.length - 1;
    pt1 = prevMovie.movie_geo_indices[lastIdx];
    pt2 = currentMgi;
  }
  if (!pt1 || !pt2) { return; }
  // -180 <= x <= 180 (真北が0度で時計側はプラス、反時計側はマイナス)から
  // 0 <= x <= 360 (真北が0度で時計回りに増加)へ
  const bearing = getBearing(
    {lat: parseFloat(pt1.lat), lon: parseFloat(pt1.lon)},
    {lat: parseFloat(pt2.lat), lon: parseFloat(pt2.lon)});
  const ifdHeading = bearing < 0 ? bearing + 360 : bearing;

  openNewInfraDoctorTab_(ifdLinkPrefix, {
    lat: currentMgi.lat,
    lon: currentMgi.lon,
    heading: ifdHeading,
  });
}
