















import GeoItemSearchCommon from './GeoItemSearchCommon.vue';
import { emergencyTypeMap, emergencyTypes } from '@/consts/ext_land_emergencies';
import {
  defineComponent,
  ref,
} from '@vue/composition-api';
import { EmergencyIndexParams } from '@/models/apis/extLandApEmergency/extLandApEmergencyRequest';

export default defineComponent({
  name: 'geo-item-search-land-ap-emergency',
  setup(_, { emit }) {
    const checkboxKeyPrefix = 'emg';
    // 初期表示は「緊急」だけチェック
    const emergencyTypesDefaultValues = [1];
    const refSearchCommon = ref<InstanceType<typeof GeoItemSearchCommon>>();
    // methods
    const getSearchParams = (): EmergencyIndexParams | null => {
      if (!refSearchCommon.value) { return null; }
      const {
        search_types: movieTypes,
        ts_from: startTs,
        ts_to: endTs,
        ...other
      } = refSearchCommon.value.getSearchParams();
      return {
        movie_types: movieTypes as number[],
        start_ts: startTs,
        end_ts: endTs,
        road_names: other.road_name_disps,
        directions: other.directions,
        kp_calc_from: other.kp_calc_from,
        kp_calc_to: other.kp_calc_to,
      };
    };
    const onChange = () => {
      emit('change');
    };

    return {
      // refs
      refSearchCommon,
      // methods
      getSearchParams,
      onChange,
      // others
      checkboxKeyPrefix,
      emergencyTypeMap,
      emergencyTypes,
      emergencyTypesDefaultValues,
    };
  },
  components: { GeoItemSearchCommon },
});
