import { ShareScopeLov, User } from '@/models/apis/user/userResponse';
import { MasterData, Lovs } from '@/models';
import {
  CommentType,
  Lov,
  RoadName,
  RoadNameDisp,
  RoadNameRaw,
} from '@/models/apis/master/masterResponse';
import { Store, useStore } from '@/hooks/useStore';
import masterApi from '@/apis/master';
import { convKiloposts } from './kilopostHelper';
import { UserActionTypes } from '@/store/modules/user';

export async function ensureUserAndMasters(store: Store): Promise<{
  user: User;
  masters: MasterData;
}> {
  const promises: [Promise<User>, Promise<MasterData>] = [
    store.dispatch(UserActionTypes.GET_ME),
    window.master.$promise,
  ];
  // window.masterの中身が変更されることもあるので、
  // $promiseで最初に取得したものではなく、window.masterそのものを返す
  const [user] = await Promise.all(promises);
  return { user, masters: window.master };
}

export async function waitForMasters<T>(arg: T): Promise<T> {
  await window.master.$promise;
  return arg;
}

export function refreshCommentTypeMaster(commentTypes: CommentType[]): void {
  window.master.commentType = {
    vals: commentTypes,
    map: commentTypes.reduce<Record<string, CommentType>>((acc, e) => {
      acc[e.comment_type] = e; return acc;
    }, {}),
  };
}

export function loadUserAndMasters(): void {
  const userPromise = loadUser_();
  loadMasters_(userPromise);
}
function loadUser_() {
  const store = useStore();
  return store.dispatch(UserActionTypes.GET_ME);
}

function loadMasters_(userPromise: Promise<User>) {
  const prms = masterApi.getAll()
    .then(({ data }) => {
      const lovs: Lovs = {
        car_kind: {
          vals: data.lovs.car_kind,
          map: data.lovs.car_kind.reduce<Record<string, Lov>>((acc, e) => {
            acc[e.key] = e; return acc;
          }, {}),
        },
        role: {
          vals: data.lovs.role,
          map: data.lovs.role.reduce<Record<string, Lov>>((acc, e) => {
            acc[e.key] = e; return acc;
          }, {}),
        },
        share_scope: {
          vals: [],
          map: {},
        },
      };

      const { kpMapByRoadnameDirectionPlace, kpMapByKpUid } = convKiloposts({
        kiloposts: data.kiloposts,
        kilopost_col_map: data.kilopost_col_map,
        kilopost_val_map: data.kilopost_val_map,
      });
      const roadNameStuff = convRoadNameStuff(data.road_names);
      const commentType = {
        vals: data.comment_types,
        map: data.comment_types.reduce<Record<string, CommentType>>((acc, e) => {
          acc[e.comment_type] = e; return acc;
        }, {}),
      };
      const commentTypeIconPaths = Object.values(data.comment_type_icon_paths);

      const prms = window.master.$promise;
      window.master = {
        $promise: prms,
        lovs,
        kpMap: kpMapByRoadnameDirectionPlace,
        kpMapByKpUid,
        roadNames: roadNameStuff.roadNames,
        roadNameDisps: roadNameStuff.roadNameDisps,
        roadNameDispMap: roadNameStuff.roadNameDispMap,
        roadNameToRoadNameDispMap: roadNameStuff.roadNameToRoadNameDispMap,
        commentType,
        commentTypeIconPaths,
        geoConnections: {},
      };
      // wait for user info
      return userPromise;
    })
    .then(user => {
      // 道路の接続情報
      const kpSetName = user.settings.kp_set_name;
      let geoConnections = {};
      try {
        geoConnections = require(`@/data/geo_connections_${kpSetName}.json`);
      } catch (e) {
        console.warn(`failure requiring geo_connections for ${user.settings.g1name}`);
        console.warn(e);
      }
      window.master.geoConnections = geoConnections;

      // 公開範囲
      window.master.lovs.share_scope = {
        vals: user.settings.share_scope_lov,
        map: user.settings.share_scope_lov.reduce<Record<string, ShareScopeLov>>((acc, e) => {
          acc[e.key] = e; return acc;
        }, {}),
      };
    })
    .then(() => {
      return window.master;
    });
  window.master = { $promise: prms } as any;
}

export function convRoadNameStuff(origRoadNames: RoadNameRaw[]): {
  roadNames: RoadName[];
  roadNameDisps: RoadNameDisp[];
  roadNameDispMap: Record<string, RoadNameDisp>;
  roadNameToRoadNameDispMap: Record<string, string>;
} {
  const roadNames: RoadName[] = [];
  const tmpMap1: Record<string, RoadNameDisp> = {};
  const roadNameDisps: RoadNameDisp[] = [];
  origRoadNames.forEach(e => {
    let obj: RoadName = Object.assign({}, {
      ...e,
      directions: e.directions.split(','),
    });
    roadNames.push(obj);

    // road_name_dispごとにまとめたものも作る
    if (!tmpMap1[obj.road_name_disp]) {
      const obj = {
        area: e.area,
        road_name_disp: e.road_name_disp,
        road_name_disp_short: e.road_name_disp_short,
        road_names: [],
        directions: [],
      };
      tmpMap1[obj.road_name_disp] = obj;
      roadNameDisps.push(obj);
    }
    // 異なる型で置き換えるが、既存ロジック踏襲
    obj = tmpMap1[obj.road_name_disp] as RoadName;
    obj.road_names.push(e.road_name);
    // 最後にuniqueする
    obj.directions.push(...e.directions.split(','));
  });
  roadNameDisps.forEach(e => {
    e.directions = [...new Set(e.directions)];
  });

  const roadNameDispMap: Record<string, RoadNameDisp> = {};
  const roadNameToRoadNameDispMap: Record<string, string> = {};
  roadNameDisps.forEach(e => {
    roadNameDispMap[e.road_name_disp] = e;
    e.road_names.forEach((roadName: string) => {
      roadNameToRoadNameDispMap[roadName] = e.road_name_disp;
    });
  });

  return { roadNames, roadNameDisps, roadNameDispMap, roadNameToRoadNameDispMap };
}
