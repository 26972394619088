




















































import {
  defineComponent,
  computed,
  ref,
} from '@vue/composition-api';
// TODO: Vue3＋Vuex4/VueRouter4にアップデート後に、useStoreに置き換える
import { useRoute } from '@/hooks/useRoute';
import { useStore } from '@/hooks/useStore';
import Vue from 'vue';
import { UserActionTypes, UserGetterTypes } from '@/store/modules/user';

export default defineComponent({
  name: 'global-header',
  setup() {
    const store = useStore();
    const { route } = useRoute();
    const userState = store.state.user;
    const refNavbarCollapse = ref<HTMLElement>();

    const displayName = computed<string>(() => {
      return userState.display_name;
    });
    const isAdmin = computed<boolean>(() => {
      return userState.has_role_admin;
    });
    const isSuperAdmin = computed<boolean>(() => {
      return userState.has_role_super_admin;
    });
    const isLoggedIn = computed<boolean>(() => {
      return store.getters[UserGetterTypes.IS_LOGGED_IN];
    });
    const isPageTop = computed<boolean>(() => {
      return route.value.name?.indexOf('Top') === 0;
    });
    const isAdminPageGroup = computed<boolean>(() => {
      return route.value.name?.indexOf('Admin') === 0;
    });
    const isDevDebugPageGroup = computed<boolean>(() => {
      return route.value.name?.indexOf('DevDebug') === 0;
    });
    const isDevelopment = Vue.prototype.$isDevelopment;
    const isStaging = Vue.prototype.$isStaging;

    const onNavVarClick = (evt: Event) => {
      // 押されたら閉じる
      const target = evt.target as HTMLElement;
      if (target.classList.contains('nav-link')) {
        if (refNavbarCollapse.value) {
          $(refNavbarCollapse.value).collapse('hide');
        }
      }
    };
    const logout = () => {
      store.dispatch(UserActionTypes.LOGOUT)
        .then(res => {
          const redirectUrl = res['redirect_url'];
          // want to explicitly reload
          location.href = redirectUrl || '/login';
        });
    };

    return {
      refNavbarCollapse,
      // computed
      displayName,
      isAdmin,
      isSuperAdmin,
      isLoggedIn,
      isPageTop,
      isAdminPageGroup,
      isDevDebugPageGroup,
      // mothods
      onNavVarClick,
      logout,
      isDevelopment,
      isStaging,
    };
  },
});
