




import {
  defineComponent,
  onMounted,
  PropType,
  reactive,
  ref,
  watch,
} from '@vue/composition-api';
import { ChartData, ChartOptions } from 'chart.js';
import { Line as LineChart } from 'vue-chartjs';

export interface LineChartProps {
  data: ChartData;
  opts: ChartOptions;
}

export default defineComponent({
  props: {
    chartData: {
      type: Object as PropType<LineChartProps>,
      default: () => {},
    },
  },
  setup(props) {
    const state = reactive({
      chartdata: {},
      options: {},
    });
    const refLine = ref<LineChart>();

    const doRenderChart = () => {
      if (refLine.value === undefined) { return; }
      if (refLine.value.$data._chart) {
        refLine.value.$data._chart.destroy();
      }
      refLine.value.renderChart(state.chartdata, state.options);
    };

    onMounted(doRenderChart);

    watch(() => props.chartData, () => {
      const { data, opts } = props.chartData;
      state.chartdata = data;
      state.options = opts;

      doRenderChart();
    }, { deep: true });

    return {
      refLine,
    };
  },
  components: { LineChart },
});
