import { EmitFn, MapElemInfo } from '@/models';
import { Ref } from '@vue/composition-api';

export default function useSwitchGeoItem<T>(emit: EmitFn, elem: Ref<MapElemInfo<T>>): {
  showPrevious: () => void;
  showNext: () => void;
} {
  const showPrevious = () => {
    emit('show-previous-geo-item', elem.value);
  };
  const showNext = () => {
    emit('show-next-geo-item', elem.value);
  };

  return {
    showPrevious,
    showNext,
  };
}
