






















































import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  toRefs,
  watch,
} from '@vue/composition-api';
import {
  getVideojs, getVideojsControlBar,
  TextObj as VideoJsTitleBar,
  updateVideojsTitleBarText,
} from '@/lib/videojsHelper';
import { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';
import {
  downloadMovieFileOfVid,
  getScreenshotBlobOfVid,
  takeScreenshotOfVid,
} from '@/lib/moviePlayerHelper';
import { GIMovie } from '@/models/geoItem';
import { VideoControlProperties } from '@/models/moviePlayer';

interface MoviePlayerVideoState {
  showVideoToolbox: boolean;
  uiPromise: Promise<void | void[] | (void | undefined)[] | VideoJsPlayer[] | undefined>;
  isActiveVjsId: boolean;
}

export default defineComponent({
  name: 'movie-player-video',
  props: {
    vid: {
      type: String,
      default: '',
    },
    playerType: {
      type: String,
      default: null,
    },
    isCurrentMovieReady: {
      type: Boolean,
      default: false,
    },
    // 複数動画を一元管理するため、外部側にコントロールを置く
    isDownloadingMovieFile: {
      type: Boolean,
      default: false,
    },
    isDownloadingScreenshot: {
      type: Boolean,
      default: false,
    },
    currentMovie: {
      type: Object as PropType<GIMovie>,
      default: () => { return {}; },
    },
    movieFileUrl: {
      type: String,
      default: null,
    },
    videoControlProperties: {
      type: Object as PropType<VideoControlProperties>,
      default: () => {
        return {
          playSpeed: 1.0,
          volume: 0,
          // playingだけでは、falseのままでは再実行されないため、
          // 元の動きと異なりますので、playとpause両方でコントロールする。
          play: 0,
          pause: 0,
          tickMsec: 0,
          reset: 0,
          loadMovie: 0, // 動画再生中に複数回切り替える場合があるため、数字で管理する。
          isPlayBackward: false,
        };
      },
    },
    isFront: {
      type: Boolean,
      default: false,
    },
    disableVolume: {
      type: Boolean,
      default: false,
    },
    syncCurrentTime: {
      type: Number,
      default: null,
    },
    titleBarTexts: {
      type: Object as PropType<VideoJsTitleBar>,
      default: () => { return {}; },
    },
    hasContinuedMovies: {
      type: Boolean,
      default: false,
    },
    isMovieEnded: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const state = reactive<MoviePlayerVideoState>({
      // vueの管理対象外にする (ただ、管理対象にしたところでほぼパフォーマンス的に変わりはない)
      // vjs: null,
      showVideoToolbox: false,
      uiPromise: Promise.resolve([]),
      isActiveVjsId: false,
    });

    const getVideoJsDefaultOptions = (): VideoJsPlayerOptions => {
      // https://docs.videojs.com/tutorial-components.html
      return {
        // autoplay: true,
        // TODO: which on is this.
        aspectRatio: '16:9', // this.aspectRatio,
        loop: false,
      };
    };

    let vjs: VideoJsPlayer;
    const setVideojsEventListeners = () => {
      const controlBar = getVideojsControlBar(vjs).el();
      const playPreviousMovieBtn = controlBar.querySelector('.vjs-play-previous-movie');
      const play1sBackwardMovieBtn = controlBar.querySelector('.vjs-play-1s-backward-movie');
      const play60sBackwardMovieBtn = controlBar.querySelector('.vjs-play-60s-backward-movie');
      const tickMovieBackwardBtn = controlBar.querySelector('.vjs-tick-movie-backward');
      const playMovieBtn = controlBar.querySelector('.vjs-play-movie');
      const pauseMovieBtn = controlBar.querySelector('.vjs-pause-movie');
      const tickMovieForwardBtn = controlBar.querySelector('.vjs-tick-movie-forward');
      const play1sForwardMovieBtn = controlBar.querySelector('.vjs-play-1s-forward-movie');
      const play60sForwardMovieBtn = controlBar.querySelector('.vjs-play-60s-forward-movie');
      const playNextMovieBtn = controlBar.querySelector('.vjs-play-next-movie');
      const playMovieBackwardCheckbox = controlBar.querySelector('.vjs-play-movie-backward');

      if (playPreviousMovieBtn) {
        playPreviousMovieBtn.addEventListener('click', () => emit('play-previous-movie'), false);
      }
      if (play1sBackwardMovieBtn) {
        play1sBackwardMovieBtn.addEventListener('click', () => emit('seek-change', -1000), false);
      }
      if (play60sBackwardMovieBtn) {
        play60sBackwardMovieBtn.addEventListener('click', () => emit('seek-change', -60000), false);
      }
      if (tickMovieBackwardBtn) {
        tickMovieBackwardBtn.addEventListener('click', () => emit('tick-backward'), false);
      }
      if (playMovieBtn) {
        playMovieBtn.addEventListener('click', () => emit('play'), false);
      }
      if (pauseMovieBtn) {
        pauseMovieBtn.addEventListener('click', () => emit('pause'), false);
      }
      if (tickMovieForwardBtn) {
        tickMovieForwardBtn.addEventListener('click', () => emit('tick-forward'), false);
      }
      if (play1sForwardMovieBtn) {
        play1sForwardMovieBtn.addEventListener('click', () => emit('seek-change', 1000), false);
      }
      if (play60sForwardMovieBtn) {
        play60sForwardMovieBtn.addEventListener('click', () => emit('seek-change', 60000), false);
      }
      if (playNextMovieBtn) {
        playNextMovieBtn.addEventListener('click', () => emit('play-next-movie'), false);
      }
      if (playMovieBackwardCheckbox) {
        playMovieBackwardCheckbox.addEventListener('click', () => emit('pause'), false);
        playMovieBackwardCheckbox.addEventListener('change', () =>
          emit('playmode-change', (playMovieBackwardCheckbox as HTMLInputElement).checked), false);
      }
    };
    const prepareVideojs = () => {
      // 何回か呼ばれるので、複数回初期化を防ぐ
      if (vjs && vjs.id() === props.vid) { return; }

      vjs = getVideojs(props.vid, getVideoJsDefaultOptions(), props.playerType);
      setVideojsEventListeners();
      vjs.ready(() => {
        vjs.muted(false);
        vjs.volume(0.0);
        emit('prepared', props.vid);
        if (props.isFront) {
          vjs.on('timeupdate', () => {
            emit('vjs-time-update', vjs.currentTime(), vjs.duration());
          });
        }
        vjs.on('error', () => {
          emit('vjs-error', props.vid);
        });
        vjs.on('playerreset', () => {
          vjs.muted(false);
          vjs.volume(0.0);
        });
        vjs.on('canplay', () => {
          vjs.playbackRate(props.videoControlProperties.playSpeed);
        });
      });
    };

    // 親の準備ができたタイミングで親側トリガで初期化する
    onMounted(prepareVideojs);

    // watch
    const setPlaySpeed = async(val: number) => {
      vjs.playbackRate(val);
    };
    watch(() => props.videoControlProperties.playSpeed, (val) => {
      setPlaySpeed(val);
    });
    const setVolume = async(val: number) => {
      vjs.volume(val);
    };
    watch(() => props.videoControlProperties.volume, (val: number) => {
      if (!props.disableVolume) {
        setVolume(val);
      }
    });
    const playMovie = () => {
      // control-areaのemit経由でのみ呼び出される想定.
      // 再生させたい場合はplayUI()を呼ぶ.
      // playのpromiseが解決しない内に再度呼び出すとエラーを吐くようなのでケアする.
      state.uiPromise = state.uiPromise.then(() => {
        if (!state.isActiveVjsId) { return; }
        const prms = vjs.play();
        return Promise.all([prms]).catch(() => {});
      });
    };
    let intervalRewind: number;
    const playMovieBackward = () => {
      vjs.addClass('vjs-playing');
      vjs.removeClass('vjs-paused');
      // 動画のフレームレート
      const fps = 15;
      // 再生速度が速いときに逆再生しようとしても仕組み上動画のロードが間に合わないので、
      // 再生速度の選択は無視し、常に1.0xの速度で逆再生するようにする。
      intervalRewind = setInterval(function() {
        if (vjs.currentTime() === 0) {
          pauseMovieBackward();
        } else {
          vjs.currentTime(vjs.currentTime() - (1 / fps));
        }
      }, 1000 / fps);
    };
    watch(() => props.videoControlProperties.play, (newVal, oldVal) => {
      // 逆再生をリセットする
      clearInterval(intervalRewind);
      if (newVal > oldVal) {
        if (!props.videoControlProperties.isPlayBackward) {
          playMovie();
        } else {
          playMovieBackward();
        }
      }
    });
    const pauseMovie = () => {
      // control-areaのemit経由でのみ呼び出される想定.
      // 一時停止させたい場合はpauseUI()を呼ぶ.
      // pauseはplayと違いpromiseを返すわけではないが、同じようにしておく.
      state.uiPromise = state.uiPromise.then(() => {
        if (!state.isActiveVjsId) { return; }
        const prms = vjs.pause();
        if (!prms) { return; }
        return Promise.all([prms]).catch(() => {});
      });
    };
    const pauseMovieBackward = () => {
      vjs.removeClass('vjs-playing');
      vjs.addClass('vjs-paused');
      clearInterval(intervalRewind);
    };
    watch(() => props.videoControlProperties.pause, (newVal, oldVal) => {
      if (newVal > oldVal) {
        if (!props.videoControlProperties.isPlayBackward) {
          pauseMovie();
        } else {
          pauseMovieBackward();
        }
      }
    });
    watch(() => props.videoControlProperties.tickMsec, (newVal, oldVal) => {
      let msec = vjs.currentTime() * 1000;
      msec += newVal - oldVal;
      vjs.currentTime(msec / 1000);
    });
    const onLoadedData = () => {
      emit('loaded-data', props.vid);
    };
    watch(() => props.videoControlProperties.reset, (newVal, oldVal) => {
      if (newVal > oldVal) {
        vjs.reset();
        // ロード中に画面が閉じられた際に、timerをクリアするため
        onLoadedData();
      }
    });
    const loadMovie = async() => {
      const url = props.movieFileUrl;
      if (!url) {
        // このプレイヤーに対応するurlObj内のプロパティがなかった.
        // frontのみでright,leftが無いなど.
        state.isActiveVjsId = false;
        // resetしないと前回再生の動画が裏で再生中になってしまう。
        vjs.reset();
        // 実際動画をロードしていなくても、呼び出し側に処理完了を知らせるためイベントを発行する必要がる。
        onLoadedData();
        return;
      }

      // 画面閉じる際に、resetを実行したため、crossOriginが消えてしまう問題に対応するため。
      vjs.crossOrigin('anonymous');

      vjs.src(url);
      state.isActiveVjsId = true;
      vjs.one('loadeddata', onLoadedData);
    };
    watch(() => props.videoControlProperties.loadMovie, (newVal, oldVal) => {
      if (newVal > oldVal) {
        loadMovie();
      }
    });
    watch(() => props.syncCurrentTime, (val) => {
      const currentTime = vjs.currentTime();
      // 0.5秒以上ずれたら直そう
      // 起動時のrealOffsetMsecを反映するため、frontも対象とする。
      if (Math.abs(val - currentTime) > 0.5) {
        vjs.currentTime(val);
      }
    });
    watch(() => props.titleBarTexts, (val) => {
      updateVideojsTitleBarText(vjs, val);
    });

    // computed: {
    const isLidarMovie = computed(() => {
      return props.vid.startsWith('vidLidar1-');
    });
    const shouldShowPostPlayView = computed(() => {
      return props.isMovieEnded && props.hasContinuedMovies;
    });

    // methods
    const onVideoToolboxAreaClicked = (evt: Event) => {
      // toolboxの黒いバー内が押された場合はtoggleVideoToolboxが
      // 呼ばれないようにする
      evt.preventDefault();
      evt.stopImmediatePropagation();
    };
    const enterFullScreen = async() => {
      vjs.requestFullscreen();
    };
    const getCurrentMovieCurrentDt = (): Date | undefined => {
      const rawCurrentTime = vjs.currentTime();
      const currentTimeMsec = rawCurrentTime * 1000;
      // 現在の再生位置が実際に撮影された時刻
      if (!props.currentMovie.ts) {
        return;
      }
      return new Date(props.currentMovie.ts.valueOf() + currentTimeMsec);
    };
    const takeScreenshot = async() => {
      // 連打防止
      if (props.isDownloadingScreenshot) { return; }

      emit('start-download-screenshot');
      const movie = props.currentMovie;
      const dt = getCurrentMovieCurrentDt();
      if (!dt) {
        return;
      }
      takeScreenshotOfVid(movie, dt, props.vid, vjs);
      // スクショを保存するだけなのですぐ終わるはずだが、
      // 念の為1秒待つ
      setTimeout(() => {
        emit('finish-download-screenshot');
      }, 1000);
    };
    const getScreenshotBlob = async() => {
      const currentDt = getCurrentMovieCurrentDt();
      if (!currentDt) {
        return;
      }
      return getScreenshotBlobOfVid(
        props.currentMovie,
        currentDt,
        props.vid,
        vjs);
    };
    const tryDownload = async() => {
      // 連打防止
      if (props.isDownloadingMovieFile) { return; }

      emit('start-download-movie-file');
      await downloadMovieFileOfVid(props.currentMovie, props.vid);
      // この時点でダウンロードは完了しているはずだが、
      // 念の為もう1秒待つ
      setTimeout(() => {
        emit('finish-download-movie-file');
      }, 1000);
    };
    const playContinuedMovies = () => {
      emit('play-continued-movies');
    };
    const changeVjsPlayMode = (isPlayBackward: boolean) => {
      const controlBar = getVideojsControlBar(vjs).el();
      const playMovieBtn = controlBar.querySelector('.vjs-play-movie');
      const playMovieBackwardCheckbox = controlBar.querySelector('.vjs-play-movie-backward') as HTMLInputElement;
      if (!playMovieBtn || !playMovieBackwardCheckbox) {
        return;
      }
      playMovieBackwardCheckbox.checked = isPlayBackward;
      if (isPlayBackward) {
        playMovieBtn.classList.add('play-backward');
        return;
      }
      playMovieBtn.classList.remove('play-backward');
    };

    return {
      ...toRefs(state),
      // computed
      isLidarMovie,
      shouldShowPostPlayView,
      // methods
      prepareVideojs,
      enterFullScreen,
      onVideoToolboxAreaClicked,
      takeScreenshot,
      getScreenshotBlob,
      tryDownload,
      playContinuedMovies,
      changeVjsPlayMode,
    };
  },
});
