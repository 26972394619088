







import { computed, defineComponent, reactive } from '@vue/composition-api';

interface TmpEventInfo {
  prevPane: HTMLElement;
  nextPane: HTMLElement;
  resizer: HTMLElement;
  initialPageX: number;
  initialPageY: number;
  resizeFunc: (offset: number) => void;
}

interface MultipaneState {
  isResizing: boolean;
  tmpEvtInfo: TmpEventInfo | null;
}

// const LAYOUT_HORIZONTAL = 'horizontal'
const LAYOUT_VERTICAL = 'vertical';

export default defineComponent({
  name: 'multipane',
  props: {
    layout: {
      type: String,
      default: LAYOUT_VERTICAL,
    },
  },
  setup(props, { emit }) {
    const state = reactive<MultipaneState>({
      isResizing: false,
      tmpEvtInfo: null,
    });

    // computed:
    const classnames = computed(() => {
      return [
        'multipane',
        'layout-' + props.layout.slice(0, 1),
        state.isResizing ? 'is-resizing' : '',
      ];
    });
    const styleCursor = computed(() => {
      return state.isResizing
        ? (props.layout === LAYOUT_VERTICAL ? 'col-resize' : 'row-resize')
        : '';
    });
    const styleUserSelect = computed(() => {
      return state.isResizing ? 'none' : '';
    });

    // methods:
    const onMouseDown = (evt: MouseEvent | TouchEvent) => {
      const eventObj: MouseEvent | Touch = evt.type.indexOf('touch') !== -1
        ? (evt as TouchEvent).changedTouches[0]
        : evt as MouseEvent;
      const resizer = eventObj.target as HTMLElement;
      if (resizer === null || !resizer.className || !resizer.className.match('multipane-resizer')) {
        return;
      }
      const initialPageX = eventObj.pageX;
      const initialPageY = eventObj.pageY;

      const prevPane = resizer.previousElementSibling as HTMLElement;
      const nextPane = resizer.nextElementSibling as HTMLElement;

      // resizerの手前の要素のサイズを+-すると同時にresizerの1個後ろの要素
      // のサイズを逆に-+する. こうしないと、resizerが複数あった時に、1個目の
      // resizerを動かした時に2個目のresizer以降が一緒に動いてしまう.
      const calcResizedPrevPane = getCalcResizedPaneFunc(
        prevPane,
        {
          initialPaneWidth: prevPane.offsetWidth,
          initialPaneHeight: prevPane.offsetHeight,
        },
      );
      const resizePrevPane = getDoResizeFunc(prevPane);
      const calcResizedNextPane = nextPane
        ? getCalcResizedPaneFunc(
          nextPane,
          {
            initialPaneWidth: nextPane.offsetWidth,
            initialPaneHeight: nextPane.offsetHeight,
          },
        ) : () => null;
      const resizeNextPane = getDoResizeFunc(nextPane);

      const resizeFunc = (offset: number) => {
        const prevPaneInfo = calcResizedPrevPane(offset);
        const nextPaneInfo = calcResizedNextPane(-offset);
        if (!prevPaneInfo.shouldResize || nextPaneInfo === null || !nextPaneInfo.shouldResize) {
          return;
        }
        resizePrevPane(prevPaneInfo.size);
        resizeNextPane(nextPaneInfo.size);
      };

      // This adds is-resizing class to container
      state.isResizing = true;
      state.tmpEvtInfo = { prevPane, nextPane, resizer, initialPageX, initialPageY, resizeFunc };

      emit('paneResizeStart', { prevPane, nextPane, resizer });

      document.documentElement.addEventListener('mousemove', onMouseMove, true);
      document.documentElement.addEventListener('mouseup', onMouseUp, true);
      // touch events
      document.documentElement.addEventListener('touchmove', onMouseMove, true);
      document.documentElement.addEventListener('touchend', onMouseUp, true);
      document.documentElement.addEventListener('touchcancel', onMouseUp, true);
      // 2本目タップされたら終了させたい
      document.documentElement.addEventListener('touchstart', onMouseUp, true);
    };
    const onMouseMove = (evt: MouseEvent | TouchEvent) => {
      if (!state.tmpEvtInfo) { return; }
      const eventObj: MouseEvent | Touch = evt.type.indexOf('touchmove') !== -1
        ? (evt as TouchEvent).changedTouches[0]
        : evt as MouseEvent;
      const pageX = eventObj.pageX;
      const pageY = eventObj.pageY;

      const resizeFunc = state.tmpEvtInfo.resizeFunc;
      props.layout === LAYOUT_VERTICAL
        ? resizeFunc(pageX - state.tmpEvtInfo.initialPageX)
        : resizeFunc(pageY - state.tmpEvtInfo.initialPageY);
      emit('paneResize', {
        prevPane: state.tmpEvtInfo.prevPane,
        nextPane: state.tmpEvtInfo.nextPane,
        resizer: state.tmpEvtInfo.resizer,
      });
    };
    const onMouseUp = () => {
      if (!state.tmpEvtInfo) { return; }
      // This removes is-resizing class to container
      state.isResizing = false;

      document.documentElement.removeEventListener('mousemove', onMouseMove, true);
      document.documentElement.removeEventListener('mouseup', onMouseUp, true);
      // touch events
      document.documentElement.removeEventListener('touchmove', onMouseMove, true);
      document.documentElement.removeEventListener('touchend', onMouseUp, true);
      document.documentElement.removeEventListener('touchcancel', onMouseUp, true);
      document.documentElement.removeEventListener('touchstart', onMouseUp, true);

      emit('paneResizeStop', {
        prevPane: state.tmpEvtInfo.prevPane,
        nextPane: state.tmpEvtInfo.nextPane,
        resizer: state.tmpEvtInfo.resizer,
      });
      state.tmpEvtInfo = null;
    };
    const getCalcResizedPaneFunc = (pane: HTMLElement,
      { initialPaneWidth, initialPaneHeight }: { initialPaneWidth: number; initialPaneHeight: number },
    ) => {
      let ret;
      if (props.layout === LAYOUT_VERTICAL) {
        // boxは横方向に並ぶ
        ret = (offset: number) => {
          let isUnderMin;
          let isOverMax;
          let size = initialPaneWidth + offset;
          if (pane.style.minWidth) {
            if (parseInt(pane.style.minWidth) > size && offset < 0) {
              isUnderMin = true;
              size = parseInt(pane.style.minWidth);
            }
          }
          if (pane.style.maxWidth) {
            if (parseInt(pane.style.maxWidth) < size && offset > 0) {
              isOverMax = true;
              size = parseInt(pane.style.maxWidth);
            }
          }
          const shouldResize = !isUnderMin && !isOverMax;
          return { size, isUnderMin, isOverMax, shouldResize };
        };
      } else {
        // boxは縦方向に並ぶ
        ret = (offset: number) => {
          let isUnderMin;
          let isOverMax;
          let size = initialPaneHeight + offset;
          if (pane.style.minHeight) {
            if (parseInt(pane.style.minHeight) > size && offset < 0) {
              isUnderMin = true;
              size = parseInt(pane.style.minHeight);
            }
          }
          if (pane.style.maxHeight) {
            if (parseInt(pane.style.maxHeight) < size && offset > 0) {
              isOverMax = true;
              size = parseInt(pane.style.maxHeight);
            }
          }
          const shouldResize = !isUnderMin && !isOverMax;
          return { size, isUnderMin, isOverMax, shouldResize };
        };
      }
      return ret;
    };
    const getDoResizeFunc = (pane: HTMLElement) => {
      const ret = props.layout === LAYOUT_VERTICAL
        ? (size: number) => { pane.style.width = size + 'px'; }
        : (size: number) => { pane.style.height = size + 'px'; };
      return ret;
    };

    return {
      // computed:
      classnames,
      styleCursor,
      styleUserSelect,
      // methods:
      onMouseDown,
    };
  },
});
