











































































import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  watch,
  ref,
} from '@vue/composition-api';

interface VolumeState {
  val: number;
  step: number;
  min: number;
  max: number;
  emitDenom: number;
}
interface MoviePlayerControlAreaState {
  isPlaying: boolean;
  playSpeeds: number[];
  playSpeed: number;
  volume: VolumeState;
  isPlayBackward: boolean;
}

export default defineComponent({
  name: 'movie-player-control-area',
  props: {
    controlAreaId: {
      type: String,
      default: '',
    },
    viewMode: {
      type: String,
      default: 'full',
    },
    sizeMode: {
      type: String,
      default: 'lg',
    },
    currentTimeDisp: {
      type: String,
      default: '',
    },
    startTimeDisp: {
      type: String,
      default: '',
    },
    endTimeDisp: {
      type: String,
      default: '',
    },
    seekMin: {
      type: Number,
      default: 0,
    },
    seekMax: {
      type: Number,
      default: 0,
    },
    seekStep: {
      type: Number,
      default: 1,
    },
    seekCurrent: {
      type: Number,
      default: 0,
    },
    cornerLabelOpacity: {
      type: Number,
      default: 1.0,
    },
  },
  setup(props, { emit }) {
    const initVolumeState = (): VolumeState => {
      return {
        val: 1.0,
        step: 0.01,
        min: 0.0,
        max: 1.0,
        emitDenom: 100,
      };
    };
    const state = reactive<MoviePlayerControlAreaState>({
      isPlaying: false,
      playSpeeds: [0.25, 0.5, 1.0, 2.0, 5.0],
      playSpeed: 1.0,
      volume: initVolumeState(),
      isPlayBackward: false,
    });

    const refSeekbarInput = ref<HTMLInputElement>();

    // computed
    const isViewModeFull = computed(() => {
      return props.viewMode === 'full';
    });
    const isViewModeComp = computed(() => {
      return props.viewMode === 'comp';
    });
    const isViewModeLidar = computed(() => {
      return props.viewMode === 'lidar';
    });
    const isViewModeOther = computed(() => {
      return !isViewModeFull.value && !isViewModeComp.value && !isViewModeLidar.value;
    });
    const isSizeModeLg = computed(() => {
      return props.sizeMode === 'lg';
    });
    const isSizeModeMd = computed(() => {
      return props.sizeMode === 'md';
    });
    const isSizeModeSm = computed(() => {
      return props.sizeMode === 'sm';
    });
    const isSizeModeXs = computed(() => {
      return props.sizeMode === 'xs';
    });
    const isSizeModeGteLg = computed(() => {
      return isSizeModeLg.value;
    });
    const isSizeModeGteMd = computed(() => {
      return isSizeModeGteLg.value || isSizeModeMd.value;
    });
    const isSizeModeGteSm = computed(() => {
      return isSizeModeGteMd.value || isSizeModeSm.value;
    });
    const isSizeModeGteXs = computed(() => {
      return isSizeModeGteSm.value || isSizeModeXs.value;
    });
    const isPaused = computed(() => {
      return !state.isPlaying;
    });
    const myCurrentTimeDisp = computed(() => {
      // currentがまだ来てなかったらstartの方を表示しとく
      return props.currentTimeDisp || props.startTimeDisp;
    });

    watch(() => state.isPlayBackward, (val) => {
      playModeChange(val);
    });

    // methods
    const play = () => {
      emit('play');
      state.isPlaying = true;
    };
    const pause = () => {
      emit('pause');
      state.isPlaying = false;
    };
    const getPlayState = () => {
      return state.isPlaying ? 'playing' : 'paused';
    };
    const setIsPlaying = (isPlaying: boolean) => {
      state.isPlaying = isPlaying;
    };
    const tickBackward = () => {
      emit('tick-backward');
    };
    const tickForward = () => {
      emit('tick-forward');
    };
    const emitSeekChange = (msec: number) => {
      if (!refSeekbarInput.value) {
        return;
      }
      const currentMSec = parseInt(refSeekbarInput.value.value);
      emit('seek-change', currentMSec + msec);
    };
    const seekBackwardOneSec = () => {
      emitSeekChange(-1000);
    };
    const seekBackwardSixtySec = () => {
      emitSeekChange(-60000);
    };
    const seekForwardOneSec = () => {
      emitSeekChange(1000);
    };
    const seekForwardSixtySec = () => {
      emitSeekChange(60000);
    };
    const playPreviousMovie = () => {
      emit('play-previous-movie');
    };
    const playNextMovie = () => {
      emit('play-next-movie');
    };
    const changePlaySpeed = (speed: number) => {
      state.playSpeed = speed;
      emit('set-play-speed', speed);
    };
    const getVolumeEmitVal = () => {
      return state.volume.val / state.volume.emitDenom;
    };
    const volumeOff = () => {
      state.volume.val = state.volume.min;
      emit('set-volume', getVolumeEmitVal());
    };
    const volumeUp = () => {
      state.volume.val = Math.min(
        state.volume.val + state.volume.step,
        state.volume.max,
      );
      emit('set-volume', getVolumeEmitVal());
    };
    const volumeChange = () => {
      emit('set-volume', getVolumeEmitVal());
    };
    const seekChange = (evt: InputEvent) => {
      if (!evt.target) { return; }
      emit('seek-change', parseInt((evt.target as HTMLInputElement).value));
    };
    const setAllUIStateToDefault = () => {
      state.isPlaying = false;
      state.playSpeed = 1.0;
      state.volume.val = state.volume.min;
      state.isPlayBackward = false;
    };
    const setPlaySpeed = (val: number) => {
      state.playSpeed = val;
    };
    const playModeChange = (isPlayBackward: boolean) => {
      emit('playmode-change', isPlayBackward);
    };
    const setPlayBackward = (isPlayBackward: boolean) => {
      state.isPlayBackward = isPlayBackward;
    };

    return {
      ...toRefs(state),
      refSeekbarInput,
      // computed
      isViewModeFull,
      isViewModeComp,
      isViewModeLidar,
      isViewModeOther,
      isSizeModeLg,
      isSizeModeMd,
      isSizeModeSm,
      isSizeModeXs,
      isSizeModeGteLg,
      isSizeModeGteMd,
      isSizeModeGteSm,
      isSizeModeGteXs,
      isPaused,
      myCurrentTimeDisp,
      // methods
      play,
      pause,
      getPlayState,
      setIsPlaying,
      tickBackward,
      tickForward,
      emitSeekChange,
      seekBackwardOneSec,
      seekBackwardSixtySec,
      seekForwardOneSec,
      seekForwardSixtySec,
      playPreviousMovie,
      playNextMovie,
      changePlaySpeed,
      getVolumeEmitVal,
      volumeOff,
      volumeUp,
      volumeChange,
      seekChange,
      setAllUIStateToDefault,
      setPlaySpeed,
      playModeChange,
      setPlayBackward,
    };
  },
});
