export const lineBreakToBR = (str: string | null): string => {
  if (!str) { return ''; }

  return str
    .replace(/\r\n|\\r\\n/g, '<br>')
    .replace(/\r|\\r/g, '<br>')
    .replace(/\n|\\n/g, '<br>');
};

export const splitByLineBreak = (str: string | null): string[] => {
  if (!str) { return []; }
  const token = '#@%;#';
  return str
    .replace(/\r\n|\\r\\n/g, token)
    .replace(/\r|\\r/g, token)
    .replace(/\n|\\n/g, token)
    .split(token);
};
