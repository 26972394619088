


import Vue from 'vue';
import tagMovieApi from '@/apis/tagMovie';
import deviceApi from '@/apis/device';
import RouteSearch from './RouteSearch/index.vue';
import mapElemInfoComponents from './mapElemInfoComponents';
import geoItemSearchComponents from './geoItemSearchComponents';
import TabPaneComponent from './TabPaneComponent/index.vue';
import TopDebugComponent from './TopDebugComponent/index.vue';
import { getLocationDispOfKp, selectRelativeCommentAfterDeleted } from '@/lib/commentHelper';
import { getGeoItemMeta } from '@/lib/geoItemHelper';
import {
  getEnsuredLayerItem,
  showWaitSpinnerTabs,
  createNewTabs,
  prepareTabResources,
} from '@/lib/tabPaneHelper';
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import { useStore } from '@/hooks/useStore';
import ExtremeMap from '@/components/lib/ExtremeMap/index.vue';
import MoviePlayer from '@/components/lib/MoviePlayer/index.vue';
import MoviePlayerCompareMode from '@/components/lib/MoviePlayerCompareMode/index.vue';
import { MovieList } from '@/models/apis/movie/movieResponse';
import { Route } from '@/models/apis/movie/movieRequest';
import { Tag } from '@/models/apis/tag/tagResponse';
import { logTagMovieAdd, logTagMovieDelete } from '@/lib/analyticsHelper';
import { getAggregatedMovieTagsFromMovieList, MovieTagMap, ResultTag } from '@/lib/tagModalHelper';
import { notifyWarning1 } from '@/lib/notificationHelper';
import {
  ExtremeMapEssentials,
  FetchGeoItemsParams,
  LayerInfo,
  MapElemInfo,
  MasterData,
  MovieLayerMeta,
  MovieSearchMode,
  QueryArea,
  TabResources,
  TopMovieSearchParams,
  Location,
  Tab,
} from '@/models';
import { MoviePlayerParams, SwitchToFullModeParams } from '@/models/moviePlayer';
import { ensureUserAndMasters } from '@/lib/masterHelper';
import {
  GeoItemMeta,
  GeoItemsMeta,
  GeoItemMetaComment,
  GICar,
  GIComment,
  GIMovieList,
  GIResource,
  GIResourceWithID,
} from '@/models/geoItem';
import useMaster from '@/composables/useMaster';
import EMAbstractLayerManager from '@/lib/extreme_map/EMAbstractLayerManager';
import { dtFormat } from '@/lib/dateHelper';
import { CarSearchGroupScope, LocalStorageActionTypes, LocalStorageGetterTypes } from '@/store/modules/localStorage';
import GICarManager from 'src/lib/geo_item/GICarManager';
import { Comment } from 'src/models/apis/comment/commentResponse';
import GeoItemSearchComment from '@/components/Top/geoItemSearchComponents/GeoItemSearchComment.vue';
import GeoItemSearchLandApEmergency from '@/components/Top/geoItemSearchComponents/GeoItemSearchLandApEmergency.vue';
import GeoItemSearchJunkaiTenkenReportTenkenData from '@/components/Top/geoItemSearchComponents/GeoItemSearchJunkaiTenkenReportTenkenData.vue';
import GeoItemSearchLandAiDetections from '@/components/Top/geoItemSearchComponents/GeoItemSearchLandAiDetections.vue';
import { CarIndexParams } from 'src/models/apis/cars/carRequest';
import GICommentManager from 'src/lib/geo_item/GICommentManager';
import { CommentIndexParams } from 'src/models/apis/comment/commentRequest';
import { CustomGeoItemLayer, User } from 'src/models/apis/user/userResponse';
import { IntervalID } from 'src/lib/requestAnimationFrame';
import { ShowImageParams } from 'src/composables/useSavedImage';
import GIMovieManager from '@/lib/geo_item/GIMovieManager';
import { Device } from 'src/models/apis/device/deviceResponse';

interface TopMovieSearch {
  params: TopMovieSearchParams;
  areaQueryObj: QueryArea | null;
  areaQueryRadiusMeter: number;
  resultModes: {
    'NONE': 'none';
    'DETAIL': 'detail';
    'AREA': 'area';
    'ROUTE': 'route';
    'ID': 'id';
  };
  isQuerying: boolean;
  resultMode: MovieSearchMode;
  results: GIMovieList[];
  showTagModal: boolean;
  defaultDateRange: number;
}

interface ShortcutKeyEvent extends KeyboardEvent {
  srcKey: string;
}

interface StyleState {
  paneSideMinWidth: string;
  paneSideMaxWidth: string;
  paneCenterMinWidth: string;
  paneCenterMaxWidth: string;
  carListMinHeight: string;
  carListMaxHeight: string;
  movieListMinHeight: string;
  movieListMaxHeight: string;
  tabContainerMinHeight: string;
  tabContentMaxHeight: string;
}

interface SimpleModalState {
  title: string;
  show: boolean;
  message: string;
  onClose: () => void;
  onDismiss: () => void;
}

interface WaitSpinnerState {
  show: boolean;
  msg: string;
  msgDefault: string;
  msgUpdating: string;
}

interface ImageViewModalState {
  show: boolean;
  imageSrc: string;
  title: string;
  downloadFilenameTpl: string;
}

interface EditMovieTagModalState {
  show: boolean;
  tags: MovieTagMap[];
  movieList: GIMovieList | null;
}

interface GroupScope {
  label: string;
  paramName: 'group2_id' | 'group3_id';
  paramValue: number;
}

interface TopState {
  extremeMapEssentials: ExtremeMapEssentials | null;
  cars: GICar[];
  carsUpdatedAt: Date | null;
  devices: Device[];
  showCarIcons: boolean;
  carSearchParams: {
    groupScopeId: CarSearchGroupScope;
  };
  movieSearch: TopMovieSearch;
  geoItemsMeta: GeoItemsMeta;
  movieLayerMeta: MovieLayerMeta;
  mapSelectedElemInfo: MapElemInfo<GIResource | Location> | null;
  timers: {
    carUpdateTimer: IntervalID | null;
    commentUpdateTimer: IntervalID | null;
  };
  tabs: Tab[];
  defaultActiveTab: string;
  waitSpinner: WaitSpinnerState;
  styles: StyleState;
  simpleModal: SimpleModalState;
  imageViewModal: ImageViewModalState;
  editMovieTagModal: EditMovieTagModalState;
  mapDeleteConfirmElem: MapElemInfo<GIComment> | null;
  moviePlayerType: 'full' | 'comp';
  moviePlayerMovieLists: GIMovieList[];
  moviePlayerFullMovieListId: string | null;
  moviePlayerCompMovieListId: string | null;
  movieSearchDateErrorMsg: string;
  mapSelectedElemCreateFailed: number;
  mapSelectedElemUpdateFailed: number;
  getGeoItemByIdFailed: number;
}

export default defineComponent({
  name: 'top',
  setup() {
    const now = new Date();
    const movieSearchDefaultDateRange = 14;
    const initMovieSearchState = (): TopMovieSearch => {
      return {
        params: {
          // 2週間前から今日
          dt_from: new Date(now.getFullYear(), now.getMonth(), now.getDate() - movieSearchDefaultDateRange),
          dt_to: new Date(now.getFullYear(), now.getMonth(), now.getDate()),
          roadNameDispObj: null,
          direction: '',
          kp_calc_from: '',
          kp_calc_to: '',
          car_name: '',
          tags: [],
        },
        areaQueryObj: null,
        areaQueryRadiusMeter: 500,
        resultModes: {
          'NONE': 'none',
          'DETAIL': 'detail',
          'AREA': 'area',
          'ROUTE': 'route',
          'ID': 'id',
        },
        isQuerying: false,
        resultMode: 'none',
        results: [],
        showTagModal: false,
        defaultDateRange: movieSearchDefaultDateRange,
      };
    };
    const initStyleState = (): StyleState => {
      return {
        paneSideMinWidth: '23%',
        paneSideMaxWidth: '30%',
        paneCenterMinWidth: '40%',
        paneCenterMaxWidth: '54%',
        carListMinHeight: '20%',
        carListMaxHeight: '38%',
        movieListMinHeight: '40%',
        movieListMaxHeight: '40%',
        tabContainerMinHeight: '40%',
        tabContentMaxHeight: '40%',
      };
    };
    const initSimpleModalState = (): SimpleModalState => {
      return {
        title: '確認',
        show: false,
        message: '',
        onClose: () => {},
        onDismiss: () => { state.simpleModal.show = false; },
      };
    };
    const initWaitSpinnerState = (): WaitSpinnerState => {
      return {
        show: true,
        msg: 'データロード中...',
        msgDefault: 'データロード中...',
        msgUpdating: '更新中...',
      };
    };
    const initImageViewModal = (): ImageViewModalState => {
      return {
        show: false,
        imageSrc: '',
        title: '',
        downloadFilenameTpl: '',
      };
    };
    const initEditMovieTagModalState = (): EditMovieTagModalState => {
      return {
        show: false,
        tags: [],
        movieList: null,
      };
    };
    const initGeoItemMetaState = (): GeoItemsMeta => {
      return {
        list: [],
        map: {},
        show: {},
        order: {},
        countsDisp: {},
      };
    };
    const state = reactive<TopState>({
      extremeMapEssentials: null,
      cars: [],
      carsUpdatedAt: null,
      devices: [],
      showCarIcons: true,

      carSearchParams: {
        groupScopeId: null,
      },
      movieSearch: initMovieSearchState(),
      geoItemsMeta: initGeoItemMetaState(),
      movieLayerMeta: {} as MovieLayerMeta,
      mapSelectedElemInfo: null,
      timers: {
        carUpdateTimer: null,
        commentUpdateTimer: null,
      },
      tabs: [],
      defaultActiveTab: 'comment',
      waitSpinner: initWaitSpinnerState(),
      styles: initStyleState(),
      simpleModal: initSimpleModalState(),
      imageViewModal: initImageViewModal(),
      editMovieTagModal: initEditMovieTagModalState(),
      mapDeleteConfirmElem: null,
      moviePlayerType: 'full',
      moviePlayerMovieLists: [],
      moviePlayerFullMovieListId: null,
      moviePlayerCompMovieListId: null,
      movieSearchDateErrorMsg: '検索期間は一ヶ月以内で指定してください',
      mapSelectedElemCreateFailed: 0,
      mapSelectedElemUpdateFailed: 0,
      getGeoItemByIdFailed: 0,
    });
    const { state: msts } = useMaster();

    // refs
    const refExtremeMap = ref<InstanceType<typeof ExtremeMap>>();
    const refGeneralSearchBarRow = ref<HTMLElement>();
    const refPaneLeft = ref<HTMLElement>();
    const refGiSearchcomment = ref<InstanceType<typeof GeoItemSearchComment>[]>();
    const refGiSearchlandApEmergency = ref<InstanceType<typeof GeoItemSearchLandApEmergency>[]>();
    const refGiSearchjunkaiTenkenReportTenkenData = ref<InstanceType<typeof GeoItemSearchJunkaiTenkenReportTenkenData>[]>();
    const refGiSearchlandAiDetections = ref<InstanceType<typeof GeoItemSearchLandAiDetections>[]>();
    const refPaneCenter = ref<HTMLElement>();
    const refMapTopMiscBar = ref<HTMLElement>();
    const refMapTopMiscBarLeft = ref<HTMLElement>();
    const refMapTopMiscBarRight = ref<HTMLElement>();
    const refMapSelectedElemInfoArea = ref<HTMLElement>();
    const refPaneRight = ref<HTMLElement>();
    const refMoviePlayer = ref<InstanceType<typeof MoviePlayer>>();
    const refMoviePlayerCompareMode = ref<InstanceType<typeof MoviePlayerCompareMode>>();

    const store = useStore();

    onMounted(async() => {
      onResize();
      window.addEventListener('resize', onResize);

      await ensureUserAndMasters(store).then(({ user, masters }) => {
        msts.roadNameDisps = masters.roadNameDisps;
        msts.roadNameDispMap = masters.roadNameDispMap;
        msts.kpMapByKpUid = masters.kpMapByKpUid;
        state.extremeMapEssentials = {
          userSettings: user.settings,
          kpMap: masters.kpMap,
        };

        const layerListDefault: CustomGeoItemLayer[] = [
          // 車はレイヤーデータ表示のとこのチェックボックスを非表示
          { name: 'car', dispName: '車両', hideToggleCheckbox: true },
          { name: 'comment', dispName: '付箋', isCreatable: true },
        ];
        let layerListCustom: CustomGeoItemLayer[] = user.settings.customGeoItemLayers || [];

        // FIXME そのうち消す
        if (Vue.prototype.$isProduction()) {
          // 本番環境の場合、AI検知結果は特定のユーザーIDの場合のみ表示する
          const isAiKakuninUser = [
            // KD(★管理者)
            ['stk_super_admin', true], // 首都技用(完全一致)
            ['honshi-6KZhWSqt', true], // 本四高速用(完全一致)
            ['koufu-kasen-7dfZH', true], // 甲府河川国道事務所(完全一致)
            ['nexco-east-eng-chohT2ah', true], // NEXCO東エンジ用(完全一致)
            ['nexco-mnt-niigata-cAUoy4qL', true], // NEXCOメンテ新潟用(完全一致)
            ['hiroshima-airport-Q5PdJ', true], // 広島空港用(完全一致)
            ['shinchitose-airport-q6BTx', true], // 新千歳空港用(完全一致)
            // KD(開発)
            ['honshi-kaihatsu0001-MKZ3g', true], // 本四高速用(完全一致)
            ['koufu-kasen-kaihatsu0001-Rl6vd', true], // 甲府河川国道事務所(完全一致)
            ['nexco-east-eng-kaihatsu0001-j9FvK', true], // NEXCO東エンジ用(完全一致)
            ['nexco-mnt-niigata-kaihatsu0001-89rSC', true], // NEXCOメンテ新潟用(完全一致)
            ['hiroshima-airport-kaihatsu0001-r8gFZ', true], // 広島空港用(完全一致)
            ['shinchitose-airport-kaihatsu0001-GP6Rl', true], // 新千歳空港用(完全一致)
            // 首都技
            ['shutogi-ai-kakunin-Fz4Vd', true], // (完全一致)
            // 本四高速
            ['HNS-KNS', false], // (前方一致)
            // 甲府河川国道事務所
            ['KTR-KFR', false], // (前方一致)
            // NEXCO東エンジ
            ['ENE-MTZ', false], // (前方一致)
            ['NEE-gijutu', true], // (完全一致)
            ['NEE-yobi', true], // (完全一致)
            ['NMK-tokorozawa', true], // (完全一致)
            ['NMK-honsha', true], // (完全一致)
            ['NEXCO-tokorozawa', true], // (完全一致)
            // NEXCOメンテ新潟
            ['NMN-YZW', false], // (前方一致)
            // 広島空港
            ['HIJ-HIJ', false], // (前方一致)
            // 新千歳空港
            ['SPK-CTS', false], // (前方一致)
          ].findIndex(([_username, isExactMatch]) => {
            return isExactMatch ? username.value === _username : username.value.startsWith(_username.toString());
          }) !== -1;
          if (!isAiKakuninUser) {
            layerListCustom = layerListCustom.filter(e => e.name !== 'landAiDetections');
          }
        }

        initializeDataManagers(
          layerListDefault.concat(layerListCustom),
          { user, masters },
        );
        showInitialDataLayers();
      });
      await deviceApi.index().then(({ data }) => {
        state.devices = data;
      });

      state.waitSpinner.show = false;
      handleExtParams();

      const preferences = store.getters[LocalStorageGetterTypes.PREFERENCES];
      state.carSearchParams.groupScopeId = preferences.car_search_group_scope || null;
      restartCarUpdateInterval().then(() => {});
      startGettingRecentComments().then(() => {});
    });

    onUnmounted(() => {
      for (const ent of Object.entries(state.timers)) {
        const timerId = ent[1];
        if (timerId) {
          window.clearRequestInterval(timerId);
        }
      }
      window.removeEventListener('resize', onResize);
    });

    const userState = store.state.user;
    // computed
    const isSuperAdmin = computed(() => userState.has_role_super_admin);
    const userId = computed(() => userState.id);
    const groupInfo = computed(() => userState.group_info);
    const username = computed(() => userState.username);
    const isIOS = computed(() => {
      const md = Vue.prototype.$getMobileDetector(window.navigator.userAgent);
      return md.isIOS();
    });
    const movingCarsCount = computed(() => {
      return state.cars.filter(car => car.isMoving).length;
    });
    const stoppedCarsCount = computed(() => {
      return state.cars.filter(car => !car.isMoving).length;
    });
    const visibleGeoItemLayers = computed(() => {
      return Object.entries(state.geoItemsMeta.show).filter(ent => !!ent[1]).map(ent => {
        return state.geoItemsMeta.map[ent[0]];
      });
    });
    const isMaxVisibleGeoItemLayersReached = computed(() => {
      return visibleGeoItemLayers.value.length >= 4;
    });
    const isMapReady = computed(() => {
      return !!state.extremeMapEssentials;
    });
    const isValidRouteMovieSearchDateParams = computed(() => {
      const params = state.movieSearch.params;
      return !!params.dt_to;
    });
    const isValidMovieSearchDateParams = computed(() => {
      const params = state.movieSearch.params;
      const maxDateRange = getMovieSearchMaxDateRange();
      return (params.dt_from && params.dt_to) &&
        (Math.abs(params.dt_to.getTime() - params.dt_from.getTime()) < (maxDateRange + 1) * 86400 * 1000);
    });
    const isValidMovieSearchParams = computed(() => {
      const isDateParamOk = isValidMovieSearchDateParams.value;
      const params = state.movieSearch.params;
      const isRoadParamOk =
        (params.roadNameDispObj && params.direction) &&
        (params.kp_calc_from === '' || /^\d+(\.\d+)?$/.test(params.kp_calc_from || '')) &&
        (params.kp_calc_to === '' || /^\d+(\.\d+)?$/.test(params.kp_calc_to || ''));
      const isCarParamOk = params.car_name;
      const isTagParamOk = params.tags && params.tags.length > 0;
      return isDateParamOk && (isRoadParamOk || isCarParamOk || isTagParamOk);
    });
    const isMovieSearchResultModeNone = computed(() => {
      return state.movieSearch.resultMode === state.movieSearch.resultModes.NONE;
    });
    const isMovieSearchResultModeDetail = computed(() => {
      return state.movieSearch.resultMode === state.movieSearch.resultModes.DETAIL;
    });
    const isMovieSearchResultModeArea = computed(() => {
      return state.movieSearch.resultMode === state.movieSearch.resultModes.AREA;
    });
    const isMovieSearchResultModeRoute = computed(() => {
      return state.movieSearch.resultMode === state.movieSearch.resultModes.ROUTE;
    });
    const currentElemInfoComponent = computed(() => {
      if (!state.mapSelectedElemInfo) {
        return 'map-elem-info-none';
      }

      if (state.mapSelectedElemInfo.dataName === 'comment') {
        return 'map-elem-info-comment-container';
      }
      return 'map-elem-info-' + state.mapSelectedElemInfo.dataName;
    });
    const showLidarMode = computed(() => {
      return groupInfo.value.g1id && groupInfo.value.g1id === 31;
    });
    const showMoviePlayerFullMode = computed(() => {
      return state.moviePlayerMovieLists.length > 0 &&
        !!state.moviePlayerFullMovieListId &&
        state.moviePlayerType === 'full';
    });
    const showMoviePlayerCompareMode = computed(() => {
      return state.moviePlayerMovieLists.length > 0 &&
        !!state.moviePlayerCompMovieListId &&
        state.moviePlayerType === 'comp';
    });
    const searchParamMovieSearchButtonTooltip = computed(() => {
      return isValidMovieSearchDateParams.value ? '' : state.movieSearchDateErrorMsg;
    });
    const groupScopeMap = computed<Record<number, GroupScope>>(() => {
      return {
        1: {
          label: '事業所内',
          paramName: 'group2_id',
          paramValue: groupInfo.value.g2id,
        },
        2: {
          label: '部署内',
          paramName: 'group3_id',
          paramValue: groupInfo.value.g3id,
        },
      };
    });

    // methods
    const initializeDataManagers = (origList: LayerInfo[], { user, masters }: { user: User; masters: MasterData }) => {
      const map = getGeoItemMeta(origList, { useGIManager: true, user, masters });
      state.geoItemsMeta.list = Object.values(map);
      state.geoItemsMeta.map = map;

      // movie関連は別途保存しておく
      try {
        const giManager = new GIMovieManager({ masters });
        const item = {
          name: 'movie',
          dispName: '動画',
          manager: giManager,
          layerManager: null,
        };
        state.movieLayerMeta = item;
      } catch (e) {}
    };
    const showInitialDataLayers = () => {
      // 車と、チェックがついてるやつ
      const initialLayers = ['car'];
      for (const [dataName, flg] of Object.entries(state.geoItemsMeta.show)) {
        if (flg) { initialLayers.push(dataName); }
      }
      for (const dataName of initialLayers) {
        const metaItem = state.geoItemsMeta.map[dataName] as GeoItemMeta;
        const zIndexOffset = state.geoItemsMeta.order[dataName] || 0;
        if (refExtremeMap.value) {
          refExtremeMap.value.showDataLayer(metaItem, [], zIndexOffset);
        }
      }
    };
    const refreshCarLayer = () => {
      const giCarMeta = state.geoItemsMeta.map.car;
      giCarMeta.layerManager.refreshLayer(state.cars);
    };
    const updateShowCarIcons = () => {
      state.cars.forEach(car => {
        car.hideCarIcon = !state.showCarIcons;
      });
      refreshCarLayer();
    };
    const onCarListRowClick = (obj: GICar) => {
      const isSelected = !obj.isSelected;
      state.cars.forEach(e => { e.isSelected = false; });
      obj.isSelected = isSelected;
      refreshCarLayer();
      afterDataLayerItemClick('car', obj, { moveCenter: true });
    };
    const getCarSearchParams = (): CarIndexParams => {
      const params: CarIndexParams = {};
      if (state.carSearchParams.groupScopeId) {
        const { paramName, paramValue } = groupScopeMap.value[state.carSearchParams.groupScopeId];
        params[paramName] = paramValue;
      }
      return params;
    };
    const restartCarUpdateInterval = async() => {
      window.clearRequestInterval(state.timers.carUpdateTimer);
      const reloadFunc = async() => {
        const giCarMeta = state.geoItemsMeta.map.car;
        const giCarManager: GICarManager = giCarMeta.manager as GICarManager;
        const newCars = await giCarManager.getResourcesByParams(getCarSearchParams());
        state.cars = giCarManager.mergeStateInfo(newCars, state.cars);
        giCarMeta.layerManager.refreshLayer(state.cars);
        state.carsUpdatedAt = new Date();
      };
      await reloadFunc();
      state.timers.carUpdateTimer = window.requestInterval(reloadFunc, 30000);
    };
    const onCarSearchParamsChange = async() => {
      await restartCarUpdateInterval();
      tryRefreshCurrentSelectedElemInfo({
        dataName: 'car',
        data: state.cars,
        idFieldName: 'device_id',
      });
      store.dispatch(LocalStorageActionTypes.SET_PREFERENCE, {
        key: 'car_search_group_scope',
        val: state.carSearchParams.groupScopeId,
      });
    };
    const startGettingRecentComments = async() => {
      const commentTab = {
        dataName: 'comment',
        label: '新着の付箋',
        resources: {
          ready: false,
          elements: [],
        },
      };
      // 検索中にlabelが初期表示されるようにtabsに入れる
      state.tabs = [commentTab];
      await initGetRecentCommentsInterval(commentTab.resources);
      commentTab.resources.ready = true;
    };
    const initGetRecentCommentsInterval = async(resources: TabResources) => {
      window.clearRequestInterval(state.timers.commentUpdateTimer);
      const getParams = (): CommentIndexParams => {
        // 直近2週間の付箋
        const dayRange = 14;
        const now = new Date();
        return {
          ts_from: new Date(now.valueOf() - 86400 * (dayRange - 1) * 1000),
          ts_to: now,
        };
      };
      const func = async() => {
        const giCommentManager = state.geoItemsMeta.map.comment.manager as GICommentManager;
        const comments = await giCommentManager.getResourcesByParams(getParams());
        resources.elements = convCommentsAsRecentComments(comments);
      };
      await func();
      state.timers.commentUpdateTimer = window.requestInterval(func, 30 * 1000);
    };
    const convCommentsAsRecentComments = (comments: GIComment[]) => {
      // 新着順にソート
      return comments.slice()
        .map(comment => {
          let kpDisp = null;
          if (comment.kp_uid) {
            const kpObj = msts.kpMapByKpUid.get(comment.kp_uid);
            if (kpObj) {
              kpDisp = getLocationDispOfKp(kpObj, msts.roadNameDispMap);
            }
          }
          return {
            ...comment,
            kpDisp,
          };
        })
        .sort((a, b) => {
          return a.ts && b.ts && a.ts < b.ts ? 1 : a.ts && b.ts && a.ts > b.ts ? -1 : 0;
        });
    };
    const changeGeoItemLayers = async(dataName: string) => {
      if (state.geoItemsMeta.show[dataName]) {
        state.geoItemsMeta.order[dataName] = visibleGeoItemLayers.value.length;
      } else {
        const deletedOrder = state.geoItemsMeta.order[dataName];
        delete state.geoItemsMeta.order[dataName];
        // 順番の付け替え
        for (const [k, v] of Object.entries(state.geoItemsMeta.order)) {
          if (v > deletedOrder) {
            state.geoItemsMeta.order[k]--;
          }
        }
        delete state.geoItemsMeta.countsDisp[dataName];
      }
      // 編集中にレイヤーを消されたら追随して消える
      if (state.mapSelectedElemInfo) {
        // carとmovieは関係ないので除外
        const ignoreDataNames = ['car', 'movie'];
        if (
          !ignoreDataNames.includes(state.mapSelectedElemInfo.dataName) &&
          !state.geoItemsMeta.show[state.mapSelectedElemInfo.dataName]
        ) {
          setMapSelectedElemInfo(null);
        }
      }
      // 再取得、再表示
      await refreshGeoItemLayers({ spinnerMsg: state.waitSpinner.msgDefault });
    };
    const refreshGeoItemLayers = async({ spinnerMsg }: { spinnerMsg: string }) => {
      const reqItems: GeoItemMeta[] = [];
      for (const [dataName, show] of Object.entries(state.geoItemsMeta.show)) {
        const metaItem = state.geoItemsMeta.map[dataName] as GeoItemMeta;
        if (!show && refExtremeMap.value) {
          refExtremeMap.value.removeDataLayer(metaItem.name);
          continue;
        }
        // あとでまとめてリクエスト
        reqItems.push(metaItem);
      }

      const currentTabs = state.tabs.filter(e => {
        return [...reqItems.map(e => e.name), 'comment'].includes(e.dataName);
      });
      if (reqItems.length === 0) {
        state.tabs = currentTabs;
        return;
      }

      state.waitSpinner.msg = spinnerMsg;
      state.waitSpinner.show = true;
      showWaitSpinnerTabs(currentTabs);
      const newTabs = createNewTabs(state.tabs, reqItems);
      state.tabs = [...currentTabs, ...newTabs];
      const results = await getGeoItems(reqItems);
      state.waitSpinner.show = false;

      for (const { item: metaItem, data } of results) {
        const dataName = metaItem.name;
        const zIndexOffset = state.geoItemsMeta.order[dataName] || 0;
        if (refExtremeMap.value) {
          refExtremeMap.value.showDataLayer(metaItem, data, zIndexOffset);
        }
        tryRefreshCurrentSelectedElemInfo({ dataName, data });
        state.geoItemsMeta.countsDisp[dataName] = `(${data.length}件)`;
        prepareTabResources(state.tabs, metaItem, data);
      }

      return results;
    };
    const hasLocation = (data: any): data is Location => {
      return data && data.hasOwnProperty('lat') && data.hasOwnProperty('lon');
    };
    const tryRefreshCurrentSelectedElemInfo = ({ dataName, data, idFieldName = 'id' }: { dataName: string; data: GIResource[]; idFieldName?: string }) => {
      if (!state.mapSelectedElemInfo) { return; }
      const idFieldKey = idFieldName as keyof GIResource;
      const selectedData = state.mapSelectedElemInfo.data;
      if (state.mapSelectedElemInfo.dataName === dataName &&
        !hasLocation(selectedData) &&
        !data.find(e => state.mapSelectedElemInfo && e[idFieldKey] === selectedData[idFieldKey])
      ) {
        setMapSelectedElemInfo(null);
      }
    };
    const getGeoItems = async(reqItems: GeoItemMeta[]) => {
      const promises = [];
      for (const item of reqItems) {
        const componentRef = item.name === 'comment' ? refGiSearchcomment.value
          : item.name === 'junkaiTenkenReportTenkenData' ? refGiSearchjunkaiTenkenReportTenkenData.value
            : item.name === 'landAiDetections' ? refGiSearchlandAiDetections.value
              : item.name === 'landApEmergency' ? refGiSearchlandApEmergency.value
                : null;
        if (!componentRef) { continue; }
        const prms = item.manager.getResources(componentRef[0]).then(data => {
          return { item, data };
        });
        promises.push(prms);
      }
      return Promise.all(promises);
    };
    const getMovieSearchMaxDateRange = () => {
      // そのうち、過去に遡るほど検索可能範囲が広がるようにしたい.
      return 31;
    };
    const adjustMovieSearchDateRange = (prop: 'from' | 'to') => {
      const dtProp: 'dt_from' | 'dt_to' = prop === 'from' ? 'dt_from' : 'dt_to';
      if (!state.movieSearch.params[dtProp]) { return; }

      const params = state.movieSearch.params;
      let otherProp: 'dt_to' | 'dt_from';
      let sign;
      if (prop === 'from') {
        otherProp = 'dt_to';
        sign = 1;
      } else {
        otherProp = 'dt_from';
        sign = -1;
      }

      let dateRange: number | null = null;
      if (!params[otherProp] || params.dt_from > params.dt_to) {
        // 編集していない方の日付が正しい日付ではない または 開始日 > 終了日の場合、
        // 検索期間をデフォルト検索期間に戻す.
        dateRange = state.movieSearch.defaultDateRange;
      } else {
        const maxDateRange = getMovieSearchMaxDateRange();
        if (params.dt_to.getTime() - params.dt_from.getTime() > maxDateRange * 86400 * 1000) {
          // 開始日 <= 終了日の場合で、検索可能期間を超えている場合、
          // 検索期間を最大検索可能期間に戻す.
          dateRange = maxDateRange;
        }
      }

      if (dateRange) {
        // 検索期間に合うよう、編集していない方の日付を調整する.
        if (prop === 'from') {
          params.dt_to = new Date(params.dt_from.valueOf() + sign * dateRange * 86400 * 1000);
        } else {
          params.dt_from = new Date(params.dt_to.valueOf() + sign * dateRange * 86400 * 1000);
        }
      }
    };
    const onDateParamChange = (prop: 'from' | 'to', val: Date) => {
      state.movieSearch.params[`dt_${prop}`] = val;

      adjustMovieSearchDateRange(prop);

      // 範囲検索の場合、再検索
      if (
        isMovieSearchResultModeArea.value &&
        isValidMovieSearchDateParams.value &&
        state.movieSearch.areaQueryObj
      ) {
        doAreaMovieSearch(state.movieSearch.areaQueryObj);
      }
    };
    const onTagParamChange = () => {
      state.movieSearch.showTagModal = false;
      // 範囲検索の場合、再検索
      if (isMovieSearchResultModeArea.value && state.movieSearch.areaQueryObj) {
        doAreaMovieSearch(state.movieSearch.areaQueryObj);
      }
    };
    const setMovieQueryStart = ({ mode }: { mode: MovieSearchMode }) => {
      state.movieSearch.isQuerying = true;
      state.movieSearch.results = [];
      state.movieSearch.resultMode = mode;
      refreshMovieLayer();
      if ((state.mapSelectedElemInfo || {}).dataName === 'movie') {
        setMapSelectedElemInfo(null);
      }
      if (!isMovieSearchResultModeArea.value && refExtremeMap.value) {
        refExtremeMap.value.hideQueryArea();
      }
    };
    const setMovieQueryDone = ({ results }: { results: GIMovieList[] }) => {
      state.movieSearch.isQuerying = false;
      state.movieSearch.results = results;
      refreshMovieLayer();
    };
    /* 検索条件指定による動画検索 */
    const doSearchParamMovieSearch = async() => {
      if (!isValidMovieSearchParams.value) { return; }

      setMovieQueryStart({ mode: state.movieSearch.resultModes.DETAIL });
      const mgr = state.movieLayerMeta.manager;
      const results =
        await mgr.getResourcesByParams(state.movieSearch.params);
      setMovieQueryDone({ results });
    };
    /* ルート指定による動画検索 */
    const doRouteMovieSearch = async(routes: { data: Route[] }) => {
      if (!isValidRouteMovieSearchDateParams.value) { return; }

      setMovieQueryStart({ mode: state.movieSearch.resultModes.ROUTE });
      const mgr = state.movieLayerMeta.manager;
      const results =
        await mgr.getResourcesByRoutes(state.movieSearch.params, routes);
      setMovieQueryDone({ results });
    };
    const onClickMap = async(data: Location) => {
      const queryArea = {
        lat: data.lat,
        lon: data.lon,
        radius: state.movieSearch.areaQueryRadiusMeter,
      };
      if (isMovieSearchResultModeNone.value || isMovieSearchResultModeArea.value) {
        // まだ検索してない、もしくは現在が範囲検索の場合
        doAreaMovieSearch(queryArea);
        // 地図下部の新規作成エリアを表示する
        setMapSelectedElemInfo(
          getNewObjForMapSelectedElem(queryArea));
      } else {
        // 現在が範囲検索ではない場合は一旦確認してから実施する
        if (refExtremeMap.value) {
          refExtremeMap.value.showQueryArea(queryArea);
        }
        state.simpleModal.message = '範囲検索に切り替えてもよろしいですか？';
        state.simpleModal.onClose = () => {
          doAreaMovieSearch(queryArea);
          // 地図下部の新規作成エリアを表示する
          setMapSelectedElemInfo(
            getNewObjForMapSelectedElem(queryArea));
          state.simpleModal.show = false;
        };
        state.simpleModal.onDismiss = () => {
          // ピン位置だけ残してみる
          if (refExtremeMap.value) {
            refExtremeMap.value.showQueryPin(queryArea);
          }
          // 地図下部の新規作成エリアを表示する
          setMapSelectedElemInfo(getNewObjForMapSelectedElem(queryArea));
          state.simpleModal.show = false;
        };
        state.simpleModal.show = true;
      }
    };
    /* 範囲指定による動画検索 */
    const doAreaMovieSearch = async(queryArea: QueryArea) => {
      if (!refExtremeMap.value) {
        return;
      }
      if (!isValidMovieSearchDateParams.value) {
        notifyWarning1(state.movieSearchDateErrorMsg);
        return;
      }

      setMovieQueryStart({ mode: state.movieSearch.resultModes.AREA });
      state.movieSearch.areaQueryObj = queryArea;
      refExtremeMap.value.showQueryArea(queryArea);

      const mgr = state.movieLayerMeta.manager;
      const results =
        await mgr.getResourcesByArea(state.movieSearch.params, queryArea);
      setMovieQueryDone({ results });
    };
    const onMovieSearchResultRowClick = (obj: GIMovieList) => {
      const isSelected = !obj.isSelected;
      state.movieSearch.results.forEach(e => { e.isSelected = false; });
      obj.isSelected = isSelected;
      refreshMovieLayer();
      afterDataLayerItemClick('movie', obj);
    };
    const onMovieSearchResultPlayClick = (evt: Event, obj: MovieList) => {
      evt.preventDefault();
      evt.stopImmediatePropagation();
      afterMoviePlayClick(obj);
    };
    const refreshMovieLayer = () => {
      if (refExtremeMap.value) {
        refExtremeMap.value.refreshMovieLayer(state.movieSearch.results);
      }
    };
    const afterMoviePlayClick = (obj: MovieList) => {
      showAndPlayMoviePlayer(obj);
    };
    const afterDataLayerItemClick = (dataName: string, obj: GIResource, opts: { moveCenter?: boolean } = {}) => {
      if (obj.isSelected && refExtremeMap.value) {
        refExtremeMap.value.deselectLayersExcept(dataName);
        setMapSelectedElemInfo({
          dataName: dataName,
          data: obj,
        }, { moveCenter: !!opts.moveCenter });
      } else {
        setMapSelectedElemInfo(null);
      }
    };
    const getNewObjForMapSelectedElem = (queryArea: QueryArea): MapElemInfo<Location> => {
      return {
        dataName: 'new',
        data: {
          lat: queryArea.lat,
          lon: queryArea.lon,
        },
      };
    };
    const isMovieList = (resource: any): resource is GIMovieList => {
      return resource && resource.hasOwnProperty('movies');
    };
    const setMapSelectedElemInfo = (val: MapElemInfo<GIResource | Location> | null, opts: { moveCenter?: boolean } = {}) => {
      state.mapSelectedElemInfo = val;
      if (val?.dataName === 'comment' && state.mapSelectedElemInfo) {
        const metaItem = state.geoItemsMeta.map[val.dataName] as GeoItemMetaComment;
        state.mapSelectedElemInfo.relativeComments = metaItem.layerManager.getRelativeComments(val.data as GIComment);
      }
      nextTick(() => {
        resizeMap();
        if (opts.moveCenter && refExtremeMap.value && val && !isMovieList(val.data)) {
          refExtremeMap.value.moveCenterTo(val.data);
        }
      });
    };
    const onMapItemClicked = (obj: MapElemInfo<GIResource>) => {
      const defaultDataList = ['movie'];
      if (
        !defaultDataList.includes(obj.dataName) &&
        !state.geoItemsMeta.map[obj.dataName]
      ) {
        console.warn('onMapItemClicked. Unknown resource type', obj.dataName);
        return;
      }
      afterDataLayerItemClick(obj.dataName, obj.data);
    };
    const onMapAllDeselected = () => {
      // 地図下部の表示を消す
      setMapSelectedElemInfo(null);
    };
    const onMapZoomChanged = ({ zoom, oldZoom }: { zoom: number; oldZoom: number }) => {
      visibleGeoItemLayers.value.forEach(layerInfo => {
        layerInfo.layerManager.refreshLayerOnZoomChange({ zoom, oldZoom });
      });
    };
    const paneResizeStopped = () => {
      resizeMap();
    };
    const onRoadNameDispChange = () => {
      state.movieSearch.params.direction = '';
      state.movieSearch.params.kp_calc_from = '';
      state.movieSearch.params.kp_calc_to = '';
    };
    const createMapSelectedElem = async(obj: MapElemInfo<Comment>) => {
      if (!state.geoItemsMeta.map[obj.dataName]) {
        console.warn('tryCreateMapSelectedElem. Unknown resource type', obj.dataName);
        return;
      }
      const metaItem = state.geoItemsMeta.map[obj.dataName];
      try {
        const resource = await metaItem.manager.createResource(obj.data);
        resource.isSelected = true;
        metaItem.layerManager.addLayerItem(resource);
        setMapSelectedElemInfo({
          dataName: obj.dataName,
          data: resource,
        });
      } catch (e) {
        state.mapSelectedElemCreateFailed++;
      }

      if (obj.dataName === 'comment') {
        refreshCommentTab();
      }
    };
    const refreshCommentTab = () => {
      const commentTab = state.tabs.find(e => e.dataName === 'comment');
      if (!commentTab) {
        return;
      }
      initGetRecentCommentsInterval(commentTab.resources).then(() => {}); // 新着付箋を更新
    };
    const updateMapSelectedElem = async(obj: MapElemInfo<GIComment>) => {
      if (!state.geoItemsMeta.map[obj.dataName]) {
        console.warn('tryUpdateMapSelectedElem. Unknown resource type', obj.dataName);
        return;
      }
      const metaItem = state.geoItemsMeta.map[obj.dataName];
      try {
        const resource = await metaItem.manager.updateResource(obj.data);
        resource.isSelected = true;
        setMapSelectedElemInfo({
          dataName: obj.dataName,
          data: resource,
        });
        metaItem.layerManager.updateLayerItem(resource);
      } catch (e) {
        state.mapSelectedElemUpdateFailed++;
      }

      if (obj.dataName === 'comment') {
        refreshCommentTab();
      }
    };
    const confirmDeleteMapSelectedElem = async(obj: MapElemInfo<GIComment>) => {
      state.mapDeleteConfirmElem = obj;
    };
    const showImage = (data: ShowImageParams) => {
      state.imageViewModal = {
        show: true,
        imageSrc: data.imageSrc,
        title: data.modalTitle,
        downloadFilenameTpl: data.downloadFilenameTpl,
      };
    };
    const closeImageModal = () => {
      state.imageViewModal = {
        show: false,
        imageSrc: '',
        title: '',
        downloadFilenameTpl: '',
      };
    };
    const doDeleteMapSelectedElem = async() => {
      const obj = state.mapDeleteConfirmElem;
      if (!obj) {
        return;
      }
      state.mapDeleteConfirmElem = null;
      if (!state.geoItemsMeta.map[obj.dataName]) {
        console.warn('tryDeleteMapSelectedElem. Unknown resource type', obj.dataName);
        return;
      }
      const metaItem = state.geoItemsMeta.map[obj.dataName] as GeoItemMetaComment;
      const resource = await metaItem.manager.deleteResource(obj.data);
      metaItem.layerManager.deleteLayerItem(resource);
      if (obj.dataName === 'comment') {
        const relativeComments = metaItem.layerManager.getRelativeComments(obj.data);
        const selectedComment = state.mapSelectedElemInfo?.data as GIComment;
        if (selectedComment && selectedComment.id !== obj.data.id) {
          relativeComments.push(selectedComment);
        }
        if (!refGiSearchcomment.value) {
          return;
        }
        const commentOrNull = await selectRelativeCommentAfterDeleted({
          deletedObj: obj,
          relativeComments,
          metaItem,
          giSearchComment: refGiSearchcomment.value[0],
        });
        setMapSelectedElemInfo(commentOrNull);
        refreshCommentTab();
      } else {
        setMapSelectedElemInfo(null);
      }
    };
    const cancelDeleteMapSelectedElem = async() => {
      state.mapDeleteConfirmElem = null;
    };
    const playMapSelectedElem = async(obj: MapElemInfo<MovieList>) => {
      afterMoviePlayClick(obj.data);
    };
    const startEditMovieTag = async(obj: MapElemInfo<GIMovieList>) => {
      state.editMovieTagModal.movieList = obj.data;
      state.editMovieTagModal.tags =
        getAggregatedMovieTagsFromMovieList(obj.data);
      state.editMovieTagModal.show = true;
    };
    const updateMovieTagsOfMovieList = async(resultTags: ResultTag[]) => {
      const movieList = state.editMovieTagModal.movieList;
      if (!movieList) {
        return;
      }
      let numMoviesTagAdded = 0;
      let numMoviesTagDeleted = 0;

      const promises = movieList.movies.map((m, idx) => {
        let adds = 0;
        let deletes = m.tags.length;
        const currentTagMap = m.tags.reduce<Record<number, Tag>>((acc, e) => {
          acc[e.id] = e; return acc;
        }, {});
        const tagsOfMovie = [];
        for (const resTag of resultTags) {
          // 完全選択且つ今自分についてなければ新たに追加したことになる
          if (!resTag.isPartiallySelected && !currentTagMap[resTag.id]) {
            ++adds;
          }
          // 完全/半 選択にかかわらず、元から自分についてるやつは削除件数から引き算
          if (currentTagMap[resTag.id]) {
            --deletes;
          }
          // 完全選択のもの、または半選択で且つ元から自分についているものを抽出
          if (!resTag.isPartiallySelected || currentTagMap[resTag.id]) {
            tagsOfMovie.push(resTag);
          }
        }
        if (adds > 0) { ++numMoviesTagAdded; }
        if (deletes > 0) { ++numMoviesTagDeleted; }
        return tagMovieApi.update(m.id, { tag_ids: tagsOfMovie.map(e => e.id) })
          .then(({ data }) => {
            movieList.movies[idx].tags = data.tags;
          });
      });
      await Promise.all(promises);

      if (numMoviesTagAdded > 0) { logTagMovieAdd(numMoviesTagAdded); }
      if (numMoviesTagDeleted > 0) { logTagMovieDelete(numMoviesTagDeleted); }

      setMapSelectedElemInfo({
        dataName: 'movie',
        data: movieList,
      });

      state.editMovieTagModal.show = false;
    };
    const onMapSelectedElemAreaSizeChange = async() => {
      nextTick(() => {
        resizeMap();
      });
    };
    const setCommentDisplayedOnLayer = async() => {
      // 付箋を開いてなければ開く
      const dataName = 'comment';
      if (!state.geoItemsMeta.show[dataName]) {
        Vue.set(state.geoItemsMeta.show, dataName, true);
        await changeGeoItemLayers(dataName);
      }
    };
    const onTabElementClick = async(element: MapElemInfo<GIResourceWithID>) => {
      if (element.dataName === 'comment') {
        await setCommentDisplayedOnLayer();
      }
      // 選択状態にする
      const layerManager = state.geoItemsMeta.map[element.dataName].layerManager as EMAbstractLayerManager;
      layerManager.deselectAll();
      const targetResource = getEnsuredLayerItem(layerManager, element.data);
      const hasGeoLocation = !!targetResource.lat && !!targetResource.lon;
      if (hasGeoLocation) {
        layerManager.updateLayerItem(targetResource);
      }
      afterDataLayerItemClick(element.dataName, targetResource, { moveCenter: hasGeoLocation });
    };
    const showAndPlayMoviePlayer = (movieList: MovieList) => {
      // 常に三面モードで開く
      state.moviePlayerType = 'full';
      state.moviePlayerMovieLists = state.movieSearch.results;
      state.moviePlayerFullMovieListId = movieList.id;
    };
    const closeMoviePlayer = () => {
      state.moviePlayerMovieLists = [];
      state.moviePlayerFullMovieListId = null;
      state.moviePlayerCompMovieListId = null;
    };
    const tryControlMoviePlayer = (evt: ShortcutKeyEvent) => {
      // moviePlayer側でやろうとすると表示していないときも拾ってしまって
      // 知らない内に何かが起こってそうなので、top側で制御する
      if (showMoviePlayerFullMode.value && refMoviePlayer.value) {
        refMoviePlayer.value.shortcutKeyAction(evt.srcKey);
      } else if (showMoviePlayerCompareMode.value && refMoviePlayerCompareMode.value) {
        refMoviePlayerCompareMode.value.shortcutKeyAction(evt.srcKey);
      }
    };
    const handleShortcutKey = (evt: ShortcutKeyEvent) => {
      if (evt.srcKey === 'escape') {
        // モーダル類を閉じる
        if (state.simpleModal.show) {
          state.simpleModal.onDismiss();
        } else if (state.imageViewModal.show) {
          closeImageModal();
        } else if (state.mapDeleteConfirmElem) {
          cancelDeleteMapSelectedElem();
        } else if (state.movieSearch.showTagModal) {
          state.movieSearch.showTagModal = false;
        } else if (state.editMovieTagModal.show) {
          state.editMovieTagModal.show = false;
        } else {
          tryControlMoviePlayer(evt);
        }
      } else {
        tryControlMoviePlayer(evt);
      }
    };
    const switchMoviePlayerToFullMode = (obj: SwitchToFullModeParams) => {
      state.moviePlayerType = 'full';
      state.moviePlayerMovieLists = state.movieSearch.results;
      state.moviePlayerFullMovieListId = null;
      nextTick(() => {
        state.moviePlayerFullMovieListId = obj.leftMovieListId;
      });
    };
    const switchMoviePlayerToCompareMode = (obj: MoviePlayerParams) => {
      state.moviePlayerType = 'comp';
      state.moviePlayerMovieLists = obj.movieLists;
      state.moviePlayerCompMovieListId = null;
      nextTick(() => {
        state.moviePlayerCompMovieListId = obj.movieListId;
      });
    };
    const handleExtParams = () => {
      // インフラドクター連携 (URLパラメータにlat,lon指定でこちらの画面が開かれる)
      // lat, lonがあったらそれで検索をかける
      const extParams = store.getters[LocalStorageGetterTypes.EXT_PARAMS];
      // 一度読んだら捨てる
      store.dispatch(LocalStorageActionTypes.SET, { key: 'extParams', val: {} });
      if (extParams?.lat && extParams?.lon) {
        const queryArea: QueryArea = {
          lat: extParams.lat,
          lon: extParams.lon,
          radius: state.movieSearch.areaQueryRadiusMeter,
        };
        doAreaMovieSearch(queryArea);
        setMapSelectedElemInfo(
          getNewObjForMapSelectedElem(queryArea));
      }
      if (extParams.commentId) {
        fetchAndShowCommentById(extParams.commentId);
      } else if (extParams.movieId && extParams.startOffset) {
        fetchAndShowMovieByIdAndOffset(extParams.movieId, extParams.startOffset);
      }
    };
    const fetchAndShowCommentById = async(commentId: string) => {
      const dataName = 'comment';
      const metaItem = state.geoItemsMeta.map[dataName] as GeoItemMetaComment;
      const comment = await metaItem.manager.getResourceById(parseInt(commentId));
      if (!comment) { return; }
      await onTabElementClick({ dataName, data: comment });
    };
    const fetchAndShowMovieByIdAndOffset = async(movieId: number, startOffset: number) => {
      const metaItem = state.movieLayerMeta;
      setMovieQueryStart({ mode: state.movieSearch.resultModes.ID });
      const movieList = await metaItem.manager.getResourceById(movieId);
      const results = movieList ? [movieList] : [];
      setMovieQueryDone({ results });
      if (!movieList) { return; }
      movieList.startOffset = startOffset;
      onMovieSearchResultRowClick(movieList);
    };
    const showPreviousGeoItem = async(current: MapElemInfo<GIResourceWithID>) => {
      switchGeoItem(current, -1);
    };
    const showNextGeoItem = async(current: MapElemInfo<GIResourceWithID>) => {
      switchGeoItem(current, 1);
    };
    const switchGeoItem = (current: MapElemInfo<GIResourceWithID>, move: number) => {
      const geoItemLayerManager = state.geoItemsMeta.map[current.dataName].layerManager;
      const targetGeoItem = geoItemLayerManager.getGeoItemByIndexDiff(current.data, move);
      geoItemLayerManager.deselectAll();
      targetGeoItem.isSelected = true;
      const hasGeoLocation = !!targetGeoItem.lat && !!targetGeoItem.lon;
      if (hasGeoLocation) {
        geoItemLayerManager.updateLayerItem(targetGeoItem);
      }
      afterDataLayerItemClick(current.dataName, targetGeoItem, { moveCenter: hasGeoLocation });
    };
    const getGeoItemById = async(obj: FetchGeoItemsParams) => {
      const metaItem = state.geoItemsMeta.map[obj.dataName];
      if (!obj.id) { return; }
      try {
        const resource = await metaItem.manager.getResourceById(obj.id);
        if (!resource) {
          return;
        }
        metaItem.layerManager.addLayerItem(resource);
      } catch {
        state.getGeoItemByIdFailed++;
      }
      // 同じGeoItemを再表示する。
      if (state.mapSelectedElemInfo) {
        setMapSelectedElemInfo({ ...state.mapSelectedElemInfo });
      }
    };
    const onResize = () => {
      resizePanes();
      resizeLists();
    };
    const resizePanes = () => {
      if (!refPaneLeft.value ||
        !refPaneCenter.value ||
        !refPaneRight.value
      ) {
        return;
      }
      const w = window.innerWidth - 36;
      const paneSideHardMaxWidth = 380;
      const paneCenterHardMinWidth = w - 380 * 2;

      state.styles.paneSideMinWidth = Math.min(Math.floor(w * 0.20), paneSideHardMaxWidth) + 'px';
      state.styles.paneSideMaxWidth = Math.min(Math.floor(w * 0.30), paneSideHardMaxWidth) + 'px';
      state.styles.paneCenterMinWidth = Math.max(Math.floor(w * 0.40), paneCenterHardMinWidth) + 'px';
      state.styles.paneCenterMaxWidth = Math.max(Math.floor(w * 0.60), paneCenterHardMinWidth) + 'px';
      // prefで変えるかもしれない
      const paneSideInitialWidth = Math.floor(w * 0.21) + 'px';
      const paneCenterInitialWidth = Math.floor(w * 0.60) + 'px';

      refPaneLeft.value.style.width = paneSideInitialWidth;
      refPaneCenter.value.style.width = paneCenterInitialWidth;
      refPaneRight.value.style.width = paneSideInitialWidth;

      nextTick(() => {
        resizeMap();
      });
    };
    const resizeMap = () => {
      if (!refGeneralSearchBarRow.value ||
        !refMapTopMiscBarLeft.value ||
        !refMapTopMiscBarRight.value ||
        !refMapSelectedElemInfoArea.value ||
        !refExtremeMap.value
      ) {
        return;
      }
      // windowの高さ - window内での地図より上の部分の高さ - window内での地図より下の部分の高さ = 地図の高さ
      const headerH = 57;
      const searchBarH = refGeneralSearchBarRow.value.clientHeight;
      // 地図の上部分の高さは固定値だが、スクロールしたら表示されている高さは減るから、それを考慮に入れてやる
      // (下にスクロールした状態で付箋を表示すると、スクロール下分だけ地図の高さは増える)
      const aboveMapH =
        Math.max(0, headerH + searchBarH - window.scrollY) +
        Math.max(refMapTopMiscBarLeft.value.clientHeight, refMapTopMiscBarRight.value.clientHeight);

      // 地図下部領域の高さは可変だが、地図の高さを決める時は最大でもこの数値までしかその領域の高さを考慮しないこととする.
      const mapSelectedElemInfoMaxH = 150;
      const belowMapH = Math.min(refMapSelectedElemInfoArea.value.clientHeight, mapSelectedElemInfoMaxH);

      const margin = 0;
      const mapHeight = window.innerHeight - aboveMapH - belowMapH - margin;
      refExtremeMap.value.setMapHeight(mapHeight);
      refExtremeMap.value.triggerResize();
    };
    const resizeLists = () => {
      const headerH = 57;
      const searchBarH = refGeneralSearchBarRow.value?.clientHeight || 0;
      const h = window.innerHeight - headerH - searchBarH;

      state.styles.carListMinHeight = Math.floor(h * 0.20) + 'px';
      state.styles.carListMaxHeight = Math.floor(h * 0.40) + 'px';
      state.styles.movieListMinHeight = Math.floor(h * 0.49) + 'px';
      state.styles.movieListMaxHeight = Math.floor(h * 0.49) + 'px';
      state.styles.tabContainerMinHeight = Math.floor(h * 0.49) + 'px';
      state.styles.tabContentMaxHeight = Math.floor(h * 2.00) + 'px';
    };

    return {
      ...toRefs(state),
      msts,
      // refs
      refExtremeMap,
      refGeneralSearchBarRow,
      refPaneLeft,
      refGiSearchcomment,
      refGiSearchlandApEmergency,
      refGiSearchjunkaiTenkenReportTenkenData,
      refGiSearchlandAiDetections,
      refPaneCenter,
      refMapTopMiscBar,
      refMapTopMiscBarLeft,
      refMapTopMiscBarRight,
      refMapSelectedElemInfoArea,
      refPaneRight,
      refMoviePlayer,
      refMoviePlayerCompareMode,
      // computed:
      isSuperAdmin,
      userId,
      groupInfo,
      username,
      isIOS,
      movingCarsCount,
      stoppedCarsCount,
      visibleGeoItemLayers,
      isMaxVisibleGeoItemLayersReached,
      isMapReady,
      isValidRouteMovieSearchDateParams,
      isValidMovieSearchDateParams,
      isValidMovieSearchParams,
      isMovieSearchResultModeNone,
      isMovieSearchResultModeDetail,
      isMovieSearchResultModeArea,
      isMovieSearchResultModeRoute,
      currentElemInfoComponent,
      showLidarMode,
      showMoviePlayerFullMode,
      showMoviePlayerCompareMode,
      searchParamMovieSearchButtonTooltip,
      groupScopeMap,
      // methods:
      initializeDataManagers,
      showInitialDataLayers,
      refreshCarLayer,
      updateShowCarIcons,
      onCarListRowClick,
      getCarSearchParams,
      restartCarUpdateInterval,
      onCarSearchParamsChange,
      startGettingRecentComments,
      initGetRecentCommentsInterval,
      convCommentsAsRecentComments,
      changeGeoItemLayers,
      refreshGeoItemLayers,
      tryRefreshCurrentSelectedElemInfo,
      getGeoItems,
      getMovieSearchMaxDateRange,
      adjustMovieSearchDateRange,
      onDateParamChange,
      onTagParamChange,
      setMovieQueryStart,
      setMovieQueryDone,
      doSearchParamMovieSearch,
      doRouteMovieSearch,
      onClickMap,
      doAreaMovieSearch,
      onMovieSearchResultRowClick,
      onMovieSearchResultPlayClick,
      refreshMovieLayer,
      afterMoviePlayClick,
      afterDataLayerItemClick,
      getNewObjForMapSelectedElem,
      setMapSelectedElemInfo,
      onMapItemClicked,
      onMapAllDeselected,
      onMapZoomChanged,
      paneResizeStopped,
      onRoadNameDispChange,
      createMapSelectedElem,
      refreshCommentTab,
      updateMapSelectedElem,
      confirmDeleteMapSelectedElem,
      showImage,
      closeImageModal,
      doDeleteMapSelectedElem,
      cancelDeleteMapSelectedElem,
      playMapSelectedElem,
      startEditMovieTag,
      updateMovieTagsOfMovieList,
      onMapSelectedElemAreaSizeChange,
      setCommentDisplayedOnLayer,
      onTabElementClick,
      showAndPlayMoviePlayer,
      closeMoviePlayer,
      tryControlMoviePlayer,
      handleShortcutKey,
      switchMoviePlayerToFullMode,
      switchMoviePlayerToCompareMode,
      handleExtParams,
      fetchAndShowCommentById,
      fetchAndShowMovieByIdAndOffset,
      showPreviousGeoItem,
      showNextGeoItem,
      switchGeoItem,
      getGeoItemById,
      onResize,
      resizePanes,
      resizeMap,
      resizeLists,
      dtFormat,
    };
  },
  components: {
    RouteSearch,
    ...mapElemInfoComponents,
    ...geoItemSearchComponents,
    TopDebugComponent,
    TabPaneComponent,
  },
});
