













import {
  defineComponent,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import { JunkaiTenkenIndexParams } from 'src/models/apis/extJunkaiTenkenReportTenkenData/extJunkaiTenkenReportTenkenDataRequest';
import GeoItemSearchCommon from './GeoItemSearchCommon.vue';

export default defineComponent({
  name: 'geo-item-search-junkai-tenken-report-tenken-data',
  setup(_, { emit }) {
    const state = reactive({
      checkboxKeyPrefix: 'tenken',
    });
    const refSearchCommon = ref<InstanceType<typeof GeoItemSearchCommon>>();
    const getSearchParams = (): JunkaiTenkenIndexParams | null => {
      if (!refSearchCommon.value) { return null; }
      const { ts_from: dtFrom, ts_to: dtTo, ...other } = refSearchCommon.value.getSearchParams();
      return {
        dt_from: dtFrom,
        dt_to: dtTo,
        road_names: other.road_name_disps,
        directions: other.directions,
      };
    };
    const onChange = () => {
      emit('change');
    };

    return {
      ...toRefs(state),
      // refs
      refSearchCommon,
      // methods
      getSearchParams,
      onChange,
    };
  },
  components: { GeoItemSearchCommon },
});
