// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import router from '@/router';
import store from '@/store';
import rafFuncs from '@/lib/requestAnimationFrame';
import App from '@/App.vue';

import VueTooltip from 'v-tooltip';
import VueShortkey from 'vue-shortkey';
import VueNotify from 'vue-notifyjs';
import { Multipane, MultipaneResizer } from '@/components/lib/multipane';
import MyCalendar from '@/components/lib/MyCalendar.vue';
import ExtremeMap from '@/components/lib/ExtremeMap/index.vue';
import Modal from '@/components/lib/Modal.vue';
import ModalImageView from '@/components/lib/ModalImageView.vue';
import TagModal from '@/components/lib/TagModal.vue';
import LoadWaitSpinner from '@/components/lib/LoadWaitSpinner.vue';
import Pagination from '@/components/lib/Pagination.vue';
import MoviePlayer from '@/components/lib/MoviePlayer/index.vue';
import MoviePlayerCompareMode from '@/components/lib/MoviePlayerCompareMode/index.vue';
import AdminListTable from '@/components/lib/AdminListTable.vue';
import VueCompositionApi from '@vue/composition-api';

Vue.config.productionTip = false;
Vue.use(VueTooltip);
Vue.use(VueShortkey, { prevent: ['input[type="text"]', 'textarea'] });
Vue.use(VueNotify);
Vue.use(VueCompositionApi);

// requestAnimationFrame funcs
window['requestAnimFrame'] = rafFuncs.requestAnimFrame;
window['requestInterval'] = rafFuncs.requestInterval;
window['clearRequestInterval'] = rafFuncs.clearRequestInterval;
window['requestTimeout'] = rafFuncs.requestTimeout;

Vue.component('multipane', Multipane);
Vue.component('multipane-resizer', MultipaneResizer);
Vue.component('my-calendar', MyCalendar);
Vue.component('extreme-map', ExtremeMap);
Vue.component('modal', Modal);
Vue.component('modal-image-view', ModalImageView);
Vue.component('tag-modal', TagModal);
Vue.component('load-wait-spinner', LoadWaitSpinner);
Vue.component('pagination', Pagination);
Vue.component('movie-player', MoviePlayer);
Vue.component('movie-player-compare-mode', MoviePlayerCompareMode);
Vue.component('admin-list-table', AdminListTable);

new Vue({
  router,
  store,
  components: { App },
  render: h => h(App),
}).$mount('#app');
