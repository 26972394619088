


import movieGroupApi from '@/apis/movieGroup';
import {
  defineComponent,
  computed,
  onMounted,
  reactive,
  toRefs,
} from '@vue/composition-api';
import { useStore } from '@/hooks/useStore';
import { AdminMovieGroup, AdminMovieTag } from '@/models/apis/movieGroup/adminMovieGroupResponse';
import { FullScreenSpinnerActionTypes } from '@/store/modules/fullScreenSpinner';
import {
  getAggregatedMovieTagsFromMovieLists,
  getAggregatedMovieTagsFromMovieList,
  MovieTagMap,
  ResultTag,
} from '@/lib/tagModalHelper';
import { PageItem } from '@/models';

interface SearchParams {
  dt_from: Date;
  dt_to: Date;
  device_id_or_car_name: string | null;
}
type FilteredMovieGroup = AdminMovieGroup & {
  checked?: boolean;
};
interface MovieTagModal {
  show: boolean;
  movieGroups: AdminMovieGroup[];
  tags: MovieTagMap[];
}
type AdminMovieGroupEdit = Omit<AdminMovieGroup, 'id'> & {
  id: number | null;
};

interface Pagination {
  currentPage: number;
  itemsPerPage: number;
}

interface MovieGroupState {
  showDestroyModal: boolean;
  showCollectionDestroyModal: boolean;
  showErrorModal: boolean;
  showSearchErrorModal: boolean;
  filteredMovieGroups: FilteredMovieGroup[];
  paginatedMovieGroups: AdminMovieGroup[];
  search: SearchParams;
  editting: Partial<AdminMovieGroupEdit>;
  allSelected: boolean;
  selectedNum: number;
  editMovieTagModal: MovieTagModal;
  pager: Pagination;
  hasMaxSearchResults: boolean;
}

export default defineComponent({
  name: 'admin-movie-groups',
  setup() {
    const now = new Date();
    const initSearchState = (): SearchParams => {
      return {
        // 1週間前から今日
        dt_from: new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7),
        dt_to: new Date(now.getFullYear(), now.getMonth(), now.getDate()),
        device_id_or_car_name: null,
      };
    };
    const initEdittingState = (): Partial<AdminMovieGroupEdit> => {
      return {
        id: null,
      };
    };
    const initEditMovieTagModalState = (): MovieTagModal => {
      return {
        show: false,
        movieGroups: [],
        tags: [],
      };
    };
    const initPagerState = (): Pagination => {
      return {
        currentPage: 1,
        itemsPerPage: 50,
      };
    };
    const state = reactive<MovieGroupState>({
      showDestroyModal: false,
      showCollectionDestroyModal: false,
      showErrorModal: false,
      showSearchErrorModal: false,
      filteredMovieGroups: [],
      paginatedMovieGroups: [],
      search: initSearchState(),
      editting: initEdittingState(),
      allSelected: false,
      selectedNum: 0,
      editMovieTagModal: initEditMovieTagModalState(),
      pager: initPagerState(),
      hasMaxSearchResults: false,
    });

    const collectionDestroyModalMsg = computed<string>(() => {
      return state.selectedNum.toString() + '件を一括削除してもよろしいですか？';
    });
    const isValidSearchParams = computed<boolean>(() => {
      const params = state.search;
      return params.dt_from instanceof Date && params.dt_to instanceof Date;
    });
    const longerThanPeriodLimit = computed<boolean>(() => {
      return state.search.dt_to.getTime() - state.search.dt_from.getTime() > 31 * 24 * 60 * 60 * 1000;
    });
    const maxSearchResultLimit = 1000;
    const doSearch = () => {
      if (!isValidSearchParams.value) { return; }
      if (longerThanPeriodLimit.value) {
        state.showSearchErrorModal = true;
        return;
      }
      resetSearchResults();

      const params = state.search;
      const reqParams = {
        from_ts: params.dt_from,
        to_ts: new Date(params.dt_to.valueOf() + 86400 * 1000),
      };

      movieGroupApi.adminIndex(reqParams)
        .then(({ data }) => {
          if (!data || data.length === 0) { return; }
          if (data.length === maxSearchResultLimit) {
            state.hasMaxSearchResults = true;
          }
          filterSearch(data);
          filterByPage();
          recalcSelectedNum();
        });
    };

    onMounted(doSearch);

    const filterSearch = (movieGroupsOrig: AdminMovieGroup[]) => {
      state.filteredMovieGroups = movieGroupsOrig.filter(movieGroup => {
        if (state.search.device_id_or_car_name != null &&
          state.search.device_id_or_car_name !== '' &&
          movieGroup.device_id.toString().indexOf(state.search.device_id_or_car_name) < 0 &&
          movieGroup.car_name.indexOf(state.search.device_id_or_car_name) < 0) {
          return false;
        }
        return true;
      });
      state.allSelected = false;
    };
    const filterByPage = () => {
      const nextPageIdx = state.pager.currentPage * state.pager.itemsPerPage;
      const minIdx = nextPageIdx - state.pager.itemsPerPage;
      state.paginatedMovieGroups = state.filteredMovieGroups.filter((e, i) => {
        return i >= minIdx && i < nextPageIdx;
      });
    };
    const resetSearchResults = () => {
      state.filteredMovieGroups = [];
      state.paginatedMovieGroups = [];
      state.pager.currentPage = 1;
      state.hasMaxSearchResults = false;
    };
    const onPageClick = (pageItem: PageItem) => {
      state.pager.currentPage = pageItem.pageNum;
      filterByPage();
    };
    const recalcSelectedNum = () => {
      state.selectedNum = state.filteredMovieGroups.filter((g) => {
        return g.checked;
      }).length;
    };
    const allSelect = () => {
      for (let i = 0; i < state.filteredMovieGroups.length; i++) {
        state.filteredMovieGroups[i].checked = true;
      }
      recalcSelectedNum();
    };
    const allDeselect = () => {
      for (let i = 0; i < state.filteredMovieGroups.length; i++) {
        state.filteredMovieGroups[i].checked = false;
      }
      recalcSelectedNum();
    };
    const allSelectBtn = () => {
      if (state.allSelected) {
        allSelect();
      } else {
        allDeselect();
      }
    };
    const collectionDestroy = () => {
      recalcSelectedNum();
      state.showCollectionDestroyModal = true;
    };
    const collectionDestroySave = () => {
      const ids = state.filteredMovieGroups.filter((g) => {
        return g.checked;
      }).map((g) => {
        return g.id;
      });
      movieGroupApi.adminCollectionDestroy({
        ids: ids,
      }).then(() => {
        state.showCollectionDestroyModal = false;
        doSearch();
      }).catch((err) => {
        console.log(err);
        state.showCollectionDestroyModal = false;
        state.showErrorModal = true;
      });
    };
    const tryDestroy = (obj: AdminMovieGroup) => {
      state.editting = obj;
      state.showDestroyModal = true;
    };
    const doDestroy = () => {
      if (state.editting.id == null) { return; }
      movieGroupApi.adminDestroy(
        state.editting.id,
      ).then(() => {
        state.showDestroyModal = false;
        doSearch();
      }).catch((err) => {
        console.log(err);
        state.showDestroyModal = false;
        state.showErrorModal = true;
      });
    };
    const movieTagsOfMovieGroup = (mg: AdminMovieGroup): AdminMovieTag[] => {
      const movieTagMap = mg.movies.reduce((acc: { [key:number] : AdminMovieTag }, movie) => {
        movie.tags.forEach(tag => { acc[tag.id] = tag; }); return acc;
      }, {});
      return Object.values(movieTagMap);
    };
    const bulkEditMovieTag = async() => {
      const mgs = state.filteredMovieGroups.filter(g => g.checked);
      state.editMovieTagModal.movieGroups = mgs;
      state.editMovieTagModal.tags =
        getAggregatedMovieTagsFromMovieLists(mgs);
      state.editMovieTagModal.show = true;
    };
    const editMovieTag = async(obj: AdminMovieGroup) => {
      state.editMovieTagModal.movieGroups = [obj];
      state.editMovieTagModal.tags =
        getAggregatedMovieTagsFromMovieList(obj);
      state.editMovieTagModal.show = true;
    };
    const store = useStore();
    const updateMovieTags = async(resultTags: ResultTag[]) => {
      await store.dispatch(FullScreenSpinnerActionTypes.NOTIFY_SHOW, { message: '保存しています...' });
      const movieGroups = state.editMovieTagModal.movieGroups;
      const promises = movieGroups.map((mg, idx) => {
        const movieInfos = mg.movies.map(m => {
          const tagIds = resultTags.filter(resTag => {
            return !resTag.isPartiallySelected || m.tags.find(e => e.id === resTag.id);
          }).map(e => e.id);
          return { movie_id: m.id, tag_ids: tagIds };
        });
        return movieGroupApi.adminUpdateMovieTags(mg.id, { movie_infos: movieInfos })
          .then(({ data }) => {
            movieGroups[idx].movies = data.movies;
          });
      });
      await Promise.all(promises);
      await store.dispatch(FullScreenSpinnerActionTypes.NOTIFY_HIDE);
      state.editMovieTagModal.show = false;
    };

    const destroyModalTitle = '削除';
    const destroyModalMsg = 'を削除してもよろしいですか？';
    const collectionDestroyModalTitle = '一括削除';
    const errorModalTitle = 'エラー';
    const errorModalMsg = '不明なエラーです。ネットワークの状態をご確認いただき再度お試し下さい。';
    const searchErrorModalMsg = '検索期間は31日以内に設定して下さい';

    return {
      ...toRefs(state),
      // computed
      collectionDestroyModalMsg,
      isValidSearchParams,
      longerThanPeriodLimit,
      // methods
      doSearch,
      filterSearch,
      filterByPage,
      resetSearchResults,
      onPageClick,
      recalcSelectedNum,
      allSelect,
      allDeselect,
      allSelectBtn,
      collectionDestroy,
      collectionDestroySave,
      tryDestroy,
      doDestroy,
      movieTagsOfMovieGroup,
      bulkEditMovieTag,
      editMovieTag,
      updateMovieTags,
      // others
      maxSearchResultLimit,
      destroyModalTitle,
      destroyModalMsg,
      collectionDestroyModalTitle,
      errorModalTitle,
      errorModalMsg,
      searchErrorModalMsg,
    };
  },
});
