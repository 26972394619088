


import group2Api from '@/apis/group2';
import group3Api from '@/apis/group3';
import { AdminListTableField } from '@/components/lib/AdminListTable.vue';
import { AdminGroup2 } from '@/models/apis/group2/adminGroup2Response';
import { AdminGroup3 } from '@/models/apis/group3/adminGroup3Response';
import {
  defineComponent,
  computed,
  onMounted,
  reactive,
  toRefs,
} from '@vue/composition-api';
import { getErrorMessages } from '@/lib/errMsgHelper';
import { AxiosError } from 'axios';

interface AdminGroup3Create {
  name: AdminGroup3['name'];
  disp_order: AdminGroup3['disp_order'];
}

interface AdminGroup3Edit extends AdminGroup3Create {
  parent_id: AdminGroup3['parent_id'];
}

type Group3Disp = AdminGroup3 & {
  g2Name: string;
};

interface SearchParam {
  parent_id: number | null;
}

interface AdminGroup3State {
  showEditModal: boolean;
  editMode: 'create' | 'edit';
  showDestroyModal: boolean;
  showErrorModal: boolean;
  errorModalMsg: string;
  groups3Orig: AdminGroup3[];
  filteredGroups3: AdminGroup3[];
  groups2: AdminGroup2[];
  search: SearchParam;
  editting: AdminGroup3Edit | AdminGroup3 | AdminGroup3Create;
  httpErrorMsgs: string[];
}

export default defineComponent({
  name: 'admin-groups3',
  setup() {
    const initSearchState = (): SearchParam => {
      return {
        parent_id: null,
      };
    };
    const initEdittingState = (): AdminGroup3Edit => {
      return {
        parent_id: null,
        name: '',
        disp_order: 0,
      };
    };
    const state = reactive<AdminGroup3State>({
      showEditModal: false,
      editMode: 'edit',
      showDestroyModal: false,
      showErrorModal: false,
      errorModalMsg: '不明なエラーです。ネットワークの状態をご確認いただき再度お試し下さい。',
      groups3Orig: [],
      filteredGroups3: [],
      groups2: [],
      search: initSearchState(),
      editting: initEdittingState(),
      httpErrorMsgs: [],
    });

    const validateEditting = computed<Array<string>>(() => {
      let errorMsgs = [];
      if (!Number.isInteger((state.editting as AdminGroup3).parent_id)) {
        errorMsgs.push('事業所は選択必須です');
      }
      if (state.editting.name.length <= 0) {
        errorMsgs.push('部署名は必須です');
      }
      if (state.editting.disp_order && !/^\d+$/.test(state.editting.disp_order.toString())) {
        errorMsgs.push('表示順は数値で入力して下さい');
      }
      return errorMsgs;
    });

    const editModalTitle = computed<string>(() => {
      return state.editMode === 'create' ? '新規作成' : '編集';
    });
    const displayData = computed<Group3Disp[]>(() => {
      return state.filteredGroups3.map(g3 => {
        return {
          ...g3,
          g2Name: getGroup2Name(g3.parent_id),
        };
      });
    });
    const getGroup2Name = (group2Id: number | null): string => {
      for (const group2 of state.groups2) {
        if (group2.id === group2Id) {
          return group2.name;
        }
      }
      return '-';
    };

    const doSearch = () => {
      resetSearchResults();
      group3Api.adminIndex()
        .then(({ data }) => {
          if (!data || data.length === 0) { return; }
          state.groups3Orig = data;
          filterSearch();
        });
    };
    const filterSearch = () => {
      state.filteredGroups3 = state.groups3Orig.filter(group3 => {
        if (state.search.parent_id == null || state.search.parent_id.toString() === '') { return true; }
        return group3.parent_id === state.search.parent_id;
      });
    };
    const resetSearchResults = () => {
      state.filteredGroups3 = [];
    };

    onMounted(() => {
      group2Api.adminIndex()
        .then(({ data }) => {
          state.groups2 = data;
          doSearch();
        });
    });
    const edit = (obj: AdminGroup3) => {
      state.editting = {
        id: obj.id,
        name: obj.name,
        parent_id: obj.parent_id,
        disp_order: obj.disp_order,
      };
      state.httpErrorMsgs = [];
      state.showEditModal = true;
      state.editMode = 'edit';
    };

    const create = () => {
      let maxDispOrder = 0;
      state.groups3Orig.forEach(e => {
        if (e.disp_order && !isNaN(e.disp_order) && maxDispOrder < e.disp_order) {
          maxDispOrder = e.disp_order;
        }
      });

      state.editting = {
        name: '',
        disp_order: maxDispOrder + 1,
      };
      state.httpErrorMsgs = [];
      state.showEditModal = true;
      state.editMode = 'create';
    };

    const save = async() => {
      try {
        if (state.editMode === 'edit') {
          await group3Api.adminUpdate((state.editting as AdminGroup3).id, {
            name: state.editting.name,
            parent_id: (state.editting as AdminGroup3).parent_id,
            disp_order: Number(state.editting.disp_order),
          });
        } else {
          await group3Api.adminCreate(state.editting as AdminGroup3Edit);
        }
        state.httpErrorMsgs = [];
        state.showEditModal = false;
        doSearch();
      } catch (err) {
        state.httpErrorMsgs = getErrorMessages(err as AxiosError);
      }
    };

    const tryDestroy = (obj: AdminGroup3) => {
      state.editting = obj;
      state.showDestroyModal = true;
    };
    const doDestroy = () => {
      group3Api.adminDestroy(
        (state.editting as AdminGroup3).id,
      ).then(() => {
        state.showDestroyModal = false;
        doSearch();
      }).catch((err) => {
        const errMsgs = getErrorMessages(err as AxiosError);
        if (errMsgs.includes('has users')) {
          state.errorModalMsg = '1名以上のユーザーが所属しているので削除できません。';
        } else if (errMsgs.includes('has devices')) {
          state.errorModalMsg = '1件以上の車載器が所属しているので削除できません。';
        } else {
          state.errorModalMsg = '不明なエラーです。ネットワークの状態をご確認いただき再度お試し下さい。';
        }
        state.showDestroyModal = false;
        state.showErrorModal = true;
      });
    };

    const destroyModalTitle = '削除';
    const destroyModalMsg = 'を削除してもよろしいですか？';
    const errorModalTitle = 'エラー';
    const fields: AdminListTableField[] = [
      { name: 'g2Name', label: '事業所名' },
      { name: 'name', label: '部署名' },
      { name: 'disp_order', label: '表示順' },
    ];

    return {
      ...toRefs(state),
      // computed
      validateEditting,
      editModalTitle,
      displayData,
      // methods
      getGroup2Name,
      doSearch,
      filterSearch,
      resetSearchResults,
      edit,
      create,
      save,
      tryDestroy,
      doDestroy,
      // others
      destroyModalTitle,
      destroyModalMsg,
      errorModalTitle,
      fields,
    };
  },
});
