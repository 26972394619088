import Vue from 'vue';

export interface NotifyOptions {
  type?: 'info' | 'warning' | 'danger' | 'success' | 'primary';
  timeout?: number;
  title?: string;
  icon?: string;
  verticalAlign?: 'top' | 'bottom';
  horizontalAlign?: 'left' | 'right' | 'center';
}

interface NotifyProps extends NotifyOptions {
  message: string;
}

export function doNotify(msg: string, opts: NotifyOptions = {}): void {
  const notifyObj: NotifyProps = {
    message: msg,
    verticalAlign: opts.verticalAlign || 'top',
    horizontalAlign: opts.horizontalAlign || 'center',
    type: opts.type || 'info',
    timeout: opts.timeout || 5 * 1000,
  };
  if (opts.title) { notifyObj.title = opts.title; }
  if (opts.icon) { notifyObj.icon = opts.icon; }

  Vue.prototype.$notify(notifyObj);
}
export function notifyWarning1(msg: string, opts: NotifyOptions = {}): void {
  opts.type = opts.type || 'warning';
  opts.timeout = opts.timeout || 5 * 1000;
  opts.icon = opts.icon || 'fas fa-exclamation-triangle';
  doNotify(msg, opts);
}
