import Vue from 'vue';
import Router from 'vue-router';

import Login from '@/components/Login.vue';
import Top from '@/components/Top/index.vue';

import AdminTop from '@/components/Admin/Top/index.vue';
import AdminUsers from '@/components/Admin/Users/index.vue';
import AdminComments from '@/components/Admin/Comments/index.vue';
import AdminDevices from '@/components/Admin/Devices/index.vue';
import AdminGroups2 from '@/components/Admin/Groups2/index.vue';
import AdminGroups3 from '@/components/Admin/Groups3/index.vue';
import AdminTags from '@/components/Admin/Tags/index.vue';
import AdminMovieGroups from '@/components/Admin/MovieGroups/index.vue';
import AdminCommentTypes from '@/components/Admin/CommentTypes/index.vue';

import DevDebugTop from '@/components/DevDebug/Top/index.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  scrollBehavior(/* to, from, savedPosition */) {
    return {x: 0, y: 0};
  },
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: Login,
    },
    {
      path: '/',
      name: 'Top',
      component: Top,
    },
    {
      path: '/admin',
      name: 'AdminTop',
      component: AdminTop,
      children: [
        {
          path: '/admin/users',
          name: 'AdminUsers',
          component: AdminUsers,
        },
        {
          path: '/admin/comments',
          name: 'AdminComments',
          component: AdminComments,
        },
        {
          path: '/admin/devices',
          name: 'AdminDevices',
          component: AdminDevices,
        },
        {
          path: '/admin/groups2',
          name: 'AdminGroups2',
          component: AdminGroups2,
        },
        {
          path: '/admin/groups3',
          name: 'AdminGroups3',
          component: AdminGroups3,
        },
        {
          path: '/admin/movie_groups',
          name: 'AdminMovieGroups',
          component: AdminMovieGroups,
        },
        {
          path: '/admin/tags',
          name: 'AdminTags',
          component: AdminTags,
        },
        {
          path: '/admin/comment_types',
          name: 'AdminCommentTypes',
          component: AdminCommentTypes,
        },
      ],
    },
    {
      path: '/dev-debug',
      name: 'DevDebugTop',
      component: DevDebugTop,
    },
    {
      path: '/dev-debug/ui-showcase',
      name: 'DevDebugUIShowcase',
      component: require('@/components/DevDebug/UIShowcase').default,
    },
    // catch all
    {
      path: '*',
      redirect: 'login',
    },
  ],
});
