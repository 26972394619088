
import analyticsApi from '@/apis/analytics';

export async function logMoviePlay(playerType: string): Promise<void> {
  try {
    const params = {
      player_type: playerType,
    };
    await analyticsApi.logMoviePlay(params);
  } catch (e) {
    console.warn('logMoviePlay failed');
    console.warn(e);
  }
}

export async function logMoviePlaySeconds(playerType: string, seconds: number): Promise<void> {
  try {
    const params = {
      player_type: playerType,
      play_seconds: seconds,
    };
    await analyticsApi.logMoviePlaySeconds(params);
  } catch (e) {
    console.warn('logMoviePlaySeconds failed');
    console.warn(e);
  }
}

export async function logTagMovieAdd(numMovies: number): Promise<void> {
  try {
    const params = {
      num_movies: numMovies,
    };
    await analyticsApi.logTagMovieAdd(params);
  } catch (e) {
    console.warn('logTagMovieAdd failed');
    console.warn(e);
  }
}

export async function logTagMovieDelete(numMovies: number): Promise<void> {
  try {
    const params = {
      num_movies: numMovies,
    };
    await analyticsApi.logTagMovieDelete(params);
  } catch (e) {
    console.warn('logTagMovieDelete failed');
    console.warn(e);
  }
}
