import axios, { AxiosResponse } from 'axios';
import * as utils from './apiUtils';
import { CarIndexParams } from 'src/models/apis/cars/carRequest';
import { CarIndexResponse } from 'src/models/apis/cars/carResponse';

export default {
  index(params: CarIndexParams): Promise<AxiosResponse<CarIndexResponse>> {
    return axios.get(utils.getApiUrl('/cars'), {
      params: params || {},
    });
  },
};
