
















































































import extLandAiDetectionsApi from '@/apis/extLandAiDetections';
import {
  defineComponent,
  onMounted,
  watch,
  reactive,
  toRefs,
  computed,
  PropType,
  toRef,
} from '@vue/composition-api';
import { MapElemInfo } from '@/models';
import { validateAngle } from '@/lib/commentHelper';
import useSwitchGeoItem from '@/composables/useSwitchGeoItem';
import useSavedImage from '@/composables/useSavedImage';
import { dtFormat } from '@/lib/dateHelper';
import { lineBreakToBR, splitByLineBreak } from '@/lib/utils';
import { GIDetection } from '@/models/geoItem';

interface MapElemInfoLandAiDetectionsState {
  detection: GIDetection;
}

export default defineComponent({
  name: 'map-elem-info-land-ai-detections',
  props: {
    elem: {
      type: Object as PropType<MapElemInfo<GIDetection>>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const state = reactive<MapElemInfoLandAiDetectionsState>({
      detection: props.elem.data,
    });
    const { elem } = toRefs(props);
    const { showPrevious, showNext } = useSwitchGeoItem(emit, elem);

    const modalTitle = computed<string>(() => `${state.detection.dateDisp} - ${kpDisp.value}`);
    const downloadFilenameTpl = computed<string>(() => {
      const detectionsTs = dtFormat(new Date(state.detection.img_ts), 'yyyymmddHHMMSS');
      return `${state.detection.detectionKindDisp}_${detectionsTs}_$TIMESTAMP.jpg`;
    });
    const skipFetchingImage = computed<boolean>(() => {
      return !state.detection?.img_path;
    });
    const params = {
      refMapElemInfoData: toRef(state, 'detection'),
      emit,
      skipFetchingImage,
      fetchApiFn: () => {
        return extLandAiDetectionsApi.getImage({
          img_path: state.detection.img_path,
        });
      },
      modalTitle,
      downloadFilenameTpl,
    };
    const { savedImage, showImage } = useSavedImage(params);

    const initDisplay = () => {
      state.detection = props.elem.data;
      state.detection.dateDisp = dtFormat(new Date(state.detection.img_ts));
    };
    onMounted(initDisplay);
    watch(() => props.elem, initDisplay);

    const getLocationDispOfKp = (): string => {
      // road_name_dispが空だったら「場所なし」と表示する
      const roadNameDisp = state.detection.road_name_disp ? state.detection.road_name_disp : '場所なし';
      // 全角のカッコが割と場所取るので半角に置換
      const placeName = !state.detection.place_name || state.detection.place_name === 'main_line'
        ? ''
        : state.detection.place_name
          .replace(/（/g, '(')
          .replace(/）/g, ')');
      // 小数第二位まで考慮
      const kpDisp = state.detection.kp
        ? `${parseFloat(state.detection.kp).toFixed(2).replace(/0$/, '')}km`
        : '';
      return [
        roadNameDisp,
        state.detection.direction,
        placeName,
        kpDisp,
      ].filter(e => !!e).join(' ');
    };
    const kpDisp = computed<string>(getLocationDispOfKp);
    const commentDisp = computed<string>(() => {
      const lineBreakComment = splitByLineBreak(state.detection.comment).join('\n');
      return lineBreakToBR(lineBreakComment);
    });
    const iconPath = `/static/img/land_ai_detection_${state.detection.detection_kind}.png`;
    const hasValidAngle = computed(() => {
      return validateAngle(state.detection.angle);
    });
    const detectionsLink = computed<string>(() => {
      if (state.detection.road_name_disp) {
        return state.detection.detections_link;
      }
      // 場所なしの場合、遷移先に場所なしも表示するため、without_landmarkをつける
      return `${state.detection.detections_link}&without_landmark`;
    });
    const isJointDamage = computed(() => {
      return state.detection.detection_kind === 'joint_damage';
    });
    const isHosouDamage = computed(() => {
      return !isJointDamage;
    });

    const openDetection = () => {
      window.open(detectionsLink.value, '_blank');
    };
    const doAreaMovieSearch = () => {
      emit('area-movie-search', {
        lat: props.elem.data.lat,
        lon: props.elem.data.lon,
        radius: 500,
      });
    };

    return {
      ...toRefs(state),
      savedImage,
      kpDisp,
      commentDisp,
      iconPath,
      hasValidAngle,
      detectionsLink,
      isJointDamage,
      isHosouDamage,
      showImage,
      showPrevious,
      showNext,
      openDetection,
      doAreaMovieSearch,
    };
  },
});
