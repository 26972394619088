


import deviceApi from '@/apis/device';
import group2Api from '@/apis/group2';
import group3Api from '@/apis/group3';
import {
  defineComponent,
  computed,
  onMounted,
  reactive,
  toRefs,
} from '@vue/composition-api';
import { Device } from '@/models/apis/device/deviceResponse';
import { AdminGroup2 } from '@/models/apis/group2/adminGroup2Response';
import { AdminGroup3 } from '@/models/apis/group3/adminGroup3Response';
import { Lov } from '@/models/apis/master/masterResponse';
import { getErrorMessages } from '@/lib/errMsgHelper';
import { AxiosError } from 'axios';
import { waitForMasters } from '@/lib/masterHelper';
import { lineBreakToBR, splitByLineBreak } from '@/lib/utils';
import { dtFormat } from '@/lib/dateHelper';

interface AdminDeviceEdit {
  id: number;
  device_id: string;
  car_name: string;
  car_kind: string | null;
  group2_id: number | null;
  group_id: number | null;
  stopped_use_on: Date | null;
  bikou1: string | null;
}
type DisplayData = Device & {
  gName: string;
};
type FilteredDevice = Device & {
  carKindDisp?: string;
  bikou1Disp?: string;
  stoppedUseOnDisp?: string;
};

interface SearchParams {
  device_id: string;
  car_name: string;
  car_kind: string;
  used_status: string;
}

interface AdminDeviceState {
  showEditModal: boolean;
  editMode: 'create' | 'edit';
  showDestroyModal: boolean;
  showErrorModal: boolean;
  lovs: {
    carKinds: Lov[];
  };
  search: SearchParams;
  filteredDevices: Device[];
  groups2: AdminGroup2[];
  groups3: AdminGroup3[];
  editting: AdminDeviceEdit;
  httpErrorMsgs: string[];
}

export default defineComponent({
  name: 'admin-devices',
  setup() {
    const initSearchState = (): SearchParams => {
      return {
        device_id: '',
        car_name: '',
        car_kind: '',
        used_status: '',
      };
    };
    const initEdittingState = (): AdminDeviceEdit => {
      return {
        id: 0,
        device_id: '',
        car_name: '',
        car_kind: null,
        group2_id: null,
        group_id: null,
        stopped_use_on: null,
        bikou1: '',
      };
    };
    const initLovsState = () => {
      return {
        carKinds: [],
      };
    };
    const state = reactive<AdminDeviceState>({
      showEditModal: false,
      editMode: 'edit',
      showDestroyModal: false,
      showErrorModal: false,
      lovs: initLovsState(),
      search: initSearchState(),
      filteredDevices: [],
      groups2: [],
      groups3: [],
      editting: initEdittingState(),
      httpErrorMsgs: [],
    });

    const validateEditting = computed<string[]>(() => {
      let errorMsgs = [];
      if (!Number.isInteger(state.editting.group2_id)) {
        errorMsgs.push('事業所は選択必須です');
      }
      if (!Number.isInteger(state.editting.group_id)) {
        errorMsgs.push('部署は選択必須です');
      }
      if (state.editting.device_id.length <= 0) {
        errorMsgs.push('車載器IDは必須です');
      }
      if (state.editting.device_id.length > 32) {
        errorMsgs.push('車載器IDは32文字以内で入力してください');
      }
      if (state.editting.car_name.length <= 0) {
        errorMsgs.push('車両番号は必須です');
      }
      if (!state.editting.car_kind) {
        errorMsgs.push('車種は選択必須です');
      }
      if (state.editting.bikou1 && state.editting.bikou1.length > 250) {
        errorMsgs.push('備考は250文字以内で入力してください');
      }
      return errorMsgs;
    });
    const selectedEditModalGroup2 = computed<boolean>(() => {
      return state.editting.group2_id != null && state.editting.group2_id.toString() !== '';
    });
    const editModalTitle = computed(() => {
      if (state.editMode === 'create') {
        return '新規作成';
      } else {
        return '編集';
      }
    });
    const displayData = computed<DisplayData[]>(() => {
      return state.filteredDevices.map(device => {
        return {
          ...device,
          gName: lineBreakToBR(`${device.g2name}\n${device.g3name}`),
        };
      });
    });
    const isDeviceIdInputReadonly = computed(() => {
      return state.editMode === 'edit';
    });

    const convData = (arr: FilteredDevice[]): FilteredDevice[] => {
      const carKindMap = window.master.lovs.car_kind.map;
      arr.forEach(e => {
        e.carKindDisp = (carKindMap[e.car_kind] || {}).val;
        e.bikou1 = splitByLineBreak(e.bikou1).join('\n');
        e.bikou1Disp = lineBreakToBR(e.bikou1);
        e.stoppedUseOnDisp = dtFormat(e.stopped_use_on, 'yyyy/mm/dd');
      });
      return arr;
    };
    const filterSearch = (devicesOrig: Device[]) => {
      const qDeviceId = state.search.device_id;
      const qCarName = state.search.car_name;
      const qCarKind = state.search.car_kind;
      const qUsedStatus = parseInt(state.search.used_status);
      state.filteredDevices = devicesOrig.filter(e => {
        if (qDeviceId) {
          if (e.device_id.indexOf(qDeviceId) === -1) { return false; }
        }
        if (qCarName) {
          if (e.car_name.indexOf(qCarName) === -1) { return false; }
        }
        if (qCarKind) {
          if (e.car_kind.indexOf(qCarKind) === -1) { return false; }
        }
        if (!isNaN(qUsedStatus)) {
          if (qUsedStatus === 1) {
            // 利用中のみ抽出するので、停止されてたらはじく.
            if (e.stopped_use_on) { return false; }
          }
          if (qUsedStatus === 0) {
            // 利用停止中のみ抽出するので、停止してなかったらはじく.
            if (!e.stopped_use_on) { return false; }
          }
        }

        return true;
      });
    };
    const resetSearchResults = () => {
      state.filteredDevices = [];
    };
    const doSearch = () => {
      resetSearchResults();
      deviceApi.adminIndex()
        .then(waitForMasters)
        .then(({ data }) => {
          if (!data || data.length === 0) { return; }
          data = convData(data);
          filterSearch(data);
        });
    };
    const create = () => {
      state.editting = {
        id: 0,
        device_id: '',
        car_name: '',
        car_kind: '',
        group2_id: null,
        group_id: null,
        stopped_use_on: null,
        bikou1: '',
      };
      state.httpErrorMsgs = [];
      state.showEditModal = true;
      state.editMode = 'create';
    };
    onMounted(() => {
      window.master.$promise.then(() => {
        state.lovs.carKinds = window.master.lovs.car_kind.vals;
      });
      group2Api.adminIndex().then(({ data }) => {
        state.groups2 = data;
      });
      group3Api.adminIndex().then(({ data }) => {
        state.groups3 = data;
      });
      doSearch();
    });
    const filterGroup3 = (groups3: AdminGroup3[], group2Id: number): AdminGroup3[] => {
      return groups3.filter((group3) => {
        return group3.parent_id === group2Id;
      });
    };
    const edit = (obj: Device) => {
      state.editting = {
        id: obj.id,
        device_id: obj.device_id,
        car_name: obj.car_name,
        car_kind: obj.car_kind,
        group2_id: obj.g2id,
        group_id: obj.g3id,
        stopped_use_on: obj.stopped_use_on ? new Date(obj.stopped_use_on) : null,
        bikou1: obj.bikou1,
      };
      state.httpErrorMsgs = [];
      state.showEditModal = true;
      state.editMode = 'edit';
    };
    const tryDestroy = (obj: Device) => {
      state.editting = {
        id: obj.id,
        device_id: obj.device_id,
        car_name: obj.car_name,
        car_kind: obj.car_kind,
        group2_id: obj.g2id,
        group_id: obj.g3id,
        stopped_use_on: obj.stopped_use_on ? new Date(obj.stopped_use_on) : null,
        bikou1: obj.bikou1,
      };
      state.showDestroyModal = true;
    };
    const doDestroy = async() => {
      if (!state.editting.device_id) {
        return;
      }
      try {
        await deviceApi.adminDestroy(state.editting.device_id);
        state.showDestroyModal = false;
        doSearch();
      } catch (err) {
        console.log(err);
        state.showDestroyModal = false;
        state.showErrorModal = true;
      }
    };
    const save = async() => {
      const edittingDevice = {
        device_id: state.editting.device_id,
        car_name: state.editting.car_name,
        car_kind: state.editting.car_kind ? state.editting.car_kind : '',
        group2_id: state.editting.group2_id || 0,
        group_id: state.editting.group_id || 0,
        stopped_use_on: dtFormat(state.editting.stopped_use_on, 'yyyy/mm/dd'),
        bikou1: state.editting.bikou1,
      };
      try {
        if (state.editMode === 'edit') {
          await deviceApi.adminUpdate(state.editting.device_id, edittingDevice);
        } else {
          await deviceApi.adminCreate(edittingDevice);
        }
        state.httpErrorMsgs = [];
        state.showEditModal = false;
        doSearch();
      } catch (err) {
        state.httpErrorMsgs = getErrorMessages(err as AxiosError);
      }
    };

    const destroyModalTitle = '削除';
    const destroyModalMsg = 'を削除してもよろしいですか？';
    const errorModalTitle = 'エラー';
    const errorModalMsg = '不明なエラーです。ネットワークの状態をご確認いただき再度お試し下さい。';
    const fields = [
      { name: 'gName', label: '所属組織', type: 'html', class: 'ws-nowrap' },
      { name: 'device_id', label: '車載器ID', class: 'ws-nowrap' },
      { name: 'car_name', label: '車両番号' },
      { name: 'carKindDisp', label: '車種' },
      { name: 'stoppedUseOnDisp', label: '利用停止日' },
      { name: 'bikou1Disp', label: '備考', type: 'html' },
    ];

    return {
      ...toRefs(state),
      // computed
      validateEditting,
      selectedEditModalGroup2,
      editModalTitle,
      displayData,
      isDeviceIdInputReadonly,
      // methods
      doSearch,
      create,
      convData,
      filterSearch,
      resetSearchResults,
      filterGroup3,
      edit,
      tryDestroy,
      doDestroy,
      save,
      // others
      destroyModalTitle,
      destroyModalMsg,
      errorModalTitle,
      errorModalMsg,
      fields,
    };
  },
});
