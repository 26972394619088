export const emergencyTypeMap = {
  1: {'val': '緊急', disp_order: 1},
  // 2: {'val': '映像要求', disp_order: 2},
  // 0: {'val': 'その他', disp_order: 3},
  3: {'val': 'A', disp_order: 4},
};

export const emergencyTypes = Object.entries(emergencyTypeMap).map(e => {
  return { key: parseInt(e[0]), val: e[1].val };
});
