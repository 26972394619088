import EMAbstractLayerManager, { InitArgs } from '@/lib/extreme_map/EMAbstractLayerManager';
import EMEventNames from '@/consts/extreme_map_event_names';
import { Style, Icon } from 'ol/style';
import { Feature } from 'ol';
import Point from 'ol/geom/Point';
import VectorSource from 'ol/source/Vector';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';
import { GITenkenData } from '@/models/geoItem';
import { NamedVectorLayer } from '@/lib/OlMapWrapper';
import { FeatureLike } from 'ol/Feature';

export default class EMJunkaiTenkenLayerManager extends EMAbstractLayerManager {
  resourceMap: Record<string, GITenkenData>;

  constructor(args: InitArgs) {
    super(args);
    this.resourceMap = {};
    this.emListenEventNames = [EMEventNames.EM_EVENT_CLICK];
  }

  getIconPath_(): { iconPath: string; selectedFramePath: string } {
    const iconPath = '/static/img/junkai_tenken_report_pin.png';
    const selectedFramePath = `/static/img/pin_selected.png`;
    return { iconPath, selectedFramePath };
  }

  getResourceStyles_(tenkenData: GITenkenData): Style[] {
    const ret = [];
    const { iconPath, selectedFramePath } = this.getIconPath_();
    if (tenkenData.isSelected) {
      ret.push(new Style({
        image: new Icon({
          src: selectedFramePath,
          anchor: [0.5, 0.82],
          anchorXUnits: IconAnchorUnits.FRACTION,
          anchorYUnits: IconAnchorUnits.FRACTION,
          scale: 0.30,
          opacity: 1.0,
        }),
      }));
    }
    ret.push(new Style({
      image: new Icon({
        src: iconPath,
        anchor: [0.5, 0.82],
        anchorXUnits: IconAnchorUnits.FRACTION,
        anchorYUnits: IconAnchorUnits.FRACTION,
        scale: 0.30,
      }),
    }));
    return ret;
  }

  getResourceFeatures_(tenkenData: GITenkenData): Feature[] {
    const coord = this.convCoord({
      lon: parseFloat(tenkenData.lon.toString()),
      lat: parseFloat(tenkenData.lat.toString()),
    });
    const feat = new Feature(new Point(coord));
    feat.setId(tenkenData.id);
    feat.setStyle(this.getResourceStyles_(tenkenData));
    return [feat];
  }

  onClickFeature_(targetFeat: FeatureLike): void {
    const targetFeatId = targetFeat.getId();
    if (!targetFeatId || !this.layer) {
      return;
    }
    const targetResource = this.resourceMap[targetFeatId];

    // 地図上の見た目を調整
    const layerSource = this.layer.getSource();
    for (const ent of Object.entries(this.resourceMap)) {
      const tmpResource = ent[1];
      const currentIsSelected = tmpResource.isSelected;
      tmpResource.isSelected =
        tmpResource.id === targetResource.id &&
        !tmpResource.isSelected;
      const hasGeoLocation = tmpResource.lat && tmpResource.lon;
      if (currentIsSelected !== tmpResource.isSelected && hasGeoLocation) {
        const feat = layerSource.getFeatureById(tmpResource.id);
        feat.setStyle(this.getResourceStyles_(tmpResource));
      }
    }

    // イベント発火
    const obj: { dataName: string; data?: GITenkenData } = this.getBaseDataForEmit();
    obj.data = targetResource;
    this.emitter.$emit(EMEventNames.EM_EVENT_CLICK, obj);
  }

  createLayer_(resources: GITenkenData[]): void {
    const feats: Feature[] = [];
    resources.filter(e => e.lat && e.lon).forEach(resource => {
      feats.push(...this.getResourceFeatures_(resource));
    });
    const layer = new NamedVectorLayer(this.dataName, {
      source: new VectorSource({features: feats}),
    });
    this.layer = layer;
    this.layerInfo.onLayerClick = ({ event, feature }) => {
      // 重なってたりする場合はそれぞれ飛んでくるので、一回で止める
      if (!event || event.originalEvent.defaultPrevented) { return; }
      event.preventDefault();
      if (!feature) {
        return;
      }
      this.onClickFeature_(feature);
    };
  }

  getGeoItemByIndexDiff(current: GITenkenData, diff: number): GITenkenData {
    const candidateJunkaiTenken = Object.values(this.resourceMap)
      .sort((a, b) => a.inspectionTs < b.inspectionTs ? -1 : a.inspectionTs > b.inspectionTs ? 1 : 0);
    const currentIdx = candidateJunkaiTenken.indexOf(current);
    // ないはずはないが、念の為救済
    if (currentIdx === -1) { return current; }

    const modBase = candidateJunkaiTenken.length;
    const move = modBase + diff % modBase;
    const targetIdx = (currentIdx + move) % modBase;
    return candidateJunkaiTenken[targetIdx];
  }
}
