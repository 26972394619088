import GIAbstractManager from '@/lib/geo_item/GIAbstractManager';
import carApi from '@/apis/car';
import { Lovs } from 'src/models';
import { GICar } from 'src/models/geoItem';
import { CarIndexParams } from 'src/models/apis/cars/carRequest';
import { Car } from 'src/models/apis/cars/carResponse';

interface InitParams {
  lovs: Lovs;
}

export default class GICarManager extends GIAbstractManager {
  private lovs: Lovs;

  constructor({ lovs }: InitParams) {
    super();
    this.lovs = lovs;
  }

  async getResources(): Promise<GICar[]> {
    // 特に検索条件とかないのでこれでよい
    const params = {};
    return this.getResourcesByParams(params);
  }

  async getResourcesByParams(params: CarIndexParams): Promise<GICar[]> {
    const { data } = await carApi.index(params);
    return this._convData(data);
  }

  _convData(data: Car[]): GICar[] {
    const carKindMap = this.lovs.car_kind.map;
    const results: GICar[] = [];
    data.forEach(e => {
      const carKind = carKindMap[e.device.car_kind] || {};

      results.push({
        ...e,
        device: {
          ...e.device,
          carKindDisp: carKind.val || '',
        },
        isMoving: e.status_disp === '移動中',
        isSelected: false,
        hideCarIcon: false,
      });
    });

    return results;
  }

  mergeStateInfo(newResources: GICar[], currentResources: GICar[]): GICar[] {
    const currentResourceMap = currentResources.reduce<Record<string, GICar>>((acc, e) => {
      acc[e.device_id] = e; return acc;
    }, {});
    newResources.forEach(e => {
      if (currentResourceMap[e.device_id]) {
        e.isSelected = currentResourceMap[e.device_id].isSelected;
        e.hideCarIcon = currentResourceMap[e.device_id].hideCarIcon;
      }
    });
    return newResources;
  }

  async getResourceById(): Promise<any> {
    console.warn('GICarManager#getResourceById is noop.');
  }

  async createResource(): Promise<any> {
    console.warn('GICarManager#createResource is noop.');
  }

  async updateResource(): Promise<any> {
    console.warn('GICarManager#updateResource is noop.');
  }

  async deleteResource(): Promise<any> {
    console.warn('GICarManager#deleteResource is noop.');
  }
}
