import { render, staticRenderFns } from "./MapElemInfoNone.vue?vue&type=template&id=2601055f&scoped=true&"
import script from "./MapElemInfoNone.vue?vue&type=script&lang=ts&"
export * from "./MapElemInfoNone.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2601055f",
  null
  
)

export default component.exports