

































































import tagApi from '@/apis/tag';
import {
  TAG_TYPE1_SYSTEM,
  TAG_TYPE1_USER,
  TAG_NAME_CHOUKI_HOZON,
} from '@/consts/tag';
import {
  defineComponent,
  computed,
  onMounted,
  reactive,
  PropType,
  toRefs,
} from '@vue/composition-api';
import { Tag } from '@/models/apis/tag/tagResponse';
import { TagCreateParams } from '@/models/apis/tag/tagRequest';
import { getErrorMessages } from '@/lib/errMsgHelper';
import { AxiosError } from 'axios';

type TagItem = Tag & {
  count?: number;
  numMovies?: number;
  isSelected?: boolean;
  isPartiallySelected?: boolean;
  isNew?: boolean;
};

type NewTag = TagCreateParams & {
  id: number;
  isNew: boolean;
  isSelected: boolean;
  isPartiallySelected: boolean;
};

interface TagModalState {
  tags: Array<TagItem | NewTag>;
  search: {
    tag_name: string;
  };
  tagNameToAdd: string;
  errorMsgs: string[];
}

const isTagItem = (tag: any): tag is TagItem => {
  return !!tag.tag_type1;
};

export default defineComponent({
  name: 'tag-modal',
  props: {
    value: {
      type: Array as PropType<TagItem[]>,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    allowCreateTag: {
      type: Boolean,
      default: true,
    },
    isChoukiHozonTagDisabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const state = reactive<TagModalState>({
      tags: [],
      search: {
        tag_name: '',
      },
      tagNameToAdd: '',
      errorMsgs: [],
    });
    const getTags = async() => {
      const { data } = await tagApi.index();
      const selectedTagMap =
        props.value.reduce((acc: Record<number, TagItem>, e) => { acc[e.id] = e; return acc; }, {});
      const tagItems = data.map(tag => {
        const selectedTag = selectedTagMap[tag.id];
        if (!selectedTag) { return tag; }
        // Tag型に追加属性を入れるため、型変換
        const tagItem: TagItem = { ...tag };
        tagItem.isSelected = true;
        tagItem.isPartiallySelected = selectedTag.isPartiallySelected;
        return tagItem;
      });
      state.tags = tagItems.slice().sort((a, b) => a.id - b.id);
    };
    onMounted(getTags);
    // eslint-disable-next-line no-extra-parens
    const filteredTags = computed<(TagItem | NewTag)[]>(() => {
      return state.tags.filter(e => new RegExp(state.search.tag_name).test(e.tag_name));
    });
    // eslint-disable-next-line no-extra-parens
    const systemTags = computed<(TagItem | NewTag)[]>(() => {
      return filteredTags.value.filter(e => {
        if (!isTagItem(e)) {
          return false;
        }
        return e.tag_type1 === TAG_TYPE1_SYSTEM;
      });
    });
    // eslint-disable-next-line no-extra-parens
    const userTags = computed<(TagItem | NewTag)[]>(() => {
      return filteredTags.value.filter(e => {
        if (!isTagItem(e)) {
          return e.isNew;
        }
        return e.tag_type1 === TAG_TYPE1_USER;
      });
    });

    const isChoukiHozonTag = (tag: TagItem | NewTag): boolean => {
      return tag.tag_name === TAG_NAME_CHOUKI_HOZON;
    };
    const selectedStatus = (tag: TagItem | NewTag): string => {
      const { count, numMovies } =
        props.value.find(e => e.id === tag.id) || {};
      return `${count}/${numMovies}の動画で選択中`;
    };
    const halfWidthLetterCount = (str: string): number => {
      let ret = 0;
      for (let i = 0, len = str.length; i < len; i++) {
        // 半角は1文字、全角は2文字として計算
        const asciiCode = str.charCodeAt(i);
        ret += asciiCode < 128 ? 1 : 2;
      }
      return ret;
    };

    const isValidTagName = (): boolean => {
      state.errorMsgs = [];
      const tagName = state.tagNameToAdd;

      if (!tagName) {
        state.errorMsgs.push('動画タグは必須です');
      }
      if (halfWidthLetterCount(tagName) > 32) {
        state.errorMsgs.push('動画タグは全角16文字以内で入力してください');
      }
      if (state.tags.find(e => e.tag_name === tagName)) {
        state.errorMsgs.push('その動画タグは既に使用されています');
      }

      return !state.errorMsgs.length;
    };
    const addTag = () => {
      if (!isValidTagName()) { return; }

      const obj: NewTag = {
        id: state.tags[state.tags.length - 1].id + 1,
        tag_name: state.tagNameToAdd,
        isNew: true,
        isSelected: false,
        isPartiallySelected: false,
      };
      state.tags.push(obj);
      state.tagNameToAdd = '';
    };
    const removeTag = (id: number) => {
      const idx = state.tags.findIndex(e => e.id === id);
      state.tags.splice(idx, 1);
    };
    const onClose = async() => {
      const promises: Promise<void>[] = [];
      const reqs: [number, TagItem][] = [];
      state.tags.forEach((tag, i) => {
        if (!tag.isNew) { return; }
        const params = {
          tag_name: tag.tag_name,
        };
        const prms = tagApi.create(params)
          .then(({ data }) => {
            reqs.push([i, data]);
          });
        promises.push(prms);
      });
      try {
        state.errorMsgs = [];
        await Promise.all(promises);
        for (const [i, tag] of reqs) {
          tag.isSelected = state.tags[i].isSelected;
          tag.isPartiallySelected = state.tags[i].isPartiallySelected;
          state.tags[i] = tag;
        }
        const selectedTags = state.tags.filter(e => {
          return e.isSelected;
        }).map(e => {
          const res = Object.assign({}, e);
          delete res['isSelected'];
          return res;
        });
        emit('input', selectedTags);
        emit('close', selectedTags);
      } catch (e) {
        state.errorMsgs = getErrorMessages(e as AxiosError);
      }
    };

    return {
      ...toRefs(state),
      // computed
      systemTags,
      userTags,
      // methods
      isChoukiHozonTag,
      selectedStatus,
      addTag,
      removeTag,
      onClose,
    };
  },
});
