























































































import ThreeAxisSensorChart from '@/components/lib/ThreeAxisSensorChart/index.vue';
import {
  computed,
  defineComponent,
  nextTick,
  PropType,
  reactive,
  ref,
  toRefs,
  watch,
} from '@vue/composition-api';
import { MovieGeoIndex } from '@/models/apis/movie/movieResponse';
import { SensorData } from '@/models/apis/mtx/mtxResponse';
import { GIMovie } from '@/models/geoItem';
import { dtFormat, ensureDate } from '@/lib/dateHelper';

interface MovieGeoIndexLocal extends MovieGeoIndex {
  ts: Date;
  locationDisp: string;
}

interface MovieMetaInfoAreaState {
  isEditingTime: boolean;
  showSensorChart: boolean;
  dateDisp: string;
  timeDisp: string;
  locationDisp: string;
  velocityDisp: string;
}

export default defineComponent({
  name: 'movie-meta-info-area',
  props: {
    viewMode: {
      type: String,
      default: 'full',
    },
    movie: {
      type: Object as PropType<GIMovie>,
      default: () => { return {}; },
    },
    movieGeoIndex: {
      type: Object as PropType<MovieGeoIndexLocal>,
      default: () => { return {}; },
    },
    movieCurrentDt: {
      type: Date,
    },
    enableSensorChart: {
      type: Boolean,
      default: true,
    },
    enableTimeSeek: {
      type: Boolean,
      default: true,
    },
    cornerLabelOpacity: {
      type: Number,
      default: 1.0,
    },
  },
  setup(props, { emit }) {
    const state = reactive<MovieMetaInfoAreaState>({
      isEditingTime: false,
      showSensorChart: false,
      dateDisp: '',
      timeDisp: '',
      locationDisp: '',
      velocityDisp: '',
    });

    watch(() => props.movieGeoIndex, () => {
      const mgi = props.movieGeoIndex;
      if (!mgi) {
        state.dateDisp = '';
        state.locationDisp = '';
        state.velocityDisp = '---';
        return;
      }

      state.dateDisp = dtFormat(mgi.ts, 'yyyy/mm/dd');
      state.locationDisp = mgi.locationDisp;
      const velo = parseInt(mgi.velocity ?? '');
      state.velocityDisp = isNaN(velo) ? '---' : velo.toString();
    });

    watch(() => props.movieCurrentDt, () => {
      if (!props.movieCurrentDt) {
        state.timeDisp = '';
        return;
      }
      state.timeDisp = dtFormat(props.movieCurrentDt, 'HH:MM:SS');
    });

    // computed:
    const isViewModeFull = computed(() => {
      return props.viewMode === 'full';
    });
    const isViewModeComp = computed(() => {
      return props.viewMode === 'comp';
    });
    const isValidTimeDispValue = computed(() => {
      const arr = state.timeDisp.split(':')
        .filter(e => e.match(/^\d\d$/)).map(e => parseInt(e));
      if (arr.length !== 3) { return false; }
      const hour = arr[0];
      const minute = arr[1];
      const second = arr[2];
      if (
        0 <= hour && hour < 24 &&
        0 <= minute && minute < 60 &&
        0 <= second && second < 60
      ) {
        return true;
      }
      return false;
    });
    const movieTags = computed(() => {
      if (!props.movie) { return []; }
      return props.movie.tags;
    });
    const canCopyLink = computed(() => {
      return !!window.navigator.clipboard;
    });
    const copyLinkTooltip = computed(() => {
      return canCopyLink.value ? '' : 'http環境ではこの機能は使えません';
    });

    // methods:
    const refSensorChart = ref<InstanceType<typeof ThreeAxisSensorChart>>();
    const setSensorData = (sensorData: SensorData[]) => {
      if (!refSensorChart.value) { return; }
      refSensorChart.value.updateChartData(sensorData);
    };
    const setAllUIStateToDefault = () => {
      state.isEditingTime = false;
      state.showSensorChart = false;
      setSensorData([]);
    };

    let timeDispOrig = '';
    const startTimeEdit = () => {
      timeDispOrig = state.timeDisp;
      state.isEditingTime = true;
      emit('start-time-edit');
    };
    const cancelTimeEdit = () => {
      state.timeDisp = timeDispOrig;
      state.isEditingTime = false;
      emit('cancel-time-edit');
    };
    const moveToEditedTime = () => {
      if (!state.isEditingTime || !isValidTimeDispValue.value) { return; }
      const dateDisp = state.dateDisp;
      const timeDisp = state.timeDisp;
      const dt = ensureDate(`${dateDisp} ${timeDisp}`);
      emit('move-to-time', { dt, dateDisp, timeDisp });
      if (document.activeElement) {
        // time-inputにフォーカスが残ったままになるのが嫌なので
        (document.activeElement as HTMLElement).blur();
      }
      nextTick(() => {
        state.isEditingTime = false;
      });
    };
    const editMovieTags = () => {
      emit('edit-movie-tags');
    };
    const openSensorChart = () => {
      state.showSensorChart = true;
      if (refSensorChart.value && !refSensorChart.value.hasSensorData) {
        emit('try-show-new-sensor-chart');
      }
    };
    const closeSensorChart = () => {
      state.showSensorChart = false;
    };
    const onSensorChartClick = (dt: Date) => {
      if (!dt) { return; }

      dt = new Date(dt);
      const dateDisp = dtFormat(dt, 'yyyy/mm/dd');
      const timeDisp = dtFormat(dt, 'HH:MM:SS');
      emit('move-to-time', { dt, dateDisp, timeDisp });
    };
    const copyLink = () => {
      const dt = ensureDate(`${state.dateDisp} ${state.timeDisp}`);
      if (!props.movie.ts || !dt) {
        return;
      }
      const offset = dt.getTime() - props.movie.ts.getTime();
      const link = `${location.origin}/?movie_id=${props.movie.id}&start_offset=${offset}`;
      window.navigator.clipboard.writeText(link);
    };

    return {
      ...toRefs(state),
      // refs
      refSensorChart,
      // computed:
      isViewModeFull,
      isViewModeComp,
      isValidTimeDispValue,
      movieTags,
      canCopyLink,
      copyLinkTooltip,
      // methods:
      setAllUIStateToDefault,
      startTimeEdit,
      cancelTimeEdit,
      onSensorChartClick,
      moveToEditedTime,
      editMovieTags,
      openSensorChart,
      closeSensorChart,
      setSensorData,
      copyLink,
    };
  },
  components: {
    ThreeAxisSensorChart,
  },
});
