import axios, { AxiosResponse } from 'axios';
import * as utils from './apiUtils';
import { AdminGroup2IndexResponse, AdminGroup2, AdminGroup2DestroyResponse } from 'src/models/apis/group2/adminGroup2Response';
import { AdminGroup2Params } from 'src/models/apis/group2/adminGroup2Request';

export default {
  adminIndex(): Promise<AxiosResponse<AdminGroup2IndexResponse>> {
    return axios.get(utils.getApiUrl('/admin/groups2'));
  },
  adminCreate(params: AdminGroup2Params): Promise<AxiosResponse<AdminGroup2>> {
    return axios.post(utils.getApiUrl('/admin/groups2'), params);
  },
  adminUpdate(id: number, params: AdminGroup2Params): Promise<AxiosResponse<AdminGroup2>> {
    return axios.put(utils.getApiUrl(`/admin/groups2/${id}`), params || {});
  },
  adminDestroy(id: number): Promise<AxiosResponse<AdminGroup2DestroyResponse>> {
    return axios.delete(utils.getApiUrl(`/admin/groups2/${id}`));
  },
};
