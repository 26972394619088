





















































import {
  defineComponent,
  onMounted,
  PropType,
  toRefs,
  watch,
  computed,
  reactive,
  toRef,
} from '@vue/composition-api';
import extJunkaiTenkenReportTenkenDataApi from '@/apis/extJunkaiTenkenReportTenkenData';
import { MapElemInfo } from '@/models';
import useSwitchGeoItem from '@/composables/useSwitchGeoItem';
import useSavedImage from '@/composables/useSavedImage';
import { dtFormat } from '@/lib/dateHelper';
import { GITenkenData } from '@/models/geoItem';

interface MapElemInfoJunkaiTenkenReportTenkenDataState {
  tenkenData: GITenkenData;
}

export default defineComponent({
  name: 'map-elem-info-junkai-tenken-report-tenken-data',
  props: {
    elem: {
      type: Object as PropType<MapElemInfo<GITenkenData>>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const state = reactive<MapElemInfoJunkaiTenkenReportTenkenDataState>({
      tenkenData: props.elem.data,
    });
    const { elem } = toRefs(props);
    const { showPrevious, showNext } = useSwitchGeoItem(emit, elem);

    const skipFetchingImage = computed<boolean>(() => {
      return !state.tenkenData?.photo_ids || state.tenkenData.photo_ids.length === 0;
    });
    const modalTitle = computed<string>(() => `${state.tenkenData.inspection_date} ${state.tenkenData.inspection_time} - ${state.tenkenData.landmark_disp}`);
    const downloadFilenameTpl = computed<string>(() => {
      const tenkenDataTs = dtFormat(state.tenkenData.inspection_date, 'yyyymmddHHMMSS');
      return `${state.tenkenData.id}_${tenkenDataTs}_$TIMESTAMP.jpg`;
    });
    const params = {
      refMapElemInfoData: toRef(state, 'tenkenData'),
      emit,
      skipFetchingImage,
      fetchApiFn: () => {
        // photo_idsから最初の1件のみ画面に表示する
        return extJunkaiTenkenReportTenkenDataApi.getImage(state.tenkenData.photo_ids[0]);
      },
      modalTitle,
      downloadFilenameTpl,
    };
    const { savedImage, showImage } = useSavedImage(params);

    const initDisplay = () => {
      state.tenkenData = props.elem.data;
      state.tenkenData.landmark_disp = [
        state.tenkenData.road_name,
        state.tenkenData.direction,
        state.tenkenData.place_name,
        state.tenkenData.landmark_number,
      ].filter(e => !!e).join(' ');
    };

    onMounted(initDisplay);

    watch(() => props.elem, initDisplay);

    const iconPath = '/static/img/junkai_tenken_report_pin.png';
    const openJunkaiTenkenReport = () => {
      window.open(state.tenkenData.tenken_data_link, '_blank');
    };

    return {
      ...toRefs(state),
      savedImage,
      openJunkaiTenkenReport,
      showImage,
      iconPath,
      showPrevious,
      showNext,
    };
  },
});
