

























import {
  defineComponent,
  computed,
  ref,
  reactive,
  toRefs,
} from '@vue/composition-api';
import { downloadObjectUrl } from '@/lib/downloadHelper';
import { dtFormat } from '@/lib/dateHelper';

interface ModalImageViewState {
  maxWidthStr: string | null;
}

export default defineComponent({
  name: 'modal-image-view',
  props: {
    title: {
      type: String,
      default: '',
    },
    imageSrc: {
      type: String,
      required: true,
    },
    downloadFilenameTpl: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const refImg1 = ref<HTMLImageElement>();
    const state = reactive<ModalImageViewState>({
      maxWidthStr: null,
    });

    const onImageLoad = () => {
      // DOMの表示上のサイズを取得する
      const imgDispWidth = refImg1.value?.width || 0;
      const imgDispHeight = refImg1.value?.height || 0;
      if (imgDispWidth <= imgDispHeight) {
        state.maxWidthStr = null;
        return;
      }
      // 画像が横長の場合は横幅のmaxを広げる
      state.maxWidthStr = '75%';
    };
    const trimmedTitle = computed<string>(() => {
      const isWideMode = state.maxWidthStr !== null;
      const maxLen = isWideMode ? 46 : 22;
      return props.title.length > maxLen
        ? `${props.title.slice(0, maxLen)}...`
        : props.title;
    });
    const downloadImage = () => {
      const timestamp = dtFormat(new Date(), 'yyyymmddHHMMSS');
      const downloadFilename = props.downloadFilenameTpl.replace(/\$TIMESTAMP/, timestamp);
      downloadObjectUrl(props.imageSrc, downloadFilename);
    };
    const onClose = () => {
      emit('close');
    };

    return {
      ...toRefs(state),
      refImg1,
      trimmedTitle,
      onImageLoad,
      downloadImage,
      onClose,
    };
  },
});
