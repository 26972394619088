import { Location } from 'src/models';
import * as sphere from 'ol/sphere';

/**
   * pt1からpt2に向かったときの真北との角度差をdegで返す.
   * 範囲は-180から180度、真北から東側がプラス、西側がマイナス.
   * https://www.movable-type.co.uk/scripts/latlong.html
   *
   * yは経度方向の差分を、緯度による半径の変化も考慮して算出したもの.
   * (緯度が高くなれば半径が小さくなるが、その変化はcos(lat1)かcos(lat2)で算出できるはず)
   * xはpt2の緯度方向ベクトル(sin(lat2)*補正)からpt1の緯度方向ベクトル(sin(lat1)*補正)を引いているのだと思うが、よくわからない
   */
export function toRadians(angleInDegrees: number): number {
  return angleInDegrees * Math.PI / 180;
}
export function toDegrees(angleInRadians: number): number {
  return angleInRadians * 180 / Math.PI;
}
export function getBearing(pt1: Location, pt2: Location): number {
  const lat1 = toRadians(pt1.lat);
  const lon1 = toRadians(pt1.lon);
  const lat2 = toRadians(pt2.lat);
  const lon2 = toRadians(pt2.lon);
  const y = Math.sin(lon2 - lon1) * Math.cos(lat2);
  const x = Math.cos(lat1) * Math.sin(lat2) -
    Math.sin(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1);
  // from -180 to 180 degrees
  return toDegrees(Math.atan2(y, x));
}

// 地図上の2点間の距離(m)を計算して返す.
export function getDistanceInMeters(start: Location, end: Location): number {
  const c1 = [ start.lat, start.lon ];
  const c2 = [ end.lat, end.lon ];
  return sphere.getDistance(c1, c2);
}
